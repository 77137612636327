import React, { Component } from 'react';
import { GetLabel, HeadersSize } from '../constants';
import { Subheader, Loading } from './';

class LoadingScreen extends Component{
	render() {
		return (
			<div style={css.container}>
				<Subheader text={GetLabel('loading')} labelStyle={{fontSize: HeadersSize}}/>
				<Loading />
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
    	width: '100%',
	},
}

export { LoadingScreen };
