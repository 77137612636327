import React, { Component } from 'react';
import { Colors, ButtonSize, GetObjectKey, IsNullOrEmpty,
	Clone, SmallButtonSize, GetHighestIdFromObjectsList,
} from '../constants';
import { Label, Button, Image, InputField } from './';
import './MinutaListEditor.css';

class MinutaListEditor extends Component {

	state = {
		isLoading: false,
		newItem: '',
	}

	componentDidMount() {
		console.log('MinutaListEditor mounted!');
	}

	deleteItemFromList(key) {
		let list = (this.props.list === undefined || this.props.list === null) ? 
			{} : this.props.list;

		if(this.props.onModify) {
			let newList = {...list};
			delete newList[key];
			this.props.onModify(newList);
		}
	}

	addItemToList() {
		let list = IsNullOrEmpty(this.props.list) ? {} : this.props.list;

		if(this.props.onModify) {
			let newList = {...list};

			let newId = IsNullOrEmpty(this.props.list) ? 0 : GetHighestIdFromObjectsList(newList) + 1;
			let newObject = {...this.props.baseObject, ...{id: newId, name: this.state.newItem}}
			let newKey = GetObjectKey('item', newObject);
			newList[newKey] = {...newObject};
			
			// console.log('newList: ',newList);

			this.props.onModify(newList);
			this.setState({newItem: ''});
		}
	}

	dragStart(event, key) {
		event.dataTransfer.setData('text', this.props.keyIndex+'_'+key);
	}

	renderAddedItem(item, key) {
		return (
			<div style={css.listContainer} key={key} draggable={true} onDragStart={(event)=> this.dragStart(event, key)}
				className={'minutaListElement'}>
				{/*<Label text={item.name} style={{width: '100%', marginLeft: '0'}}/>*/}
				<Image src={'img/sys/menu.png'} color={Colors.primary} height={`calc(${ButtonSize} * 0.7)`} width={ButtonSize}/>
				<InputField 
					placeholder={item.name} value={item.name}
					inputStyle={{marginTop: '0px', marginBottom: '0px'}}
					onChange={ (newValue) => {
						let list = IsNullOrEmpty(this.props.list) ? {} : this.props.list;
						let newList = Clone(list);
						newList[key].name = newValue;
						this.props.onModify(newList);
					}}
					/>
				<div style={css.buttonsContainer}>
					<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.deleteItemFromList(key)}}>
						<Image src={'img/sys/delete.png'} color={Colors.red} height={ButtonSize} width={ButtonSize}/>
					</Button>
				</div>
			</div>
		);
	}

	listOrderChanged(fromIndex, toIndex) {
		let list = IsNullOrEmpty(this.props.list) ? {} : this.props.list;
		let newList = Clone(list);
		let newListAsArray = [];

		let fromIndexMainKey = fromIndex.split('_')[0];
		fromIndex = fromIndex.split('_')[1];

		let toIndexMainKey = toIndex.split('_')[0];
		toIndex = toIndex.split('_')[1];

		if(fromIndexMainKey !== toIndexMainKey) {
			console.log('different parents, DO NOTHING');
			return;
		}

		Object.keys(newList).forEach(key => {
			newListAsArray.push(Clone(newList[key]));
		});

		// console.log('fromIndex: ',fromIndex);
		// console.log('toIndex: ',toIndex);

		toIndex = (toIndex > fromIndex) ? toIndex-1 : toIndex;

		let itemToMove = newListAsArray[fromIndex];
		newListAsArray.splice(fromIndex, 1);//Elimina el indice
		newListAsArray.splice(toIndex, 0, itemToMove);//Mueve el indice

		this.props.onModify(newListAsArray);
	}

	renderAddedItems(list) {
		let { keyIndex } = this.props;
		// keyIndex = 'minutaListElement_'+keyIndex;
		let jsx = [];
		// let counter = 0;

		// https://www.html5rocks.com/es/tutorials/dnd/basics/
		// https://www.w3schools.com/html/tryit.asp?filename=tryhtml5_draganddrop
		// https://www.w3schools.com/html/tryit.asp?filename=tryhtml5_draganddrop2
		// https://www.w3schools.com/html/html5_draganddrop.asp

		Object.keys(list).forEach((key, i) => {
			jsx.push(<div onDragOver={(event) => event.preventDefault()} 
				style={css.dropTarget}
				onDragLeave={(event) => {
					event.target.style.background = 'transparent';
				}}
				onDrop={(event) => {
					event.preventDefault();
					event.target.style.background = 'transparent';
					let fromIndex = event.dataTransfer.getData('text');
  					// ev.target.appendChild(document.getElementById(data));
					// console.log('Move item here: ',event.dataTransfer);

					// console.log('fromIndex moved: ',fromIndex);
					// console.log('container: ',i);
					// console.log('keyIndex: ',keyIndex);
					this.listOrderChanged(fromIndex, this.props.keyIndex+'_'+i);
				}}
				onDragEnter={(event) => {
					event.target.style.background = Colors.primary+'30';
				}}/>);
			// counter++;
			jsx.push(this.renderAddedItem(list[key], key));
		});
		jsx.push(<div onDragOver={(event) => event.preventDefault()} 
			style={css.dropTarget}
			onDragLeave={(event) => {
				event.target.style.background = 'transparent';
			}}
			onDrop={(event) => {
				event.preventDefault();
				event.target.style.background = 'transparent';
				let fromIndex = event.dataTransfer.getData('text');
				let i = Object.keys(list).length;
				
				// console.log('data moved: ',fromIndex);
				// console.log('container: ',i);
				// console.log('keyIndex: ',keyIndex);
				this.listOrderChanged(fromIndex, this.props.keyIndex+'_'+i);
			}}
			onDragEnter={(event) => {
				event.target.style.background = Colors.primary+'30';
			}}/>);
		// counter++;
		return jsx;
	}

	renderMoveHandleIfNeeded() {
		return undefined;
	}

	renderAddButtonIfNeeded() {
		if(IsNullOrEmpty(this.state.newItem))
			return <div />;
		else
			return (
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.addItemToList()}}>
					<Image src={'img/sys/add.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			);
	}

	render() {
		let list = (this.props.list === undefined || this.props.list === null) ? {} : this.props.list;
		//console.log('list to render: ',list);
		return (
			<div style={{...css.container, ...this.props.style}}>	
				{ this.renderAddedItems(list) }
				<div style={css.adderSectionContainer}>
					<InputField onChange={ (newValue) => {this.setState({ newItem: newValue })} }
						placeholder={this.props.placeholder} value={this.state.newItem}/>
					{ this.renderAddButtonIfNeeded() }
				</div>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: '100%',
	},
	dropTarget: {
		background: 'transparent',
		width: '100%',
		height: '1.5rem',
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
	}, 
	adderSectionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
	}
}

export { MinutaListEditor };
