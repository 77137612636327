import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize, 
	UpdateUsers, Clone, DirtyStyle, IsNullOrEmpty, ObjectToArrayOfObjects, ObjectToArray,
	MediumButtonSize, SelectFile, Sleep, SubHeadersSize, GetAllDatabaseProjects } from '../../constants';
import { Header, ConfirmationButtons, Label, Subheader, WideButton, InputField, ListEditor,
	Div, Expansible, Space, DropDown, SeparatorLarge, Profile, Image, Checkbox } from '../../components';
import { UploadFile } from '../../firebase';

class UsersEditor extends Component {

	state = {
		isLoading: false,
		isDirty: false,
		userIdBeingEdited: '',
		users: {},
		isPhotoDirty: false,
		imageURL: '',
		imageContent: '', 
		imageFile: '',
	}

	saveChanges(onSuccess) {
		let { isDirty, isPhotoDirty, userIdBeingEdited } = this.state;
		this.setState({isLoading: true});

		let usersClone = Clone(this.state.users);
		onSuccess = (onSuccess) ? onSuccess : ()=>{};

		if(isPhotoDirty) {
			UploadFile(
				'profiles/'+userIdBeingEdited, 
				this.state.imageFile,
				(imageURL) => { 
					//console.log('saveChanges> usersClone: ',usersClone);
					usersClone[userIdBeingEdited].image = imageURL;
					UpdateUsers(usersClone,
						() => {this.clearDirtyState(); onSuccess();}, 
						() => this.setState({ isLoading: false }));
				}, 
				() => { this.setState({ isLoading: false }); }
			);
		}
		else {
			UpdateUsers(usersClone,
				() => {this.clearDirtyState(); onSuccess();}, 
				() => this.setState({ isLoading: false }));
		}
	}

	clearDirtyState() {
		this.setState({
			isLoading: false,
			isDirty: false,
			users: {},
			isPhotoDirty: false,
			imageURL: '',
			imageContent: '', 
			imageFile: '',
		});
	}

	renderSaveButtonIfDirty() {
		let { isDirty, isPhotoDirty } = this.state;
		
		if(isDirty || isPhotoDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	usersModified(key, modificationObject) {
		let usersClone = {};
		if(this.state.isDirty) 
			usersClone = Clone(this.state.users);
		else{
			this.setState({
				isDirty: true,
				users: Clone(global.users)
			});
			usersClone = Clone(global.users);
		}
		usersClone[key].rank = IsNullOrEmpty(modificationObject.rank) ? usersClone[key].rank : modificationObject.rank;
		usersClone[key].domainName = IsNullOrEmpty(modificationObject.domainName) ? usersClone[key].domainName : modificationObject.domainName;
		usersClone[key].name = (modificationObject.name === undefined || modificationObject.name === null) ? usersClone[key].name : modificationObject.name;
		usersClone[key].isDisabled = (modificationObject.isDisabled === undefined || modificationObject.isDisabled === null) ? usersClone[key].isDisabled : modificationObject.isDisabled;
		usersClone[key].isHidden = (modificationObject.isHidden === undefined || modificationObject.isHidden === null) ? usersClone[key].isHidden : modificationObject.isHidden;
		usersClone[key].projectAccess = (modificationObject.projectAccess === undefined || modificationObject.projectAccess === null) ? usersClone[key].projectAccess : modificationObject.projectAccess;

		//console.log('usersClone: ',usersClone);
		this.setState({users: usersClone});
	}

	async deleteUser(key) {
		let usersClone = Clone(global.users);
		delete usersClone[key];
		this.setState({users: usersClone});
		await Sleep(10);
		this.saveChanges(() => {
			this.setState({userIdBeingEdited: ''});
		});
	}

	selectFile(key) {
		SelectFile((selectedFile, content, imageURL) => {
			this.usersModified(key, {});
			this.setState({
				isPhotoDirty: true, 
				imageURL: imageURL,
				imageContent: content, 
				imageFile: selectedFile,
			});
		});
	}

	renderUser(user) {
		if(IsNullOrEmpty(user))
			return <div />;

		let name = (user.name === null || user.name === undefined) ? user.email : user.name;
		let image = (this.state.isPhotoDirty) ? this.state.imageURL : user.image;
		let isHidden = (user.isHidden === null || user.isHidden === undefined) ? false : user.isHidden;
		let isDisabled = (user.isDisabled === null || user.isDisabled === undefined) ? false : user.isDisabled;
		let projectAccess = (this.state.isDirty) ? this.state.users[user.id].projectAccess : global.users[user.id].projectAccess;
		console.log(projectAccess);

		return (
			<div key={user.id}>
				<SeparatorLarge color={Colors.primary}/>
				<Space/>
				<div style={css.sameRow}>
					<div style={{flex: '0.65'}}>
						<Profile image={image} name={''} labelStyle={css.name} style={css.profile} disableHover={true}
							event={{id: 'event_00000', onClick: () =>  { this.selectFile(user.id) } }}>
							<Image src={'img/sys/edit.png'} color={Colors.primary} height={MediumButtonSize} width={MediumButtonSize} 
								style={css.editIcon}/>
						</Profile>

						<Subheader text={GetLabel('name')} labelStyle={{color: Colors.secondary}}/>
						<InputField onChange={ (newValue) => {this.usersModified(user.id, {name: newValue})} }
							placeholder={GetLabel('name')} value={name}/><Space />

						<Subheader text={GetLabel('email')} labelStyle={{color: Colors.secondary}}/>
						<InputField onChange={ (newValue) => {} } disabled={true}
							placeholder={GetLabel('email')} value={user.email}/><Space />

						<Subheader text={GetLabel('rank')} labelStyle={{color: Colors.secondary}}/>
						<DropDown 
							placeholder={GetLabel('rank')}
							value={user.rank} 
							containerStyle={{flex: '0.65'}}
							concatenatedString={''}
							options={ObjectToArrayOfObjects(global.ranks)}
							optionsAreObjects={true}
							editable={true}
							event={{
								id: 'event_00000', 
								onClick: (newRank) => this.usersModified(user.id, {rank: newRank})
							}}/>
						<Space/>
						<Subheader text={GetLabel('domainName')} labelStyle={{color: Colors.secondary}}/>
						<DropDown 
							placeholder={GetLabel('domainName')}
							value={user.domainName} 
							containerStyle={{flex: '0.65'}}
							concatenatedString={''}
							options={ObjectToArrayOfObjects(global.domainNames)}
							optionsAreObjects={true}
							editable={true}
							event={{
								id: 'event_00000', 
								onClick: (newDomainName) => this.usersModified(user.id, {domainName: newDomainName})
							}}/>
						<Space/>

						<Checkbox event={{id: 'event_00000', onClick: (value) => this.usersModified(user.id, {isHidden: value})}} 
							style={{margin: '0em', padding: '0em'}}
							checked={isHidden}>
							<Label text={GetLabel('hideUser')} labelStyle={{fontSize: SubHeadersSize}}/>
						</Checkbox>

						<Checkbox event={{id: 'event_00000', onClick: (value) => this.usersModified(user.id, {isDisabled: value})}} 
							style={{margin: '0em', padding: '0em'}}
							checked={isDisabled}>
							<Label text={GetLabel('disableUser')} labelStyle={{fontSize: SubHeadersSize}}/>
						</Checkbox>

						{/*<Subheader text={GetLabel('selectProjectsAccess')}/>*/}
						
						<ListEditor 
							isObject={true}
							itemStyle={{color: Colors.secondary}}
							options={this.getProjectsAccessRemovingCurrentlySelected(projectAccess)}
							list={projectAccess} 
							placeholder={GetLabel('projectAccess')} 
							eventId={'event_00006'} 
							onModify={(newList) => this.usersModified(user.id, {projectAccess: newList})}/>
						
						<WideButton text={GetLabel('deleteUser')} style={{
								backgroundColor: Colors.red+'50',
								border: `2px solid ${Colors.red}`,
							}}
							iconColor={Colors.red}
							labelStyle={{color: '#ffffff'}}
							event={{ id: 'event_00000', onClick:() => {
								let confirmation = window.confirm(GetLabel('deleteUserConfirmation'));
  								if(confirmation) {
  									this.deleteUser(user.id)
  								}
							}, icon: 'img/sys/delete.png'}} />
					</div>
				</div>
			</div>
		);
	}

	getProjectsAccessRemovingCurrentlySelected(projectAccess) {
		let allProjectsInDatabase = GetAllDatabaseProjects();

		let allAvailableProjectAccess = [{name: GetLabel('all'), id: 'all'}];//['all'];//['All', 'None', 'ProjectA', 'ProjectB', 'Etc...'];//GetAllSelectableRanks();

		allProjectsInDatabase.forEach((project) => {
			allAvailableProjectAccess.push({name: project.name, id: project.id/*GetProjectKey(project)*/})
		});

		let filtered = [];
		projectAccess = IsNullOrEmpty(projectAccess) ? [] : projectAccess;
		allAvailableProjectAccess.forEach(proj => {
			if(!projectAccess.includes(proj))
				filtered.push(proj);
		});
		return filtered;
	}

	getUsersArray() {
		let usersArray = ObjectToArray(global.users);
		
		usersArray.sort((a, b) => {
			let aName = GetLabel(a.name);
			let bName = GetLabel(b.name);

			if (aName > bName)
		        return 1;
		    if (bName > aName)
		        return -1;
		    return 0;
		});

		return usersArray;
	}

	render() {

		let users = (this.state.isDirty) ? this.state.users : global.users;

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<Header text={GetLabel('manageUserAccounts')} 
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					
					<Subheader text={GetLabel('selectAnUserToModify')}/>
					<DropDown 
						placeholder={GetLabel('userToModify')}
						value={this.state.userIdBeingEdited} 
						concatenatedString={''}
						options={this.getUsersArray()}
						optionsAreObjects={true}
						editable={!this.state.isLoading}
						event={{
							id: 'event_00000', 
							onClick: (userId)=> {
								if(!this.state.isLoading) {
									this.clearDirtyState();
									this.setState({userIdBeingEdited: userId});	
								}
							}
						}}/>
					<Space /><Space />


					{
						this.renderUser(users[this.state.userIdBeingEdited])
						//Object.keys(users).map(user => {
						//	return this.renderUser(users[user]);
						//}) 
					}

					{ this.renderSaveButtonIfDirty() }

					<Space /><Space /><Space /><Space />
					<Space /><Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	sameRow: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: '7em',
	},
	profile: {
		background: Colors.transparent
	},
	editIcon: {
		position: 'absolute',
		left: 'calc(100vw - 50vw + 1em)',
		marginTop: '0.5em',
	},
}

export { UsersEditor };
