export function GetDeviceLanguage() {
	return 'es';
}

export function GetLabel(key) {
	var language = GetDeviceLanguage();
	var label = global.labels[key];
	if(label === undefined)
		return key;
	else
		return label[language];
}

export function GetFullMonth(number) {
	let calendarLabels = GetLabel('calendarLabels');
	return calendarLabels.monthNames[number-1];
}

export function GetShortMonth(number) {
	let calendarLabels = GetLabel('calendarLabels');
	return calendarLabels.monthNamesShort[number-1];
}

export function SortLabelsAlphabetically() {
	let sortedLabels = Object.keys(global.labels).sort().reduce(function (acc, key) {
        acc[key] = global.labels[key];
        return acc;
    }, {});
    global.labels = {...sortedLabels};
}

global.labels = {
	calendarLabels: {
		en: {
			dayNames: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			dayNamesShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
			monthNames: ['January','February','March','April','May','June','July','August','September','October','November','December'],
			monthNamesShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
		},
		es: {
			dayNames: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
			dayNamesShort: ['Dom','Lun','Mar','Mié','Jue','Vie','Sáb'],
			monthNames: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
			monthNamesShort: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
		}
	},
	email: {
		en: 'Email',
		es: 'Correo electrónico'
	},
	username: {
		en: 'Username',
		es: 'Usuario'
	},
	password: {
		en: 'Password',
		es: 'Contraseña'
	},
	disclaimer: {
		en: 'For internal use only. Grupo Bimbo',
		es: 'Para uso interno exclusivo de Grupo Bimbo'
	},
	login: {
		en: 'Log in',
		es: 'Iniciar sesión'
	},
	myProfile: {
		en: 'My profile',
		es: 'Mi perfil'
	},
	portfolio: {
		en: 'Projects portfolio',
		es: 'Portafolio de proyectos'
	},
	projects: {
		en: 'Projects',
		es: 'Proyectos'
	},
	kpis: {
		en: 'Numeralia',
		es: 'Numeralia'
	},
	toDo: {
		en: 'To do list',
		es: 'Minuta'
	},
	citadelle: {
		en: 'Citadelle',
		es: 'Citadelle'
	},
	pendings: {
		en: 'Pending',
		es: 'Pendientes'
	},
	dsm: {
		en: 'DSM',
		es: 'DSM'
	},
	rios: {
		en: 'RIOS',
		es: 'RIOS'
	},
	commitmentsGtd: {
		en: 'Commitments GTD',
		es: 'Compromisos GTD'
	},
	visits: {
		en: 'Visits',
		es: 'Visitas'
	},
	preadvice: {
		en: 'Pre-advice',
		es: 'Preconsejo'
	},
	startDate: {
		en: 'Start date',
		es: 'Inicio'
	},
	endDate: {
		en: 'End date',
		es: 'Final'
	},
	retrospectivesReport: {
		en: 'Retrospectives report',
		es: 'Reporte de retrospectivas'
	},
	ideators: {
		en: 'Ideators',
		es: 'Ideadores'
	},
	signOut: {
		en: 'Sign out',
		es: 'Cerrar sesión'
	},
	domainName: {
		en: 'Domain name',
		es: 'Nivel de dominio'
	},
	projectLeaderIn: {
		en: 'Project Owner in: ',
		es: 'Project Owner en:'
	},
	scrumMasterIn: {
		en: 'Scrum master in:',
		es: 'Scrum master en:'
	},
	quarterbackIn: {
		en: 'Quarterback in:',
		es: 'Quarterback en:'
	},
	manageUserAccounts: {
		en: 'Manage user accoounts',
		es: 'Administrar usuarios'
	},
	emailSent: {
		en: '[Email sent]',
		es: '[Email enviado]'
	},
	timeElapsed: {
		en: 'Time elapsed',
		es: 'Tiempo transcurrido'
	},
	theCitadelleServerIsNotRunning: {
		en: 'The citadelle server is not running... Please execute the citadelle search console',
		es: 'El servidor de Citadelle se encuentra apagado, favor de iniciarlo desde el icono del escritorio y reiniciar esta ventana o presionar F5 una vez iniciado...'
	},
	retrospectivesFactories: {
		en: 'Retrospectives factories',
		es: 'Retrospectivas fábricas'
	},
	retrospectivesCapabilities: {
		en: 'Retrospectives capabilities',
		es: 'Retrospectivas capabilities'
	},
	factoriesIdeas: {
		en: 'Ideas factory',
		es: 'Fábrica de ideas'
	},
	factoriesSolutions: {
		en: 'Solutions factory',
		es: 'Fábrica de soluciones'
	},
	factoriesEntrepreneurs: {
		en: 'Entrepreneurs factory',
		es: 'Fábrica de emprendedores'
	},
	factoryLeader: {
		en: 'Factory leader',
		es: 'Líder de fábrica'
	},
	generalActivities: {
		en: 'General activities',
		es: 'Actividades generales'
	},
	itActivity: {
		en: 'IT Activity',
		es: 'Actividad IT'
	},
	leader: {
		en: 'PO',
		es: 'PO'
	},
	support: {
		en: 'Support',
		es: 'Soporte'
	},
	scrumMaster: {
		en: 'Scrum Master',
		es: 'Scrum Master'
	},
	deadline: {
		en: 'Deadline',
		es: 'Deadline'
	},
	kickOff: {
		en: 'Kick off',
		es: 'Fecha de inicio'
	},
	balanceScore: {
		en: 'Balance Score',
		es: 'Balance Score'
	},
	retrospectives: {
		en: 'Retrospectives',
		es: 'Retrospectivas'
	},
	unknownFactoryLeader: {
		en: 'Unknown: Factory leader',
		es: 'Desconocido: Líder de fábrica'
	},
	unknownFactoryScrumMaster: {
		en: 'Unknown: Factory scrum master',
		es: 'Desconocido: Scrum master de fábrica'
	},
	unknownFactoryIdeator: {
		en: 'Unknown: Factory ideator',
		es: 'Desconocido: Ideador de fábrica'
	},
	unknownProjectLeader: {
		en: 'Unknown: Project leader',
		es: 'Desconocido: Líder de proyecto'
	},
	unknownProjectQuarterback: {
		en: 'Unknown: Project quarterback',
		es: 'Desconocido: Quarterback de proyecto'
	},
	unknownProjectScrumMaster: {
		en: 'Unknown: Project scrum master',
		es: 'Desconocido: Scrum master de proyecto'
	},
	addNewIdeator: {
		en: 'Add new ideator',
		es: 'Agregar nuevo ideador'
	},
	removeLastIdeator: {
		en: 'Remove last ideator',
		es: 'Eliminar último ideador'
	},
	sprints: {
		en: 'Sprints',
		es: 'Sprints'
	},
	nextSteps: {
		en: 'Next sprints',
		es: 'Futuros sprints'
	},
	retrospective: {
		en: 'Retrospective',
		es: 'Retrospectiva'
	},
	retrospectiveScrumMaster: {
		en: 'Retrospective Scrum Master',
		es: 'Retrospectiva al Scrum Master'
	},
	retrospectiveScrumMaster1: {
		en: 'Has been available to the team when needed?',
		es: '¿Ha estado disponible para el equipo cuando se necesita?'
	},
	retrospectiveScrumMaster2: {
		en: 'His/her communication with the team has been efficient?',
		es: '¿Su comunicación con el equipo ha sido eficiente?'
	},
	retrospectiveScrumMaster3: {
		en: 'His/her knowledge and experience have been of benefit for the development of the project?',
		es: '¿Sus conocimientos y experiencia han sido de provecho para el desarrollo del proyecto?'
	},
	retrospectiveScrumMaster4: {
		en: 'He/she has been proactive when obstacles have arisen in the development of the project?',
		es: '¿Se ha mostrado proactiv@ cuando han surgido obstáculos en el desarrollo del proyecto?'
	},
	retrospectivePO: {
		en: 'Retrospective PO',
		es: 'Retrospectiva al PO'
	},
	retrospectivePO1: {
		en: 'Decisions were made seeking an agile development for the project?',
		es: '¿Se tomaron decisiones procurando un desarrollo ágil para el proyecto?'
	},
	retrospectivePO2: {
		en: 'The prioritization for the project has been appropriate?',
		es: '¿La priorización para con el proyecto ha sido la adecuada?'
	},
	retrospectivePO3: {
		en: 'Is he/she following a strategic vision and aligned with the objectives of the area?',
		es: '¿Está siguiendo una visión estratégica y alineada a los objetivos del área?'
	},
	retrospectiveQuarterback: {
		en: 'Retrospective Quarterback',
		es: 'Retrospectiva al Quarterback'
	},
	retrospectiveQuarterback1: {
		en: 'Has he/she been involved in the project according to its needs?',
		es: '¿Se ha involucrado en el proyecto conforme a las necesidades del mismo?'
	},
	retrospectiveQuarterback2: {
		en: 'Has his/her attitude and aptitude generated value to the team or to the development of the project?',
		es: '¿Sus actitudes y aptitudes han generado valor al equipo o al desarrollo del proyecto?'
	},
	retrospectiveQuarterback3: {
		en: 'Has he/she prioritized their responsibilities within the project efficiently?',
		es: '¿Ha priorizado sus responsabilidades en el proyecto de forma eficiente?'
	},
	capabilities: {
		en: 'Capabilities',
		es: 'Capabilities'
	},
	spanish: {
		en: 'Spanish',
		es: 'Español'
	},
	english: {
		en: 'English',
		es: 'Inglés'
	},
	both: {
		en: 'Both',
		es: 'Ambos'
	},
	considerSynonyms: {
		en: 'Considering synonyms',
		es: 'Considerando sinónimos'
	},
	exactPhrase: {
		en: 'Exact phrase',
		es: 'Frase exacta'
	},
	considerSynonyms: {
		en: 'Considering synonyms',
		es: 'Considerando sinónimos'
	},
	searchHere: {
		en: 'Search in citadelle...',
		es: 'Buscar en citadelle...'
	},
	searchProjectHere: {
		en: 'Search project here...',
		es: 'Busca por nombre del proyecto...'
	},
	colorPalette: {
		en: 'Color palette',
		es: 'Paleta de colores'
	},
	primary: {
		en: 'Primary',
		es: 'Principal'
	},
	primaryDarker: {
		en: 'Principal darker',
		es: 'Principal oscuro'
	},
	secondary: {
		en: 'Secondary',
		es: 'Secundario'
	},
	background_1: {
		en: 'Background 1',
		es: 'Fondo 1'
	},
	background_2: {
		en: 'Background 2',
		es: 'Fondo 2'
	},
	buttonBackground: {
		en: 'Buttons background',
		es: 'Fondo de botones'
	},
	green: {
		en: 'Green semaphore',
		es: 'Semáforo verde'
	},
	red: {
		en: 'Red semaphore',
		es: 'Semáforo rojo'
	},
	debugLabels: {
		en: 'Debug labels',
		es: 'Color para debug'
	},
	noScrumComments: {
		en: 'There are no monthly scrum comments...',
		es: 'Sin comentarios de scrum mensual...'
	},
	monthlyScrumComments: {
		en: 'Monthly scrum comments',
		es: 'Comentarios scrum mensual'
	},
	addMonthlyScrumComments: {
		en: 'Add comments...',
		es: 'Añadir comentarios...'
	},
	projectDetails: {
		en: 'Project details',
		es: 'Detalles del proyecto'
	},
	sprintsEmpty: {
		en: 'Sprints empty...',
		es: 'No hay sprints, agrega alguno con el botón +'
	},
	noSprintsInProject: {
		en: 'The project has no sprints...',
		es: 'El proyecto no tiene sprints...'
	},
	nextStepsEmpty: {
		en: 'Next steps empty...',
		es: 'No hay next steps, agrega alguno con el botón +'
	},
	seeFiles: {
		en: 'See files',
		es: 'Ver archivos'
	},
	quarterback: {
		en: 'Quarterback',
		es: 'Quarterback'
	},
	initialDeadline: {
		en: 'Initial deadline',
		es: 'Deadline inicial'
	},
	projectObjectives: {
		en: 'Project objectives',
		es: 'Objetivos del proyecto'
	},
	selectProjectsAccess: {
		en: 'Select project\'s access',
		es: 'Selecciona los accesos a proyectos'
	},
	projectAccess: {
		en: 'Access per project',
		es: 'Acceso a proyecto(s)'
	},
	pending: {
		en: 'Pending',
		es: 'Pending'
	},
	ideation: {
		en: 'Ideation',
		es: 'Ideation'
	},
	frame: {
		en: 'Frame',
		es: 'Frame'
	},
	understand: {
		en: 'Understand',
		es: 'Understand'
	},
	experiment: {
		en: 'Experiment',
		es: 'Experiment'
	},
	accelerate: {
		en: 'Accelerate',
		es: 'Accelerate'
	},
	scale: {
		en: 'Scale',
		es: 'Scale'
	},
	description: {
		en: 'Description',
		es: 'Descripción'
	},
	help: {
		en: 'Help!',
		es: 'Ayuda!'
	},
	helpNeeded: {
		en: 'Help needed',
		es: 'Necesita ayuda'
	},
	activities: {
		en: 'Activities',
		es: 'Actividades'
	},
	status: {
		en: 'Status',
		es: 'Estatus'
	},
	completed: {
		en: 'Completed',
		es: 'Cumplido'
	},
	inProcess: {
		en: 'In process',
		es: 'En proceso'
	},
	disabled: {
		en: 'Disabled',
		es: 'Deshabilitado'
	},
	cancelled: {
		en: 'Cancelled',
		es: 'No cumplido'
	},
	edit: {
		en: 'Edit',
		es: 'Editar'
	},
	files: {
		en: 'Project files',
		es: 'Archivos del proyecto'
	},
	sprintEditor: {
		en: 'Edit sprint details',
		es: 'Editar detalles del sprint'
	},
	projectEditor: {
		en: 'Edit project details',
		es: 'Editar detalles del proyecto'
	},
	save: {
		en: 'Save',
		es: 'Guardar'
	},
	previous: {
		en: 'Previous',
		es: 'Anterior'
	},
	next: {
		en: 'Next',
		es: 'Siguiente'
	},
	desirable: {
		en: 'Desirable',
		es: 'Desirable'
	},
	feasible: {
		en: 'Feasible',
		es: 'Feasible'
	},
	viable: {
		en: 'Viable',
		es: 'Viable'
	},
	saveScrumComments: {
		en: 'Save scrum comments',
		es: 'Guardar comentarios'
	},
	sprintTitle: {
		en: 'Sprint title...',
		es: 'Título del sprint...'
	},
	sprintDetails: {
		en: 'Sprint details...',
		es: 'Detalles del sprint...'
	},
	activityTitle: {
		en: 'Activity title...',
		es: 'Título de la actividad...'
	},
	activityDescription: {
		en: 'Activity details...',
		es: 'Detalles de la actividad...'
	},
	activityEditor: {
		en: 'Activity editor',
		es: 'Editar detalles de la actividad'
	},
	currentSprint: {
		en: 'Current sprint',
		es: 'Sprint actual'
	},
	stage: {
		en: 'Stage',
		es: 'Stage'
	},
	total: {
		en: 'Total',
		es: 'Total'
	},
	see: {
		en: 'See',
		es: 'Ver'
	},
	transferred: {
		en: 'Transferred',
		es: 'Transferred'
	},
	research: {
		en: 'Research',
		es: 'Research'
	},
	closed: {
		en: 'Closed',
		es: 'Closed'
	},
	ongoing: {
		en: 'Ongoing',
		es: 'Ongoing'
	},
	projectsScore: {
		en: 'Projects score',
		es: 'Projects score'
	},
	solutionsStatus: {
		en: 'Solutions status',
		es: 'Solutions status'
	},
	minuta: {
		en: 'Minuta',
		es: 'Minuta'
	},
	sendMinutaViaEmail: {
		en: 'Send everyone minuta via email',
		es: 'Enviar minuta via email a todos'
	},
	loading: {
		en: 'Loading...',
		es: 'Cargando...'
	},
	edited: {
		en: 'Edited',
		es: 'Editado'
	},
	stageConfirmationQuestion: {
		en: 'Does the project meet the characteristics listed above?',
		es: '¿El proyecto cumple con las características listadas arriba?'
	},
	yes: {
		en: 'Yes',
		es: 'Sí'
	},
	no: {
		en: 'No',
		es: 'No'
	},
	discard: {
		en: 'Discard',
		es: 'Descartar'
	},
	projectObjectivesEmpty: {
		en: 'The project has no objectives',
		es: 'El proyecto no tiene objetivos'
	},
	desirableEmpty: {
		en: 'No information given',
		es: 'Rubro vacío'
	},
	feasibleEmpty: {
		en: 'No information given',
		es: 'Rubro vacío'
	},
	viableEmpty: {
		en: 'No information given',
		es: 'Rubro vacío'
	},
	selectTheClosingCategory: {
		en: 'Select the closing category',
		es: 'Seleccione la categoría de cierre. El valor por defecto es: "Closed"'
	},
	addNewObjective: {
		en: 'Add new objective',
		es: 'Agregar un objetivo nuevo'
	},
	addNewDesirable: {
		en: 'Add new desirable point',
		es: 'Agregar un punto deseable'
	},
	addNewFeasible: {
		en: 'Add new feasible point',
		es: 'Agregar un punto factible'
	},
	addNewViable: {
		en: 'Add new viable point',
		es: 'Agregar un punto viable'
	},
	projectDeletionError: {
		en: 'Project deletion error',
		es: 'Hubo un problema al borrar el proyecto'
	},
	sprintDeletionError: {
		en: 'Sprint deletion error',
		es: 'Hubo un problema al borrar el sprint'
	},
	deleteConfirmation: {
		en: 'Are you sure you want to delete this item?',
		es: '¿Estás seguro de que deseas eliminar este elemento?'
	},
	deleteProjectConfirmation: {
		en: 'Are you sure you want to delete this project?',
		es: '¿Estás seguro de que deseas eliminar este proyecto?'
	},
	deleteSprintConfirmation: {
		en: 'Are you sure you want to delete this sprint?',
		es: '¿Estás seguro de que deseas eliminar este sprint?'
	},
	deleteActivityConfirmation: {
		en: 'Are you sure you want to delete this activity?',
		es: '¿Estás seguro de que deseas eliminar esta actividad?'
	},
	days: {
		en: 'Days',
		es: 'Días'
	},
	today: {
		en: 'Today',
		es: 'Hoy'
	},
	projectName: {
		en: 'Project name',
		es: 'Nombre del proyecto'
	},
	mainProjectSemaphore: {
		en: 'Main project semaphore',
		es: 'Semáforo principal del proyecto'
	},
	legal: {
		en: 'Legal',
		es: 'Legal'
	},
	vinculaciones: {
		en: 'Vinculaciones',
		es: 'Vinculaciones'
	},
	equipo: {
		en: 'Team',
		es: 'Equipo'
	},
	rokkett: {
		en: 'Rokkett',
		es: 'Rokkett'
	},
	finanzas: {
		en: 'Finance',
		es: 'Finanzas'
	},
	administration: {
		en: 'Administration',
		es: 'Administración'
	},
	evaluacionFinanciera: {
		en: 'Financial evaluation',
		es: 'Evaluación financiera'
	},
	potentialClients: {
		en: 'Potential clients',
		es: 'Clientes potenciales'
	},
	gestionDeProyectos: {
		en: 'Project management',
		es: 'Gestión de proyectos'
	},
	vigilanciasTecnologicas: {
		en: 'Technological matters',
		es: 'Vigilancias tecnológicas'
	},
	addNewLegalItems: {
		en: 'Add new legal point',
		es: 'Agregar un rubro legal'
	},
	addNewVinculacionesItems: {
		en: 'Add new linking point',
		es: 'Agregar un rubro de vinculaciones'
	},
	addNewEquipoItems: {
		en: 'Add new team point',
		es: 'Agregar un rubro del equipo'
	},
	addNewRokkettItems: {
		en: 'Add new rokkett point',
		es: 'Agregar un rubro de rokkett'
	},
	addNewFinanzasItems: {
		en: 'Add new financial point',
		es: 'Agregar un rubro de finanzas'
	},
	addNewEvaluacionFinancieraItems: {
		en: 'Add new financial evaluation point',
		es: 'Agregar un rubro de evaluación financiera'
	},
	addNewPotentialClientsItems: {
		en: 'Add new potential clients point',
		es: 'Agregar un rubro de clientes potenciales'
	},
	addNewGestionDeProyectosItems: {
		en: 'Add new project management point',
		es: 'Agregar un rubro de gestión de proyectos'
	},
	addNewVigilanciasTecnologicasItems: {
		en: 'Add new technology-related point',
		es: 'Agregar un rubro de vigilancias tecnológicas'
	},
	editProfile: {
		en: 'Edit profile',
		es: 'Editar perfil'
	},
	userNotFound: {
		en: 'User not found',
		es: 'Desconocido'
	},
	name: {
		en: 'Name',
		es: 'Nombre'
	},
	phoneNumber: {
		en: 'Phone number',
		es: 'Teléfono'
	},
	rank: {
		en: 'User type',
		es: 'Tipo de usuario'
	},
	none: {
		en: 'None',
		es: 'Ninguno'
	},
	olympian: {
		en: 'Olympian',
		es: 'Olympian'
	},
	pmo: {
		en: 'PMO',
		es: 'PMO'
	},
	scrumMonster: {
		en: 'Scrum monster',
		es: 'Scrum monster'
	},
	admin: {
		en: 'Admin',
		es: 'Admin'
	},
	apprentice: {
		en: 'Apprentice',
		es: 'Apprentice'
	},
	padawan: {
		en: 'Padawan',
		es: 'Padawan'
	},
	knight: {
		en: 'Knight',
		es: 'Knight'
	},
	jedi: {
		en: 'Jedi',
		es: 'Jedi'
	},
	wookiee: {
		en: 'Wookiee',
		es: 'Wookiee'
	},
	viewer: {
		en: 'Viewer',
		es: 'Viewer'
	},
	viewers: {
		en: 'Viewers',
		es: 'Viewers'
	},
	managePermissions: {
		en: 'Manage permissions',
		es: 'Gestionar permisos'
	},
	all: {
		en: 'Everyone',
		es: 'Todos'
	},
	allowedRanks: {
		en: 'Allowed users',
		es: 'Usuarios autorizados'
	},
	addAllowedRanks: {
		en: 'Add allowed user',
		es: 'Agregar autorización'
	},
	selectAnEventToModify: {
		en: 'Select an event to modify',
		es: 'Seleccione un evento para modificar sus permisos'
	},
	eventsEditor: {
		en: 'Permissions editor',
		es: 'Gestor de permisos'
	},
	eventToModify: {
		en: 'Event to modify...',
		es: 'Evento a modificar...'
	},
	selectAnUserToModify: {
		en: 'Select an user to modify',
		es: 'Seleccione un usuario para modificar sus datos'
	},
	userToModify: {
		en: 'User to modify...',
		es: 'Usuario a modificar...'
	},
	//All the events by name must be added here
	editProject: {
		en: 'PROJECT: Edit project',
		es: 'PROYECTO: Editar proyecto'
	},
	addSprints: {
		en: 'SPRINTS: Add new sprints',
		es: 'SPRINTS: Crear un nuevo sprint'
	},
	addNewProject: {
		en: 'PROJECT: Add new project',
		es: 'PROYECTO: Crear un nuevo proyecto'
	},
	openBalanceScore: {
		en: 'BALANCE SCORE: Open balance score',
		es: 'BALANCE SCORE: Visualizar detalles del balance score'
	},
	editProyectStage: {
		en: 'PROJECT: Edit proyect stage',
		es: 'PROYECTO: Cambiar la etapa del proyecto (frame, understand, experiment, scale)'
	},
	webAdminLabel: {
		en: 'Web administrator',
		es: 'Administrador web'
	},
	citadelleEvent: {
		en: 'CITADELLE: Access to citadelle',
		es: 'CITADELLE: Acceso a citadelle'
	},
	retrospectivesProjects: {
		en: 'RETROSPECTIVES: Access to see retrospectives',
		es: 'RETROSPECTIVES: Acceso a ver retrospectivas'
	},
	editProjectRetrospectives: {
		en: 'RETROSPECTIVES: Access to edit retrospectives per project',
		es: 'RETROSPECTIVES: Acceso a editar retrospectivas por proyecto'
	},
	editFactoriesRetrospectives: {
		en: 'RETROSPECTIVES: Access to edit factory retrospectives',
		es: 'RETROSPECTIVES: Acceso a editar retrospectivas de fábricas'
	},
	webAdmin: {
		en: '* ADMIN: Web administrator',
		es: '* ADMIN: Administrador web'
	},
	manageUsers: {
		en: '* ADMIN: Manage users',
		es: '* ADMIN: Gestionar usuarios'
	},
	editSprints: {
		en: 'SPRINTS: Edit sprints',
		es: 'SPRINTS: Editar información de un sprint'
	},
	addActivities: {
		en: 'ACTIVITIES: Add activities',
		es: 'ACTIVIDADES: Agregar una actividad a un sprint'
	},
	setActivitiesAsFinished: {
		en: 'ACTIVITIES: Set activities as finished',
		es: 'ACTIVIDADES: Marcar una actividad como terminada'
	},
	editActivitiesInfo: {
		en: 'ACTIVITIES: Edit activities info',
		es: 'ACTIVIDADES: Editar información de una actividad'
	},
	editSprintStatus: {
		en: 'SPRINTS: Edit sprint status',
		es: 'SPRINTS: Editar el estatus de un sprint'
	},
	editSprintDeadline: {
		en: 'SPRINTS: Edit sprint deadline',
		es: 'SPRINTS: Editar el deadline de un sprint'
	},
	deleteSprint: {
		en: 'SPRINTS: Delete sprint',
		es: 'SPRINTS: Eliminar / Borrar un sprint'
	},
	deleteProject: {
		en: 'PROJECT: Delete project',
		es: 'PROYECTO: Eliminar / Borrar un proyecto'
	},
	editProjectTeam: {
		en: 'PROJECT: Edit project team (PO, Scrum master, Quarterback)',
		es: 'PROYECTO: Editar el equipo de un proyecto (PO, Scrum master, Quarterback)'
	},
	editProjectDeadline: {
		en: 'PROJECT: Edit project deadline',
		es: 'PROYECTO: Editar la fecha límite de un proyecto'
	},
	editProjectNameAndIcon: {
		en: 'PROJECT: Edit project name and icon',
		es: 'PROYECTO: Editar el nombre de un proyecto y su ícono'
	},
	editProjectScrumComments: {
		en: 'PROJECT: Edit project scrum comments',
		es: 'PROYECTO: Editar comentarios del scrum mensual'
	},
	editProjectObjectives: {
		en: 'PROJECT: Edit project objectives',
		es: 'PROYECTO: Editar los objetivos del proyecto'
	},
	editProjectDesirableFeasibleViable: {
		en: 'PROJECT: Edit project desirable, feasible, viable points',
		es: 'PROYECTO: Editar los rubros desirable, feasibley viable'
	},
	deleteActivity: {
		en: 'ACTIVITIES: Delete activity',
		es: 'ACTIVIDADES: Eliminar / Borrar una actividad'
	},
	editBalanceScore: {
		en: 'BALANCE SCORE: Edit balance score',
		es: 'BALANCE SCORE: Editar semáforos y rubros en el balance score'
	},
	checkItemsInBalanceScore: {
		en: 'BALANCE SCORE: Check items in balance score',
		es: 'BALANCE SCORE: Marcar un rubro en el balance score como terminado'
	},
	editEventsTable: {
		en: '* ADMIN: Edit events table',
		es: '* ADMIN: Editar permisos en tabla de eventos'
	},
	uploadFiles: {
		en: 'PROJECT: Upload files',
		es: 'PROYECTO: Subir archivos'
	},
	player: {
		en: 'Player',
		es: 'Player'
	},
	extra: {
		en: 'Player extra',
		es: 'Player extra'
	},
	developmentEnvironment: {
		en: 'DEVELOPMENT ENVIRONMENT',
		es: 'AMBIENTE DE DESARROLLO'
	},
	testingEnvironment: {
		en: 'TESTING ENVIRONMENT',
		es: 'AMBIENTE DE PRUEBAS'
	},
	productionEnvironment: {
		en: 'PRODUCTION ENVIRONMENT',
		es: 'AMBIENTE PRODUCTIVO'
	},
	profileDetails: {
		en: 'Profile details',
		es: 'Detalles del perfil'
	},
	download: {
		en: 'Download',
		es: 'Descargar'
	},
	replace: {
		en: 'Replace',
		es: 'Reemplazar'
	},
	upload: {
		en: 'Upload',
		es: 'Subir'
	},
	onePage: {
		en: 'One page',
		es: 'One page'
	},
	generalPresentation: {
		en: 'Presentation',
		es: 'Presentación'
	},
	sketchOrVideo: {
		en: 'Sketch or video',
		es: 'Sketch o video'
	},
	fishBoneDiagram: {
		en: 'Fishbone diagram',
		es: 'Fishbone diagram'
	},
	treasureBucket: {
		en: 'Treasure bucket',
		es: 'Treasure bucket'
	},
	selectFile: {
		en: 'Select file',
		es: 'Elegir archivo'
	},
	selectOtherFile: {
		en: 'Select other file',
		es: 'Elegir otro archivo'
	},
	editMinuta: {
		en: 'MINUTA: Add or edit minuta',
		es: 'MINUTA: Editar o crear minuta'
	},
	minutaEditor: {
		en: 'Edit minuta',
		es: 'Editar minuta'
	},
	minutaDetails: {
		en: 'Minuta details',
		es: 'Minuta de la junta'
	},
	minutaWasEdited: {
		en: 'There have been changes to the current minuta.',
		es: 'Ha habido cambios a la minuta actual.'
	},
	changesInMinuta: {
		en: 'Changes made to the Minuta',
		es: 'Cambios en minuta'
	},
	leaderQuarterbackScrumMasterCannotBeEmpty: {
		en: 'Every project must have a Leader, Scrum Master and Quarterback',
		es: 'El proyecto debe tener un PO, un Scrum Master y un Quarterback'
	},
	createdBy: {
		en: 'Created by',
		es: 'Creado por'
	},
	toDoPoints: {
		en: 'To do points',
		es: 'Puntos y pendientes'
	},
	addNewToDoPointTo: {
		en: 'Add new to do point to ',
		es: 'Agregar pendiente a '
	},
	addNewPoint: {
		en: 'Add new point',
		es: 'Agregar punto nuevo'
	},
	creator: {
		en: 'Creator',
		es: 'Creador'
	},
	creation: {
		en: 'Creation',
		es: 'Creación'
	},
	lastEdition: {
		en: 'Last edition',
		es: 'Última edición'
	},
	maintenanceInProgress: {
		en: 'Maintenance in progress',
		es: 'Sitio en mantenimiento. \nFavor de regresar en algunos minutos.'
	},
	allDeadlines: {
		en: 'All deadlines',
		es: 'Histórico de deadlines'
	},
	added: {
		en: 'Added',
		es: 'Agregado'
	},
	projectPercentage: {
		en: 'Project percentage',
		es: 'Porcentaje del proyecto'
	},
	loggingOutDueToInactivityIn: {
		en: 'Logging out due to inactivity in: ',
		es: 'Cerrando sesión por inactividad en: '
	},
	invalidEmail: {
		en: 'Invalid email',
		es: 'Email inválido'
	},
	biometricLogin: {
		en: 'Biometric login',
		es: 'Acceso biométrico'
	},
	or: {
		en: 'Or',
		es: 'O'
	},
	seeMore: {
		en: 'See more',
		es: 'Ver más'
	},
	deadlineChanged: {
		en: 'Deadline of the project ~ changed',
		es: 'El deadline del proyecto ~ cambió'
	},
	projectStageChanged: {
		en: 'Stage of the project ~ changed to ',
		es: 'La etapa del proyecto ~ cambió a '
	},
	teamChanged: {
		en: 'The team of the project ~ changed',
		es: 'El equipo del proyecto ~ cambió'
	},
	notificationSent: {
		en: 'Notifications job initiated',
		es: 'Notificaciones iniciadas'
	},
	addUserToBeNotified: {
		en: 'Add users to be notified...',
		es: 'Agregar usuarios a notificar...'
	},
	notificationTitle: {
		en: 'Notification title',
		es: 'Título de la notificación'
	},
	notificationBody: {
		en: 'Notification body',
		es: 'Cuerpo de la notificación'
	},
	customNotifications: {
		en: 'Custom notifications',
		es: 'Notificaciones personalizadas'
	},
	sendNotification: {
		en: 'Send notification',
		es: 'Enviar notificación'
	},
	notificationTitleOrBodyCannotBeEmpty: {
		en: 'Notification title or body cannot be empty',
		es: 'La notificación debe de tener título y cuerpo'
	},
	youNeedToAddAtLeastOneUserToNotify: {
		en: 'You need to add at least one user to notify',
		es: 'Debes agregar al menos un usuario a notificar'
	},
	sprintWasCancelled: {
		en: `Sprint of the project ~,set as cancelled`,
		es: `Sprint de proyecto '~' no cumplido`
	},
	sprintWasCancelledDetails_1: {
		en: `The sprint '~' of the project `,
		es: `El sprint '~' del proyecto `
	},
	sprintWasCancelledDetails_2: {
		en: `'~' was just set as cancelled`,
		es: `'~' cambió a estatus de no cumplido.`
	},
	deleteMinuta: {
		en: 'Delete minuta',
		es: 'Eliminar minuta'
	},
	deleteMinutaConfirmation: {
		en: 'Are you sure you want to delete the minuta',
		es: '¿Estás seguro de querer borrar la minuta?'
	},
	deleteUser: {
		en: 'Delete user',
		es: 'Eliminar usuario'
	},
	deleteUserConfirmation: {
		en: 'Are you sure you want to delete an user?',
		es: 'Recuerda que para borrar a un usuario completamente, es necesario después de eliminarlo de aquí, ir a la consola de firebase y en la sección de autenticación, eliminarlo de ahí.\n¿Estás seguro de querer borrar el usuario de aquí?'
	},
	downloadIPA: {
		en: 'Download IOS app',
		es: 'Descargar app de IOS'
	},
	downloadAPK: {
		en: 'Download Android app',
		es: 'Descargar app de Android'
	},
	ipaInstallationInstructions: {
		en: 'IOS installation instructions',
		es: 'Instrucciones para IOS'
	},
	ipaInstallationInstructions1: {
		en: ``,
		es: `Descargar archivo desde una computadora Mac.`
	},
	ipaInstallationInstructions2: {
		en: '',
		es: 'Enviar el archivo via Airdrop, o mediante Itunes si el teléfono se encuentra conectado por USB.'
	},
	ipaInstallationInstructions3: {
		en: '',
		es: 'La app se instala en automático al llegar al dispositivo.'
	},
	ipaInstallationInstructions4: {
		en: '',
		es: 'Al intentar abrirla sale una notificación de que se necesita otorgar permisos a MOLDES Y EXHIBIDORES SA DE CV.'
	},
	ipaInstallationInstructions5: {
		en: '',
		es: 'Ir a Ajustes > General > Admón. de dispositivo, y en ese menú darle confiar y permitir a MOLDES Y EXHIBIDORES SA DE CV.'
	},
	ipaInstallationInstructions6: {
		en: '',
		es: 'La app está lista para ser usada. Recuerda permitir el recibir notificaciones y auto guardar tu contraseña cuando se ingresa por primera vez.'
	},
	apkInstallationInstructions: {
		en: 'Android installation instructions',
		es: 'Instrucciones para Android'
	},
	apkInstallationInstructions1: {
		en: ``,
		es: `Es necesario que el dispositivo no tenga bloqueos de seguridad por parte de Grupo Bimbo, en caso de tenerlo, solicitar el desbloqueo para poder continuar con la instalación.`
	},
	apkInstallationInstructions2: {
		en: '',
		es: 'Descargar archivo desde el celular Android.'
	},
	apkInstallationInstructions3: {
		en: '',
		es: 'Una vez terminada la descarga, dar click en archivo descargado para iniciar la instalación.'
	},
	apkInstallationInstructions4: {
		en: '',
		es: 'Saldrá una alerta para permitir instalar aplicaciones de "Fuentes desconocidas" desde la configuración. Es necesario otorgar ese permiso.'
	},
	apkInstallationInstructions5: {
		en: '',
		es: 'Darle click en "Instalar", y esperar a que la app se instale.'
	},
	apkInstallationInstructions6: {
		en: '',
		es: 'La app está lista para ser usada. Recuerda permitir el recibir notificaciones y auto guardar tu contraseña cuando se ingresa por primera vez.'
	},
	onlineLabel: {
		en: 'Online',
		es: 'Conectado'	
	},
	offlineLabel: {
		en: 'Offline',
		es: 'Desconectado'	
	},
	databaseWriteInProcess: {
		en: 'Database write in process... Please wait before leaving this page.',
		es: 'Escritura de base de datos en proceso... Por favor espere a que termine antes de salir de esta página.'
	},
	hideUser: {
		en: 'Hide or archive user',
		es: 'Ocultar / Archivar usuario'	
	},
	disableUser: {
		en: 'Disable notifications and mails to user',
		es: 'Deshabilitar notificaciones y correos a usuario'	
	},
};
