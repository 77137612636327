import React, { Component } from 'react';
import { Colors, SubHeadersSize } from '../constants';
import { Label } from './';

class Subheader extends Component{
	render() {
		return (
			<Label text={this.props.text} labelStyle={{...css.label, ...this.props.labelStyle}} style={this.props.style} children={this.props.children}/>
		);
	}
}

const css = {
	label: {
		color: Colors.primary,
		fontSize: SubHeadersSize,
	}
}

export { Subheader };
