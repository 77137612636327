import React, { Component } from 'react';
import { Colors, GetLabel } from '../../constants';
import { SideMenu, Header, SeparatorLarge, Label, Profile, BulletPointsList, Subheader, 
	WideButton, Div, Space } from '../../components';
import * as firebase from 'firebase';

class ProfileDetails extends Component {

	state = {

	}

	downloadIPA() {
		var storage = firebase.storage();
		var storageRef = storage.ref('builds/');

		storageRef.child('ios/application-b7e16274-8717-4cd7-bdc3-5cc3a1082006.ipa').getDownloadURL()
		
			.then(function(url) {
				window.open(url, '_self');
			})
			.catch(function(error) {
			// Handle any errors
			});
	}

	downloadAPK() {
		var storage = firebase.storage();
		var storageRef = storage.ref('builds/');

		storageRef.child('android/application-dec55a03-2bcb-44d9-8e37-adb0fb41a2a4.apk').getDownloadURL()
			.then(function(url) {
				window.open(url, '_self');
			})
			.catch(function(error) {
			// Handle any errors
			});
	}

	render() {
		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('myProfile')} buttonRight={{
						id: 'event_00000', 
						icon: 'img/sys/edit.png', 
						onClick: () => this.props.innerNavigate('profileEditor')
					}}/>

				<Profile image={global.profile.image} name={global.profile.name} labelStyle={css.name} style={css.profile} disableHover={true}
					event={{id: 'event_00000', onClick: () => this.props.innerNavigate('profileEditor') }}>
					<Label text={GetLabel(global.domainNames[global.profile.domainName])} style={css.domainName}/>
				</Profile>

				<SeparatorLarge disableBackgroundLines/>
				<Div>	
					<Subheader text={GetLabel('projectLeaderIn')}/>
					<BulletPointsList>
						{
							//global.projects.map((project, i) => {
							Object.keys(global.projects).map((key) =>{
								if (global.projects[key].leader === global.profile.id)
									return (<Label text={global.projects[key].name} key={key}/>);
								return undefined;
							})
						}
					</BulletPointsList>

					<Subheader text={GetLabel('scrumMasterIn')}/>
					<BulletPointsList>
						{
							//global.projects.map((project, i) => {
							Object.keys(global.projects).map((key) =>{
								if (global.projects[key].scrumMaster === global.profile.id)
									return (<Label text={global.projects[key].name} key={key}/>);
								return undefined;
							})
						}
					</BulletPointsList>

					<Subheader text={GetLabel('quarterbackIn')}/>
					<BulletPointsList>
						{
							//global.projects.map((project, i) => {
							Object.keys(global.projects).map((key) =>{
								if (global.projects[key].quarterback === global.profile.id)
									return (<Label text={global.projects[key].name} key={key}/>);
								return undefined;
							})
						}
					</BulletPointsList>

					<WideButton text={GetLabel('manageUserAccounts')} style={{marginTop: '3em'}}
						event={{ id: 'event_00006', onClick:() => this.props.innerNavigate('usersEditor'), icon:'img/sys/user.png'}} />

					<WideButton text={GetLabel('managePermissions')}
						event={{ id: 'event_00017', onClick:() => this.props.innerNavigate('eventsEditor'), icon:'img/sys/key.png'}} />

					<WideButton text={GetLabel('retrospectivesReport')}
						event={{ id: 'event_00032', onClick:() => this.props.innerNavigate('retrospectivesReport'), icon:'img/sys/calendar.png'}} />

					<WideButton text={GetLabel('webAdminLabel')} style={{marginBottom: '5em'}}
						event={{ id: 'event_00027', onClick:() => this.props.innerNavigate('webAdmin'), icon:'img/sys/edit.png'}} />

					<SeparatorLarge disableBackgroundLines/><Space /><Space /><Space />
					<Subheader text={GetLabel('ipaInstallationInstructions')}/>
					<BulletPointsList numbered>
						<Label text={GetLabel('ipaInstallationInstructions1')}/>
						<Label text={GetLabel('ipaInstallationInstructions2')}/>
						<Label text={GetLabel('ipaInstallationInstructions3')}/>
						<Label text={GetLabel('ipaInstallationInstructions4')}/>
						<Label text={GetLabel('ipaInstallationInstructions5')}/>
						<Label text={GetLabel('ipaInstallationInstructions6')}/>
					</BulletPointsList>

					<WideButton text={GetLabel('downloadIPA')} style={{marginBottom: '5em'}}
						event={{ id: 'event_00000', onClick:() => this.downloadIPA(), icon:'img/sys/apple.png'}} />

					<Subheader text={GetLabel('apkInstallationInstructions')}/>
					<BulletPointsList numbered>
						<Label text={GetLabel('apkInstallationInstructions1')}/>
						<Label text={GetLabel('apkInstallationInstructions2')}/>
						<Label text={GetLabel('apkInstallationInstructions3')}/>
						<Label text={GetLabel('apkInstallationInstructions4')}/>
						<Label text={GetLabel('apkInstallationInstructions5')}/>
						<Label text={GetLabel('apkInstallationInstructions6')}/>
					</BulletPointsList>

					<WideButton text={GetLabel('downloadAPK')} style={{marginBottom: '20em'}}
						event={{ id: 'event_00000', onClick:() => this.downloadAPK(), icon:'img/sys/android.png'}} />

					<Space /><Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	name: {
		textTransform: 'normal',
		color: Colors.primary,
	},
	profile: {
		background: 'transparent',
		marginTop: '-2em',
	},
	domainName: {
		paddingLeft: '0.5em',
		paddingRight: '0.5em',
		paddingTop: '0em',
		paddingBottom: '0em',
	},
}

export { ProfileDetails };