import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, 
	DefaultRank, DefaultDomainName, Today } from './';
import { UploadObject } from '../firebase';

export function UpdateRetrospectives(retrospectives, onSuccess, onFail) {
	global.retrospectives = Clone(retrospectives);

	UploadObject('retrospectives', global.retrospectives, 
		()=> {
			//console.log('User uploaded: ',profile);
			global.retrospectives = Clone(retrospectives);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading retrospectives: ',retrospectives);
			if(onFail)
				onFail();
		}
	);
}