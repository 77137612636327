import React, { Component } from 'react';
import { SpaceHeight } from '../constants';

class Space extends Component{
	render() {
		return (
			<div style={{...css.container, ...this.props.style}} />
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: SpaceHeight,
		minHeight: SpaceHeight,
	},
}

export { Space };
