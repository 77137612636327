import React, { Component } from 'react';
import { GetLabel } from '../constants';
import { WideButton, Loading } from './';

class ConfirmationButtons extends Component{
	
	render() {
		if(this.props.isLoading)
			return <Loading />;
	
		return (
			<div style={{display: 'flex', justifyContent: 'space-between',}}>
				<WideButton text={this.props.eventYes.label} style={{width: '45%'}} 
					labelContainerStyle={{transform: 'translateX(0.5em)'}}
					event={this.props.eventYes}/>
				<WideButton text={this.props.eventNo.label} style={{width: '45%'}} 
					labelContainerStyle={{transform: 'translateX(0.5em)'}}
					event={this.props.eventNo}/>
			</div>
		);
	}
}

const css = {
	
}

export { ConfirmationButtons };
