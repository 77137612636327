import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, GetProjectById, IsNullOrEmpty, Colors, 
	ReplaceProjectInGlobalById, Clone, DirtyStyle, SubHeadersSize } from '../../constants';
import { Header, Separator, Label, ProjectIcon, BulletPointsList, ObjectListEditor, 
	Div, Subheader, ConfirmationButtons, Checkbox, SeparatorLarge, Space, SemaphoreToggler, } from '../../components';
import deepmerge from 'deepmerge';

class BalanceScore extends Component {

	state = {
		inEditMode: false,
		isDirtyMainBalanceScore:false,
		isDirtyLegal:false,
		isDirtyVinculaciones:false,
		isDirtyEvaluacionFinanciera:false,
		isDirtyGestionDeProyectos:false,
		isDirtyVigilanciasTecnologicas: false,
		isDirtyPotentialClients: false,
		isLoading: false,
		listsModified: [],

		balanceScore: {
			semaphore: 'red',//green, red
			legal: {
				semaphore: 'red',
			},
			vinculaciones: {
				semaphore: 'red',
			},
			evaluacionFinanciera: {
				semaphore: 'red',
			},
			gestionDeProyectos: {
				semaphore: 'red',
			},
			vigilanciasTecnologicas: {
				semaphore: 'red',
			},
			potentialClients: {
				semaphore: 'red',
			},
		}
	}

	newBalanceScoreObject() {
		return {
			semaphore: 'red',
			legal: {
				semaphore: 'red',
			},
			vinculaciones: {
				semaphore: 'red',
			},
			evaluacionFinanciera: {
				semaphore: 'red',
			},
			gestionDeProyectos: {
				semaphore: 'red',
			},
			vigilanciasTecnologicas: {
				semaphore: 'red',
			},
			potentialClients: {
				semaphore: 'red',
			},
		};
	}

	componentDidMount() {
		let { project } = this.props.navParams;
		if(!IsNullOrEmpty(project.balanceScore)) {
			this.initialBalanceScore = Clone(project.balanceScore);
			this.setState({balanceScore: Clone(project.balanceScore)});
		}
		else
			this.initialBalanceScore = this.newBalanceScoreObject();
	}

	renderHeaderFromState() {
		if(this.state.inEditMode) {
			return (
				<Header text={GetLabel('balanceScore')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.clearDirtyState()}}/>
			);
		}else{
			return (
				<Header text={GetLabel('balanceScore')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					buttonRight={{id: 'event_00015', icon: 'img/sys/edit.png', onClick: ()=>{
						this.clearDirtyState();
						this.setState({inEditMode: true});
					}}}/>
			);//event_00015
		}
	}

	itemsModified(key, newListOfItems) {
		let newBalanceScore = {...this.state.balanceScore};
		newBalanceScore[key] = IsNullOrEmpty(newBalanceScore[key]) ? {semaphore: 'red', items: {}} : newBalanceScore[key];
		newBalanceScore[key].items = {...newListOfItems};
		//Report modification
		//this.setState({isDirty: true, balanceScore: newBalanceScore});
		let newState = {
			balanceScore: newBalanceScore,
		};

		switch(key){
			case 'evaluacionFinanciera': newState.isDirtyEvaluacionFinanciera = true; break;
			case 'vigilanciasTecnologicas': newState.isDirtyVigilanciasTecnologicas = true; break;
			case 'legal': newState.isDirtyLegal = true; break;
			case 'gestionDeProyectos': newState.isDirtyGestionDeProyectos = true; break;
			case 'vinculaciones': newState.isDirtyVinculaciones = true; break;
			case 'potentialClients': newState.isDirtyPotentialClients = true; break;
		}

		this.setState(newState);
	}

	mainSemaphoreModified(newColor) {
		let { project } = this.props.navParams;
		project = {...GetProjectById(project.id)};

		let newBalanceScore = Clone(this.state.balanceScore);//IsNullOrEmpty(project.balanceScore) ? Clone(this.state.balanceScore) : Clone(project.balanceScore);
		newBalanceScore.semaphore = newColor;
		
		this.setState({isDirtyMainBalanceScore: true, balanceScore: newBalanceScore});
	}

	semaphoreOfCategoryModified(listName, newColor) {
		let { project } = this.props.navParams;
		project = {...GetProjectById(project.id)};

		let newBalanceScore = Clone(this.state.balanceScore);//IsNullOrEmpty(project.balanceScore) ? Clone(this.state.balanceScore) : Clone(project.balanceScore);
		/*this.state.listsModified.forEach(listModified => {
			newBalanceScore[listModified].semaphore = this.state.balanceScore[listModified].semaphore;
		});*/

		if(newBalanceScore[listName] === undefined)
			newBalanceScore[listName] = {};
		newBalanceScore[listName].semaphore = newColor;		

		//let newList = Clone(this.state.listsModified);
		//newList.push(listName);

		let newState = {
			balanceScore: newBalanceScore,
			//listsModified: newList
		};

		switch(listName){
			case 'evaluacionFinanciera': newState.isDirtyEvaluacionFinanciera = true; break;
			case 'vigilanciasTecnologicas': newState.isDirtyVigilanciasTecnologicas = true; break;
			case 'legal': newState.isDirtyLegal = true; break;
			case 'gestionDeProyectos': newState.isDirtyGestionDeProyectos = true; break;
			case 'vinculaciones': newState.isDirtyVinculaciones = true; break;
			case 'potentialClients': newState.isDirtyPotentialClients = true; break;
		}

		console.log('state to be set: ', newState);

		this.setState(newState);
		/*
		let { project } = this.props.navParams;
		project = {...GetProjectById(project.id)};

		let newBalanceScore = IsNullOrEmpty(project.balanceScore) ? Clone(this.state.balanceScore) : Clone(project.balanceScore);
		this.state.listsModified.forEach(listModified => {
			newBalanceScore[listModified].semaphore = this.state.balanceScore[listModified].semaphore;
		});

		newBalanceScore[listName].semaphore = newColor;		

		let newList = Clone(this.state.listsModified);
		newList.push(listName);

		this.setState({isDirty: true, balanceScore: newBalanceScore, listsModified: newList});
		*/
	}

	clearDirtyState() {
		this.setState({
			isDirtyMainBalanceScore:false,
			isDirtyLegal:false,
			isDirtyVinculaciones:false,
			isDirtyEvaluacionFinanciera:false,
			isDirtyGestionDeProyectos:false,
			isDirtyVigilanciasTecnologicas: false,
			isDirtyPotentialClients: false,
			inEditMode: false, 
			isLoading: false, 
			balanceScore: Clone(this.initialBalanceScore),//this.newBalanceScoreObject(),
			listsModified: [],
		});
	}

	saveChanges() {
		this.setState({isLoading: true });

		let { project } = this.props.navParams;
		project = GetProjectById(project.id);
		project.balanceScore = this.state.balanceScore;
		
		ReplaceProjectInGlobalById(project, 
			() => { 
				this.props.navParams.project = {...project}; 
				this.initialBalanceScore = Clone(project.balanceScore); 
				this.clearDirtyState();
			},
			() => this.setState({ isLoading: false }));

	}

	checkItem(listName, key) { 
		let newBalanceScore = {...this.state.balanceScore};
		newBalanceScore[listName].items[key].checked = !newBalanceScore[listName].items[key].checked;
		
		let newState = {
			balanceScore: newBalanceScore,
		};

		switch(listName){
			case 'evaluacionFinanciera': newState.isDirtyEvaluacionFinanciera = true; break;
			case 'vigilanciasTecnologicas': newState.isDirtyVigilanciasTecnologicas = true; break;
			case 'legal': newState.isDirtyLegal = true; break;
			case 'gestionDeProyectos': newState.isDirtyGestionDeProyectos = true; break;
			case 'vinculaciones': newState.isDirtyVinculaciones = true; break;
			case 'potentialClients': newState.isDirtyPotentialClients = true; break;
		}
		//this.setState({isDirty: true, balanceScore: newBalanceScore});
		this.setState(newState);
	}

	renderObjectList(listName, objectList, eventId) {
		let { project } = this.props.navParams;
		let jsx = [];
		let color = (objectList.semaphore === 'red') ? Colors.red : Colors.green;

		//console.log('listName: '+listName+' objectList: ',objectList);
		objectList.items = IsNullOrEmpty(objectList.items) ? {} : objectList.items;

		jsx.push(<SeparatorLarge color={color} key={'separatorLarge'}/>);
		jsx.push(<Space key={'space1'}/>);
		jsx.push(
			<SemaphoreToggler event={{id: eventId, project: project, onClick: (newColor)=>this.semaphoreOfCategoryModified(listName, newColor)}} 
				key={'semaphoreToggler'}
				color={objectList.semaphore}>
				<Subheader text={GetLabel(listName)}/>
			</SemaphoreToggler>
		);

		Object.keys(objectList.items).forEach((key) => {
			jsx.push(
				<Checkbox event={{id: eventId, project: project, onClick: ()=> this.checkItem(listName, key)}} 
					style={{margin: '0em', padding: '0em'}}
					key={key} checked={objectList.items[key].checked}>
					<Label text={objectList.items[key].name} labelStyle={{fontSize: SubHeadersSize}}/>
				</Checkbox>
			)
		});

		jsx.push(<Space key={'space2'}/>);

		return jsx;
	}

	renderSaveButtonIfDirty() {
		let { eventId, project } = this.props.navParams;
		let { inEditMode, isDirtyMainBalanceScore, isDirtyLegal, isDirtyVinculaciones,
			isDirtyGestionDeProyectos, isDirtyVigilanciasTecnologicas, isDirtyEvaluacionFinanciera, isDirtyPotentialClients } = this.state;

		let isDirty = (isDirtyMainBalanceScore || isDirtyLegal || isDirtyVinculaciones ||
			isDirtyGestionDeProyectos || isDirtyVigilanciasTecnologicas || isDirtyEvaluacionFinanciera || isDirtyPotentialClients);

		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00015', project: project, onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	renderBalanceScorePoints() {
		let { isDirtyMainBalanceScore, isDirtyLegal, isDirtyVinculaciones, 
			isDirtyEvaluacionFinanciera, isDirtyGestionDeProyectos, isDirtyVigilanciasTecnologicas, isLoading, isDirtyPotentialClients } = this.state;
		let { project } = this.props.navParams;
		project = GetProjectById(project.id);

		//console.log('isDirtyLegal: ',isDirtyLegal);

		if(IsNullOrEmpty(project.balanceScore))
			project.balanceScore = this.newBalanceScoreObject();

		let balanceScore = (isDirtyMainBalanceScore) ? {...this.state.balanceScore} : {...project.balanceScore};
		let legalItems = (isDirtyLegal) ? this.state.balanceScore.legal : project.balanceScore.legal;
		let vinculacionesItems = (isDirtyVinculaciones) ? this.state.balanceScore.vinculaciones : project.balanceScore.vinculaciones;
		let evaluacionFinancieraItems = (isDirtyEvaluacionFinanciera) ? this.state.balanceScore.evaluacionFinanciera : project.balanceScore.evaluacionFinanciera;
		let gestionDeProyectosItems = (isDirtyGestionDeProyectos) ? this.state.balanceScore.gestionDeProyectos : project.balanceScore.gestionDeProyectos;
		let vigilanciasTecnologicasItems = (isDirtyVigilanciasTecnologicas) ? this.state.balanceScore.vigilanciasTecnologicas : project.balanceScore.vigilanciasTecnologicas;
		let potentialClientsItems = (isDirtyPotentialClients) ? this.state.balanceScore.potentialClients : project.balanceScore.potentialClients;

		legalItems = IsNullOrEmpty(legalItems) ? {semaphore: 'red', items: {}} : legalItems;
		vinculacionesItems = IsNullOrEmpty(vinculacionesItems) ? {semaphore: 'red', items: {}} : vinculacionesItems;
		evaluacionFinancieraItems = IsNullOrEmpty(evaluacionFinancieraItems) ? {semaphore: 'red', items: {}} : evaluacionFinancieraItems;
		gestionDeProyectosItems = IsNullOrEmpty(gestionDeProyectosItems) ? {semaphore: 'red', items: {}} : gestionDeProyectosItems;
		vigilanciasTecnologicasItems = IsNullOrEmpty(vigilanciasTecnologicasItems) ? {semaphore: 'red', items: {}} : vigilanciasTecnologicasItems;
		potentialClientsItems = IsNullOrEmpty(potentialClientsItems) ? {semaphore: 'red', items: {}} : potentialClientsItems;

		if(this.state.inEditMode) {
			return (
				<Div>
					<Subheader text={GetLabel('evaluacionFinanciera')}/>
					<ObjectListEditor 
						baseObject={{checked: false}}
						list={evaluacionFinancieraItems.items} 
						placeholder={GetLabel('addNewEvaluacionFinancieraItems')} eventId={'event_00015'} project={project}//event_00015
						onModify={(newList) => this.itemsModified('evaluacionFinanciera', newList)}/><Space/>

					<Subheader text={GetLabel('potentialClients')}/>
					<ObjectListEditor 
						baseObject={{checked: false}}
						list={potentialClientsItems.items} 
						placeholder={GetLabel('addNewPotentialClientsItems')} eventId={'event_00015'} project={project}//event_00015
						onModify={(newList) => this.itemsModified('potentialClients', newList)}/><Space/>

					<Subheader text={GetLabel('legal')}/>
					<ObjectListEditor 
						baseObject={{checked: false}}
						list={legalItems.items} 
						placeholder={GetLabel('addNewLegalItems')} eventId={'event_00015'} project={project}//event_00015
						onModify={(newList) => this.itemsModified('legal', newList)}/><Space/>

					<Subheader text={GetLabel('gestionDeProyectos')}/>
					<ObjectListEditor 
						baseObject={{checked: false}}
						list={gestionDeProyectosItems.items} 
						placeholder={GetLabel('addNewGestionDeProyectosItems')} eventId={'event_00015'} project={project}//event_00015
						onModify={(newList) => this.itemsModified('gestionDeProyectos', newList)}/><Space/>

					<Subheader text={GetLabel('vinculaciones')}/>
					<ObjectListEditor 
						baseObject={{checked: false}}
						list={vinculacionesItems.items} 
						placeholder={GetLabel('addNewVinculacionesItems')} eventId={'event_00015'} project={project}//event_00015
						onModify={(newList) => this.itemsModified('vinculaciones', newList)}/><Space/>					

					<Subheader text={GetLabel('vigilanciasTecnologicas')}/>
					<ObjectListEditor 
						baseObject={{checked: false}}
						list={vigilanciasTecnologicasItems.items} 
						placeholder={GetLabel('addNewVigilanciasTecnologicasItems')} eventId={'event_00015'} project={project}//event_00015
						onModify={(newList) => this.itemsModified('vigilanciasTecnologicas', newList)}/><Space/>

					{ this.renderSaveButtonIfDirty() }
					<Space/><Space/><Space/><Space/><Space/>
				</Div>
			);
		}
		else {
			return (
				<Div>
					<SemaphoreToggler 
						event={{id: 'event_00015', onClick: (newColor)=>this.mainSemaphoreModified(newColor) }}
						color={balanceScore.semaphore}>
						<Subheader text={GetLabel('mainProjectSemaphore')}/>
					</SemaphoreToggler><Space/>
					{ this.renderObjectList('evaluacionFinanciera', evaluacionFinancieraItems, 'event_00016') }
					{ this.renderObjectList('potentialClients', potentialClientsItems, 'event_00016') }
					{ this.renderObjectList('legal', legalItems, 'event_00016') }
					{ this.renderObjectList('gestionDeProyectos', gestionDeProyectosItems, 'event_00016') }
					{ this.renderObjectList('vinculaciones', vinculacionesItems, 'event_00016') }
					{ this.renderObjectList('vigilanciasTecnologicas', vigilanciasTecnologicasItems, 'event_00016') }

					{ this.renderSaveButtonIfDirty() }
					<Space/><Space/><Space/><Space/><Space/>
				</Div>
			);
		}
	}

	render() {
		let { project } = this.props.navParams;

		project = GetProjectById(project.id);

		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		return (
			<div style={{height: '100%'}}>
				{ this.renderHeaderFromState() }
				{ this.renderBalanceScorePoints() }
			</div>
		);
	}
}

const css = {

}

export { BalanceScore };