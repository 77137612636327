import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, IsAnObjectArray, FormatDateTimeLong, Today,
	GetFullMonth, SubHeadersSize, DirtyStyle, ReplaceProjectInGlobalById, Clone, ObjectToArray,
	UpdatePendings, IsNullOrEmpty,
} from '../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, SeparatorLarge, MonthSelector, StarGrading, ConfirmationButtons, 
	SideMenu, PendingsListEditor,
} from '../components';

class Pendings extends Component {

	state = {
		dsm: IsNullOrEmpty(global.pendings.dsm) ? [] : global.pendings.dsm,
		rios: IsNullOrEmpty(global.pendings.rios) ? [] : global.pendings.rios,
		commitmentsGtd: IsNullOrEmpty(global.pendings.commitmentsGtd) ? [] : global.pendings.commitmentsGtd,
		visits: IsNullOrEmpty(global.pendings.visits) ? [] : global.pendings.visits,
		factoriesSolutions: IsNullOrEmpty(global.pendings.factoriesSolutions) ? [] : global.pendings.factoriesSolutions,
		factoriesIdeas: IsNullOrEmpty(global.pendings.factoriesIdeas) ? [] : global.pendings.factoriesIdeas,
		factoriesEntrepreneurs: IsNullOrEmpty(global.pendings.factoriesEntrepreneurs) ? [] : global.pendings.factoriesEntrepreneurs,
		legal: IsNullOrEmpty(global.pendings.legal) ? [] : global.pendings.legal,
		finanzas: IsNullOrEmpty(global.pendings.finanzas) ? [] : global.pendings.finanzas,
		vinculaciones: IsNullOrEmpty(global.pendings.vinculaciones) ? [] : global.pendings.vinculaciones,
		administration: IsNullOrEmpty(global.pendings.administration) ? [] : global.pendings.administration,
		preadvice: IsNullOrEmpty(global.pendings.preadvice) ? [] : global.pendings.preadvice,
		isDirty: false,
		isLoading: false,
	}

	modifyList(moduleName, newList) {
		let clonedState = Clone(this.state);
		clonedState[moduleName] = newList;
		clonedState.isDirty = true;
		this.setState(clonedState);
	}

	renderListModule(moduleName) {
		if(!Array.isArray(this.state[moduleName]))
			return undefined;

		return (
			<div>
				<Subheader text={GetLabel(moduleName)} labelStyle={{color: Colors.secondary}}>
				</Subheader>
				<PendingsListEditor 
					isObject={true}
					listName={moduleName}
					list={this.state[moduleName]} 
					placeholder={GetLabel('addNewToDoPointTo')+moduleName} 
					eventId={'event_00000'} 
					onModify={(newList) => { this.modifyList(moduleName, newList) }}/>
			</div>
		);
	}

	clearDirtyState() {
		this.setState({
			dsm: IsNullOrEmpty(global.pendings.dsm) ? [] : global.pendings.dsm,
			rios: IsNullOrEmpty(global.pendings.rios) ? [] : global.pendings.rios,
			commitmentsGtd: IsNullOrEmpty(global.pendings.commitmentsGtd) ? [] : global.pendings.commitmentsGtd,
			visits: IsNullOrEmpty(global.pendings.visits) ? [] : global.pendings.visits,
			factoriesSolutions: IsNullOrEmpty(global.pendings.factoriesSolutions) ? [] : global.pendings.factoriesSolutions,
			factoriesIdeas: IsNullOrEmpty(global.pendings.factoriesIdeas) ? [] : global.pendings.factoriesIdeas,
			factoriesEntrepreneurs: IsNullOrEmpty(global.pendings.factoriesEntrepreneurs) ? [] : global.pendings.factoriesEntrepreneurs,
			legal: IsNullOrEmpty(global.pendings.legal) ? [] : global.pendings.legal,
			finanzas: IsNullOrEmpty(global.pendings.finanzas) ? [] : global.pendings.finanzas,
			vinculaciones: IsNullOrEmpty(global.pendings.vinculaciones) ? [] : global.pendings.vinculaciones,
			administration: IsNullOrEmpty(global.pendings.administration) ? [] : global.pendings.administration,
			preadvice: IsNullOrEmpty(global.pendings.preadvice) ? [] : global.pendings.preadvice,
			isDirty: false,
			isLoading: false, 
		});
	}

	uploadJsonToCode91Server(jsonData) {
		fetch('https://code91.mx/bimbo/gtd_pendientes/json_writer.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: jsonData,
		})
		.then(response => response.text())
		.then(data => {
			// Handle response from the server if needed
			console.log('Server response:', data);
		})
		.catch(error => {
			console.error('Error sending JSON:', error);
		});
	}

	saveChanges() {
		this.setState({isLoading: true,});

		let pendingsClone = Clone(global.pendings);
		let stateClone = Clone(this.state);

		pendingsClone.dsm = stateClone.dsm;
		pendingsClone.rios = stateClone.rios;
		pendingsClone.commitmentsGtd = stateClone.commitmentsGtd;
		pendingsClone.visits = stateClone.visits;
		pendingsClone.factoriesSolutions = stateClone.factoriesSolutions;
		pendingsClone.factoriesIdeas = stateClone.factoriesIdeas;
		pendingsClone.factoriesEntrepreneurs = stateClone.factoriesEntrepreneurs;
		pendingsClone.legal = stateClone.legal;
		pendingsClone.finanzas = stateClone.finanzas;
		pendingsClone.vinculaciones = stateClone.vinculaciones;
		pendingsClone.administration = stateClone.administration;
		pendingsClone.preadvice = stateClone.preadvice;

		this.uploadJsonToCode91Server(JSON.stringify(pendingsClone));

		// let { minuta } = this.props.navParams;
		// minuta = GetMinutaById(minuta);
		// minuta.toDoList = Clone(this.state.toDoList);

		// //console.log('minuta to be replaced: ',minuta);

		// PushMinuta(minuta, 
		// 	() => { 
		// 		this.props.navParams.minuta = Clone(minuta); 
		// 		//this.initialToDoList = Clone(minuta.toDoList); 
		// 		this.clearDirtyState(); 
		// 	}, 
		// 	() => this.setState({ isLoading: false }));

		UpdatePendings(pendingsClone,
			() => 
				this.setState({
					isLoading: false,
					isDirty: false,
				}), 
			() =>
				this.setState({
					isLoading: false,
					isDirty: false,
				}));
	}

	renderSaveButtonIfDirty() {
		let { isDirty } = this.state;
		
		if(isDirty)
			return (
				<div style={{...DirtyStyle, ...css.dirtyStyle}}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	render() {

		let className = (this.props.className) ? this.props.className : 'moveIn';
		return (
			<div style={css.container} className={className}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('pendings')} 
					// buttonRight={{
					// 	id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],  
					// 	icon: 'img/sys/edit.png', 
					// 	project: project,
					// 	onClick: ()=>this.props.innerNavigate('projectEditor', {
					// 		project: GetProjectById(project.id),
					// 		eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
					// 	})}}
					// buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					/>
				<div className={'hideScroll'} style={css.outerDivContainer}>
					<div className={'hideScroll'} style={css.innerDivContainer}>
						{
							Object.keys(this.state).map(key => {
								return this.renderListModule(key);
							})
						}

						{ this.renderSaveButtonIfDirty() }
						<Space/><Space/>
						<Space/><Space/>
						<Space/><Space/>
					</div>
				</div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	starGrading: {
		alignItems: 'flex-start',
	},
	starLabelStyle: {
		// alignSelf: 'center',
		marginTop: '1rem',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.background_2,
		fontWeight: 'bold',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	listButton: {
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		paddingLeft: '0',
		paddingRight: '0',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',

	},
	outerDivContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: `calc(100%)`,
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		maxWidth: '100%',
		overflowY: 'scroll',
		overflowX: 'hidden',
	    position: 'relative',
	},
	innerDivContainer: {
		display: 'flex',
		flexDirection: 'column',
		// height: `calc(100%)`,
		width: '85%',
		maxWidth: '1100px',
		minWidth: '610px',
		overflowY: 'scroll',
		overflowX: 'hidden',
	    position: 'relative',
	},
	dirtyStyle: {
		marginLeft: '0px',
		overflow: 'visible',
		width: 'auto',
		maxWidth: 'auto',
	},
}

export { Pendings };