import React, { Component } from 'react';
import { Colors, MainFontSize, FontName, SmallFontSize } from '../constants';
import { Button, Image } from './';

class StarGrading extends Component{
	state = {
		stars: (this.props.stars === undefined) ? 0 : this.props.stars
	}

	renderStars() {
		let jsx = [];
		let starsSize = '3.5em';

		if(this.props.considerZero) {
			let icon = (this.state.stars === 0) ? 'img/sys/disabled_glow.png' : 'img/sys/circle.png';
			jsx.push(
				<Button 
					key={0}
					renderBlockedIfNotAllowedToExecuteEvent
					event={{
						id: this.props.event.id, 
						onClick: ()=> {
							if(!this.props.editable)
								return;
							this.setState({stars: 0})
							this.props.event.onChange(0);
						},
						project: this.props.event.project,
					}}>
					<Image 
						className={'buttonLarge'}
						src={icon}
						width={starsSize} height={starsSize} 
						color={Colors.secondary} />
				</Button>
			);
		}
		else {
			jsx.push(<div style={{width: starsSize}}/>);
		}

		for(let j=1; j<=5; j++) {
			let icon = (j > this.state.stars) ? 'img/sys/star_empty.png' : 'img/sys/star_full.png';
			jsx.push(
				<Button 
					key={j}
					renderBlockedIfNotAllowedToExecuteEvent
					event={{
						id: this.props.event.id, 
						onClick: ()=> {
							if(!this.props.editable)
								return;
							this.setState({stars: j})
							this.props.event.onChange(j);
						},
						project: this.props.event.project,
						retrospectivesFactories: this.props.event.retrospectivesFactories,
					}}>
					<Image 
						className={'buttonLarge'}
						src={icon}
						width={starsSize} height={starsSize} 
						color={Colors.secondary} />
				</Button>
			);
		}

		return jsx;
	}

	render() {
		return (
			<div style={{...css.container, ...this.props.style}} className={this.props.className}>
				<label style={{...css.label, ...this.props.labelStyle}}>
					{ this.props.text }
				</label>
				{ this.props.children }
				<div style={css.starsContainer}>
					{ this.renderStars() }
				</div>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: '0.5em',
		marginBottom: '0.5em',
		padding: '0.5em',
		marginLeft: '1em',
		marginRight: '1em',
	},
	label: {
		color: Colors.primary,
		fontFamily: FontName,
		fontSize: SmallFontSize,
		letterSpacing: '1px',
		wordWrap: 'break-word',
		overflowWrap: 'break-word',
		width: '100%',
		textAlign: 'right',
	},
	starsContainer: {
		display: 'flex',
		flexDirection: 'row',
	}
}

export { StarGrading };
