import React, { Component } from 'react';
import { Colors, ProjectIconDiameter, IsNullOrEmpty } from '../constants';
import { Image, Button } from './';

class ProjectIcon extends Component{

	state = {
		isLoading: true,
	}

	getSemaphoreColor() {
		let { project } = this.props;
		let semaphore = (IsNullOrEmpty(project.balanceScore.semaphore)) ? 
			'red' : project.balanceScore.semaphore;
		switch(semaphore) {
			case 'red':
				return Colors.red;
			case 'green':
				return Colors.green;
			default:
				return Colors.red;
		}
	}

	getVerticalLines(hexagonStyle) {
		if(this.props.verticalLines) {
			return (
				<div style={this.css().hexagonContainer}>
					<Image src={'img/sys/hexagon.png'} color={this.getSemaphoreColor()} width={'1.2em'} height={'1.2em'} style={hexagonStyle}/>
					<div style={{...this.css().verticalLines, ...{background: this.getSemaphoreColor()}}}/>
				</div>
			);
		}
		else
			return undefined;
	}

	renderLoadingIndicators() {
		return undefined;

		let sizes = (this.state.isLoading) ? {} : {width: '0', height: '0'};
		let rotation = (this.state.isLoading) ? {} : {transform: 'rotate(90deg)'};

		return (
			<div className={'transitionAll'} style={{...this.css().loadingIndicatorsContainer, ...rotation}}>
				<div className={'transitionAll'} style={{...this.css().loadingIndicator, ...{top: '0', left: '0'}, ...sizes}}/>
				<div className={'transitionAll'} style={{...this.css().loadingIndicator, ...{top: '0', right: '0'}, ...sizes}}/>
				<div className={'transitionAll'} style={{...this.css().loadingIndicator, ...{bottom: '0', left: '0'}, ...sizes}}/>
				<div className={'transitionAll'} style={{...this.css().loadingIndicator, ...{bottom: '0', right: '0'}, ...sizes}}/>
			</div>
		);
	}

	render() {
		let className = (this.props.event) ? 'buttonLarge' : '';
		let buttonOffset = (this.props.verticalLines) ? {marginTop: '-0.1em'} : {};
		let event = (this.props.event) ? this.props.event : {id: 'event_00000', onClick: ()=>{}};
		let projectIconDiameter = (this.props.projectIconDiameter) ? this.props.projectIconDiameter : ProjectIconDiameter;
		let color = (this.props.color) ? this.props.color : Colors.primary;
		let icon = this.props.project.icon;

		if(this.props.default && !icon)
			icon = this.props.default;

		if(this.props.doNotUseButton) 
			return (
				<div style={{...this.css().button, ...this.props.style, ...buttonOffset}} buttonStyle={this.css().innerButtonStyle}
					onClick={event} className={className} disablehover={this.props.disableHover} id={this.props.id}>
					{ this.getVerticalLines({marginBottom: '2em'}) }
					<div style={{...this.css().imageContainer, ...this.props.imageContainer}}>
						{ this.renderLoadingIndicators() }
						<Image src={icon} width={projectIconDiameter} height={projectIconDiameter} 
							color={color} onLoad={() => this.setState({isLoading: false})}/>
					</div>
					{ this.props.children }
					{ this.getVerticalLines({marginTop: '2em'}) }
				</div>
			);
		else
			return (
				<Button style={{...this.css().button, ...this.props.style, ...buttonOffset}} buttonStyle={this.css().innerButtonStyle}
					event={event} className={className} disablehover={this.props.disableHover} id={this.props.id}>
					{ this.getVerticalLines({marginBottom: '2em'}) }
					<div style={{...this.css().imageContainer, ...this.props.imageContainer}}>
						{ this.renderLoadingIndicators() }
						<Image src={icon} width={projectIconDiameter} height={projectIconDiameter} 
							color={color} onLoad={() => this.setState({isLoading: false})}/>
					</div>
					{ this.props.children }
					{ this.getVerticalLines({marginTop: '2em'}) }
				</Button>
			);
	}

	css() {
		let projectIconDiameter = (this.props.projectIconDiameter) ? this.props.projectIconDiameter : ProjectIconDiameter;
		return {
			button: {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				margin: '1em',
				backgroundColor: 'transparent',
				border: 'none',
				color: Colors.primary,
				fontSize: '1rem',
				letterSpacing: '2px',
				zIndex: '2',
			},
			innerButtonStyle: {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			},
			imageContainer: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: `calc(${projectIconDiameter} + 3em)`,
				minWidth: `calc(${projectIconDiameter} + 3em)`,
				height: `calc(${projectIconDiameter} + 3em)`,
				minHeight: `calc(${projectIconDiameter} + 3em)`,
				padding: '0em',
				background: `linear-gradient(${Colors.background_1} 0%, ${Colors.background_2} 100%)`,//Colors.background_1,
				borderRadius: `calc(${projectIconDiameter} + 6em / 2)`,
				overflow: 'hidden',
				border: `0.5em solid ${this.getSemaphoreColor()}`,
				zIndex: '3',
			},
			icon: {
				position: 'absolute',
				marginTop: '-0.3em',
				marginLeft: '-0.5em',
				zIndex: '4',
			}, 
			verticalLines: {
				width: '0.4em',
				height: '2em',
				position: 'absolute',
			},
			hexagonContainer: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
			loadingIndicator: {
				width: `calc((${projectIconDiameter} + 3em) / 2 + 2px)`,
				height: `calc((${projectIconDiameter} + 3em) / 2 + 2px)`,
				background: this.getSemaphoreColor()+'bb',
				position: 'absolute',
			},
			loadingIndicatorsContainer: {
				width: `calc(${projectIconDiameter} + 3em + 4px)`,
				height: `calc(${projectIconDiameter} + 3em + 4px)`,
				position: 'absolute',
				marginTop: '-1px',
				marginLeft: '-1px',
				borderRadius: '5em',
				overflow: 'hidden',
				zIndex: '4',
			}
		};
	}
}

export { ProjectIcon };
