import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, IsAnObjectArray, FormatDateTimeLong, 
	} from '../../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, SeparatorLarge, } from '../../components';

class ProjectCore extends Component {

	state = {
		
	}

	renderProjectHeader() {
		let { project } = this.props.navParams;
		project = GetProjectById(project.id);

		if(project.stage === 'ideation') 
			return undefined;

		return (
			<div style={css.projectHeaderContainer}>
				<ProjectIcon project={project} style={css.projectIcon} verticalLines={true}/>
				<div>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('leader')} 
						text={GetUserNameById(project.leader)}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('scrumMaster')} 
						text={GetUserNameById(project.scrumMaster)}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('quarterback')}
						text={GetUserNameById(project.quarterback)}/>
					<Parameter icon={'img/sys/stage.png'} iconLabel={GetLabel('stage')} 
						text={GetLabel(project.stage)}/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('kickOff')} 
						text={FormatDateShort(project.kickOff, ' ')}/>
				</div>
			</div>
		);
	}

	renderProjectStage() {
		let { project } = this.props.navParams;
		project = GetProjectById(project.id);

		let eventId = 'event_00005';
		
		return (
			<DropDown 
				value={`${project.stage}`} 
				calculatePercentage={(param) => GetPercentageFromStage(param)}
				color={Colors.green}
				options={['pending', 'ideation', 'frame', 'understand', 'experiment', 'accelerate', 'scale']}
				editable={AmIAllowedToExecuteThis(eventId, project)}
				event={{id: eventId, onClick: ()=>{}, project: project}}/>
		);
	}

	renderBullets(title, bulletsArray) {
		bulletsArray = (bulletsArray) ? bulletsArray : [`[${GetLabel(title+'Empty')}]`];
		return (
			<div>
				<Subheader text={GetLabel(title)} labelStyle={{color: Colors.secondary}}/>
				<BulletPointsList>
					{
						bulletsArray.map((item, i) => {
							return (<Label text={item} style={css.bullets} key={i}/>);
						})
					}
				</BulletPointsList><Space/>
			</div>
		);
	}

	renderDeadlines(project) {
		if(project.stage === 'ideation') 
			return undefined;

		if(IsAnObjectArray(project.deadline)) {

			return (
				<div>
					<Subheader text={GetLabel('allDeadlines')} labelStyle={{color: Colors.secondary}}/>
					{
						Object.keys(project.deadline).map((key, i) => {
							let item = project.deadline[key];
							let userName = global.users[item.updatedBy] ? global.users[item.updatedBy].name : GetLabel('userNotFound')+': '+item.updatedBy;
							let text1 = `${FormatDateShort(item.deadline, ' ')}`;
							let text2 = `${GetLabel('added')}: ${FormatDateTimeLong(item.dateUpdated)} - ${userName}`;

							let iconLabel = (i === 0) ? GetLabel('initialDeadline') : GetLabel('deadline');

							return (
								<Parameter icon={'img/sys/calendar.png'} iconLabel={iconLabel} key={key +' '+ i}>
									<div style={{lineHeight: '0.5em'}}>
										<Subheader text={text1} style={css.bullets}/>
										<Label text={text2} style={{...css.bullets, ...{lineHeight: '1.3em'}}}/>
									</div>
								</Parameter>
							);
						})
					}
				</div>
			);
		}
		else 
			return (
				<div>
					<Subheader text={GetLabel('allDeadlines')} labelStyle={{color: Colors.secondary}}/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')} 
						text={FormatDateShort(project.deadline, ' ')}/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('initialDeadline')} 
						text={FormatDateShort(project.initialDeadline, ' ')}/>
				</div>
			);
	}

	renderSeeFilesButton() {
		let { project } = this.props.navParams;
		project = GetProjectById(project.id);

		if(project.stage === 'ideation') 
			return undefined;

		return (
			<WideButton text={GetLabel('seeFiles')}
				event={{
					id: 'event_00000', icon:'img/sys/info.png', 
					onClick:()=> this.props.innerNavigate('files', {project: GetProjectById(project.id)})
				}} />
		);
	}

	renderSeparatorSet() {
		let { project } = this.props.navParams;
		if(project.stage === 'ideation') 
			return undefined;

		let jsx = [];

		jsx.push(<Space/>);
		jsx.push(<SeparatorLarge disableBackgroundLines/>);
		jsx.push(<Space/>);

		return jsx;
	}

	render() {
		let { project } = this.props.navParams;
		project = GetProjectById(project.id);
		
		//if(!project)
		//	return <LoadingScreen />;
		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		let className = (this.props.className) ? this.props.className : 'moveIn';
		return (
			<div style={css.container} className={className}>
				<Header text={project.name} 
					buttonRight={{
						id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],  
						icon: 'img/sys/edit.png', 
						project: project,
						onClick: ()=>this.props.innerNavigate('projectEditor', {
							project: GetProjectById(project.id),
							eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
						})}}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					{ this.renderProjectHeader() }
					{ /*this.renderProjectStage()*/}

					{/*
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('initialDeadline')} 
						text={FormatDateShort(project.initialDeadline, ' ')}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('quarterback')} text={project.quarterback}/>
					*/}
					{ this.renderSeparatorSet() }
					{ this.renderDeadlines(project) }
					{ this.renderSeparatorSet() }
					{ this.renderBullets('projectObjectives', project.objectives) }
					{ this.renderBullets('desirable', project.desirable) }
					{ this.renderBullets('feasible', project.feasible) }
					{ this.renderBullets('viable', project.viable) }
					{ this.renderSeeFilesButton() }

					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.background_2,
		fontWeight: 'bold',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	listButton: {
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		paddingLeft: '0',
		paddingRight: '0',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',

	},
}

export { ProjectCore };