import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, 
	DefaultRank, DefaultDomainName, Today } from './';
import { UploadObject } from '../firebase';

export function GetUserNameById(userId) {
	let user = global.users[userId];//global.users.find((item) => {return item.id == userId});
	if(IsNullOrEmpty(user))
		return GetLabel('userNotFound')+': '+userId;
	else
		return user.name;
}

export function UpdateMyInfoInRealtimeDatabase(firebaseUser) {
	let profileInDatabase = IsNullOrEmpty(global.users) ? undefined : global.users[firebaseUser.uid];
	let email = firebaseUser.email;
	let expoPushToken = [];
	let isDisabled = 
		IsNullOrEmpty(profileInDatabase) ? false : 
		(profileInDatabase.isDisabled === null || profileInDatabase.isDisabled === undefined) ? false : profileInDatabase.isDisabled;
	let isHidden = 
		IsNullOrEmpty(profileInDatabase) ? false : 
		(profileInDatabase.isHidden === null || profileInDatabase.isHidden === undefined) ? false : profileInDatabase.isHidden;

	if(!IsNullOrEmpty(profileInDatabase)) {
		email = profileInDatabase.email;
		if(Array.isArray(profileInDatabase.expoPushToken)){
			expoPushToken = profileInDatabase.expoPushToken;
		}
	}

	let profile = {
		id: firebaseUser.uid,
		name: IsNullOrEmpty(profileInDatabase) ? firebaseUser.email : profileInDatabase.name,
		email: email,
		emailVerified: firebaseUser.emailVerified,
		isAnonymous: firebaseUser.isAnonymous,
		phoneNumber: IsNullOrEmpty(profileInDatabase) ? '' : profileInDatabase.phoneNumber,
		image: IsNullOrEmpty(profileInDatabase) ? '' : profileInDatabase.image,
		domainName: IsNullOrEmpty(profileInDatabase) ? DefaultDomainName() : profileInDatabase.domainName,
		rank: IsNullOrEmpty(profileInDatabase) ? DefaultRank() : profileInDatabase.rank,
		lastLogin: Today('DD/MM/YYYY HH:mm'),
		expoPushToken: expoPushToken,
		isDisabled: isDisabled,
		isHidden: isHidden,
		projectAccess: IsNullOrEmpty(profileInDatabase) ? [] : (profileInDatabase.projectAccess === undefined ? [{id: 'all', name: 'Todos'}] : profileInDatabase.projectAccess),
	}

	if(IsNullOrEmpty(global.users))
		global.users = {};

	UpdateProfile(profile, ()=>{}, ()=>{});
}

export function UpdateProfile(profile, onSuccess, onFail) {
	global.profile = Clone(profile);
	global.users[profile.id] = Clone(profile);

	UploadObject('users', global.users, 
		()=> {
			//console.log('User uploaded: ',profile);
			global.profile = Clone(profile);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading user: ',profile);
			if(onFail)
				onFail();
		}
	);
}

export function UpdateUsers(users, onSuccess, onFail) {
	UploadObject('users', Clone(users), 
		()=> {
			//console.log('Users uploadeds: ',users);
			global.users = Clone(users);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading users: ',users);
			if(onFail)
				onFail();
		}
	);
}