import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, FormatDateShortest, ButtonSize, ClampLengthAt, 
	GetProjectById, GetPercentageFromStage, AmIAllowedToExecuteThis, GetSprintStatusIcon,
	LargeButtonSize, GetSprintById, GetPresentSprints, GetFutureSprints, GetUserNameById,
	ReplaceProjectInGlobalById, IsNullOrEmpty, DirtyStyle, GetHighestIdFromObjectsList, 
	IsAnObjectArray, PadNumber, DefaultPadNumber, GetProjectPercentage, 
	MaxSprintsToRenderInProjectDetails, SetValue,
} from '../../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, SeparatorLarge, Label, InputField,
	ProjectIcon, ListButton, Image, Subheader, DropDown, BulletPointsList, Space, SprintInRow,
	ConfirmationButtons, PercentageSelector, 
} from '../../components';
import { UploadObject, SendPushNotification } from '../../firebase';
import './Projects.css';

class ProjectDetails extends Component {

	state = {
		//project: this.props.project
		dirtyScrumComments: false,
		dirtyProjectStage: false,
		dirtyProjectPercentage: false,
		scrumComments: '',
		stage: '',
		percentage: '0%',
		closedStage: '',
		isLoading: false,
		renderAllSprints: false,
	}

	componentDidMount() {
		//let project = global.projects.find((item) => {return item.id === this.props.id});
		//this.setState({project: project});
	}

	getProject() {
		let { project } = this.props.navParams;
		return GetProjectById(project.id);
	}

	getLastDeadline(deadline) {
		let lastDeadline = '';

		if(IsAnObjectArray(deadline)) {
			let lastDeadlineNumber = GetHighestIdFromObjectsList(deadline);
			let lastDeadlineId = 'item_'+PadNumber(lastDeadlineNumber, DefaultPadNumber);
			lastDeadline = FormatDateShort(deadline[lastDeadlineId].deadline, ' ');
			return lastDeadline;
		}
		else {
			console.log('This project has the OLD VERSION OF THE DEADLINES. This else is to be deleted after the REFACTOR');
			lastDeadline = FormatDateShort(deadline, ' ');
			return lastDeadline;
		}
	}

	renderProjectHeader() {
		let project = this.getProject();

		return (
			<div style={css.projectHeaderContainer}>
				<ProjectIcon project={project} style={css.projectIcon} verticalLines={true}/>
				<div>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('leader')} 
						text={GetUserNameById(project.leader)}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('scrumMaster')} 
						text={GetUserNameById(project.scrumMaster)}/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')} text={this.getLastDeadline(project.deadline)}/>
				</div>
			</div>
		);
	}

	renderList(category) {
		let project = this.getProject();

		//Get list from sprints depending on date here
		let list = (category==='sprints') ? 
			GetPresentSprints(project.sprints) : 
			GetFutureSprints(project.sprints);

		let jsx = [];

		if(list.length === 0)
			jsx = <Label text={GetLabel(category+'Empty')}/>;

		list.map((item, i) => {
			if(!this.state.renderAllSprints && i === MaxSprintsToRenderInProjectDetails) {
				jsx.push(
					<WideButton text={GetLabel('seeMore')} 
						event={{
							id: 'event_00000', icon:'img/sys/add.png', 
							onClick:()=> this.setState({renderAllSprints: true})
						}} />
				);
			}
			else if(!this.state.renderAllSprints && i > MaxSprintsToRenderInProjectDetails) {}
			else {
				jsx.push(
					<ListButton key={i} text={'demo'} style={css.listButton} event={{id: 'event_00000', onClick: ()=>{
						this.props.innerNavigate('sprint', {sprint: item, project: GetProjectById(project.id)})
					}}}>
						{ <SprintInRow sprint={item}/> }
					</ListButton>
				);
			}
		});

		return (
			<div style={css.sprintsContainer}>
				{ jsx }
				{ (category === 'nextSteps') ? <Space /> : undefined }
				{ (category === 'nextSteps') ? <Space /> : undefined }
				{ (category === 'nextSteps') ? <Space /> : undefined }
			</div>
		);
	}

	renderProjectPercentageSelector() {
		let project = this.getProject();
		let { dirtyProjectPercentage, dirtyProjectStage } = this.state;

		let eventId = 'event_00001';
		let percentage = (dirtyProjectPercentage) ? this.state.percentage : project.percentage;
		percentage = IsNullOrEmpty(percentage) ? '0%' : percentage;
		//console.log('stage: '+stage);
		let stageContainerStyle = (dirtyProjectPercentage) ? {...DirtyStyle, ...{marginTop: '-2em'}} : {};
		let color = (IsNullOrEmpty(project.balanceScore.semaphore)) ? 
			Colors.red : 
			(project.balanceScore.semaphore === 'red') ? Colors.red : Colors.green;

		let stage = (dirtyProjectStage) ? this.state.stage : project.stage;
			
		return (
			<div style={stageContainerStyle}>
				{ (dirtyProjectPercentage) ? <Space /> : undefined }
				{/*<DropDown 
					value={`${percentage}`} 
					calculatePercentage={(param) => GetProjectPercentage(param)}
					color={color}
					prefixString={GetLabel('projectPercentage')+': '}
					concatenatedString={(dirtyProjectPercentage) ? ` (${GetLabel('edited')})` : ''}
					options={['0%', '10%', '20%', '30%', '40%', '50%','60%', '70%', '80%', '90%', '100%']}
					editable={AmIAllowedToExecuteThis(eventId, project)}
					event={{
						id: eventId, 
						project: project,
						onClick: (newPercentage)=>{
							this.setState({dirtyProjectPercentage: true, percentage: newPercentage});
						}
					}}/>*/}
				{ (stage === 'pending') ? undefined :
					<PercentageSelector color={color} percentage={percentage} 
						editable={AmIAllowedToExecuteThis(eventId, project)}
						event={{
							id: eventId, 
							project: project,
							onClick: (newPercentage)=>{
								this.setState({dirtyProjectPercentage: true, percentage: newPercentage});
							}
						}}/>
				}
				{ this.renderPercentageConfirmation() }
			</div>
		);
	}

	renderProjectStage() {
		let project = this.getProject();
		let { dirtyProjectStage } = this.state;

		let eventId = 'event_00005';
		let stage = (dirtyProjectStage) ? this.state.stage : project.stage;
		//console.log('stage: '+stage);
		let stageContainerStyle = (dirtyProjectStage) ? DirtyStyle : {};
		let color = (IsNullOrEmpty(project.balanceScore.semaphore)) ? 
			Colors.red : 
			(project.balanceScore.semaphore === 'red') ? Colors.red : Colors.green;
			
		return (
			<div style={stageContainerStyle}>
				{ (dirtyProjectStage) ? <Space /> : undefined }
				<DropDown 
					value={`${stage}`} 
					calculatePercentage={(param) => {return 0}/*GetPercentageFromStage(param)*/}
					//color={color}
					concatenatedString={(dirtyProjectStage) ? ` (${GetLabel('edited')})` : ''}
					options={['pending', 'ideation', 'frame', 'understand', 'experiment', 'accelerate', 'scale', 'closed']}
					editable={AmIAllowedToExecuteThis(eventId, project)}
					event={{
						id: eventId, 
						project: project,
						onClick: (newStage)=>{
							this.setState({dirtyProjectStage: true, stage: newStage});
						}
					}}/>
				{ this.renderConfirmationOptionsIfDirty() }
			</div>
		);
	}

	renderStageConfirmationBullets(listToRender) {
		let project = this.getProject();

		let bulletsList = [];
		switch(listToRender) {
			case 'desirable':
				bulletsList = IsNullOrEmpty(project.desirable) ? [] : project.desirable;
				break;
			case 'feasible':
				bulletsList = IsNullOrEmpty(project.feasible) ? [] : project.feasible;
				break;
			case 'viable':
				bulletsList = IsNullOrEmpty(project.viable) ? [] : project.viable;
				break;
		}

		return (
			<div>
				<Space />
				<Subheader text={GetLabel(listToRender)}/>
				<BulletPointsList>
					{
						bulletsList.map((label, i) => {
							return (<Label text={label} key={i}/>);
						})
					}
				</BulletPointsList>
				<Subheader text={GetLabel('stageConfirmationQuestion')}/>
				<ConfirmationButtons isLoading={this.state.isLoading}
					eventYes={{id: 'event_00000', onClick: ()=>this.saveStageChanges(), 
						icon: 'img/sys/save.png', label: GetLabel('yes')}}
					eventNo={{id: 'event_00000', onClick: ()=>this.cancelStageChanges(), 
						icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
				<Space />
			</div>
		);
	}

	renderPercentageConfirmation() {
		let { dirtyProjectPercentage, percentage } = this.state;
		let project = this.getProject();
		if(dirtyProjectPercentage) {
			return (
				<ConfirmationButtons isLoading={this.state.isLoading}
					eventYes={{id: 'event_00000', onClick: ()=>this.savePercentageChanges(), 
						icon: 'img/sys/save.png', label: GetLabel('yes')}}
					eventNo={{id: 'event_00000', onClick: ()=>this.cancelPercentageChanges(), 
						icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
			);
		}
		else
			return <div/>;
	}

	renderConfirmationOptionsIfDirty() {
		let { dirtyProjectStage, stage, closedStage } = this.state;
		let project = this.getProject();

		let eventId = 'event_00005';
		if(dirtyProjectStage) {
			switch(stage) {
				case 'frame': 
					return (
						<div>
							<Space />
							<ConfirmationButtons isLoading={this.state.isLoading}
								eventYes={{id: 'event_00000', onClick: ()=>this.saveStageChanges(), 
									icon: 'img/sys/save.png', label: GetLabel('yes')}}
								eventNo={{id: 'event_00000', onClick: ()=>this.cancelStageChanges(), 
									icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
							<Space />
						</div>
					);
				case 'understand':
					return this.renderStageConfirmationBullets('desirable');
				case 'experiment':
					return this.renderStageConfirmationBullets('feasible');
				case 'scale':
					return this.renderStageConfirmationBullets('viable');
				case 'closed':
				case 'research':
				case 'transferred':
					return (
						<div>
							<Space />
							<Subheader text={GetLabel('selectTheClosingCategory')}/>
							<DropDown 
								value={`${IsNullOrEmpty(closedStage) ? `${GetLabel('closed')}` : closedStage}`} 
								calculatePercentage={(param) => GetPercentageFromStage(param)}
								color={Colors.green}
								//concatenatedString={(dirtyProjectStage) ? ` (${GetLabel('edited')})` : ''}
								options={['closed', 'transferred', 'research']}
								editable={AmIAllowedToExecuteThis(eventId, project)}
								event={{
									id: eventId, 
									project: project,
									onClick: (newStage)=>{
										this.setState({closedStage: newStage, stage: newStage});
									}
								}}/>
							<ConfirmationButtons isLoading={this.state.isLoading}
								eventYes={{id: 'event_00000', onClick: ()=>this.saveStageChanges(), 
									icon: 'img/sys/save.png', label: GetLabel('yes')}}
								eventNo={{id: 'event_00000', onClick: ()=>this.cancelStageChanges(), 
									icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
							<Space />
						</div>
					);
				default:
					return (
						<div>
							<WideButton text={GetLabel('saveStageChanges')} 
								event={{id: 'event_00000', icon:'img/sys/save.png', onClick:()=>this.saveStageChanges() }}/>
							<Space />
						</div>
					);
			}
		}
		else
			return <div/>;
	}

	saveStageChanges() {
		this.setState({isLoading: true });

		let project = this.getProject();
		project.stage = this.state.stage;

		ReplaceProjectInGlobalById(project,
			() => {
				SendPushNotification({
					messageTitle: project.name,
					messageBody: SetValue(GetLabel('projectStageChanged'), project.name) + GetLabel(project.stage),
					projectLeader: project.leader,
					projectScrumMaster: project.scrumMaster,
					projectQuarterback: undefined,
					targetUsers: ['rank_00004'],
				});
				this.setState({
					dirtyProjectStage: false,
					stage: '',
					closedStage: '',
					isLoading: false,
				});
			}, 
			() =>
				this.setState({
					isLoading: false,
				}));
	}

	savePercentageChanges() {
		this.setState({isLoading: true });

		let project = this.getProject();
		project.percentage = this.state.percentage;
		ReplaceProjectInGlobalById(project,
			() => 
				this.setState({
					dirtyProjectPercentage: false,
					percentage: '0%',
					isLoading: false,
				}), 
			() =>
				this.setState({
					isLoading: false,
				}));
	}

	saveScrumCommentsChanges() {
		this.setState({isLoading: true });

		let project = this.getProject();
		project.scrumComments = this.state.scrumComments;
		ReplaceProjectInGlobalById(project, 
			() => 
				this.setState({
					dirtyScrumComments: false,
					scrumComments: '',
					isLoading: false,
				}), 
			() =>
				this.setState({
					isLoading: false,
				}));
	}

	cancelStageChanges() {
		this.setState({
			dirtyProjectStage: false,
			stage: '',
			closedStage: '',
		});
	}

	cancelScrumCommentsChanges() {
		this.setState({
			dirtyScrumComments: false,
			scrumComments: ''
		});
	}

	cancelPercentageChanges() {
		this.setState({
			dirtyProjectPercentage: false,
			percentage: '0%'
		});
	}

	goBack() {
		let { dirtyProjectStage, dirtyScrumComments } = this.state;

		//if(dirtyScrumComments || dirtyProjectStage)
		//	ConfirmationDialog();
		this.props.goBack();
	}

	renderSaveButtonIfChangesAreMade() {
		if(this.state.dirtyScrumComments)
			return (
				<ConfirmationButtons isLoading={this.state.isLoading}
					eventYes={{id: 'event_00000', onClick: ()=>this.saveScrumCommentsChanges(), 
						icon: 'img/sys/save.png', label: GetLabel('yes')}}
					eventNo={{id: 'event_00000', onClick: ()=>this.cancelScrumCommentsChanges(), 
						icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
			);
		else
			return <div/>;
	}

	renderScrumComments() {
		let project = this.getProject();
		let { dirtyScrumComments, dirtyProjectStage } = this.state;

		let stage = (dirtyProjectStage) ? this.state.stage : project.stage;
		if(stage === 'ideation') 
			return undefined;

		let scrumComments = (dirtyScrumComments) ? this.state.scrumComments : project.scrumComments;
		let scrumContainerStyle = (dirtyScrumComments) ? DirtyStyle : {};
		return (
			<div style={scrumContainerStyle}>
				{ (dirtyScrumComments) ? <Space /> : undefined }
				<Label text={`${GetLabel('monthlyScrumComments')}${
					(dirtyScrumComments) ? ` (${GetLabel('edited')})` : ''}`}/>
				<InputField 
					multiline={true} 
					value={scrumComments} 
					placeholder={GetLabel('addMonthlyScrumComments')}
					onChange={ 
						(newValue) => {
							if(AmIAllowedToExecuteThis('event_00022', project))
								this.setState({dirtyScrumComments: true, scrumComments: newValue})
						} 
					}
					disabled={!AmIAllowedToExecuteThis('event_00022', project)}/>
				{ this.renderSaveButtonIfChangesAreMade() }
				{ (dirtyScrumComments) ? <Space /> : undefined }
			</div>
		);
	}

	renderProjectDetailsButton() {
		let project = this.getProject();
		let { dirtyProjectStage } = this.state;

		let stage = (dirtyProjectStage) ? this.state.stage : project.stage;
		if(stage === 'pending') 
			return undefined;

		return (
			<WideButton text={GetLabel('projectDetails')} 
				event={{
					id: 'event_00000', icon:'img/sys/info.png', 
					onClick:()=> this.props.innerNavigate('projectCore', {project: GetProjectById(project.id)})
				}} />
		);
	}

	renderBalanceScoreButton() {
		let project = this.getProject();
		let { dirtyProjectStage } = this.state;

		let stage = (dirtyProjectStage) ? this.state.stage : project.stage;
		if(stage === 'pending') 
			return undefined;
		
		return (
			<WideButton text={GetLabel('balanceScore')} 
				event={ {id: 'event_00004', project: project, icon:'img/sys/balanceScore.png', onClick:()=> 
					this.props.innerNavigate('balanceScore', {
						project: GetProjectById(project.id),
						eventId: 'event_00004',
					})
			} }/>
		);
	}

	renderRetrospectivesButton() {
		let project = this.getProject();
		let { dirtyProjectStage } = this.state;
		
		return (
			<WideButton text={GetLabel('retrospectives')} 
				event={ {id: 'event_00029', project: project, icon:'img/sys/calendar.png', onClick:()=> 
					this.props.innerNavigate('retrospectives', {
						project: GetProjectById(project.id),
						eventId: 'event_00029',
					})
			} }/>
		);
	}

	render() {
		let project = this.getProject();

		//if(!project)
		//	return <LoadingScreen />;
		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		let className = (this.props.className) ? this.props.className : 'moveIn';
		

		return (
			<div style={css.container} className={className}>
				<Header text={project.name} 
					buttonRight={{
						id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'], 
						icon: 'img/sys/edit.png', 
						project: project, 
						onClick: ()=>this.props.innerNavigate('projectEditor', {
							project: GetProjectById(project.id),
							eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
						})}}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.goBack()}}/>
				<Div noVerticalPadding>
					{ this.renderProjectHeader() }
					{ this.renderProjectPercentageSelector() }
					{ this.renderProjectStage() }
					{ this.renderBalanceScoreButton() }
					{ this.renderRetrospectivesButton() }
					{ this.renderScrumComments() }
					{ this.renderProjectDetailsButton() }
					
					<SeparatorLarge style={{marginTop: '3em', marginBottom: '3em'}} disableBackgroundLines/>
					<Header text={GetLabel('sprints')} style={css.subheaders} labelStyle={css.subheadersLabelStyle}
						buttonRight={{
							id: 'event_00002', project: project, icon: 'img/sys/add.png', 
							onClick: ()=>this.props.innerNavigate('sprintEditor', {
								project: GetProjectById(project.id),
								sprint: GetSprintById(GetProjectById(project.id), -1),
								eventId: 'event_00002',
							})
						}}/>
					{ this.renderList('sprints') }

					<SeparatorLarge style={{marginTop: '3em', marginBottom: '3em'}} disableBackgroundLines/>
					<Header text={GetLabel('nextSteps')} style={css.subheaders} labelStyle={css.subheadersLabelStyle}
						buttonRight={{
							id: 'event_00002', project: project, icon: 'img/sys/add.png', 
							onClick: ()=>this.props.innerNavigate('sprintEditor', {
								project: GetProjectById(project.id),
								sprint: GetSprintById(GetProjectById(project.id), -1),
								eventId: 'event_00002',
							})
						}}/>
					{ this.renderList('nextSteps') }
					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '2em',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.secondary,
		fontWeight: 'bold',
	},
	listButton: {
		marginTop: '0em',//'0.5em', 
		marginBottom: '0em',//'1.5em',
		padding: '0',
		height: 'initial',
	},
}

export { ProjectDetails };