import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, IsAnObjectArray, FormatDateTimeLong, Today,
	GetFullMonth, SubHeadersSize, DirtyStyle, ReplaceProjectInGlobalById, Clone, ObjectToArray,
	UpdateRetrospectivesCapabilities, 
} from '../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, SeparatorLarge, MonthSelector, StarGrading, ConfirmationButtons, 
	SideMenu,
} from '../components';

class RetrospectivesCapabilities extends Component {

	state = {
		monthYear: Today('MM_YYYY'),
		isLoading: false,
	}

	renderRetrospectivasTitle() {
		return (
			<Label 
				labelStyle={{fontSize: SubHeadersSize, textAlign: 'center'}}
				text={`${GetLabel('capabilities')} ${GetFullMonth(parseInt(this.state.monthYear.substring(0, 2)))} ${this.state.monthYear.substring(3)}`}/>
		);
	}

	renderRetrospectivasDetails() {

		let { monthYear } = this.state;
		if(global.capabilities === undefined) global.capabilities = {};
		if(global.capabilities[monthYear] === undefined) global.capabilities[monthYear] = {};

		return (
			<div style={{marginLeft: '-6rem', marginRight: '-4rem'}}>

				<Label text={`${GetLabel('capabilities')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('finanzas')} stars={global.capabilities[monthYear]['finanzas']} editable considerZero key={monthYear+'1'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('finanzas', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('administration')} stars={global.capabilities[monthYear]['administration']} editable considerZero key={monthYear+'2'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('administration', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('legal')} stars={global.capabilities[monthYear]['legal']} editable considerZero key={monthYear+'3'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('legal', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('vinculaciones')} stars={global.capabilities[monthYear]['vinculaciones']} editable considerZero key={monthYear+'4'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('vinculaciones', newNumberOfStars);
				}}}/>
				<Space/><Space/><Space/>
			</div>
		);
	}

	renderSeparatorSet() {
		let jsx = [];

		jsx.push(<Space/>);
		jsx.push(<SeparatorLarge disableBackgroundLines/>);
		jsx.push(<Space/>);

		return jsx;
	}

	saveRetrospectiveChange(category, value) {
		this.setState({isLoading: true });

		let retrospectivesClone = Clone(global.capabilities);

		if(retrospectivesClone === undefined) retrospectivesClone = {};
		if(retrospectivesClone[this.state.monthYear] === undefined) retrospectivesClone[this.state.monthYear] = {};
		if(retrospectivesClone[this.state.monthYear][category] === undefined) retrospectivesClone[this.state.monthYear][category] = {};

		retrospectivesClone[this.state.monthYear][category] = value;
		// console.log('object to write: ',retrospectivesClone);

		UpdateRetrospectivesCapabilities(retrospectivesClone,
			() => 
				this.setState({
					isLoading: false,
				}), 
			() =>
				this.setState({
					isLoading: false,
				}));
	}

	render() {

		let className = (this.props.className) ? this.props.className : 'moveIn';
		return (
			<div style={css.container} className={className}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('retrospectivesCapabilities')} 
					// buttonRight={{
					// 	id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],  
					// 	icon: 'img/sys/edit.png', 
					// 	project: project,
					// 	onClick: ()=>this.props.innerNavigate('projectEditor', {
					// 		project: GetProjectById(project.id),
					// 		eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
					// 	})}}
					// buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					/>
				<Div noVerticalPadding>
					<MonthSelector editable onClick={(newDate) => { this.setState({monthYear: newDate}); }}/>
					{/*
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('initialDeadline')} 
						text={FormatDateShort(project.initialDeadline, ' ')}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('quarterback')} text={project.quarterback}/>
					*/}
					{ this.renderRetrospectivasTitle() }
					{ this.renderRetrospectivasDetails() }

					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	starGrading: {
		alignItems: 'flex-start',
	},
	starLabelStyle: {
		// alignSelf: 'center',
		marginTop: '1rem',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.background_2,
		fontWeight: 'bold',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	listButton: {
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		paddingLeft: '0',
		paddingRight: '0',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',

	},
}

export { RetrospectivesCapabilities };