global.isInDebug = false;
global.lastActionTimer = 0;

global.Log = (object) => {
	if(global.isInDebug)
		console.log(object);
};

global.profile = {
	/*
	id: '0',
	name: 'Victor R. Esquivel Estrada',
	image: 'img/sys/demoProfile.png',
	domainName: 'domainName',
	rank: 'scrumMaster',*/
}

global.users = {
/*
	{ id: 0, name: 'Victor R. Esquivel Estrada', image: '', domainName: 'domainName', rank: 'scrumMaster' }, 
	{ id: 1, name: 'Joe Doe', image: '', domainName: 'domainName', rank: 'scrumMaster' }*/
};

global.colorFilters = {};
global.ranks = {};

global.ranksProject = {};

global.events = {
	event_00000: { id: 'event_00000',  adminEvent: true,  name: 'allowedAll', allowedRanks: ['all'] },//false,
	/*
	event_00001: { id: 'event_00001',  adminEvent: false, name: 'editProject', allowedRanks: ['leader'] },//true, 
	event_00002: { id: 'event_00002',  adminEvent: false, name: 'addSprints', allowedRanks: ['leader'] },//true, 
	event_00003: { id: 'event_00003',  adminEvent: false, name: 'addNewProject', allowedRanks: ['all'],  },//false,
	event_00004: { id: 'event_00004',  adminEvent: false, name: 'openBalanceScore', allowedRanks: ['leader'] },//true, 
	event_00005: { id: 'event_00005',  adminEvent: false, name: 'editProyectStage', allowedRanks: ['leader'] },//true, 
	event_00006: { id: 'event_00006',  adminEvent: false, name: 'editPermissionsInUsers', allowedRanks: ['all'] },//false,
	event_00007: { id: 'event_00007',  adminEvent: false, name: 'editSprints', allowedRanks: ['leader'] },//true, 
	event_00008: { id: 'event_00008',  adminEvent: false, name: 'addActivities', allowedRanks: ['leader'] },//true, 
	event_00009: { id: 'event_00009',  adminEvent: false, name: 'setActivitiesAsFinished', allowedRanks: ['leader'] },//true, 
	event_00010: { id: 'event_00010',  adminEvent: false, name: 'editActivitiesInfo', allowedRanks: ['leader'] },//true, 
	event_00011: { id: 'event_00011',  adminEvent: false, name: 'editSprintStatus', allowedRanks: ['leader'] },//true, 
	event_00012: { id: 'event_00012',  adminEvent: false, name: 'editSprintDeadline', allowedRanks: ['leader'] },//true, 
	event_00013: { id: 'event_00013',  adminEvent: false, name: 'deleteSprint', allowedRanks: ['leader'] },//true, 
	event_00014: { id: 'event_00014',  adminEvent: false, name: 'deleteProject', allowedRanks: ['all'] },//false,
	event_00015: { id: 'event_00015',  adminEvent: false, name: 'editBalanceScore', allowedRanks: ['leader'] },//true, 
	event_00016: { id: 'event_00016',  adminEvent: false, name: 'checkItemsInBalanceScore', allowedRanks: ['leader'] },//true, 
	event_00017: { id: 'event_00017',  adminEvent: true,  name: 'editEventsTable', allowedRanks: ['admin'] },//false,
	*/
} 

global.projects = {/*
	{
		id: 1,
		name: 'Project awesome',
		icon: 'img/sys/demoProject.png',
		semaphore: 'green',
		leader: '0',
		scrumMaster: '0',
		quarterback: '0',
		deadline: '20/12/2020',
		initialDeadline: '20/10/2020',
		balanceScore: '',
		stage: 'frame',
		scrumComments: 'Comentarios del scrum mensual\n1. Demo',
		sprints: [{
			id: 1,
			title: 'Sprint 1',
			description: 'Decripción del sprint 1.',
			deadline: '14/10/2019',
			status: 'completed',//inProcess, disabled, cancelled, completed
			helpNeeded: true,
			activities: [
				{id: 1, title: 'Activity title', description: 'Activity description', deadline: '23/02/2020', completed: true},
				{id: 1, title: 'Activity title', description: 'Activity description', deadline: '23/02/2020', completed: false},
				{id: 1, title: 'Activity title', description: 'Activity description', deadline: '23/02/2020', completed: false},
			],
		},{
			id: 2,
			title: 'Sprint 2',
			description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras odio mi, maximus sed gravida nec, accumsan tincidunt mauris. Vestibulum molestie.',
			deadline: '04/10/2019',
			status: 'inProcess',//inProcess, disabled, cancelled, completed
			helpNeeded: false,
			activities: [],
		},
		{
			id: 3,
			title: 'Sprint 3',
			description: 'Decripción del sprint 3.',
			deadline: '24/10/2019',
			status: 'disabled',//inProcess, disabled, cancelled, completed
			helpNeeded: false,
			activities: [],
		},
		{
			id: 4,
			title: 'Sprint 4',
			description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras odio mi, maximus sed gravida nec, accumsan tincidunt mauris. Vestibulum molestie.',
			deadline: '31/10/2019',
			status: 'cancelled',//inProcess, disabled, cancelled, completed
			helpNeeded: false,
			activities: [],
		},
		],
		objectives: ['Objective 1', 'Objective 2'],
		desirable: ['Eat indulgent food without any guilt'],
		feasible: ['Technology: Microwave technology', 'Experimentation on Bimbo Iberia (El vergel): Satisfactory', 'Reduction at least 7% of fat'],
		viable: ['viable 1', 'viable 2'],
		files: []
	},
	{
		id: 2,
		name: 'Project world climate',
		icon: 'img/sys/demoProject2.png',
		semaphore: 'red',
		leader: '0',
		scrumMaster: '0',
		quarterback: '0',
		deadline: '12/02/2020',
		initialDeadline: '',
		balanceScore: '',
		stage: 'scale',
		scrumComments: '',
		sprints: [],
		nextSteps: [],
		objectives: [],
		desirable: ['desirable 1', 'desirable 2'],
		feasible: ['feasible 1', 'feasible 2'],
		viable: ['viable 1', 'viable 2'],
		files: []
	},
	{
		id: 3,
		name: 'Proyecto numero 3',
		icon: 'img/sys/demoProject2.png',
		semaphore: 'green',
		leader: '0',
		scrumMaster: '0',
		quarterback: '0',
		deadline: '12/02/2020',
		initialDeadline: '',
		balanceScore: '',
		stage: 'understand',
		scrumComments: '',
		sprints: [],
		nextSteps: [],
		objectives: [],
		desirable: ['desirable 1', 'desirable 2'],
		feasible: ['feasible 1', 'feasible 2'],
		viable: ['viable 1', 'viable 2'],
		files: []
	},
	{
		id: 4,
		name: 'Project 4',
		icon: 'img/sys/demoProject2.png',
		semaphore: 'red',
		leader: '0',
		scrumMaster: '0',
		quarterback: '0',
		deadline: '12/02/2020',
		initialDeadline: '',
		balanceScore: '',
		stage: 'experiment',
		scrumComments: '',
		sprints: [],
		nextSteps: [],
		objectives: [],
		desirable: ['desirable 1', 'desirable 2'],
		feasible: ['feasible 1', 'feasible 2'],
		viable: ['viable 1', 'viable 2'],
		files: []
	},
	{
		id: 5,
		name: 'Project 4',
		icon: 'img/sys/demoProject2.png',
		semaphore: 'red',
		leader: '0',
		scrumMaster: '0',
		quarterback: '0',
		deadline: '12/02/2020',
		initialDeadline: '',
		balanceScore: '',
		stage: 'research',
		scrumComments: '',
		sprints: [],
		nextSteps: [],
		objectives: [],
		desirable: ['desirable 1', 'desirable 2'],
		feasible: ['feasible 1', 'feasible 2'],
		viable: ['viable 1', 'viable 2'],
		files: []
	},*/
};
