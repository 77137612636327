import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, ButtonSize, FormatDateShortest, GetSprintStatusIcon,
	LargeButtonSize, ClampLengthAt, GetPresentSprints, Colors, FormatDateShort, SeparatorWidth, 
	GetUserNameById, Sleep, SmallButtonSize, GetFutureSprints, GetAllActiveProjects, IsNullOrEmpty,
	GetAllSprintsByDate, IsTodayBetweenTheseDates, MaximumNumberOfSprintsToShowInPresentationMode,
	PresentationHeaders, PresentationSubheaders, PresentationText, GetTimeDifference, DateFormat } from '../../constants';
import { Header, Separator, Label, ProjectIcon, Div, LoadingScreen, BulletPointsList, 
	Subheader, Image, Parameter, Space, Button, Checkbox, WideButton, SprintInRow } from '../../components';
import Moment from 'moment';
import './Presentation.css';
//References:
//https://freefrontend.com/css-slideshows/
//https://codepen.io/Reklino/pen/wawopV

const AnimationDuration = 0.47e3;
let NavBarHeight = '5vh';

class PresentationNew extends Component {

	state = {
		allProjects: [],
		currentProjectIndex: 0,
		nextProjectIndex: -1,
		loadNextProject: false,
		maximumNumberOfSprintsToShowInPresentationMode: MaximumNumberOfSprintsToShowInPresentationMode
	}

	async componentDidMount() {
		this.setState({
			allProjects: GetAllActiveProjects(),
			loadNextProject: true,
			nextProjectIndex: 0, 
			currentProjectIndex: -1,
		});
		await Sleep(AnimationDuration);
		this.setState({
			loadNextProject: false,
			currentProjectIndex: this.state.nextProjectIndex,
			nextProjectIndex: -1, 
		});
	}

	componentWillReceiveProps() {
		let currentProjects = JSON.stringify(this.state.allProjects);
		let newProjects = JSON.stringify(GetAllActiveProjects());

		if(currentProjects !== newProjects)
			this.setState({allProjects: GetAllActiveProjects()});
	}

	// renderBullets(title, bulletsArray) {
	// 	if(IsNullOrEmpty(bulletsArray))
	// 		return <div />;
	// 	return (
	// 		<div>
	// 			<Subheader text={title} labelStyle={css.labelsSubheaders}/>
	// 			<BulletPointsList>
	// 				{
	// 					bulletsArray.map((item, i) => {
	// 						return (<Label text={item} style={css.bullets} key={i} labelStyle={css.labelsText}/>);
	// 					})
	// 				}
	// 			</BulletPointsList>
	// 		</div>
	// 	);
	// }

	renderColumnRight(project) {
		let sprints = GetAllSprintsByDate(project.sprints);//GetPresentSprints(project.sprints);
		//sprints = (sprints) ? sprints : GetFutureSprints(project.sprints);
		//let sprint = sprints[0];
		let scrumComments = project.scrumComments.split('\n');
		return (
			<div style={css.sprintCenterer}>
				{ /*this.renderSprint(sprint)*/ }
				{ this.renderSprints(sprints) }
			</div>
		);
	}

	seeAllSprints(sprints) {
		this.setState({maximumNumberOfSprintsToShowInPresentationMode: sprints.length});
	}

	renderSprints(sprints) {
		let jsx = [];
		if(sprints.length === 0)
			jsx = <Label text={GetLabel('sprintsEmpty')} labelStyle={css.labelsText}/>;

		sprints.map((item, i) => {

			if(i < this.state.maximumNumberOfSprintsToShowInPresentationMode) {
				jsx.push( <SprintInRow key={i} sprint={item} 
					flagStyle={{position: 'relative', marginTop: '-1rem'}}
					labelStyle={css.labelsText} titleLabelStyle={css.labelsSubheaders}/> );

				if((i + 1) < sprints.length)
					if(IsTodayBetweenTheseDates(item.deadline, sprints[i+1].deadline))
						jsx.push(<Separator text={GetLabel('today')}/>);
			}
			
		});

		if(this.state.maximumNumberOfSprintsToShowInPresentationMode === MaximumNumberOfSprintsToShowInPresentationMode && 
			sprints.length > this.state.maximumNumberOfSprintsToShowInPresentationMode)
			jsx.push( <WideButton text={GetLabel('seeMore')} style={{width: '80%', marginLeft: '13%'}}
						event={{id: 'event_00000', onClick:()=>this.seeAllSprints(sprints) }}/> );

		return (
			<div style={css.sprintsContainer}>
				{ jsx }
			</div>
		);
	}

	async loadPreviousProject() {
		let { currentProjectIndex, allProjects } = this.state;
		let nextIndex = (currentProjectIndex - 1) < 0 ? allProjects.length - 1: currentProjectIndex - 1;
		await this.loadProjectWithIndex(nextIndex);
	}

	async loadNextProject() {
		let { currentProjectIndex, allProjects } = this.state;
		let nextIndex = (currentProjectIndex + 1) > allProjects.length - 1 ? 0 : currentProjectIndex + 1;
		await this.loadProjectWithIndex(nextIndex);
	}

	async loadProjectWithIndex(nextIndex) {
		if(this.state.loadNextProject)
			return;

		this.setState({
			loadNextProject: true,
			nextProjectIndex: nextIndex,//currentProjectIndex + 1,
		});
		await Sleep(AnimationDuration);
		this.setState({
			loadNextProject: false,
			currentProjectIndex: this.state.nextProjectIndex,
			nextProjectIndex: -1,
		});
		this.columnL.scrollTop = 0;
		this.columnR.scrollTop = 0;
	}

	getTimeElapsed(project) {
		let difference = Math.abs(GetTimeDifference(Moment(project.kickOff, DateFormat), Moment(new Date())));
		// console.log('difference: ',difference);
		return difference + ' '+ GetLabel('days');
	}

	renderPresentationCard(project, type, classColumnLeft, classColumnRight) {
		if(!project)
			return <div className={'empty'}/>;
		let scrumComments = project.scrumComments.split('\n');
		if (scrumComments.length === 0 || (scrumComments.length === 1 && IsNullOrEmpty(scrumComments[0])))
			scrumComments.push(GetLabel('noScrumComments'));

		console.log('scrumComments: ',scrumComments);

		return (
			<div style={css.card}>
				
				<div style={css.columnLeftNew} className={classColumnLeft+' verticalScroll'} ref={(l) => {this.columnL = l}}>
					<Space />
					<div style={css.cardHeader}>
						<Header style={css.projectName} text={project.name} labelStyle={css.labelsHeaders}/><Space />
						<div style={{display: 'flex', flexDirection: 'row'}} className={'toColumnIfTooTight'}>
							<ProjectIcon project={project} verticalLines={false} projectIconDiameter={'min(10em, 10vw)'}/>
							<div>
								<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('leader')} 
									text={GetUserNameById(project.leader)} labelStyle={css.labelsText}/>
								<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('scrumMaster')} 
									text={GetUserNameById(project.scrumMaster)} labelStyle={css.labelsText}/>
								<Parameter icon={'img/sys/stage.png'} iconLabel={GetLabel('stage')} 
									text={GetLabel(project.stage)} labelStyle={css.labelsText}/>
								<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('timeElapsed')} 
									text={this.getTimeElapsed(project)} labelStyle={css.labelsText}/>
							</div>
						</div>
					</div>
					<Space />
					<Separator />
					<Space />
					<Subheader text={GetLabel('monthlyScrumComments')} labelStyle={css.monthlyScrumCommentsSubheader}/>
					{
						scrumComments.map((item, i) => {
							return <Label key={i} text={item} style={css.bullets} labelStyle={{...css.labelsText, ...{lineHeight: '2rem'}}}/>;
						})
					}
					<Space /><Space />
				</div>
				<div style={css.columnRightNew} className={classColumnRight+' verticalScroll'} ref={(r) => {this.columnR = r}}>
					<Space />
					{ this.renderColumnRight(project) }
					<Space /><Space />
				</div>
				
			</div>
		);
	}

	currentProject() {
		return this.state.allProjects[this.state.currentProjectIndex];
	}

	nextProject() {
		return this.state.allProjects[this.state.nextProjectIndex];
	}

	renderNavigationBar() {
		return (
			<div style={css.navBar}>
				<Button event={{id: 'event_00000', onClick: ()=> this.loadPreviousProject()}} buttonStyle={css.arrowButton}
					style={{transformOrigin: 'left'}}>
					<Image src={'img/sys/left.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
					<Label text={GetLabel('previous').toUpperCase()} className={'hiddenInSmallScreens'}
						labelStyle={css.arrowButtonLabel} style={css.noSpaces}/>
				</Button>
				<div style={{display: 'flex', flexDirection: 'row'}}>
				{ 
					this.state.allProjects.map((item, index) => {
						let color = (index === this.state.currentProjectIndex) ? Colors.primary : Colors.disabled;
						if(this.state.nextProjectIndex !== -1) {
							color = (index === this.state.nextProjectIndex) ? Colors.primary : Colors.disabled;
						}

						return (
							<Button key={index} event={{id: 'event_00000', onClick: ()=>this.loadProjectWithIndex(index)}} 
								buttonStyle={css.navigationHexagons}>
								<Image src={'img/sys/hexagon.png'} color={color} 
									width={SmallButtonSize} height={SmallButtonSize}/>
							</Button>
						);
					}) 
				}
				</div>
				<Button event={{id: 'event_00000', onClick: ()=> this.loadNextProject()}} buttonStyle={css.arrowButton}
					style={{transformOrigin: 'right'}}>
					<Label text={GetLabel('next').toUpperCase()} className={'hiddenInSmallScreens'}
						labelStyle={css.arrowButtonLabel} style={css.noSpaces}/>
					<Image src={'img/sys/right.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			</div>
		);
	}

	render() {
		let className = (this.props.className) ? this.props.className : '';
		let { loadNextProject } = this.state;

		if(this.state.allProjects.length === 0)
			return <LoadingScreen />;

		return (
			<div style={{height: '100%'}} className={className}>
				<Header text={''} labelStyle={css.labelsHeaders}
					style={{position: 'fixed', margin: '0'}}
					buttonRight={[
						{id: 'event_00000', icon: 'img/sys/exit.png', onClick: ()=> this.props.goBack()}
					]}/>
				<div style={css.container}>
					{/* this.renderPresentationCard(this.currentProject(), 'current',
						loadNextProject ? 'centerToBottom' : 'topToCenter', 
						loadNextProject ? 'centerToTop' : 'bottomToCenter') */}
					{/* this.renderPresentationCard(this.nextProject(), 'next',
						loadNextProject ? 'centerToBottom' : 'topToCenter', 
						loadNextProject ? 'centerToTop' : 'bottomToCenter') */}

					{ this.renderPresentationCard(this.currentProject(), 'current',
						loadNextProject ? 'centerToBottom' : '', 
						loadNextProject ? 'centerToTop' : '') }
					{ this.renderPresentationCard(this.nextProject(), 'next',
						loadNextProject ? 'topToCenter' : 'centerToBottom', 
						loadNextProject ? 'bottomToCenter' : 'centerToTop') }
				</div>
				{ this.renderNavigationBar() }
			</div>
		);
	}
}

const css = {
	container: {
		height: '100%',
		//paddingLeft: '3vw',
		//paddingRight: '3vw',
	},
	scrollable: {
		overflowX: 'scroll',
		display: 'flex',
		height: `calc(${ProjectIconDiameter} + 6em)`,
	},
	columnsContainerNew: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
		minWidth: '100%',
		overflowY: 'visible',
	},
	navigationHexagons: {
		padding: '0.5vw',
	},
	labelsSubheaders: {
		fontSize: PresentationSubheaders,
	},
	monthlyScrumCommentsSubheader: {
		fontSize: `calc(${PresentationSubheaders} * 1.1)`,
	},
	labelsHeaders: {
		fontSize: `calc(${PresentationHeaders} * 2.8)`,
		textAlign: 'center',
	},
	labelsText: {
		fontSize: PresentationText,
		lineHeight: '1.5rem',
	},
	card: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexDirection: 'row',
		width: '100%',
		minWidth: '100%',
		//overflowY: 'scroll',
		//background: 'blue',
		height: `calc(100% - ${NavBarHeight} - 5vh)`,
	},
	projectName: {
		justifyContent: 'center',
		marginLeft: '0em',
		marginBottom: '2.3rem',
	},
	cardHeader: {

	},
	checkbox: {
		marginTop: '0',
		marginBottom: '0',
	},
	columnLeftNew: {
		//background: '#00ffff80',
   		overflowY: 'scroll',
   		overflowX: 'hidden',
   		flex: 0.4,
   		height: `100%`,
   		top: '0',
   		display: 'flex',
   		flexDirection: 'column',
   		justifyContent: 'flex-start',
   		paddingLeft: '3vw',
   		paddingTop: '3vh',
	},
	columnRightNew: {
		//background: '#ff00ff80',
   		overflowY: 'scroll',
   		overflowX: 'visible',
   		flex: 0.6,
   		height: `100%`,
   		top: '0',
   		display: 'flex',
   		flexDirection: 'column',
   		justifyContent: 'flex-start',
   		paddingRight: '3vw',
   		paddingTop: '3vh',
	},
	sprintCenterer: {
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center',
		//height: '100%',
	},
	sprintRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	navBar: {
		height: NavBarHeight,
		maxHeight: NavBarHeight,
		minHeight: NavBarHeight,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		position: 'absolute',
		bottom: '0.5em',
	},
	arrowButton: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	noSpaces: {
		margin: '0',
		padding: '0',
	},
	arrowButtonLabel: {
		lineHeight: ButtonSize,
		color: Colors.primary,
		fontSize: PresentationText,
	},
	bullets: {
		marginBottom: '0.1em',
		marginTop: '0.1em',
		paddingBottom: '0',
		paddingTop: '0',
	}, 
	sprintCard: {
		background: Colors.background_1+'33',
		border: `${SeparatorWidth} solid #00000044`, 
		borderRadius: '1em',
		padding: '1em'
	},
	activitiesElement: {
		padding: '0',
		marginLeft: '0',
		marginRight: '0',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
}

export { PresentationNew };