import React, { Component } from 'react';
import { Colors, UploadLocalLabels, UploadLocalEvents, SortLabelsAlphabetically, 
	UpdateMyInfoInRealtimeDatabase, IsLocalHost, Environment, GetLabel, SmallFontSize,
	IsNullOrEmpty, Clone, UpdateAdminObject, ButtonSize } from './constants';
import { Label } from './components';
import { Login, MainScreen, Maintenance } from './screens';
import { InitializeRealtimeDatabase, InitializeFirebase, UploadObject, IsOnlineValidation } from './firebase';
import './constants/DataStructures.js';
import './Animations.css';
import './constants/plugins/Mousechaser.css';
import * as firebase from 'firebase';

const LocalStorage = require('local-storage');
const FirstScreenToLoad = 'login';//login, main, maintenance. login es la opcion por default

class App extends Component {
	state = {
		currentScreen: FirstScreenToLoad,
		onlineStatus: 'online',
	};

	constructor(props) {
		super(props);
		//this.mouseChaser = new Mousechaser();
		InitializeFirebase();
		SortLabelsAlphabetically();

		window.addEventListener('offline', this.setState({onlineStatus: 'offline'}));
		window.addEventListener('online',  this.setState({onlineStatus: 'online'}));

		global.onlineCheckInterval = 4e3;
		global.onlineStatus = true;
		this.checkIfIAmOnline();
		setInterval(() => { 
			if(window.navigator.onLine) 
				this.checkIfIAmOnline();
			else
				global.onlineStatus = false;
		}, global.onlineCheckInterval);
	}

	checkIfIAmOnline() {
		console.log('window.location.href: '+window.location.href);

		let url = window.location.href;
		// let url = 'https://app.gbtentech.com/';
		// let url = 'http://webcode.me';
		// let url = 'http://localhost:3000/';

		IsOnlineValidation(url).then(isOnline => {
			global.onlineStatus = isOnline;
			let isOnlineLabel = (isOnline) ? 'online' : 'offline';

			if (isOnlineLabel !== this.state.onlineStatus) {
				this.setState({onlineStatus: isOnlineLabel});
			}
		});
	}

	uploadLabelsIfLocalHost(snapshot) {
		if(UploadLocalLabels && JSON.stringify(global.labels) !== JSON.stringify(snapshot.labels))
			UploadObject('labels', global.labels, 
				()=>console.log('Labels uploaded'), ()=>console.log('Error uploading labels'));//UploadLabels();
		else
			global.labels = IsNullOrEmpty(snapshot.labels) ? {} : snapshot.labels;

		//EVENTS
		/*
		if(UploadLocalEvents && JSON.stringify(global.events) !== JSON.stringify(snapshot.events))
			UploadObject('events', global.events, 
				()=>console.log('Events uploaded'), ()=>console.log('Error uploading events'));//UploadEvents();
		else
			global.events = snapshot.events;*/
	}

	onLoginSuccess(user) {
		InitializeRealtimeDatabase((snapshot) => {
			// console.log('global.initializedDatabase: '+global.initializedDatabase);
			if(snapshot) {
				global.admin = IsNullOrEmpty(snapshot.admin) ? {} : snapshot.admin;
				global.projects = IsNullOrEmpty(snapshot.projects) ? {} : snapshot.projects;
				global.users = IsNullOrEmpty(snapshot.users) ? {} : snapshot.users;
				global.ranks = IsNullOrEmpty(snapshot.ranks) ? {} : snapshot.ranks;
				global.ranksProject = IsNullOrEmpty(snapshot.ranksProject) ? {} : snapshot.ranksProject;
				global.domainNames = IsNullOrEmpty(snapshot.domainNames) ? {} : snapshot.domainNames;
				global.events = IsNullOrEmpty(snapshot.events) ? {} : snapshot.events;
				global.minutas = IsNullOrEmpty(snapshot.minutas) ? {} : snapshot.minutas;
				global.retrospectives = IsNullOrEmpty(snapshot.retrospectives) ? {} : snapshot.retrospectives;
				global.capabilities = IsNullOrEmpty(snapshot.capabilities) ? {} : snapshot.capabilities;
				global.pendings = IsNullOrEmpty(snapshot.pendings) ? {} : snapshot.pendings;

				LocalStorage('admin', JSON.stringify(global.admin));
				if(global.initializedDatabase !== 'INITIALIZED')
					UpdateMyInfoInRealtimeDatabase(user);
				// UpdateAdminObject({
				// 	colors: {
				// 		primary: '#ffbb00',
				// 		primaryDarker: '#906E11',
				// 		secondary: '#ffffff',
				// 		background_1: '#222222',
				// 		background_2: '#161616',
				// 		disabled: "#666666",
				// 		buttonBackground: '#39321E',
				// 		black: '#000000',
				// 		transparent: '#00000000',
				// 		green: '#6EDA12',
				// 		red: '#CF0021',
				// 		debugLabels: '#00ffff',
				// 	}, 
				// 	defaultColors: {
				// 		primary: '#ffbb00',
				// 		primaryDarker: '#906E11',
				// 		secondary: '#ffffff',
				// 		background_1: '#222222',
				// 		background_2: '#161616',
				// 		disabled: "#666666",
				// 		buttonBackground: '#39321E',
				// 		black: '#000000',
				// 		transparent: '#00000000',
				// 		green: '#6EDA12',
				// 		red: '#CF0021',
				// 		debugLabels: '#00ffff',
				// 	}, 
				// 	language: 'default'
				// });

				//LABELS
				if (IsLocalHost()) 
					this.uploadLabelsIfLocalHost(snapshot);
				else 
					global.labels = IsNullOrEmpty(snapshot.labels) ? {} : snapshot.labels;


				// if(FirstScreenToLoad === 'maintenance') {
				// 	firebase.database().ref(`/`).on('value', (snapshot) => {
				// 		let fullDatabase = Clone(snapshot.val());
				// 		fullDatabase.ddt_production = Clone(fullDatabase.ddt_testing);

				// 		console.log('LOGGING ALL DATABASE: ',fullDatabase);
				// 		console.log('fullDatabase JSON: ',JSON.stringify(fullDatabase));
				// 	});
				// }

				this.setState({refresh: true});
			}
			global.initializedDatabase = 'INITIALIZED';
		});

		this.setState({currentScreen: 'main'});
	}

	getCurrentComponent() {
		switch(this.state.currentScreen) {
			case 'firstUse': 
				return (<div/>);
			case 'login':
				return (<Login onLoginSuccess={(user)=> this.onLoginSuccess(user)}/>);
			case 'main':
				return (<MainScreen onLogOut={()=> { global.initializedDatabase = undefined; this.setState({currentScreen: 'login'}); }}/>);
			case 'maintenance':
				return (<Maintenance onLoginSuccess={(user)=> this.onLoginSuccess(user)}/>);
			default:
				return (<Login onLoginSuccess={(user)=> this.onLoginSuccess(user)}/>);
		}
	}

	renderEnvironmentLabel() {
		if(Environment === 'production')
			return <div/>;
		else
			return (
				<div style={css.environmentLabel}>
					<Label style={{margin: '0'}} labelStyle={{fontSize: SmallFontSize}} 
						text={GetLabel(Environment+'Environment')}/>
				</div>
			);
	}

	renderOnlineIndicator() {
		let onlineStatusLabel = (this.state.onlineStatus === 'online') ? 'onlineLabel' : 'offlineLabel';
		// let customStyle = (this.state.onlineStatus === 'online') ? { color: Colors.green } : { color: Colors.red };

		let circleIndicator = (<div style={{background: (this.state.onlineStatus === 'offline') ? Colors.red : Colors.green, borderRadius: '50%', width: '0.7em', height: '0.7em', marginRight: '0.5em'}}/>);

		return (
			<div style={css.onlineStatus}>
				<Label style={{margin: '0', width: 'auto', height: '100%', justifyContent: 'center'}} labelStyle={{width: 'auto', fontSize: '1rem', color: (this.state.onlineStatus === 'offline') ? Colors.red : Colors.green, fontWeight: 'bold'}} //{...{fontSize: SmallFontSize}, ...{customStyle}
					text={GetLabel(onlineStatusLabel)} childrenAsPrefix={circleIndicator}>
				</Label>
			</div>
		);
		
	}

	render() {
		return (
			<div style={css.mainContainer}>
				{ this.renderOnlineIndicator() }
				{ this.getCurrentComponent() }
				{ this.renderEnvironmentLabel() }
			</div>
		);
	}
}

const css = {
	mainContainer: {
		width: '100%',
	    height: '100%',
	    position: 'fixed',
	    bottom: '0',
	    fontFamily: 'Consolas !important',
	    /*
	    background: 'linear-gradient(-45deg,#161616 25%,#000 0,#000 50%,#161616 0,#161616 75%,#000 0,#000)',
		backgroundRepeat: 'repeat',
	    backgroundSize: '3.4em 3.4em',
	    */
	    background: 'url(img/sys/stars.png)',//'`linear-gradient(-45deg, ${Colors.background_1} 0,${Colors.background_1} 65%,${Colors.background_2} 0,${Colors.background_2})`,
	    //boxShadow: 'inset 0 0 10em 2em #000',
	    backgroundSize: 'cover',
	    backgroundRepeat: 'no-repeat',
	    backgroundPosition: 'center center',
	    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 3em 0em inset',
	    zIndex: '-1',
	},
	environmentLabel: {
		position: 'fixed',
		bottom: '0',
		width: '100vw',
		background: '#000000aa',
		zIndex: '20',
		textAlign: 'center',
		pointerEvents: 'none',
	},
	onlineStatus: {
		position: 'fixed',
		top: '0',
		width: '100vw',
		height: ButtonSize,
		maxHeight: ButtonSize,
		minHeight: ButtonSize,
		background: 'transparent',
		zIndex: '20',
		textAlign: 'center',
		pointerEvents: 'none',
		justifyContent: 'center'
	}
}

export default App;
