import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, 
	DefaultRank, DefaultDomainName, Today } from './';
import { UploadObject } from '../firebase';

export function UpdateEvents(events, onSuccess, onFail) {
	UploadObject('events', events, 
		()=> {
			console.log('Events uploaded: ',events);
			global.events = Clone(events);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading events: ',events);
			if(onFail)
				onFail();
		}
	);
}