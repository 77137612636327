import React, { Component } from 'react';
import { Colors, FontName, MainFontSize, SubHeadersSize } from '../constants';

let inputFieldHorizontalPadding = '2em';
let inputFieldBorderWidth = '2px';

class InputField extends Component {
	state = {
		value: '',
	}

	componentDidMount() {
		this.setState({value: this.props.value});
		if(this.textArea) 
			this.textArea.style.height = this.getTextAreaHeightOfContent(this.textArea);
	}

	updateContent(event) {
		this.setState({value: event.target.value});
		if(this.props.onChange)
			this.props.onChange(event.target.value);
	}

	updateSize(event) {
		// Reset field height
		event.target.style.height = 'inherit';
    	event.target.style.height = this.getTextAreaHeightOfContent(event.target);//`${height}px`;
    	this.setState({value: event.target.value});
    	if(this.props.onChange)
			this.props.onChange(event.target.value);
	}

	getTextAreaHeightOfContent(target) {
		// Get the computed styles for the element
   		const computed = window.getComputedStyle(target);

	    // Calculate the height
	    //const height = event.target.scrollHeight;
	    const height = parseInt(computed.getPropertyValue('border-top-width'), 3)
                 + parseInt(computed.getPropertyValue('padding-top'), 3)
                 + target.scrollHeight
                 + parseInt(computed.getPropertyValue('padding-bottom'), 3)
                 + parseInt(computed.getPropertyValue('border-bottom-width'), 3);

        return height+'px';
	}

	handleKeyDown (e) {
		if (e.key === 'Enter') 
			if(this.props.onEnter)
				this.props.onEnter();
	}

	render() {
		let jsx = undefined;
		let styleIfDisabled = (this.props.disabled) ? css.disabled : {};

		if(this.props.multiline) //rows={this.state.rows}
			jsx =<textarea ref={(ta) => this.textArea = ta} 
				draggable={false} 
				disabled={this.props.disabled} 
				style={{...css.textArea, ...styleIfDisabled}} 
				placeholder={this.props.placeholder} 
				onChange={(event)=>this.updateSize(event)} 
				value={this.props.value}/>;
		else
			jsx = <input type={this.props.type}
				draggable={false} 
				disabled={this.props.disabled} 
				maxLength={this.props.maxlength}
				style={{...css.inputField, ...styleIfDisabled, ...this.props.inputStyle}} 
				placeholder={this.props.placeholder} 
				onChange={(event)=>this.updateContent(event)} 
				value={this.props.value} onKeyDown={(e) => this.handleKeyDown(e)}/>;
		return (
			<div style={{...css.container, ...this.props.style}}>
				{ jsx }
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
	},
	disabled: {
		backgroundColor: Colors.transparent, 
		border: `calc(${inputFieldBorderWidth}) solid ${Colors.primaryDarker}55`,
		color: Colors.primary,
		fontSize: SubHeadersSize,
	},
	textArea: {
		backgroundColor: Colors.background_1,
		color: Colors.secondary,
		fontFamily: FontName,
		border: `${inputFieldBorderWidth} solid ${Colors.primary}`,
		borderRadius: '0.5em',
		fontSize: MainFontSize,
		marginTop: '1em',
		marginBottom: '1em',
		paddingLeft: inputFieldHorizontalPadding,
		paddingRight: inputFieldHorizontalPadding,
		paddingTop: '1em',
		paddingBottom: '1em',
		letterSpacing: '2px',
		overflow: 'hidden',
		width: `calc(100% - ${inputFieldHorizontalPadding}*2 - ${inputFieldBorderWidth} * 2)`,
		minWidth: `calc(100% - ${inputFieldHorizontalPadding}*2 - ${inputFieldBorderWidth} * 2)`,
		maxWidth: `20vw`,
		height: '5em'
	},
	inputField: {
		backgroundColor: Colors.background_1+'aa',
		border: `${inputFieldBorderWidth} solid ${Colors.primary}`,
		borderRadius: '0.5em',
		paddingTop: '1em',
		paddingBottom: '1em',
		paddingLeft: inputFieldHorizontalPadding,
		paddingRight: inputFieldHorizontalPadding,
		color: Colors.secondary,
		fontFamily: FontName,
		fontSize: MainFontSize,
		letterSpacing: '2px',
		marginTop: '1em',
		marginBottom: '1em',
		//width: '25em',
		//maxWidth: '70vw',
		width: `calc(100% - ${inputFieldHorizontalPadding}*2 - ${inputFieldBorderWidth} * 2)`,
	}
}

export { InputField };
