import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize,
	ButtonSize, IsNullOrEmpty, DirtyStyle, Clone, PushMinuta, GetMinutaById,
	Today, DateFormat, ObjectToArray, GetMinutaKeyById, Sleep,
	} from '../../constants';
import { SideMenu, Header, SeparatorLarge, Label, BulletPointsList, Subheader, WideButton, 
	Div, Expansible, Space, ListButton, MinutaListEditor, Image, Button, InputField, 
	Separator, ConfirmationButtons, Loading } from '../../components';
import { SendPushNotification } from '../../firebase';
import * as firebase from 'firebase';

class MinutaEditor extends Component {

	state = {
		toDoList: [],
		newToDoPoint: '',
		isLoading: false,
		isDirty: false,
		sendingEmail: false,
		emailSent: false
	}

	componentDidMount() {
		let { minuta } = this.props.navParams;

		if(IsNullOrEmpty(minuta)) {
			minuta = {};
			minuta.toDoList = [];
		}

		if(!IsNullOrEmpty(minuta.toDoList)) {
			this.initialToDoList = Clone(minuta.toDoList);
			this.setState({toDoList: Clone(minuta.toDoList)});
		}
		else
			this.initialToDoList = [];
	}

	addNewMainPoint() {
		let newToDoList = this.state.toDoList.slice(0);

		newToDoList.push({
			name: this.state.newToDoPoint,
			points: [],
			hideCheckbox: false,
		});

		this.setState({
			newToDoPoint: '',
			isDirty: true, 
			toDoList: Clone(newToDoList)
		});
	}

	editMainPointText(index, newValue) {
		let newToDoList = this.state.toDoList.slice(0);
		newToDoList[index].name = newValue;

		this.setState({
			isDirty: true, 
			toDoList: Clone(newToDoList)
		});
	}

	editHideCheckbox(index, newValue) {
		let newToDoList = this.state.toDoList.slice(0);
		// console.log('1. newToDoList[index]: ',newToDoList[index]);
		newToDoList[index].hideCheckbox = newValue;
		// console.log('2. newToDoList[index]: ',newToDoList[index]);
		// console.log('-------------------------');

		this.setState({
			isDirty: true, 
			toDoList: Clone(newToDoList)
		});
	}

	deleteOneMainPoint(index) {
		let newToDoList = this.state.toDoList.slice(0);

		newToDoList.splice(index, 1);

		this.setState({
			isDirty: true, 
			toDoList: Clone(newToDoList)
		});
	}

	modifyList(index, newListOfPoints) {
		let newToDoList = Clone(this.state.toDoList);
		newToDoList[index].points = Clone(ObjectToArray(newListOfPoints));

		// console.log('newToDoList: ',newToDoList);

		this.setState({
			isDirty: true,
			toDoList: Clone(newToDoList)
		});
	}

	saveChanges() {
		this.setState({isLoading: true });

		let { minuta } = this.props.navParams;

		minuta = IsNullOrEmpty(minuta) ? global.minutas[GetMinutaKeyById(Today())] : minuta;
		minuta = IsNullOrEmpty(minuta) ? {} : minuta;

		if(IsNullOrEmpty(minuta.id)) {
			minuta.id = Today();
			minuta.createdBy = global.profile.id;//'dYEY9R6mACOKc2PmlKHW956znXu2',//user UD
			minuta.creationMoment = Today(DateFormat+' HH:mm');//'22/12/2020 20:12',
			minuta.lastEditedBy = global.profile.id;
			minuta.lastModification = Today(DateFormat+' HH:mm');
			minuta.toDoList = Clone(this.state.toDoList);
		}
		else {
			minuta = GetMinutaById(minuta);
			minuta.lastEditedBy = global.profile.id;//user UD
			minuta.lastModification = Today(DateFormat+' HH:mm');
			minuta.toDoList = Clone(this.state.toDoList);
		}

		SendPushNotification({
			messageTitle: GetLabel('changesInMinuta'),
			messageBody: GetLabel('minutaWasEdited'),
			projectLeader: undefined,
			projectScrumMaster: undefined,
			projectQuarterback: undefined,
			targetUsers: ['rank_00000', 'rank_00002', 'rank_00003', 'rank_00004'],
		});

		PushMinuta(minuta, 
			() => { 
				this.props.navParams.minuta = Clone(minuta); 
				this.initialToDoList = Clone(minuta.toDoList); 
				this.clearDirtyState(); 
			}, 
			() => this.setState({ isLoading: false }));
	}

	clearDirtyState() {
		this.setState({
			isDirty: false,
			isLoading: false, 
			toDoList: Clone(this.initialToDoList),
			newToDoPoint: '',
		});
	}

	renderSaveButtonIfDirty() {
		let { isDirty } = this.state;
		
		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	renderCheckIfChecked(checked) {
		let color = Colors.primary;
		let icon = 'img/sys/check.png';
		checked = !checked;
		if(checked)
			return (
				<Image height={`calc(${ButtonSize}/2)`} width={`calc(${ButtonSize}/2)`} maskSize={`calc(${ButtonSize}*0.8)`}
					src={icon} color={color} className={'appear'} imgStyle={{height: '130%', marginTop: '-15%', marginLeft: '-15%'}}/>
			);
		else
			return <div />;
	}

	renderToDoPointsEditor() {
		let { minuta } = this.props.navParams;

		if(IsNullOrEmpty(minuta)) {
			minuta = {};
			minuta.toDoList = [];
		}

		let toDoList = [];

		
		if(this.state.isDirty)
			toDoList = Clone(this.state.toDoList);
		else {
			minuta.toDoList = (minuta.toDoList) ? minuta.toDoList : [];
			toDoList = Clone(minuta.toDoList);
		}

		let jsx = [];
		toDoList.map((toDoItem, i) => {
			jsx.push(
				<div key={i}>
					<div style={css.mainPontContainer}>
						{/*<Subheader 
							text={toDoItem.name} 
							buttonRight={{id: 'event_00000', icon: 'img/sys/delete.png', onClick: ()=> {} }}
							style={ {...css.noHorizontalSpace, ...{paddingLeft: '0.5em'}} }
							labelStyle={{color: Colors.primary}}/>*/}
						<InputField 
							placeholder={toDoItem.name} value={toDoItem.name}
							inputStyle={{marginTop: '0px', marginBottom: '0px'}}
							onChange={ (newValue) => {
								this.editMainPointText(i, newValue)
							}}
							/>
						<div style={css.iconContainer}>
							<Button style={css.checkbox} buttonStyle={css.checkboxButton}
								className={''}
								event={{id: 'event_00000', onClick: () => {
									this.editHideCheckbox(i, !toDoItem.hideCheckbox)
								}}}>
								{ this.renderCheckIfChecked(toDoItem.hideCheckbox) }
							</Button>
						</div>
						<Button event={{id: 'event_00000', onClick: ()=> this.deleteOneMainPoint(i)}}>
							<Image src={'img/sys/delete.png'}  width={ButtonSize} height={ButtonSize} color={Colors.red}/>
						</Button>
					</div>
					<MinutaListEditor 
						keyIndex={i}
						list={toDoItem.points} 
						baseObject={{checked: false}}
						//objectKeyToPlaceValue={'name'}
						placeholder={GetLabel('addNewToDoPointTo')+toDoItem.name} 
						eventId={'event_00000'} 
						onModify={(newList) => { this.modifyList(i, newList) }}/>
					<Space /><Space />
					<SeparatorLarge disableBackgroundLines/>
					<Space /><Space />
				</div>
			)
		});

		jsx.push(
			<div style={css.adderSectionContainer} key={'inputField'}>
				<InputField onChange={ (newValue) => {this.setState({ newToDoPoint: newValue })} }
					placeholder={GetLabel('addNewPoint')} value={this.state.newToDoPoint}/>
				{ this.renderAddButtonIfNeeded() }
			</div>
		);

		return jsx;
	}

	async isEmailSent() {
		await Sleep(5e3);
		this.setState({sendingEmail: false, emailSent: true});
	}

	renderAddButtonIfNeeded() {
		if(IsNullOrEmpty(this.state.newToDoPoint))
			return <div />;
		else
			return (
				<Button event={{id: 'event_00000', onClick: ()=>this.addNewMainPoint()}}>
					<Image src={'img/sys/add.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			);
	}

	render() {
		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<Header text={GetLabel('minutaEditor')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					{ this.renderToDoPointsEditor() }
					{ this.renderSaveButtonIfDirty() }
					{
						(this.state.emailSent) ? <Label text={GetLabel('emailSent')} style={{marginTop: '4rem'}} labelStyle={{textAlign: 'center'}}/> : 
						(this.state.toDoList.length === 0) ? undefined :  
						<WideButton text={GetLabel('sendMinutaViaEmail')} style={{marginTop: '4rem', marginBottom: '3rem'}}
							event={{ id: 'event_00000', onClick:() => {
								let functions = firebase.functions();
								
								let sendEmailConfirmationRequestTecnovoali = functions.httpsCallable('sendEmailConfirmationRequestTecnovoali');// let sendEmailConfirmationRequest = functions.httpsCallable('sendEmailConfirmationRequest');
								this.setState({sendingEmail: true});
								this.isEmailSent();
								
								sendEmailConfirmationRequestTecnovoali();// sendEmailConfirmationRequest();
							}, icon:'img/sys/toDo.png'}} />
					}
					<Space /><Space /><Space /><Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	noHorizontalSpace: {
		paddingRight: '0',
		paddingLeft: '0',
		marginRight: '0',
		marginLeft: '0'
	},
	adderSectionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	mainPontContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	iconContainer:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '5em',
		maxWidth: '5em',
		marginRight: '0em',
		minWidth: '5em',
	},
	checkbox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		//background: Colors.buttonBackground,
		borderRadius: '0.5em',
		border: `2px solid ${Colors.primary}`,
		//width: `calc(${ButtonSize})`,
		//height: `calc(${ButtonSize})`,
		width: `calc(${ButtonSize}/2)`,
		height: `calc(${ButtonSize}/2)`,
	},
	checkboxButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}

export { MinutaEditor };
