import React, { Component  } from 'react';
import { Colors, ProjectIconDiameter, GetLabel, Alert } from '../constants';
import { Portfolio, ProjectDetails, ProjectCore, Sprint, Files, SprintEditor, 
	ActivityEditor, PresentationNew, ProjectEditor, BalanceScore, Retrospectives } from '../screens/screens_projects';

class Projects extends Component {

	state = {
		screen: 'portfolio',
		poppedScreen: '',
		navParams: {}
	}

	componentDidMount() {
		this.stack = [];
	}

	navigate(target, params) {
		this.stack.push(target);
		this.setState({screen: target, navParams: params, poppedScreen: ''});//selectedProjectId
	}

	goBack() {
		if(global.databaseWriteInProcess) {
			Alert(GetLabel('databaseWriteInProcess'));
			return;
		}
		let poppedScreen = (this.stack.length > 0) ? this.stack[this.stack.length-1] : '';
		this.stack.pop();
		let prevScreen = (this.stack.length > 0) ? this.stack[this.stack.length-1] : 'portfolio';
		this.setState({screen: prevScreen, poppedScreen: poppedScreen});
	}

	renderFromState(type, screenName) {
		let classToAdd = undefined;

		if(type === 'popped') 
			classToAdd = 'moveOut';
		
		switch(screenName) {
			case 'portfolio':
				return (
					<Portfolio 
						navigate={ this.props.navigate } 
						innerNavigate={ (target, params) => this.navigate(target, params) }
						className={classToAdd}/>
				);
			case 'projectDetails':
				return (
					<ProjectDetails 
						//id={this.state.selectedProjectId} 
						//project={global.projects.find((item) => {return item.id === this.state.selectedProjectId})}
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={() => this.goBack()}
						className={classToAdd}/>
				);
			case 'projectCore':
				return (
					<ProjectCore 
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={() => this.goBack()}
						className={classToAdd}/>
				);
			case 'sprint': 
				return (
					<Sprint
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'files': 
				return (
					<Files
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'sprintEditor': 
				return (
					<SprintEditor
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'activityEditor':
				return (
					<ActivityEditor
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'presentation':
				return (
					<PresentationNew
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'projectEditor':
				return (
					<ProjectEditor
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'balanceScore':
				return (
					<BalanceScore
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			case 'retrospectives':
				return (
					<Retrospectives
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={()=>this.goBack()}
						className={classToAdd}/>
				);
			default:
				return <div/>; 
		}
	}

	render() {

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				{ /*this.renderFromState('popped', this.state.poppedScreen)*/ }
				{ this.renderFromState('current', this.state.screen) }
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	sectionNameContainer: {
		justifyContent: 'flex-start',
		marginLeft: '0.5em',
	},
	sectionName: {
		textTransform: 'uppercase',
		textAlign: 'left',
	},
	scrollable: {
		overflowX: 'scroll',
		display: 'flex',
		height: `calc(${ProjectIconDiameter} + 6em)`,
	},
}

export { Projects };