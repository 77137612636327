import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, GetProjectById, Colors, ButtonSize,
	SelectFile, IsNullOrEmpty, DirtyStyle, Download, Clone, 
	AsyncReplaceProjectInGlobalById, GetProjectKey, GetFileExtension } from '../../constants';
import { Header, Separator, Label, ProjectIcon, ListButton, Image, Space, 
	Div, WideButton, ConfirmationButtons } from '../../components';
import { AsyncUploadFile } from '../../firebase';

class Files extends Component {

	state = {
		isLoading: false,
		isOnePageDirty: false,
		isGeneralPresentationDirty: false,
		isSketchOrVideoDirty: false,
		isFishboneDiagramDirty: false, 
		isTreasureBucketDirty: false,
		files: {},
	}

	componentDidMount() {
		let { project } = this.props.navParams;
		let files = IsNullOrEmpty(project.files) ? {} : project.files;
		this.setState({files: Clone(files)});
	}

	selectFile(fileKey) {
		SelectFile((selectedFile, content, fileURL) => {

			let newState = {};

			if(fileKey === 'onePage')
				newState.isOnePageDirty = true;
			if(fileKey === 'generalPresentation')
				newState.isGeneralPresentationDirty = true;
			if(fileKey === 'sketchOrVideo')
				newState.isSketchOrVideoDirty = true;
			if(fileKey === 'fishBoneDiagram')
				newState.isFishboneDiagramDirty = true;
			if(fileKey === 'treasureBucket')
				newState.isTreasureBucketDirty = true;

			newState.files = Clone(this.state.files);
			newState.files[fileKey] = IsNullOrEmpty(newState.files[fileKey]) ? {} : newState.files[fileKey];
			newState.files[fileKey].url = fileURL;
			newState.files[fileKey].content = content;
			newState.files[fileKey].file = selectedFile;
			newState.files[fileKey].fileName = selectedFile.name;

			console.log('File info: ',newState.files[fileKey]);

			this.setState(newState);
		});
	}

	async saveChanges() {
		//All the upload goes here
		//Replace the project variable from the navigation params if success
		let { isOnePageDirty, isGeneralPresentationDirty, isSketchOrVideoDirty, 
			isFishboneDiagramDirty, isTreasureBucketDirty } = this.state;
		let { project } = this.props.navParams;
		this.setState({isLoading: true});

		let projectClone = Clone(project);
		projectClone.files = IsNullOrEmpty(projectClone.files) ? {} : projectClone.files;
		let error = false;
		let onFail = () => { error = true; this.setState({ isLoading: false }); };
		let onSuccess = (projectUpdated) => { this.props.navParams.project = Clone(projectUpdated); this.clearDirtyState(); };
		
		if(isOnePageDirty)
			projectClone = await this.uploadFile('onePage', projectClone, onFail);
		if(isGeneralPresentationDirty)
			projectClone = await this.uploadFile('generalPresentation', projectClone, onFail);
		if(isSketchOrVideoDirty)
			projectClone = await this.uploadFile('sketchOrVideo', projectClone, onFail);
		if(isFishboneDiagramDirty)
			projectClone = await this.uploadFile('fishBoneDiagram', projectClone, onFail);
		if(isTreasureBucketDirty)
			projectClone = await this.uploadFile('treasureBucket', projectClone, onFail);

		if(!error)
			onSuccess(projectClone);
	}

	async uploadFile(fileKey, project, onFail) {
		console.log('this.state.files', this.state.files);
		let imageURL = await AsyncUploadFile(
			'projects/'+GetProjectKey(project)+'/'+fileKey + GetFileExtension(this.state.files[fileKey].fileName), 
			//this.state.files[fileKey].file, 
			new File([this.state.files[fileKey].content], this.state.files[fileKey].fileName), 
			() => onFail()
		);

		project.files[fileKey] = IsNullOrEmpty(project.files[fileKey]) ? {} : project.files[fileKey];
		project.files[fileKey].url = imageURL;
		//project.files[fileKey].fileType = GetFileExtension(this.state.files[fileKey].file.name);
		console.log('About to start AsyncReplaceProjectInGlobalById with info: ',project.files[fileKey]);
		await AsyncReplaceProjectInGlobalById(project, () => onFail());

		return project;
	}

	isThisFileDirty(fileKey) {
		if(fileKey === 'onePage')
			return this.state.isOnePageDirty;
		if(fileKey === 'generalPresentation')
			return this.state.isGeneralPresentationDirty;
		if(fileKey === 'sketchOrVideo')
			return this.state.isSketchOrVideoDirty;
		if(fileKey === 'fishBoneDiagram')
			return this.state.isFishboneDiagramDirty;
		if(fileKey === 'treasureBucket')
			return this.state.isTreasureBucketDirty;
		return false;
	}

	renderFileRow(fileKey, project, eventId) {
		let isDirty = this.isThisFileDirty(fileKey);

		let files = IsNullOrEmpty(project.files) ? {} : project.files;
		let isFileUploaded = !IsNullOrEmpty(files[fileKey]);
		isFileUploaded = (isFileUploaded) ? !IsNullOrEmpty(files[fileKey].url) : isFileUploaded;

		let styleIfDirty = (isDirty) ? { color: Colors.green } : {};
		let labelSuffix = (isDirty) ? ` (${GetLabel('edited')})` : '';
		let color = (isDirty) ? Colors.green : (isFileUploaded) ? Colors.primary : Colors.disabled;
		let icon = (isDirty) ? '/img/sys/uploadFile.png' : (isFileUploaded) ? '/img/sys/file.png' : '/img/sys/noFile.png';

		return (
			<div style={css.listItem}>
				<div style={css.nameAndIcon}>
					<Image src={icon} 
						height={ButtonSize} width={ButtonSize} 
						color={color}/>
					<Label text={GetLabel(fileKey) + labelSuffix} labelStyle={styleIfDirty}/>
				</div>
				<div style={css.buttonsContainer}>
					{
						(isFileUploaded && !isDirty) ? 
							<WideButton  text={GetLabel('download')} style={css.smallButton}
							event={{
								id: 'event_00000', 
								onClick: () => Download(files[fileKey].url)
							}}/> : 
							undefined
					}
					<WideButton 
						text={(isDirty) ? GetLabel('selectOtherFile') : (isFileUploaded) ? GetLabel('replace') : GetLabel('selectFile')} 
						style={css.smallButton}
						event={{
							id: eventId, 
							project: project,
							onClick: ()=> this.selectFile(fileKey)
						}}/>
				</div>
			</div>
		);
	}

	clearDirtyState() {
		let { project } = this.props.navParams;
		let files = IsNullOrEmpty(project.files) ? {} : project.files;

		this.setState({
			isLoading: false,
			isOnePageDirty: false,
			isGeneralPresentationDirty: false,
			isSketchOrVideoDirty: false,
			isFishboneDiagramDirty: false, 
			isTreasureBucketDirty: false,
			files: Clone(files),
			/*
			imageURL: '',
			imageContent: '', 
			imageFile: '',*/
		});
	}

	renderSaveButtonIfDirty() {
		let { isOnePageDirty, isGeneralPresentationDirty, isSketchOrVideoDirty, 
			isFishboneDiagramDirty, isTreasureBucketDirty } = this.state;
		
		if(isOnePageDirty || isGeneralPresentationDirty || isSketchOrVideoDirty ||
			isFishboneDiagramDirty || isTreasureBucketDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	render() {
		let { sprint, project } = this.props.navParams;

		project = GetProjectById(project.id);

		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		return (
			<div style={{height: '100%'}}>
				<Header text={GetLabel('files')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					{ this.renderFileRow('onePage', project, 'event_00023') }
					<Separator />
					{ this.renderFileRow('generalPresentation', project, 'event_00023') }
					<Separator />
					{ this.renderFileRow('sketchOrVideo', project, 'event_00023') }
					<Separator />
					{ this.renderFileRow('fishBoneDiagram', project, 'event_00023') }
					<Separator />
					{ this.renderFileRow('treasureBucket', project, 'event_00023') }

					{ this.renderSaveButtonIfDirty() }

					<Space /><Space /><Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: '2em',
		paddingBottom: '2em',
	},
	nameAndIcon: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	smallButton: {
		paddingTop: '0',
		paddingBottom: '0',
		marginLeft: '0',
		marginRight: '0',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	buttonsContainer: {

	}
}

export { Files };