import React, { Component } from 'react';
import { Colors, ButtonSize, SubHeadersSize, IsNullOrEmpty, AmIAllowedToExecuteThis, DebugLabelsSize } from '../constants';
import { Image, Label } from './';

class WideButton extends Component{

	onMouseHover() {
		if(!IsNullOrEmpty(this.props.event.id))
			this.showFollower();
	}

	hideFollower(){
		document.getElementById('follower').style.display = 'none';
	}

	showFollower(){
		document.getElementById('follower').style.display = 'block';
	}
	
	renderEventId() {
		if(!global.isInDebug) 
			return undefined;
		else {
			let id = (Array.isArray(this.props.event.id)) ? this.props.event.id.join(',') : 
				(IsNullOrEmpty(this.props.event.id)) ? 'undef' : this.props.event.id.substr(8);
			return (<Label text={id} style={css.eventIdContainer} labelStyle={css.eventIdLabel}/>);
		}
	}

	render() {
		let iconColor = (this.props.iconColor) ? this.props.iconColor : Colors.primary;
		let icon = (this.props.event.icon) ? 
			(<Image src={this.props.event.icon} width={ButtonSize} height={ButtonSize} color={iconColor} style={css.icon} />) : 
			undefined;

		if(AmIAllowedToExecuteThis(this.props.event.id, this.props.event.project, this.props.event.retrospectivesFactories))
			return (
				<button style={{...css.button, ...this.props.style}} className={'buttonLarge'}
					onClick={() => {this.props.event.onClick(); this.hideFollower(); global.lastActionTimer = 0;}}
					onMouseOver={()=>this.onMouseHover()} onMouseOut={()=>this.hideFollower()}>
					{ icon }
					<Label text={this.props.text} style={{...css.labelContainer, ...this.props.labelContainerStyle}} labelStyle={{...css.labelStyle, ...this.props.labelStyle}}/>
					{ this.renderEventId() }
				</button>
			);
		else 
			return <div />;
	}
}

const css = {
	button: {
		margin: '0',
		backgroundColor: Colors.primary+'50',
		border: `2px solid ${Colors.primary}`,
		borderRadius: '0.5em',
		paddingTop: '1em',
		paddingBottom: '1em',
		paddingLeft: '2em',
		paddingRight: '2em',
		color: Colors.primary,
		fontSize: '1rem',
		letterSpacing: '2px',
		marginTop: '1em',
		marginBottom: '1em',
		width: '100%',
		//maxWidth: 'calc(70vw + 4em)',
	},
	icon: {
		position: 'absolute',
		marginTop: `calc(${ButtonSize} / -2.8)`,
		marginLeft: `calc(${ButtonSize} / -2.8)`,
	},
	labelContainer: {
		margin: '0',
		padding: '0',
		justifyContent: 'center',
	},
	labelStyle: {
		color: Colors.primary,
		fontSize: SubHeadersSize,
	},
	eventIdContainer: {
		display: 'block',
		position: 'absolute',
		left: '0',
		right: '0',
		padding: '0',
		marginTop: '-0.3em',
	},
	eventIdLabel: {
		color: Colors.debugLabels,
		fontSize: DebugLabelsSize,
	}
}

export { WideButton };
