import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone } from './';
import { UploadObject, UploadFile, AsyncUploadObject } from '../firebase';
import Moment from 'moment';


export function GetProjectKey(project) {
	return 'project_'+PadNumber(project.id, DefaultPadNumber);
}

export function GetSprintKey(sprint) {
	return 'sprint_'+PadNumber(sprint.id, DefaultPadNumber);
}

export function GetActivityKey(activity) {
	return 'activity_'+PadNumber(activity.id, DefaultPadNumber);
}

//GET
export function GetProjectById(id) {
	//return global.projects.find((project) => {return project.id === id});
	return global.projects['project_'+(PadNumber(id, DefaultPadNumber))];
}

export function GetSprintById(project, sprint) {
	/*
	if(!sprint)
		return undefined;
	project = GetProjectById(project.id);
	let sprintId = sprint.id;
	if(!project.sprints)
		return undefined;
	return project.sprints.find((sprintIterator) => {return sprintIterator.id === sprintId});
	*/

	if(!sprint)
		return undefined;

	let projectKey = GetProjectKey(project);
	let sprintKey = GetSprintKey(sprint);

	//let sprintId = sprint.id;
	if(!global.projects[projectKey].sprints)
		return undefined;
	//return project.sprints.find((sprintIterator) => {return sprintIterator.id === sprintId});
	return global.projects[projectKey].sprints[sprintKey];
}

export function GetActivityById(project, sprint, activity) {
	/*
	if(!sprint.activities)
		return undefined;
	return sprint.activities.find((activity) => {return activity.id === activityId});*/
	if(!activity)
		return undefined;

	sprint = GetSprintById(project, sprint);
	if(!sprint)
		return undefined;

	if(!sprint.activities)
		return undefined;

	let activityKey = GetActivityKey(activity);
	return sprint.activities[activityKey];
	
}

//REPLACE
export function ReplaceProjectInGlobalById(projectUpdated, onSuccess, onFail) {
	//let index = GetItemIndexById(global.projects, projectUpdated);
	let key = GetProjectKey(projectUpdated);
	if(projectUpdated.id !== -1) {
		global.projects[key] = {...projectUpdated };
		UploadObject('projects/'+key, {...projectUpdated }, onSuccess, onFail);
	}
	else
		Alert(GetLabel('projectIdNotFound')+': '+projectUpdated.id);
}

export async function AsyncReplaceProjectInGlobalById(projectUpdated, onFail) {
	let key = GetProjectKey(projectUpdated);
	if(projectUpdated.id !== -1) {
		global.projects[key] = {...projectUpdated };
		await AsyncUploadObject('projects/'+key, {...projectUpdated }, onFail);
	}
	else
		Alert(GetLabel('projectIdNotFound')+': '+projectUpdated.id);

	return projectUpdated;
}

export function ReplaceSprintInGlobalById(project, sprintUpdated, onSuccess, onFail) {
	//let projectIndex = GetItemIndexById(global.projects, project);
	let projectKey = GetProjectKey(project);
	let sprintKey = GetSprintKey(sprintUpdated);

	//if(project.id !== -1 && sprintUpdated.id !== -1)	

	/*
	let sprintIndex = -1;
	for(let j=0; j<global.projects[projectKey].sprints.length; j++) {
		if(global.projects[projectKey].sprints[j].id === sprintUpdated.id) {
			global.projects[projectKey].sprints[j] = {...sprintUpdated };
			sprintIndex = j;
			break;
		}
	}*/

	if(project.id !== -1) {
		if(sprintUpdated.id !== -1) {
			global.projects[projectKey].sprints[sprintKey] = {...sprintUpdated};
			UploadObject('projects/'+projectKey+'/sprints/'+sprintKey, {...sprintUpdated }, onSuccess, onFail);
		}
		else {
			if(onFail)
				onFail();
			Alert(GetLabel('sprintIdNotFound')+': '+sprintUpdated.id);
		}
	}
	else {
		if(onFail)
			onFail();
		Alert(GetLabel('projectIdNotFound')+': '+project.id);
	}
}

export function ReplaceActivityInGlobalById(project, sprint, activity, onSuccess, onFail) {
	let projectKey = GetProjectKey(project);
	let sprintKey = GetSprintKey(sprint);
	let activityKey = GetActivityKey(activity);
	let error = '';

	if(project.id !== -1) {
		if(sprint.id !== -1) {
			if(activity.id !== -1) {
				global.projects[projectKey].sprints[sprintKey].activities[activityKey] = {...activity};
				UploadObject('projects/'+projectKey+'/sprints/'+sprintKey+'/activities/'+activityKey, 
					{...activity }, onSuccess, onFail);
			}
			else
				error = GetLabel('activityIdNotFound')+': '+activity.id;
		}
		else 
			error = GetLabel('sprintIdNotFound')+': '+sprint.id;
	}
	else 
		error = GetLabel('projectIdNotFound')+': '+project.id;

	if(!IsNullOrEmpty(error)) {
		if(onFail)
			onFail();
		Alert(error);
	}
}

//PUSH
export function PushNewProject(project, dirtyIcon, iconFile, onSuccess, onFail) {
	project.id = GetHighestIdFromObjectsList(global.projects) + 1;
	let newProjectKey = GetProjectKey(project);

	if(dirtyIcon) {
		UploadFile(
			'projects/'+newProjectKey+'/icon', 
			iconFile,
			(imageURL) => { 
				project.icon = imageURL;
				UploadObject('projects/'+newProjectKey, Clone(project), onSuccess, onFail);
			}, 
			() => { if(onFail) onFail(); }
		);
	}
	else 
		UploadObject('projects/'+newProjectKey, Clone(project), onSuccess, onFail);
}

export function PushNewSprint(project, sprint, onSuccess, onFail) {

	let projectKey = GetProjectKey(project);

	//let projectIndex = GetItemIndexById(global.projects, project);
	project = GetProjectById(project.id);
	
	//let newSprintIndex = project.sprints.length;
	sprint.id = GetHighestIdFromObjectsList(project.sprints) + 1;
	let newSprintKey =  GetSprintKey(sprint);
	
	UploadObject('projects/'+projectKey+'/sprints/'+newSprintKey, {...sprint }, onSuccess, onFail);
}

export function PushNewActivity(project, sprint, activity, onSuccess, onFail) {
	let projectKey = GetProjectKey(project);
	let sprintKey = GetSprintKey(sprint);

	project = GetProjectById(project.id);
	sprint = GetSprintById(project, sprint);

	activity.id = GetHighestIdFromObjectsList(sprint.activities) + 1;
	let newActivityKey =  GetActivityKey(activity);

	console.log('push: '+'projects/'+projectKey+'/sprints/'+sprintKey+'/activities/'+newActivityKey);
	console.log('activity: ',activity);
	
	UploadObject('projects/'+projectKey+'/sprints/'+sprintKey+'/activities/'+newActivityKey, 
		{...activity }, onSuccess, onFail);
}

//DELETE
export function DeleteProjectInGlobalById(project, onSuccess, onFail) {
	let projectKey = GetProjectKey(project);

	if(project.id !== -1) {
		delete global.projects[projectKey];
		UploadObject('projects/'+projectKey, null, onSuccess, onFail);
	}
	else {
		if(onFail)
			onFail();
		Alert(GetLabel('projectDeletionError'));
	}
}

export function DeleteSprintInGlobalById(project, sprint, onSuccess, onFail) {
	let projectKey = GetProjectKey(project);
	let sprintKey = GetSprintKey(sprint);

	if(project.id !== -1 && sprint.id !== -1) {
		delete global.projects[projectKey].sprints[sprintKey];
		UploadObject('projects/'+projectKey+'/sprints/'+sprintKey, null, onSuccess, onFail);
	}
	else {
		if(onFail)
			onFail();
		Alert(GetLabel('sprintDeletionError'));
	}
}

export function DeleteActivityInGlobalById(project, sprint, activity, onSuccess, onFail) {
	let projectKey = GetProjectKey(project);
	let sprintKey = GetSprintKey(sprint);
	let activityKey = GetActivityKey(activity);

	if(project.id !== -1 && sprint.id !== -1 && activity.id !== -1) {
		delete global.projects[projectKey].sprints[sprintKey].activities[activityKey];
		UploadObject('projects/'+projectKey+'/sprints/'+sprintKey+'/activities/'+activityKey, 
			null, onSuccess, onFail);
	}
	else {
		if(onFail)
			onFail();
		Alert(GetLabel('activityDeletionError'));
	}
}
