import React, { Component } from 'react';
import { HorizontalPadding, VerticalPadding, Today, DateFormat, GetShortMonth } from '../constants';
import { Selector } from './';
import Moment from 'moment';

class DaySelector extends Component {

	state = {
		//dateString: ''// dd/MM/yyyy
	}

	componentDidMount() {
		let today = Today();
		//this.setState({
		//	dateString: (this.props.value) ? this.props.value : today
		//});
	}

	notifyChange(nextDate) {
		this.props.event.onModify(nextDate);
		//this.setState({ dateString: nextDate });
	}
/*
	onDayChange(delta){
		let daysInMonth = Moment(this.state.dateString, DateFormat).daysInMonth();
		let dayNumber = parseInt(this.state.dateString.substr(0, 2));
		let nextDay = (dayNumber + delta);

		if(nextDay > daysInMonth) 
			nextDay = 1;
		if(nextDay < 1)
			nextDay = daysInMonth;
		if(nextDay < 10)
			nextDay = '0'+nextDay;
		nextDay += '';

		let updatedDate = nextDay +'/'+this.state.dateString.substr(3, 2)+'/'+this.getYear();

		//let updatedDate = Moment(this.state.dateString, DateFormat).add(delta, 'day').format(DateFormat);
		this.setState({ dateString: updatedDate });
	}
	
	onMonthChange(delta){
		let monthNumber = parseInt(this.state.dateString.substr(3, 2));
		let nextMonth = (monthNumber + delta);

		if(nextMonth > 12) 
			nextMonth = 1;
		if(nextMonth < 1)
			nextMonth = 12;
		if(nextMonth < 10)
			nextMonth = '0'+nextMonth;
		nextMonth += '';

		let updatedDate = this.getDay() +'/'+nextMonth+'/'+this.getYear();

		//let updatedDate = Moment(this.state.dateString, DateFormat).add(delta, 'month').format(DateFormat);
		this.setState({ dateString: updatedDate });
	}
	*/

	nextMonth(){
		var year = this.getYear();
		let month = this.props.value.substr(3, 2);

		if(month == '12')
			year = Moment(this.getDay()+'/'+month+'/'+year, DateFormat).add(-1, 'year').format('YYYY');

		var nextDate = Moment(this.getDay()+'/'+month+'/'+year, DateFormat).add(1, 'month');
		
		nextDate = nextDate.format(DateFormat);

		this.notifyChange(nextDate);
	}

	previousMonth() {
		var year = this.getYear();
		let month = this.props.value.substr(3, 2);

		if(month == '01')
			year = Moment(this.getDay()+'/'+month+'/'+year, DateFormat).add(1, 'year').format('YYYY');
		
		var nextDate = Moment(this.getDay()+'/'+month+'/'+year, DateFormat).add(-1, 'month');
		
		nextDate = nextDate.format(DateFormat);

		this.notifyChange(nextDate);
	}

	nextDay() {
		var nextDate = Moment(this.props.value, DateFormat).add(1, 'day');
		if(parseInt(this.getDay()) == Moment(this.props.value, DateFormat).daysInMonth())
			nextDate = nextDate.add(-1, 'month');

		nextDate = nextDate.format(DateFormat);

		this.notifyChange(nextDate);
	}

	previousDay() {
		var nextDate = Moment(this.props.value, DateFormat);

		if(this.getDay() === '01'){
			var lastDay = Moment(this.props.value, DateFormat).daysInMonth();
			nextDate = Moment(lastDay+'/'+this.props.value.substr(3), DateFormat);
		}else{
			nextDate = Moment(this.props.value, DateFormat).add(-1, 'day');
		}		

		nextDate = nextDate.format(DateFormat);

		this.notifyChange(nextDate);
	}

	onYearChange(delta){
		let updatedDate = Moment(this.props.value, DateFormat).add(delta, 'year').format(DateFormat);
		this.notifyChange(updatedDate);
	}

	getDay() {
		return this.props.value.substr(0, 2);
	}

	getMonth() {
		return GetShortMonth(parseInt(this.props.value.substr(3, 2)));
	}

	getYear() {
		return this.props.value.substr(6);
	}

	render() {
		let compactStyle = (this.props.compactStyle) ? {minWidth: '4rem', maxWidth: '4rem'} : {};

		return (
			<div style={{...css.container, ...this.props.style}}>	
				<Selector value={this.getDay()} eventId={this.props.event.id} project={this.props.event.project} style={{...css.selector, ...compactStyle}} 
					compactStyle={this.props.compactStyle}
					onUp={()=> this.nextDay()}
					onDown={()=> this.previousDay()}/>
				<Selector value={this.getMonth()} eventId={this.props.event.id} project={this.props.event.project} style={{...css.selector, ...compactStyle}}
					compactStyle={this.props.compactStyle}
					onUp={()=> this.nextMonth()}
					onDown={()=> this.previousMonth()}/>
				<Selector value={this.getYear()} eventId={this.props.event.id} project={this.props.event.project} style={{...css.selector, ...compactStyle}}
					compactStyle={this.props.compactStyle}
					onUp={()=> this.onYearChange(1)}
					onDown={()=> this.onYearChange(-1)}/>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	selector: {
		minWidth: 'initial',
	}
}

export { DaySelector };