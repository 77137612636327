import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize,
	FormatDateTimeLong, Clone, FixedColors, ButtonSize, SmallFontSize } from '../../constants';
import { Loading, Label, Div, Separator, Image } from '../../components';
import Moment from 'moment';

class SearchResults extends Component {

	state = {

	}

	openFile(link) {
		window.open(link, '_blank');
	}

	formatValue(unformattedValue) {
		let number = (parseInt(unformattedValue * 1e3) / 10);
		return number;
	}

	formatFileName(fileName) {
		let extension = '.'+this.getFileExtension(fileName);
		fileName = fileName.substr(0, fileName.length - extension.length);

		if(fileName.length > 45)
			fileName = fileName.substr(0, 45)+'...';
		return fileName;
	}

	getFileExtension (path) {
		path = path.replace(/\\/gm, '/');
		path = path.slice(path.lastIndexOf('/') + 1);

		if (path.lastIndexOf(".") === -1)
			return '';
		return path.replace(/^.*[\.]/, '');
	}

	formatResult(result, key, index) {
		// console.log(result);
		let icon = 'icon_pdf';
		let color = FixedColors.black;

		switch(result.type) {
			case 'pdf': color = FixedColors.red; icon = 'icon_pdf'; break;
			case 'xls':
			case 'xlsx': color = FixedColors.green; icon = 'icon_xls'; break;
			case 'docx': color = FixedColors.blue; icon = 'icon_docx'; break;
			case 'jpeg':
			case 'jpg':
			case 'png':
			case 'gif': color = FixedColors.purple; icon = 'icon_img'; break;
			case 'ppt':
			case 'pptx': color = FixedColors.orange; icon = 'icon_pptx'; break;
			case 'txt':
			case 'log':
			case 'html':
			case '': color = FixedColors.gray; icon = 'icon_txt'; break;
		}
		// console.log(icon);
		// console.log(color);
		let value = this.formatValue(result.value);
		let valueColor = value > 80 ? Colors.green : value > 60 ? Colors.primary : Colors.red;
		return (
			<div style={this.css().resultContainer} key={index} onClick={() => this.openFile(result.link)} className={'resultEntry'}>
				<Label text={(index+1)+'. '} labelStyle={{...this.css().resultNumber, ...{color: color, fontWeight: 'bold'}}} style={{margin: '0px', padding: '0px'}}/>
				<Image src={`img/sys/${icon}.png`} color={color} style={this.css().flex} imgStyle={{width: `calc(${ButtonSize} - 1.4em)`, height: `calc(${ButtonSize} - 1.4em)`}}/>
				<div style={this.css().resultMainTextContainer}>
					<Label text={this.formatFileName(result.file)} style={this.css().resultFileName} labelStyle={{whiteSpace: 'nowrap', cursor: 'pointer', color: color, fontWeight: 'bold'}}/>
					<Label text={value+'%'} style={this.css().resultValue} labelStyle={{...this.css().resultValueLabel, ...{border: `2px solid ${color}`, color: color, background: color+'30'}}}/>
				</div>
			</div>
		);
	}

	render() {
		if(this.props.isLoading)
			return <Loading />;

		let jsx = [];
		Object.keys(this.props.results.items).forEach((key, index) => {
			jsx.push(this.formatResult(this.props.results.items[key], key, index));
		});

		return (
			<div style={{paddingBottom: '10em'}}>
				{ jsx }
			</div>
		);
	}

	css() {
		return {
			resultContainer: {
				display: 'flex',
				flexDirection: 'row',
				paddingTop: '1.5em',
				paddingBottom: '2em',
				cursor: 'pointer',
			},
			resultNumber: {
				fontSize: '1.8em',
				minWidth: '1.5em',
				textAlign: 'right',
				alignSelf: 'flex-end',
				marginBottom: '-0.2em',
				cursor: 'pointer',
			},
			resultValue: {
				marginTop: '0px',
				marginBottom: '0px',
				padding: '0em 0em',
				cursor: 'pointer',
				marginLeft: '1.4em',
			},
			resultValueLabel: {
				cursor: 'pointer', 
				fontWeight: 'bold', 
				padding: '0.1em 0.3em 0em 0.5em',  
				borderRadius: '0.5em', 
				width: 'auto',
				fontSize: SmallFontSize,
			},
			resultFileName: {
				textTransform: 'uppercase',
				marginTop: '-0.2em',
				marginBottom: '0px',
				paddingTop: '0.1em',
				paddingBottom: '0.1em',
				cursor: 'pointer',
			},
			resultMainTextContainer: {
				display: 'flex',
				flexDirection: 'column',
				overflowX: 'visible',
				cursor: 'pointer',
				marginLeft: '-0.8em',
			},
			flex: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
		}
	}
}

export { SearchResults };
