import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber } from './';
import { ColorFixer, Solver } from './plugins';
import { UploadObject } from '../firebase';
import Moment from 'moment';

export function FormatDateLong(unformattedDate, separator) { //DD/MM/YYYY
	separator = (separator === undefined || separator === null) ? ' ' : separator;
	return unformattedDate.substr(0, 2) + separator +
		GetFullMonth(parseInt(unformattedDate.substr(3, 2))) + separator + 
		unformattedDate.substr(6, 4);
}

export function FormatDateTimeLong(unformattedDate, separator) {
	separator = (separator === undefined || separator === null) ? ' ' : separator;
	return unformattedDate.substr(0, 2) + separator +
		GetFullMonth(parseInt(unformattedDate.substr(3, 2))) + separator + 
		unformattedDate.substr(6, 4) + ','+ separator + unformattedDate.substr(10);
}

export function FormatDateShort(unformattedDate, separator) { //DD/MM/YYYY
	separator = (separator === undefined || separator === null) ? ' ' : separator;
	return unformattedDate.substr(0, 2) + separator +
		GetShortMonth(parseInt(unformattedDate.substr(3, 2))) + separator + 
		unformattedDate.substr(6, 4);
}

export function FormatDateShortest(unformattedDate, separator, returnYear) { //DD/MM/YYYY
	separator = (separator === undefined || separator === null) ? ' ' : separator;
	let year = (returnYear) ? separator + unformattedDate.substr(8, 2) : '';
	return unformattedDate.substr(0, 2) + separator +
		GetShortMonth(parseInt(unformattedDate.substr(3, 2))).toUpperCase()+year;
}

export function SetValue(vessel, insertion) {
	return vessel.replace(/~/g, insertion);
}

export function IsMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

export function GetObjectKey(prefix, obj) {
	return prefix+'_'+PadNumber(obj.id, DefaultPadNumber);
}

export function Sleep(time) {
	return new Promise((res) => {
		setTimeout( () => {
			res()
		},time);
	});
}

export function Today(formatWanted){
	formatWanted = (IsNullOrEmpty(formatWanted)) ? DateFormat : formatWanted;
	return Moment(new Date()).format(formatWanted);
}

export function LastMonth(formatWanted){
	formatWanted = (IsNullOrEmpty(formatWanted)) ? DateFormat : formatWanted;
	let currentDate = new Date();
	currentDate.setMonth(currentDate.getMonth() - 1);
	return Moment(currentDate).format(formatWanted);
}

export function Clone(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function Alert(message) {
	//Checar Alertify JS
	//https://alertifyjs.com/
	alert(message);
}

export function GetSprintStatusIcon(status) {
	switch(status) {
		case 'completed':
			return {icon: 'img/sys/ok.png', color: Colors.green};
		case 'inProcess':
			return {icon: 'img/sys/progress.png', color: Colors.primary};
		case 'disabled':
			return {icon: 'img/sys/disabled.png', color: Colors.disabled};
		case 'cancelled':
			return {icon: 'img/sys/cancel.png', color: Colors.red};
		default:
			return {icon: 'img/sys/cancel.png', color: Colors.red};
	}
}

export function IsEmailValid(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function GetFilter(colorHex) {
	const rgb = HexToRgb(colorHex);
    const color = new ColorFixer(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    return solver.solve();
}

export function GetProjectPercentage(percentage) {
	percentage = percentage.substr(0, percentage.length -1);
	return parseFloat(percentage) / 100;
}

export function GetPercentageFromStage(stage) {
	switch(stage) {
		case 'pending':
			return 0;
		case 'ideation':
			return 0.1666;
		case 'frame':
			return 0.3333;
		case 'understand':
			return 0.5;
		case 'experiment':
			return 0.6666;
		case 'accelerate':
			return 0.8333;
		case 'scale':
			return 0.99999;
		default:
			return 0;
	}
}

export function GetHighestIdFromObjectsList(obj) {
	let highestId = 0;
	if(obj === null || obj === undefined)
		return highestId;
	//for(let i=0; i<obj.length; i++) {
	Object.keys(obj).forEach(key => {
		if(obj[key].id > highestId)
			highestId = obj[key].id;
	});
	return highestId;
}

export function IsAnObjectArray(obj) {
	let flag = false;
	if(obj === null || obj === undefined)
		return false;
	if(typeof obj === 'string')
		return false;
	if(typeof obj === 'object')
		return true;
	return false;
}

export function GetItemIndexById(list, item) {
	let index = -1;
	for(let i=0; i<list.length; i++) {
		if(list[i].id === item.id) {
			list[i] = {...item };
			index = i;
			break;
		}
	}
	return index;
}

export function HexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? [
			parseInt(result[1], 16),
			parseInt(result[2], 16),
			parseInt(result[3], 16),
		]
		: null;
}

export function ClampLengthAt(str, charCount) {
	if(str.length > charCount)
		return str.substr(0, charCount)+'...';
	else
		return str;
}

export function IsTodayBetweenTheseDates(dateA, dateB) {
	//if(IsNullOrEmpty(dateA) || IsNullOrEmpty(dateB))
	//	return false;
	

	dateA = Moment(dateA, DateFormat);
	dateB = Moment(dateB, DateFormat);
	let today = Moment(new Date(), DateFormat);

	//console.log('dateA: '+dateA+' dateB: '+dateB+' today: '+today);
	//return true;
	//return today.isBetween(dateA, dateB, 'days', true);
	return today.diff(dateA, 'days') <= 0 && today.diff(dateB, 'days') > 0;
}

export function TheArrayContains(array, item) {
	return (array.indexOf(item) > -1);
}

export function GetAllProjects() {
	return GetProjectsByStage(['transferred', 'research', 'closed', 'pending', 'ideation', 'frame', 'understand', 'experiment', 'accelerate', 'scale']);
}

export function GetAllDatabaseProjects() {
	return GetProjectsByStage(['transferred', 'research', 'closed', 'pending', 'ideation', 'frame', 'understand', 'experiment', 'accelerate', 'scale'], true);
}

export function GetAllClosedProjects() {
	return GetProjectsByStage(['transferred', 'research', 'closed']);
}

export function GetAllActiveProjects() {
	let _arr1 =  GetProjectsByStage('pending');
	let arr0 =  GetProjectsByStage('ideation');
	let arr1 =  GetProjectsByStage('frame');
	let arr2 =  GetProjectsByStage('understand');
	let arr3 =  GetProjectsByStage('experiment');
	let arr4 =  GetProjectsByStage('accelerate');
	let arr5 =  GetProjectsByStage('scale');

	return [..._arr1, ...arr0, ...arr1, ...arr2, ...arr3, ...arr4, ...arr5];
	/*
	let allProjects = [];
	for(let i=0; i<global.projects.length; i++) {
		let { stage } = global.projects[i];
		if(stage === 'frame' || stage === 'understand' || stage === 'experiment' || stage === 'scale') {
			allProjects.push({...global.projects[i]});
		}
	}
	return  allProjects;*/
}

export function GetProjectsByStage(stageWanted, getAllProjects=false) {
	let isArray = Array.isArray(stageWanted);
	let allProjects = [];
	Object.keys(global.projects).forEach((key) => {
		let { stage } = global.projects[key];
		if(isArray){
			if(TheArrayContains(stageWanted, stage))
				allProjects.push({...global.projects[key]});
		}else{
			if(stage === stageWanted) 
				allProjects.push({...global.projects[key]});
		}
	});

	if(allProjects.length > 0) {
		allProjects.sort((a, b) => {
			let aName = GetLabel(a.name);
			let bName = GetLabel(b.name);

			if (aName > bName)
		        return 1;
		    if (bName > aName)
		        return -1;
		    return 0;
		});
	}

	if(!getAllProjects) {
		allProjects = allProjects.filter((project) => {
			if(global.profile.projectAccess === undefined)
				return true;

			for(let i=0; i<global.profile.projectAccess.length; i++) {
				let access = global.profile.projectAccess[i];
				if(access.id === 'all')
					return true;
				if(project.id === access.id)
					return true;
			}
			return false;
		});
	}

	return allProjects;
}

export function GetAllSprintsByDate(allSprints) {
	let sortedSprints = [];
	if(!allSprints)
		return sortedSprints;
	//sortedSprints = allSprints.slice(0);

	Object.keys(allSprints).forEach(key => {
		sortedSprints.push({...allSprints[key]});
	});

	sortedSprints.sort((a, b) => {
		let deadlineA = Moment(a.deadline, DateFormat);
		let deadlineB = Moment(b.deadline, DateFormat);
		return deadlineB.diff(deadlineA, 'days');
	});
	return sortedSprints;
}

export function GetPresentSprints(allSprints) {
	let presentSprints = [];
	if(!allSprints)
		return presentSprints;

	//console.log('allSprints.length: '+Object.keys(allSprints).length);
	//for(let i=0; i<allSprints.length; i++) {
	Object.keys(allSprints).forEach(key => {
		let currentSprint = allSprints[key];
		let deadline = Moment(currentSprint.deadline, DateFormat);
		let today = Moment(new Date());
		if(deadline.isSameOrBefore(today))
			presentSprints.push({...currentSprint});
	});
	presentSprints.sort((a, b) => {
		let deadlineA = Moment(a.deadline, DateFormat);
		let deadlineB = Moment(b.deadline, DateFormat);
		return deadlineB.diff(deadlineA, 'days');
	});
	return presentSprints;
}

export function GetFileExtension(fileFullName){
	let splited = fileFullName.split('.');
	let extension = splited[splited.length -1];
	return '.'+extension;
}

export function PadNumber(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
}

export function GetTimeDifference(dateA, dateB) {
	let difference = dateA.diff(dateB, 'days');
	return difference;
}

export function GetFutureSprints(allSprints) {
	let futureSprints = [];
	if(!allSprints)
		return futureSprints;
	//for(let i=0; i<allSprints.length; i++) {
	Object.keys(allSprints).forEach(key => {
		let currentSprint = allSprints[key];
		let deadline = Moment(currentSprint.deadline, DateFormat);
		let today = Moment(new Date());
		if(deadline.isAfter(today))
			futureSprints.push({...currentSprint});
	});
	futureSprints.sort((a, b) => {
		let deadlineA = Moment(a.deadline, DateFormat);
		let deadlineB = Moment(b.deadline, DateFormat);
		return deadlineA.diff(deadlineB, 'days');
	});
	return futureSprints;
}

export function IsNullOrEmpty(value){
	if(value !== undefined && value !== null) {
		if(value === '')
			return true;
		else if(Object.keys(value).length === 0)
			return true;
		else
			return false;
	}
	return true;
}

export function IsEventProjectRelated(event) {
	//if included in ranksProject
	let isProjectRelated = false;

	Object.keys(global.ranksProject).forEach(rankKey => {
		let rank = global.ranksProject[rankKey];
		if(event.allowedRanks.includes(rank))
			isProjectRelated = true;
	});

	return isProjectRelated;
}

export function AmIAllowedToExecuteAnyOfThis(eventIds, project) {
	let amIAllowed = false;

	for(let i=0; i<eventIds.length; i++) {
		amIAllowed = (amIAllowed) ? amIAllowed : AmIAllowedToExecuteThis(eventIds[i], project);
	}

	return amIAllowed;
}

export function AmIAllowedToExecuteThis(eventId, project, retrospectivesFactories=undefined) {
	//let substr = eventId.substr(0, 3);
	//Here validation is meant to be made!!

	if(Array.isArray(eventId))
		return AmIAllowedToExecuteAnyOfThis(eventId, project);

	let event = global.events[eventId];
	if(event === undefined)
		window.alert(eventId);

	if(IsNullOrEmpty(event.allowedRanks))
		return false;

	if(event.allowedRanks.includes('all'))
		return true;

	if(event.allowedRanks.includes(global.ranks[global.profile.rank]))
		return true;

	if(IsEventProjectRelated(event)) {
		if(IsNullOrEmpty(project))
			return false;
	//if(event.permissionInsideProject) {
		//Search inside project
		if(	project.leader === global.profile.id && 
			event.allowedRanks.includes('leader')) //I am the project leader			
			return true;

		if(	project.scrumMaster === global.profile.id &&
			event.allowedRanks.includes('scrumMaster'))//I am the scrum master
			return true;
		

		if(	project.quarterback === global.profile.id &&
			event.allowedRanks.includes('quarterback')) //I am the quarterback
			return true;
	}

	if(!IsNullOrEmpty(retrospectivesFactories)) {
		if(retrospectivesFactories.lastMonthValue === global.profile.id)
			return true;
	}

	return false;
	/*
	if(eventId === undefined || eventId === null)
		return true;

	let eventInfo = global.events['event_'+(PadNumber(eventId, DefaultPadNumber))];
	//global.events.find((item) => {return item.id === eventId;});
	if(eventInfo.allowedRanks.includes('all'))
		return true;

	if(eventInfo.allowedRanks.includes(global.profile.rank))
		return true;
	else
		return false;*/
}

export function AreEqualObjects(object1, object2) {
	return (JSON.stringify(object1) === JSON.stringify(object2));
}

export function ObjectToArray(obj) {
	let array = [];
	Object.keys(obj).forEach(key => {
		array.push(Clone(obj[key]));
	});
	return array;
}

export function ObjectToArrayOfObjects(obj) {
	let array = [];
	Object.keys(obj).forEach(key => {
		array.push({
			id: key, 
			name: Clone(obj[key]),
		});
	});
	return array;
}

export function GetAllSelectableRanks() {
	let possibleRanks = ['all'];

	let ranks = ObjectToArray(global.ranks);
	let ranksProject = ObjectToArray(global.ranksProject);

	ranks.splice(0, 1);

	possibleRanks = [...possibleRanks, ...ranksProject, ...ranks];

	return possibleRanks;
}

export function SelectFile(onFileSelected) {
	var input = document.createElement('input');
	input.type = 'file';
	input.onchange = (e) => { 
	   //global.selectedFile = e.target.files[0];
	   //console.log('file name: ', global.selectedFile.name);
		let file = e.target.files[0];

   		var reader = new FileReader();
		//reader.readAsDataURL(file);
		//reader.readAsBinaryString(file);
		reader.readAsArrayBuffer(file);

		reader.onload = readerEvent => {
			var content = readerEvent.target.result; // this is the content!
			//console.log( content );

			if(onFileSelected)
	   			onFileSelected(file, content, URL.createObjectURL(file));
		}
	}
	input.click();
}

export function Download(uri) {
	var link = document.createElement("a");
	link.target= "_blank";
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	//delete link;
}
