import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, 
	DefaultRank, DefaultDomainName, Today } from './';
import { UploadObject } from '../firebase';

export function UpdatePendings(pendings, onSuccess, onFail) {
	global.pendings = Clone(pendings);

	UploadObject('pendings', global.pendings, 
		()=> {
			//console.log('User uploaded: ',profile);
			global.pendings = Clone(pendings);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading pendings: ',pendings);
			if(onFail)
				onFail();
		}
	);
}