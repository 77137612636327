import React, { Component  } from 'react';
import { Colors, GetLabel, Alert, ButtonSize, FixedColors,
	SmallFontSize, GetAllProjects, IsNullOrEmpty, Sleep } from '../constants';
import { ProfileDetails, ProfileEditor, EventsEditor, 
	WebAdmin, UsersEditor,
} from '../screens/screens_profile';
import { SearchResults } from '../screens/screens_citadelle';
import { SideMenu, Header, SeparatorLarge, Label, BulletPointsList, Subheader, WideButton, 
	Div, Expansible, Space, Image, InputField, Checkbox, Button, Loading, ProjectIcon } from '../components';
import './Citadelle.css';

class Citadelle extends Component {

	defaultProjectIcon = 'https://firebasestorage.googleapis.com/v0/b/gtd-app-acaa2.appspot.com/o/ddt_production%2Fcitadelle%2Ficon.png?alt=media&token=02b5053d-91a6-486b-8c46-8666d142f2ab';

	state = {
		domain: 'localhost',
		query: '',
		queryProject: '',
		isLoading: false,
		synonyms: false,
		exactPhrase: false,
		spanish: true,
		english: false,
		both: false,
		hoveringHorizontalScroll: false,
		results: {
			items: {},
			synonyms: []
		},
		projectsSelected: [],
		allProjects: [],
		filteredProjects: [],
		projectIconDiameter: '3em',
	}

	componentDidMount() {
		this.getAllCitadelleProjects();
	}

	goBack() {
		if(global.databaseWriteInProcess) {
			Alert(GetLabel('databaseWriteInProcess'));
			return;
		}
	}

	getAllCitadelleProjects() {
		console.log('getAllCitadelleProjects called');
		var request = new XMLHttpRequest();
		request.onreadystatechange = () => {
			if (request.readyState == 4 && request.status >= 200 && request.status < 300) {
				let json = request.responseText;
				let results = JSON.parse(json);
				console.log('getAllCitadelleProjects: ',results);
				this.setState({allProjects: results, filteredProjects: results});
			}
		};
		// console.log(`https://${this.state.domain}:8080/?type=json&search=${query}`);
	    request.open( "GET", `https://${this.state.domain}:8080/?type=json&getAllProjects=true`, true );
	    request.send();
	}

	search(query) {
		this.setState({
			results: {
				items: {},
				synonyms: []
			}, 
			isLoading: true
		});

		var request = new XMLHttpRequest();
		request.onreadystatechange = () => {
			if (request.readyState == 4 && request.status >= 200 && request.status < 300) {
				let json = request.responseText;
				let results = JSON.parse(json);
				// console.log(results);
				this.setState({results: results, isLoading: false});
			}
		};
		let langKey = 
			(this.state.both) ? 'BOTH' : 
			(this.state.spanish) ? 'SPA' : 
			'ENG';

		// console.log(`https://${this.state.domain}:8080/?type=json&search=${query}`);
	    request.open( "GET", `https://${this.state.domain}:8080/?type=json&search=${query}&synonyms=${this.state.synonyms}&projects=${this.state.projectsSelected.join('~')}&exactPhrase=${this.state.exactPhrase}&langKey=${langKey}`, true ); // false for synchronous request
	    request.send();
	}

	async getAllFilesFromProjects(projectsSelected) {
		this.setState({
			results: {
				items: {},
				synonyms: []
			}, 
			isLoading: true
		});
		await Sleep(0.1e3);

		var request = new XMLHttpRequest();
		request.onreadystatechange = () => {
			if (request.readyState == 4 && request.status >= 200 && request.status < 300) {
				let json = request.responseText;
				let results = JSON.parse(json);
				// console.log(results);
				this.setState({results: results, isLoading: false});
			}
		};
		let langKey = 
			(this.state.both) ? 'BOTH' : 
			(this.state.spanish) ? 'SPA' : 
			'ENG';

		// console.log(`https://${this.state.domain}:8080/?type=json&search=${query}`);
	    request.open( "GET", `https://${this.state.domain}:8080/?type=json&search=*&projects=${projectsSelected.join('~')}&langKey=${langKey}`, true ); // false for synchronous request
	    request.send();
	}

	renderHeader() {
		return (
			<div style={this.css().header}>
				<Image src={'img/sys/citadelle.png'} color={Colors.primary} style={this.css().mainIconContainer} imgStyle={{float: 'right'}}/>
				<Header text={GetLabel('citadelle')} style={this.css().headerLabelContainer} labelStyle={this.css().headerLabel}/>
			</div>
		);
	}


	/*
	openFile(link) {
		window.open(link, '_blank');
	}

	formatValue(unformattedValue) {
		let number = (parseInt(unformattedValue * 1e3) / 10);
		return number;
	}

	formatFileName(fileName) {
		let extension = '.'+this.getFileExtension(fileName);
		fileName = fileName.substr(0, fileName.length - extension.length);

		if(fileName.length > 45)
			fileName = fileName.substr(0, 45)+'...';
		return fileName;
	}

	getFileExtension (path) {
		path = path.replace(/\\/gm, '/');
		path = path.slice(path.lastIndexOf('/') + 1);

		if (path.lastIndexOf(".") === -1)
			return '';
		return path.replace(/^.*[\.]/, '');
	}

	formatResult(result, key, index) {
		// console.log(result);
		let icon = 'icon_pdf';
		let color = FixedColors.black;

		switch(result.type) {
			case 'pdf': color = FixedColors.red; icon = 'icon_pdf'; break;
			case 'xls':
			case 'xlsx': color = FixedColors.green; icon = 'icon_xls'; break;
			case 'docx': color = FixedColors.blue; icon = 'icon_docx'; break;
			case 'jpeg':
			case 'jpg':
			case 'png':
			case 'gif': color = FixedColors.purple; icon = 'icon_img'; break;
			case 'ppt':
			case 'pptx': color = FixedColors.orange; icon = 'icon_pptx'; break;
			case 'txt':
			case 'log':
			case 'html':
			case '': color = FixedColors.gray; icon = 'icon_txt'; break;
		}
		// console.log(icon);
		// console.log(color);
		let value = this.formatValue(result.value);
		let valueColor = value > 80 ? Colors.green : value > 60 ? Colors.primary : Colors.red;
		return (
			<div style={this.css().resultContainer} key={index} onClick={() => this.openFile(result.link)} className={'resultEntry'}>
				<Label text={(index+1)+'. '} labelStyle={{...this.css().resultNumber, ...{color: color, fontWeight: 'bold'}}} style={{margin: '0px', padding: '0px'}}/>
				<Image src={`img/sys/${icon}.png`} color={color} style={this.css().flex} imgStyle={{width: `calc(${ButtonSize} - 1.4em)`, height: `calc(${ButtonSize} - 1.4em)`}}/>
				<div style={this.css().resultMainTextContainer}>
					<Label text={this.formatFileName(result.file)} style={this.css().resultFileName} labelStyle={{whiteSpace: 'nowrap', cursor: 'pointer', color: color, fontWeight: 'bold'}}/>
					<Label text={value+'%'} style={this.css().resultValue} labelStyle={{...this.css().resultValueLabel, ...{border: `2px solid ${color}`, color: color, background: color+'30'}}}/>
				</div>
			</div>
		);
	}

	renderResults() {
		if(this.state.isLoading)
			return <Loading />;

		let jsx = [];
		Object.keys(this.state.results.items).forEach((key, index) => {
			jsx.push(this.formatResult(this.state.results.items[key], key, index));
		});

		return (
			<div>
				{ jsx }
			</div>
		);
	}
	*/

	renderSearchBar() {
		return (
			<div style={{display: 'flex'}}>
				<InputField placeholder={GetLabel('searchHere')} type={'text'} inputStyle={{minWidth: `calc(100% + 8px)`}}
		     		onChange={(query) => this.setState({query: query})} onEnter={() => { this.search(this.state.query); }}/>
		     	<Button event={{id: 'event_00000', onClick: ()=> this.search(this.state.query)}} style={this.css().flex} buttonStyle={this.css().searchButtonStyle} disablehover>
 					<Image src={'img/sys/search.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize} style={{marginTop: '-4px'}}/>
 				</Button>
			</div>
		);
	}

	renderCitadelleBackground() {
		return (
			<div style={this.css().citadelleBackground}/>
		);
	}

	toggleSelectionOfProject(projectName) {
		let projectsSelectedClone = this.state.projectsSelected.slice();
		let index = projectsSelectedClone.indexOf(projectName);
		if(index !== -1) {
			projectsSelectedClone.splice(index, 1);
		}
		else {
			projectsSelectedClone.push(projectName);
		}
		this.setState({projectsSelected: projectsSelectedClone});
		this.getAllFilesFromProjects(projectsSelectedClone);
	}

	getProjectIconByName(projectName) {
		let allProjectsInDatabase = GetAllProjects();
		let wantedProject = allProjectsInDatabase.filter(project => project.name.toLowerCase() === projectName.toLowerCase());
		// console.log(allProjectsInDatabase);
		// console.log('searched: ',projectName);
		// console.log(wantedProject);
		wantedProject = wantedProject[0];

		if(!wantedProject) {
			wantedProject = {
				icon: this.defaultProjectIcon, 
				name: projectName,// === '_Extra' ? 'Extra',
				balanceScore: {
					semaphore: 'green',
				}
			}
		}

		return wantedProject;
	}

	getAllProjects() {
		let jsx = [];
		if(IsNullOrEmpty(global.projects))
			return (
				<Label text={GetLabel('theCitadelleServerIsNotRunning')} style={{width: '100%', backgroundColor: Colors.red, textAlign: 'center', borderRadius: '0.3em'}}/>
			);
		
		let projects =  this.state.filteredProjects;
		projects.map((project, i) => {
			let projectObj = this.getProjectIconByName(project);
			if(projectObj)
				jsx.push(
					<div key={project+'_'+i}>
						<ProjectIcon project={projectObj} key={i} id={'projectIcon'+i}
						projectIconDiameter={this.state.projectIconDiameter} 
						doNotUseButton
						default={this.defaultProjectIcon}
						style={{marginTop: '0.4em'}}
						imageContainer={{...{border: '2px solid '+((this.state.projectsSelected.indexOf(project) !== -1) ? Colors.primary : Colors.background_2)}, ...{
							width: `calc(${this.state.projectIconDiameter} + 2em)`,
							minWidth: `calc(${this.state.projectIconDiameter} + 2em)`,
							height: `calc(${this.state.projectIconDiameter} + 2em)`,
							minHeight: `calc(${this.state.projectIconDiameter} + 2em)`,
						}}}
						color={(this.state.projectsSelected.indexOf(project) !== -1) ? Colors.primary : Colors.background_2}
						event={
							() => { this.toggleSelectionOfProject(project) }
						}/>
						<Label text={project} style={{marginBottom: '0px', padding: '0px', marginTop: '-0.5em'}} labelStyle={{textAlign: 'center', fontSize: SmallFontSize}}/>
					</div>
				);
		});

		if(jsx.length === 0) 
			return <Label text={GetLabel('theCitadelleServerIsNotRunning')} style={{width: '100%', backgroundColor: Colors.red, textAlign: 'center', borderRadius: '0.3em'}}/>;

		return  jsx;
	}

	wheel(event) {
		//event.preventDefault();
		let target = (event.target.className === 'horizontalScrollableCitadelle') ? event.target : null;

		target = (target === null && event.target.parentElement.className === 'horizontalScrollableCitadelle') ? event.target.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.className === 'horizontalScrollableCitadelle') ? event.target.parentElement.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.parentElement.className === 'horizontalScrollableCitadelle') ? event.target.parentElement.parentElement.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.parentElement.parentElement.className === 'horizontalScrollableCitadelle') ? event.target.parentElement.parentElement.parentElement.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.parentElement.parentElement.parentElement.className === 'horizontalScrollableCitadelle') ? event.target.parentElement.parentElement.parentElement.parentElement.parentElement : target;

		//console.log('target: ',target);

		if(target !== null) {
			//event.preventDefault();
			target.scrollLeft = target.scrollLeft += event.deltaY / 2;
		}
	}

	searchProject(queryProject) {
		let filteredProjects = this.state.allProjects.slice(0);//this.getAllCitadelleProjects();
		// let allProjects = [];

		// for(let i=0; i<allProjectsObj.length; i++) {
		// 	allProjects.push(allProjectsObj[i].name);
		// }

		filteredProjects = filteredProjects.filter(project => {
			return project.toLowerCase().indexOf(queryProject.toLowerCase()) !== -1;
		});
		// console.log('allProjects.length: ',allProjects.length);

		this.setState({queryProject: queryProject, filteredProjects: filteredProjects});
	}

	renderProjectFilterSelector(sectionName) {
		// let renderArrows = (this.state.sectionsWithOverflow.includes(sectionName));
		return (
			<div style={this.css().projectsSelectorContainer} >
				<InputField placeholder={GetLabel('searchProjectHere')} type={'text'} 
					inputStyle={this.css().projectSearchInputField}
					style={{...this.css().flex, ...{width: '25vw', minWidth: '400px', height: '3em', alignSelf: 'center', justifySelf: 'center', /*transform: 'perspective(15px) rotateX(-5deg)',*/ border: '2px solid '+Colors.primary, background: '#040404', marginTop: '-2px', borderRadius: '0px 0px 10px 10px', zIndex: 100, borderTop: '0px solid #191919'}}}
		     		onChange={(query) => this.searchProject(query)} onEnter={() => { this.searchProject(this.state.queryProject); }}/>
				<div ref={'projectFilteringBar'} 
					onMouseOver={() => this.setState({hoveringHorizontalScroll: true})}
					onMouseOut={() => this.setState({hoveringHorizontalScroll: false})}
					style={{
						...this.css().scrollable, 
						...(!this.state.hoveringHorizontalScroll ? {} : {})
					}} 
					className={'horizontalScrollableCitadelle'}
					onWheel={(e) => this.wheel(e)}>
					{/*{ (renderArrows) ? this.renderArrow('left', sectionName+'Bar') : undefined }*/}
					{ this.getAllProjects(sectionName) }
					{/*{ (renderArrows) ? this.renderArrow('right', sectionName+'Bar') : undefined }*/}
				</div>
			</div>
		);
	}

	render() {
		let disableVerticalScroll = this.state.hoveringHorizontalScroll ? {overflowY: 'hidden'} : {overflowY: 'overlay'};
		return (
			<div style={{...this.css().container, ...this.props.style}} className={`${this.props.className}`}>
				<div style={{...this.css().container, ...this.props.style}} className={`${this.props.className}`}>
					<SideMenu navigate={this.props.navigate}/>
					<Header text={GetLabel('citadelle')}/>
					<Div noVerticalPadding style={{...disableVerticalScroll, ...{justifyContent: 'center',}}} innerStyle={{maxWidth: '450px', height: 'auto !important'}}>
						{ this.renderHeader() }
						{ this.renderSearchBar() }
     					<Checkbox event={{id: 'event_00000', onClick: ()=> this.setState({synonyms: !this.state.synonyms})}} 
  		 					style={{margin: '0em', padding: '0em'}}
  		 					checked={this.state.synonyms}>
  		 					<Label text={GetLabel('considerSynonyms')} labelStyle={{}}/>
  		 				</Checkbox>
  		 				<Checkbox event={{id: 'event_00000', onClick: ()=> this.setState({exactPhrase: !this.state.exactPhrase})}} 
  		 					style={{margin: '0em', padding: '0em'}}
  		 					checked={this.state.exactPhrase}>
  		 					<Label text={GetLabel('exactPhrase')} labelStyle={{}}/>
  		 				</Checkbox>
  		 				<div style={{display: 'flex', flexDirection: 'row', marginTop: '-1.2em'}}>
	  		 				<Checkbox event={{id: 'event_00000', onClick: ()=> {this.setState({english: false, spanish: true, both: false}); this.getAllFilesFromProjects(this.state.projectsSelected);}}} 
	  		 					style={{margin: '0em', padding: '0em'}}
	  		 					checked={this.state.spanish}>
	  		 					<Label text={GetLabel('spanish')} labelStyle={{}}/>
	  		 				</Checkbox>
	  		 				<Checkbox event={{id: 'event_00000', onClick: ()=> {this.setState({both: false, spanish: false, english: true}); this.getAllFilesFromProjects(this.state.projectsSelected);}}} 
	  		 					style={{margin: '0em', padding: '0em'}}
	  		 					checked={this.state.english}>
	  		 					<Label text={GetLabel('english')} labelStyle={{}}/>
	  		 				</Checkbox>
	  		 				<Checkbox event={{id: 'event_00000', onClick: ()=> {this.setState({both: true, english: false, spanish: false}); this.getAllFilesFromProjects(this.state.projectsSelected);}}} 
	  		 					style={{margin: '0em', padding: '0em'}}
	  		 					checked={this.state.both}>
	  		 					<Label text={GetLabel('both')} labelStyle={{}}/>
	  		 				</Checkbox>
  		 				</div>
  		 				{ this.renderCitadelleBackground() }
  		 				<Space />
  		 				{ this.renderProjectFilterSelector() }
						<SeparatorLarge style={this.css().separatorLarge} color={'#000000'} mainColorLinesOpacity={'1.0'}  backgroundDiagonalsContainer={{minHeight: '180vh'}}/>
						<Space /><Space /><Space />
						<Space /><Space /><Space />
						<Space /><Space /><Space />

						<SearchResults isLoading={this.state.isLoading} results={this.state.results}/>
						{/*{ this.renderResults() }*/}
					</Div>
					{/*<Div noVerticalPadding innerStyle={{maxWidth: '45vw'}}>
						<Space />
						{ this.renderResults() }
						<Space /><Space /><Space />
					</Div>*/}
				</div>
			</div>
		);
	}

	css() {
		return {
			container: {
				color: Colors.primary,
				width: '100%',
				height: '100%',
			},
			/*
			resultContainer: {
				display: 'flex',
				flexDirection: 'row',
				paddingTop: '1.5em',
				paddingBottom: '2em',
				cursor: 'pointer',
			},
			resultNumber: {
				fontSize: '1.8em',
				minWidth: '1.5em',
				textAlign: 'right',
				alignSelf: 'flex-end',
				marginBottom: '-0.2em',
				cursor: 'pointer',
			},
			resultValue: {
				marginTop: '0px',
				marginBottom: '0px',
				padding: '0em 0em',
				cursor: 'pointer',
				marginLeft: '1.4em',
			},
			resultValueLabel: {
				cursor: 'pointer', 
				fontWeight: 'bold', 
				padding: '0.1em 0.3em 0em 0.5em',  
				borderRadius: '0.5em', 
				width: 'auto',
				fontSize: SmallFontSize,
			},
			resultFileName: {
				textTransform: 'uppercase',
				marginTop: '-0.2em',
				marginBottom: '0px',
				paddingTop: '0.1em',
				paddingBottom: '0.1em',
				cursor: 'pointer',
			},
			resultMainTextContainer: {
				display: 'flex',
				flexDirection: 'column',
				overflowX: 'visible',
				cursor: 'pointer',
				marginLeft: '-0.8em',
			},*/
			flex: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			header: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			},
			headerLabelContainer: {
				textAlign: 'left',
				// margin: '0px',
				textTransform: 'uppercase',
				width: '50%',
				marginLeft: '-1em',
			},
			headerLabel: {
				color: Colors.primary,
				fontSize: '1.3rem',
				letterSpacing: '1px',
			},
			separatorLarge: {
				// filter: 'saturate(0) contrast(2.5) opacity(0.2)',
				zoom: '100%',
				minWidth: '200vw',
				marginLeft: '-162vw',
				minHeight: '50vh',
				position: 'absolute',
				opacity: '0.6',
				zIndex: '-6',
				// top: '0px',
				// WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
				// WebkitMaskImage: 'linear-gradient(180deg, #000, transparent)',
				// maskImage: 'linear-gradient(black, transparent)',
			},
			citadelleBackground: {
				backgroundImage: `url("img/sys/citadelle_background.png")`,
		  		backgroundRepeat: 'repeat-x',
				width: '100vw',
				position: 'absolute',
				height: '400px',
				left: '0',
				zIndex: '-3',
				pointerEvents: 'none',
				marginTop: '-220px',
			},
			mainIconContainer: {
				height: '6em',
			    opacity: '1',
			    width: '50%',
			    marginLeft: '-'+ButtonSize,
				// marginRight: 'calc(-5em - 6vw)',
			 //    marginLeft: 'calc(6vw)',
			 //    marginTop: '-1em',
			},
			projectsSelectorContainer: {
				display: 'flex',
				flexDirection: 'column',
				background: '#00000033',//Colors.primary+'20',
				minWidth: 'calc(100vw + 4px)',
				position: 'absolute',
				left: '-2px',
				border: `2px solid ${Colors.primary}`,

				background: 'transparent',
				borderBottom: '0px solid transparent',
				boxShadow: '#040404 0px 200px 80px -92px inset',//'inset 0 130px 90px -92px black',
			},
			projectSearchInputField: {
				maxWidth: '40vw',
				marginTop: '-2px',
				marginBottom: '0px',
				// transform: 'perspective(15px) rotateX(5deg)',
				border: '0px solid transparent',
				background: 'transparent',
				outline: 'none',
				textAlign: 'center',
			},
			searchButtonStyle: {
				border: `2px solid ${Colors.primary}`,
				backgroundColor: Colors.primary+'50',
			    width: '100%',
			    height: '100%',
			    maxHeight: `calc(${ButtonSize} - 1px)`,
			    borderRadius: '0.5em',
			    // marginLeft: `calc(${ButtonSize} + 1em - 2px)`,
			    minWidth: `calc(${ButtonSize} + 1em)`,
			},
			sectionNameContainer: {
				justifyContent: 'flex-start',
				marginLeft: '0.5em',
			},
			sectionName: {
				textTransform: 'uppercase',
				textAlign: 'left',
			},
			scrollable: {
				display: 'flex',
				height: `calc(${this.state.projectIconDiameter} + 10em)`,
				maxWidth: '100vw',
				display: 'flex',
				// justifyContent: 'center',
				alignItems: 'center',
			},
		}
	}
}

export { Citadelle };