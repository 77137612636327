import React, { Component } from 'react';
import { HorizontalPadding, VerticalPadding } from '../constants';
import './Div.css';

class Div extends Component {

	state = {

	}

	render() {
		//let className = (this.props.className) ? 'verticalScroll '+this.props.className : 'verticalScroll';
		let className = 'verticalScroll';

		let noVerticalPadding = (this.props.noVerticalPadding) ? {paddingTop: '0', paddingBottom: '0'} : {};

		return (
			<div style={{...css.container, ...this.props.style}} className={className}>	
				<div className={'widthCompensator'}/>
					<div style={{...css.innerContainer, ...this.props.innerStyle, ...noVerticalPadding}} className={'sandwichDiv'}>
						{ this.props.children }
					</div>
				<div className={'widthCompensator'}/>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		height: `calc(100%)`,
		width: '100%',
		maxWidth: '100%',
		overflowY: 'scroll',
		overflowX: 'hidden',
	    position: 'relative',
	    //width: `calc(100% - ${HorizontalPadding} * 2)`,
	    //paddingLeft: HorizontalPadding,
	    //paddingRight: HorizontalPadding,
	},
	innerContainer: {
		height: '100%',
		width: '100%',
		paddingLeft: HorizontalPadding,
	    paddingRight: HorizontalPadding,
	    paddingTop: VerticalPadding,
	    paddingBottom: VerticalPadding,

		//overflowY: 'scroll',
	    //position: 'relative',
	}
}

export { Div };