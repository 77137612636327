import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, IsAnObjectArray, FormatDateTimeLong, Today,
	GetFullMonth, SubHeadersSize, DirtyStyle, ReplaceProjectInGlobalById, Clone,
} from '../../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, SeparatorLarge, MonthSelector, StarGrading, ConfirmationButtons, 
} from '../../components';

class Retrospectives extends Component {

	state = {
		monthYear: Today('MM_YYYY'),
		isLoading: false,
	}

	renderRetrospectivasTitle() {
		return (
			<Label 
				labelStyle={{fontSize: SubHeadersSize, textAlign: 'center'}}
				text={`${GetLabel('retrospective')} ${GetFullMonth(parseInt(this.state.monthYear.substring(0, 2)))} ${this.state.monthYear.substring(3)}`}/>
		);
	}

	renderRetrospectivasDetails(project) {
		let pClone = Clone(project);

		let { monthYear } = this.state;
		if(pClone.retrospectives === undefined) pClone.retrospectives = {};
		if(pClone.retrospectives[monthYear] === undefined) pClone.retrospectives[monthYear] = {};
		if(pClone.retrospectives[monthYear].capabilities === undefined) pClone.retrospectives[monthYear].capabilities = {};
		if(pClone.retrospectives[monthYear].retrospectiveScrumMaster === undefined) pClone.retrospectives[monthYear].retrospectiveScrumMaster = {};
		if(pClone.retrospectives[monthYear].retrospectivePO === undefined) pClone.retrospectives[monthYear].retrospectivePO = {};
		if(pClone.retrospectives[monthYear].retrospectiveQuarterback === undefined) pClone.retrospectives[monthYear].retrospectiveQuarterback = {};

		return (
			<div>
				<Label text={`${GetLabel('capabilities')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('finanzas')} stars={pClone.retrospectives[monthYear].capabilities['finanzas']} editable considerZero key={monthYear+'1'} event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('capabilities', 'finanzas', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('administration')} stars={pClone.retrospectives[monthYear].capabilities['administration']} editable considerZero key={monthYear+'2'} event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('capabilities', 'administration', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('legal')} stars={pClone.retrospectives[monthYear].capabilities['legal']} editable considerZero key={monthYear+'3'} event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('capabilities', 'legal', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('vinculaciones')} stars={pClone.retrospectives[monthYear].capabilities['vinculaciones']} editable considerZero key={monthYear+'4'} event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('capabilities', 'vinculaciones', newNumberOfStars);
				}}}/>

				<Space/><Space/><Space/>
				<Label text={`${GetLabel('retrospectiveScrumMaster')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('retrospectiveScrumMaster1')} stars={pClone.retrospectives[monthYear].retrospectiveScrumMaster['_1']} key={monthYear+'5'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveScrumMaster', '_1', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectiveScrumMaster2')} stars={pClone.retrospectives[monthYear].retrospectiveScrumMaster['_2']} key={monthYear+'6'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveScrumMaster', '_2', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectiveScrumMaster3')} stars={pClone.retrospectives[monthYear].retrospectiveScrumMaster['_3']} key={monthYear+'7'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveScrumMaster', '_3', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectiveScrumMaster4')} stars={pClone.retrospectives[monthYear].retrospectiveScrumMaster['_4']} key={monthYear+'8'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveScrumMaster', '_4', newNumberOfStars);
				}}}/>

				<Space/><Space/><Space/>
				<Label text={`${GetLabel('retrospectivePO')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('retrospectivePO1')} stars={pClone.retrospectives[monthYear].retrospectivePO['_1']} key={monthYear+'9'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectivePO', '_1', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectivePO2')} stars={pClone.retrospectives[monthYear].retrospectivePO['_2']} key={monthYear+'10'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectivePO', '_2', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectivePO3')} stars={pClone.retrospectives[monthYear].retrospectivePO['_3']} key={monthYear+'11'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectivePO', '_3', newNumberOfStars);
				}}}/>

				<Space/><Space/><Space/>
				<Label text={`${GetLabel('retrospectiveQuarterback')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('retrospectiveQuarterback1')} stars={pClone.retrospectives[monthYear].retrospectiveQuarterback['_1']} key={monthYear+'12'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveQuarterback', '_1', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectiveQuarterback2')} stars={pClone.retrospectives[monthYear].retrospectiveQuarterback['_2']} key={monthYear+'13'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveQuarterback', '_2', newNumberOfStars);
				}}}/>
				<StarGrading text={GetLabel('retrospectiveQuarterback3')} stars={pClone.retrospectives[monthYear].retrospectiveQuarterback['_3']} key={monthYear+'14'} editable considerZero event={{id: 'event_00030', project: pClone, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('retrospectiveQuarterback', '_3', newNumberOfStars);
				}}}/>

				<Space/><Space/><Space/>
			</div>
		);
	}

	renderSeparatorSet() {
		let project = this.getProject();
		if(project.stage === 'ideation') 
			return undefined;

		let jsx = [];

		jsx.push(<Space/>);
		jsx.push(<SeparatorLarge disableBackgroundLines/>);
		jsx.push(<Space/>);

		return jsx;
	}

	getProject() {
		let { project } = this.props.navParams;
		return GetProjectById(project.id);
	}

	saveRetrospectiveChange(category, subcategory, value) {
		this.setState({isLoading: true });

		let project = this.getProject();

		if(project.retrospectives === undefined) project.retrospectives = {};
		if(project.retrospectives[this.state.monthYear] === undefined) project.retrospectives[this.state.monthYear] = {};
		if(project.retrospectives[this.state.monthYear][category] === undefined) project.retrospectives[this.state.monthYear][category] = {};
		
		project.retrospectives[this.state.monthYear][category][subcategory] = value;

		console.log('object to write: ',project);

		// project.percentage = this.state.percentage;
		ReplaceProjectInGlobalById(project,
			() => 
				this.setState({
					// dirtyProjectPercentage: false,
					// percentage: '0%',
					isLoading: false,
				}), 
			() =>
				this.setState({
					isLoading: false,
				}));
	}

	/*renderSaveButtonIfDirty() {
		let { eventId, project } = this.props.navParams;
		let { dirtyName, dirtyScrumMaster, dirtyLeader, dirtyDeadline, dirtyProjectStage,
			dirtyKickOff, dirtyQuarterback, dirtyObjectives, dirtyDesirable,
			dirtyFeasible, dirtyViable, dirtyIcon } = this.state;
		
		let isDirty = (dirtyName || dirtyScrumMaster || dirtyLeader || dirtyDeadline || 
			dirtyProjectStage || dirtyKickOff || dirtyQuarterback || dirtyObjectives ||
			dirtyDesirable || dirtyFeasible || dirtyViable || dirtyIcon);

		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: eventId, project: project, onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}*/

	render() {
		// let { project } = this.props.navParams;
		// project = GetProjectById(project.id);
		let project = this.getProject();
		
		//if(!project)
		//	return <LoadingScreen />;
		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		let className = (this.props.className) ? this.props.className : 'moveIn';
		return (
			<div style={css.container} className={className}>
				<Header text={GetLabel('retrospectives')} 
					// buttonRight={{
					// 	id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],  
					// 	icon: 'img/sys/edit.png', 
					// 	project: project,
					// 	onClick: ()=>this.props.innerNavigate('projectEditor', {
					// 		project: GetProjectById(project.id),
					// 		eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
					// 	})}}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					<MonthSelector editable onClick={(newDate) => { this.setState({monthYear: newDate}); }}/>
					{/*
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('initialDeadline')} 
						text={FormatDateShort(project.initialDeadline, ' ')}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('quarterback')} text={project.quarterback}/>
					*/}
					{ this.renderSeparatorSet() }
					{ this.renderRetrospectivasTitle() }
					{ this.renderSeparatorSet() }
					{ this.renderRetrospectivasDetails(project) }

					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.background_2,
		fontWeight: 'bold',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	listButton: {
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		paddingLeft: '0',
		paddingRight: '0',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',

	},
}

export { Retrospectives };