import React, { Component } from 'react';
import { Colors, MainFontSize, FontName } from '../constants';

class Label extends Component{
	render() {
		return (
			<div style={{...css.container, ...this.props.style}} className={this.props.className}>
				{ this.props.childrenAsPrefix }
				<label style={{...css.label, ...this.props.labelStyle}}>
					{ this.props.text }
				</label>
				{ this.props.children }
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: '0.5em',
		marginBottom: '0.5em',
		padding: '0.5em',
		marginLeft: '1em',
		marginRight: '1em',
	},
	label: {
		color: Colors.secondary,
		fontFamily: FontName,
		fontSize: MainFontSize,
		letterSpacing: '1px',
		wordWrap: 'break-word',
		overflowWrap: 'break-word',
		width: '100%',
	}
}

export { Label };
