import React, { Component } from 'react';
import { Colors, SeparatorWidth, IsNullOrEmpty, SmallFontSize } from '../constants';
import { Label } from './';

class Separator extends Component{

	renderTextIfNeeded() {
		if(IsNullOrEmpty(this.props.text))
			return undefined;
		else
			return (
				<Label style={css.today} labelStyle={css.todayLabel} text={this.props.text}/>
			);
	}

	render() {
		let propsStyle = {};
		
		if (this.props.color){
			propsStyle.background = this.props.color;
			propsStyle.boxShadow = `${this.props.color} 0px 0px 20px`;
		}
		if (this.props.disableGlow) 
			propsStyle.boxShadow = 'initial';

		return (
			<div style={{...css.container, ...this.props.style, ...propsStyle}}>
				{ this.renderTextIfNeeded() }
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: SeparatorWidth,
		minHeight: SeparatorWidth,
		background: Colors.primary,
    	boxShadow: `${Colors.primary} 0px 0px 20px`,
    	zIndex: '10',
	},
	today: {
		marginTop: '0',
		marginRight: '0',
		marginBottom: '0',
		marginLeft: '0.5vw',
		//position: 'absolute',
		left: '0',
		paddingTop: '2px',
		paddingBottom: '0',
		paddingLeft: '0',
	},
	todayLabel: {
		color: Colors.primary, 
		textTransform: 'uppercase',
		fontSize: SmallFontSize,
	}
}

export { Separator };
