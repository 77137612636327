import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, IsAnObjectArray, FormatDateTimeLong, Today, LastMonth,
	GetFullMonth, SubHeadersSize, DirtyStyle, ReplaceProjectInGlobalById, Clone, ObjectToArray,
	UpdateRetrospectives, 
} from '../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, SeparatorLarge, MonthSelector, StarGrading, ConfirmationButtons, 
	SideMenu,
} from '../components';

class RetrospectivesFactories extends Component {

	state = {
		monthYear: Today('MM_YYYY'),
		isLoading: false,
	}

	renderRetrospectivasTitle() {
		return (
			<Label 
				labelStyle={{fontSize: SubHeadersSize, textAlign: 'center'}}
				text={`${GetLabel('retrospective')} ${GetFullMonth(parseInt(this.state.monthYear.substring(0, 2)))} ${this.state.monthYear.substring(3)}`}/>
		);
	}

	getUsersArray() {
		let usersArray = ObjectToArray(global.users);
		
		usersArray.sort((a, b) => {
			let aName = GetLabel(a.name);
			let bName = GetLabel(b.name);

			if (aName > bName)
		        return 1;
		    if (bName > aName)
		        return -1;
		    return 0;
		});

		return usersArray;
	}

	renderUserDropdown(labelKey, category, subcategory, lastScrumMasterIdDataObject) {
		let value = global.retrospectives[this.state.monthYear][category][subcategory] === undefined ? '' : global.retrospectives[this.state.monthYear][category][subcategory];

		return (
			<DropDown 
				placeholder={GetLabel(labelKey)}
				value={value} 
				renderAnyway={true}
				containerStyle={{width: '10rem', marginLeft: '1rem'}}
				style={{padding: '0.3rem', minHeight: '3rem', marginTop: '0rem'}}
				concatenatedString={''}
				options={this.getUsersArray()}
				optionsAreObjects={true}
				editable={!this.state.isLoading && AmIAllowedToExecuteThis('event_00031', undefined, lastScrumMasterIdDataObject)}
				event={{
					id: 'event_00031', 
					onClick: (userId)=> {
						if(!this.state.isLoading) {
							// this.clearDirtyState();
							// this.setState({userIdBeingEdited: userId});
							global.retrospectives[this.state.monthYear][category][subcategory] = userId;
							// this.setState({refresh: true});
							this.saveRetrospectiveChange(category, subcategory, userId);
						}
					}
				}}/>
		);
	}

	renderIdeadores(labelKey, category, lastScrumMasterId) {
		let { monthYear } = this.state;

		if(global.retrospectives[monthYear][category].numIdeadores === undefined) global.retrospectives[monthYear][category].numIdeadores = 0;

		let jsx = [];

		for(let j=0; j<global.retrospectives[monthYear][category].numIdeadores; j++) {
			jsx.push(
				<StarGrading text={GetLabel(labelKey)} stars={global.retrospectives[monthYear][category][labelKey+'_'+j]} editable considerZero key={monthYear+((j+100)+'')} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: lastScrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange(category, labelKey+'_'+j, newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown(labelKey, category, labelKey+'_'+j+'Id', {lastMonthValue: lastScrumMasterId})}
				</StarGrading>
			);
		}

		jsx.push(
			<WideButton text={GetLabel('addNewIdeator')} 
				style={{width: 'calc(100% - 10rem)', marginLeft: '7rem'}}
				event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: lastScrumMasterId}, onClick: () => {
				let newNumberOfIdeadores = global.retrospectives[monthYear][category].numIdeadores + 1;
				// this.setState({refresh: true});
				this.saveRetrospectiveChange(category, 'numIdeadores', newNumberOfIdeadores);
			}}}/>
		);

		if(global.retrospectives[monthYear][category].numIdeadores >= 1) {
			jsx.push(
				<WideButton text={GetLabel('removeLastIdeator')} 
					style={{width: 'calc(100% - 10rem)', marginLeft: '7rem'}}
					event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: lastScrumMasterId}, onClick: () => {
					// global.retrospectives[monthYear][category].numIdeadores--;
					// this.setState({refresh: true});
					let newNumberOfIdeadores = global.retrospectives[monthYear][category].numIdeadores - 1;
					this.saveRetrospectiveChange(category, 'numIdeadores', newNumberOfIdeadores);
				}}}/>
			);
		}

		return jsx;
	}

	renderRetrospectivasDetails() {

		let { monthYear } = this.state;
		if(global.retrospectives === undefined) global.retrospectives = {};
		if(global.retrospectives[monthYear] === undefined) global.retrospectives[monthYear] = {};
		if(global.retrospectives[monthYear].factoriesIdeas === undefined) global.retrospectives[monthYear].factoriesIdeas = {};
		if(global.retrospectives[monthYear].factoriesSolutions === undefined) global.retrospectives[monthYear].factoriesSolutions = {};
		if(global.retrospectives[monthYear].factoriesEntrepreneurs === undefined) global.retrospectives[monthYear].factoriesEntrepreneurs = {};
		if(global.retrospectives[monthYear].generalActivities === undefined) global.retrospectives[monthYear].generalActivities = {};

		let lastMonthYear = LastMonth('MM_YYYY');
		let retrospectivesClone = Clone(global.retrospectives);
		if(retrospectivesClone === undefined) retrospectivesClone = {};
		if(retrospectivesClone[lastMonthYear] === undefined) retrospectivesClone[lastMonthYear] = {};
		if(retrospectivesClone[lastMonthYear].factoriesIdeas === undefined) retrospectivesClone[lastMonthYear].factoriesIdeas = {};
		if(retrospectivesClone[lastMonthYear].factoriesSolutions === undefined) retrospectivesClone[lastMonthYear].factoriesSolutions = {};
		if(retrospectivesClone[lastMonthYear].factoriesEntrepreneurs === undefined) retrospectivesClone[lastMonthYear].factoriesEntrepreneurs = {};
		if(retrospectivesClone[lastMonthYear].generalActivities === undefined) retrospectivesClone[lastMonthYear].generalActivities = {};


		console.log('lastMonthYear: '+lastMonthYear);


		return (
			<div style={{marginLeft: '-6rem', marginRight: '-4rem'}}>
				<Label text={`${GetLabel('factoriesIdeas')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('scrumMaster')} stars={global.retrospectives[monthYear].factoriesIdeas['scrumMaster']} editable considerZero key={monthYear+'1'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesIdeas', 'scrumMaster', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('scrumMaster', 'factoriesIdeas', 'scrumMasterId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('factoryLeader')} stars={global.retrospectives[monthYear].factoriesIdeas['factoryLeader']} editable considerZero key={monthYear+'2'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesIdeas', 'factoryLeader', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('factoryLeader', 'factoriesIdeas', 'factoryLeaderId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('quarterback')} stars={global.retrospectives[monthYear].factoriesIdeas['quarterback']} editable considerZero key={monthYear+'3'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesIdeas', 'quarterback', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('quarterback', 'factoriesIdeas', 'quarterbackId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('support')} stars={global.retrospectives[monthYear].factoriesIdeas['support']} editable considerZero key={monthYear+'4'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesIdeas', 'support', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('support', 'factoriesIdeas', 'supportId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId})}
				</StarGrading>
				{ this.renderIdeadores('ideators', 'factoriesIdeas', retrospectivesClone[lastMonthYear]['factoriesIdeas'].scrumMasterId) }

				<Space/><Space/><Space/>
				<Label text={`${GetLabel('factoriesSolutions')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('scrumMaster')} stars={global.retrospectives[monthYear].factoriesSolutions['scrumMaster']} editable considerZero key={monthYear+'6'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesSolutions', 'scrumMaster', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('scrumMaster', 'factoriesSolutions', 'scrumMasterId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('factoryLeader')} stars={global.retrospectives[monthYear].factoriesSolutions['factoryLeader']} editable considerZero key={monthYear+'7'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesSolutions', 'factoryLeader', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('factoryLeader', 'factoriesSolutions', 'factoryLeaderId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('quarterback')} stars={global.retrospectives[monthYear].factoriesSolutions['quarterback']} editable considerZero key={monthYear+'8'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesSolutions', 'quarterback', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('quarterback', 'factoriesSolutions', 'quarterbackId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('support')} stars={global.retrospectives[monthYear].factoriesSolutions['support']} editable considerZero key={monthYear+'9'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesSolutions', 'support', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('support', 'factoriesSolutions', 'supportId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId})}
				</StarGrading>
				{ this.renderIdeadores('ideators', 'factoriesSolutions', retrospectivesClone[lastMonthYear]['factoriesSolutions'].scrumMasterId) }
				{/*<StarGrading text={GetLabel('ideators')} stars={global.retrospectives[monthYear].factoriesSolutions['ideators']} editable considerZero key={monthYear+'10'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesSolutions', 'ideators', newNumberOfStars);
				}}}>
					{this.renderUserDropdown('ideators', 'factoriesSolutions', 'ideatorsId')}
				</StarGrading>*/}



				<Space/><Space/><Space/>
				<Label text={`${GetLabel('factoriesEntrepreneurs')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('scrumMaster')} stars={global.retrospectives[monthYear].factoriesEntrepreneurs['scrumMaster']} editable considerZero key={monthYear+'11'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesEntrepreneurs', 'scrumMaster', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('scrumMaster', 'factoriesEntrepreneurs', 'scrumMasterId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('factoryLeader')} stars={global.retrospectives[monthYear].factoriesEntrepreneurs['factoryLeader']} editable considerZero key={monthYear+'12'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesEntrepreneurs', 'factoryLeader', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('factoryLeader', 'factoriesEntrepreneurs', 'factoryLeaderId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('quarterback')} stars={global.retrospectives[monthYear].factoriesEntrepreneurs['quarterback']} editable considerZero key={monthYear+'13'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesEntrepreneurs', 'quarterback', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('quarterback', 'factoriesEntrepreneurs', 'quarterbackId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId})}
				</StarGrading>
				<StarGrading text={GetLabel('support')} stars={global.retrospectives[monthYear].factoriesEntrepreneurs['support']} editable considerZero key={monthYear+'14'} event={{id: 'event_00031', retrospectivesFactories: {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId}, onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesEntrepreneurs', 'support', newNumberOfStars);
				}}} style={css.starGrading} labelStyle={css.starLabelStyle}>
					{this.renderUserDropdown('support', 'factoriesEntrepreneurs', 'supportId', {lastMonthValue: retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId})}
				</StarGrading>
				{ this.renderIdeadores('ideators', 'factoriesEntrepreneurs', retrospectivesClone[lastMonthYear]['factoriesEntrepreneurs'].scrumMasterId) }
				{/*<StarGrading text={GetLabel('ideators')} stars={global.retrospectives[monthYear].factoriesEntrepreneurs['ideators']} editable considerZero key={monthYear+'15'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('factoriesEntrepreneurs', 'ideators', newNumberOfStars);
				}}}>
					{this.renderUserDropdown('ideators', 'factoriesEntrepreneurs', 'ideatorsId')}
				</StarGrading>*/}



				<Space/><Space/><Space/>
				<Label text={`${GetLabel('generalActivities')}`} labelStyle={{fontSize: SubHeadersSize}}/>
				<StarGrading text={GetLabel('itActivity')} stars={global.retrospectives[monthYear].generalActivities['itActivity']} editable considerZero key={monthYear+'16'} event={{id: 'event_00031', onChange: (newNumberOfStars) => {
					this.saveRetrospectiveChange('generalActivities', 'itActivity', newNumberOfStars);
				}}}/>

				<Space/><Space/><Space/>
			</div>
		);
	}

	renderSeparatorSet() {
		let jsx = [];

		jsx.push(<Space/>);
		jsx.push(<SeparatorLarge disableBackgroundLines/>);
		jsx.push(<Space/>);

		return jsx;
	}

	saveRetrospectiveChange(category, subcategory, value) {
		this.setState({isLoading: true });

		let retrospectivesClone = Clone(global.retrospectives);

		if(retrospectivesClone === undefined) retrospectivesClone = {};
		if(retrospectivesClone[this.state.monthYear] === undefined) retrospectivesClone[this.state.monthYear] = {};
		if(retrospectivesClone[this.state.monthYear][category] === undefined) retrospectivesClone[this.state.monthYear][category] = {};
		retrospectivesClone[this.state.monthYear][category][subcategory] = value;
		// console.log('object to write: ',retrospectivesClone);

		UpdateRetrospectives(retrospectivesClone,
			() => 
				this.setState({
					isLoading: false,
				}), 
			() =>
				this.setState({
					isLoading: false,
				}));
	}

	render() {

		let className = (this.props.className) ? this.props.className : 'moveIn';
		return (
			<div style={css.container} className={className}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('retrospectives')} 
					// buttonRight={{
					// 	id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],  
					// 	icon: 'img/sys/edit.png', 
					// 	project: project,
					// 	onClick: ()=>this.props.innerNavigate('projectEditor', {
					// 		project: GetProjectById(project.id),
					// 		eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
					// 	})}}
					// buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					/>
				<Div noVerticalPadding>
					<MonthSelector editable onClick={(newDate) => { this.setState({monthYear: newDate}); }}/>
					{/*
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('initialDeadline')} 
						text={FormatDateShort(project.initialDeadline, ' ')}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('quarterback')} text={project.quarterback}/>
					*/}
					{ this.renderRetrospectivasTitle() }
					{ this.renderRetrospectivasDetails() }

					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	starGrading: {
		alignItems: 'flex-start',
	},
	starLabelStyle: {
		// alignSelf: 'center',
		marginTop: '1rem',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.background_2,
		fontWeight: 'bold',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	listButton: {
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		paddingLeft: '0',
		paddingRight: '0',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',

	},
}

export { RetrospectivesFactories };