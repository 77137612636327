import React, { Component  } from 'react';
import { Colors, ProjectIconDiameter, Clone, GetLabel, Alert } from '../constants';
import { Minuta, MinutaEditor, MinutaDetails } from '../screens/screens_minutas';
import Moment from 'moment';

class Minutas extends Component {

	state = {
		screen: 'minutas',
		poppedScreen: '',
		navParams: {}
	}

	componentDidMount() {
		this.stack = [];

		if(this.props.navigateToCurrentMinuta) {
			// let minutaKeys = Object.keys(global.minutas);

			let sortedMinutaKeys = Object.keys(global.minutas).sort(function(a, b) {
				let aMoment = Moment(a, '_DD_MM_YYYY');
				let bMoment = Moment(b, '_DD_MM_YYYY');
				let difference = aMoment.diff(bMoment , 'days');
				difference = (difference > 0) ? 1 : difference;
				difference = (difference < 0) ? - 1 : difference;
				difference = (difference == 0) ? 0 : difference;
				return  difference;
			});
			sortedMinutaKeys = sortedMinutaKeys.reverse();


			if(sortedMinutaKeys.length > 0) {
				let minutaKey = sortedMinutaKeys[0];
				let minuta = global.minutas[minutaKey];
				this.navigate('minutaDetails', {
					eventId: 'event_00000',
					minuta: Clone(minuta),
				});
			}
		}
	}

	navigate(target, params) {
		this.stack.push(target);
		this.setState({screen: target, navParams: params, poppedScreen: ''});//selectedProjectId
	}

	goBack() {
		if(global.databaseWriteInProcess) {
			Alert(GetLabel('databaseWriteInProcess'));
			return;
		}
		let poppedScreen = (this.stack.length > 0) ? this.stack[this.stack.length-1] : '';
		this.stack.pop();
		let prevScreen = (this.stack.length > 0) ? this.stack[this.stack.length-1] : 'minutas';
		this.setState({screen: prevScreen, poppedScreen: poppedScreen});
	}

	renderFromState(type, screenName) {
		let classToAdd = undefined;

		if(type === 'popped') 
			classToAdd = 'moveOut';
		
		switch(screenName) {
			case 'minutas':
				return (
					<Minuta 
						key={'minuta'}
						navigate={ this.props.navigate } 
						innerNavigate={ (target, params) => this.navigate(target, params) }
						className={classToAdd}/>
				);
			case 'minutaEditor':
				return (
					<MinutaEditor 
						key={'minutaEditor'}
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={() => this.goBack()}
						className={classToAdd}/>
				);
			case 'minutaDetails':
				return (
					<MinutaDetails
						key={'minutaDetails'}
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						goBack={() => this.goBack()}
						className={classToAdd}/>
				);
			default:
				return <div/>; 
		}
	}

	render() {

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				{ this.renderFromState('current', this.state.screen) }
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
}

export { Minutas };