import React, { Component } from 'react';
import { Colors, SubHeadersSize, IsNullOrEmpty, GetLabel } from '../constants';
import { Image, Label, ListButton, Button } from './';

class DropDown extends Component{

	state = {
		value: '',
		closed: true,
		percentage: 0,
		selectorOptionsClass: '',
	}

	constructor(props){
		super(props);
		this.selectorOptionsHeight = '4em';
	}

	componentDidMount() {
		let { calculatePercentage, value, optionsAreObjects } = this.props;
		value = (optionsAreObjects) ? value.id : value;
		this.setState({
			value: value,
			percentage: (calculatePercentage) ? calculatePercentage(value) : 0
		});
	}

	/*findObjectInOptions(newValue) {
		let objectFound = {id: -1, name: newValue};
		for(let i=0; i<this.props.options.length; i++) {
			if(this.props.options[i].name === newValue) {
				objectFound = {...this.props.options[i]};
				break;	
			}
		}
		return objectFound;
	}*/

	changeValue(newValue) {
		newValue = (this.props.optionsAreObjects) ? newValue.id : newValue;
		this.setState({
			value: newValue, 
			closed: true,
			selectorOptionsClass: 'contract'
		});
		this.props.event.onClick(newValue);
		//OnModify should be called here!
	}

	renderEventId() {
		if(!global.isInDebug) 
			return undefined;
		else {
			let id = (Array.isArray(this.props.event.id)) ? this.props.event.id.join(',') : 
				(IsNullOrEmpty(this.props.event.id)) ? 'undef' : this.props.event.id.substr(8);
			return (<Label text={id} style={css.eventIdContainer} labelStyle={css.eventIdLabel}/>);
		}
	}

	toggleDropdown() {
		this.setState({
			selectorOptionsClass: (this.state.closed) ? 'expand' : 'contract',
			closed: !this.state.closed
		});
	}

	getArrowStyleFromState() {
		if(this.state.closed)
			return {};
		else
			return {
				MozTransform: 'scale(-1, -1)',
			    OTransform: 'scale(-1, -1)',
			    WebkitTransform: 'scale(-1, -1)',
			    transform: 'scale(-1, -1)',
			};
	}

	renderOptions() {
		let color = (this.props.color) ? this.props.color : Colors.primary;

		let optionsContainer = {
			transition: 'height 0.3s',
			height: '0',
			borderLeft: `2px solid ${color}`,
			borderRight: `2px solid ${color}`,
			borderBottom: `2px solid ${color}`,
			background: `${color}22`,
			borderRadius: '0 0 0.5em 0.5em',
			//marginTop: 'calc(-1.3em + 2px)',
			//borderRadius: '0.5em',
			marginTop: `calc(-1em - 2px)`,//1.4
			//zIndex: '-1',
			overflow: 'hidden',
			position: 'relative',
		}

		let expandOrContract = (this.state.closed) ? {
			height: '0'
		} : {
			height: `calc(${this.selectorOptionsHeight} * ${this.props.options.length} + 2px * ${this.props.options.length - 1})`
		};

		//if(this.state.closed)
		//	return <div/>
		//else
			return (
				<div style={{...optionsContainer, ...expandOrContract}}>
				{
					this.props.options.map((item, i) => {
						let label = (item.id === null || item.id === undefined) ? GetLabel(item) : GetLabel(item.name);
						label = (global.isInDebug && !IsNullOrEmpty(item.id)) ? label + ' '+item.id : label;
						return (
							<ListButton event={{id: this.props.event.id, project: this.props.event.project, icon: undefined, onClick: ()=>{this.changeValue(item)}}}
								renderAnyway={this.props.renderAnyway}
								containerStyle={{height: this.selectorOptionsHeight, borderTop: `2px solid ${color}22`}}
								style={{height: '100%', paddingBottom: '0', paddingTop: '0'}} key={i}>
								<Label text={label}/>
							</ListButton>
						);
					}) 
				}
				</div>
			);
	}

	findObjectNameById(id) {
		let objectName = '';
		for(let i=0; i<this.props.options.length; i++) {
			if(this.props.options[i].id == id) {
				objectName = this.props.options[i].name;
				break;	
			}
		}
		return objectName;
	}

	getLabelToRender() {
		let value = (this.props.optionsAreObjects) ? this.findObjectNameById(this.props.value) : this.props.value;

		let percentageInfo = (this.props.calculatePercentage && this.props.showPercentage) ? 
			` ${this.props.calculatePercentage(value) * 100}%` : '';
		let concatenatedString = (this.props.concatenatedString) ? this.props.concatenatedString : '';
		let prefixString = (this.props.prefixString) ? this.props.prefixString : '';

		let stringToRender = prefixString + GetLabel(value) + percentageInfo + concatenatedString;

		if(IsNullOrEmpty(stringToRender))
			stringToRender = IsNullOrEmpty(this.props.placeholder) ? '' : this.props.placeholder;

		return stringToRender;
	}

	onMouseHover() {
		if(this.props.editable)
			this.showFollower();
	}

	hideFollower(){
		document.getElementById('follower').style.display = 'none';
	}

	showFollower(){
		document.getElementById('follower').style.display = 'block';
	}

	renderArrow(color) {
		if(this.props.editable) 
			return (
				<Image 
					className={'buttonLarge'}
					src={'img/sys/arrow.png'} width={'1em'} height={'1em'} 
					color={(this.props.calculatePercentage) ? Colors.secondary : color} 
					style={{...css.icon, ...this.getArrowStyleFromState()}} />
			);
		else
			return (
				<div />
			);
	}

	render() {

		let { calculatePercentage, editable, optionsAreObjects } = this.props;

		let styleFromProps = {};
		let color = Colors.primary;
		let onClick = (editable) ? ()=>{this.toggleDropdown()} : ()=>{};
		let value = (optionsAreObjects) ? this.findObjectNameById(this.props.value) : this.props.value;

		if(this.props.color) {
			color = this.props.color;
			styleFromProps = {
				border: `2px solid ${color}`
			}
		}

		if (calculatePercentage) {
			styleFromProps = {...styleFromProps, 
				...{background: `linear-gradient(to right, 
					${color} 0%, 
					${color} ${(calculatePercentage(value) * 0.9999)*100}%, 
					${color}55 ${calculatePercentage(value)*100}%)`
				}
			};
		}

		//if(AmIAllowedToExecuteThis(this.props.event.id))
		return (
			<div style={this.props.containerStyle}>
				<Button 
					renderAnyway={true}
					glowInHover={color}
					buttonStyle={{...css.button, ...this.props.style, ...styleFromProps}} 
					className={'button'} 
					event={{
						id: this.props.event.id, 
						onClick: () => {
							onClick();
							this.hideFollower();
						},
						project: this.props.event.project,
					}}
					//onClick={() => {onClick(); this.hideFollower();}} 
					onMouseOver={()=>this.onMouseHover()} onMouseOut={()=>this.hideFollower()}>
					<div style={{display: 'flex'}}>
						{ this.props.children }
						<Label text={this.getLabelToRender()} style={css.labelContainer} 
							labelStyle={{...css.labelStyle, ...(this.props.calculatePercentage) ? {color: Colors.secondary} : {} }}/>
					</div>
					{ this.renderArrow(color) }
					{ this.renderEventId() }
				</Button>
				{ this.renderOptions() }
			</div>
		);
		//else 
		//	return <div />;
	}
}

const css = {
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: '0',
		background: Colors.primary+'50',
		border: `2px solid ${Colors.primary}`,
		//borderRadius: '0.5em',
		borderRadius: '0.5em 0.5em 0 0',
		paddingTop: '1em',
		paddingBottom: '1em',
		paddingLeft: '2em',
		paddingRight: '2em',
		color: Colors.primary,
		fontSize: '1rem',
		letterSpacing: '2px',
		marginTop: '1em',
		marginBottom: '1em',
		width: '100%',
		alignItems: 'center',
		//maxWidth: 'calc(70vw + 4em)',
	},
	labelContainer: {
		margin: '0',
		padding: '0',
		justifyContent: 'flex-start',
	},
	icon: {
		//position: 'absolute',
		marginTop: `0.2em`,
		//marginLeft: `calc(${ButtonSize} / -2.8)`,
	},
	labelStyle: {
		color: Colors.primary,
		fontSize: SubHeadersSize,
	},
	eventIdContainer: {
		display: 'block',
		position: 'absolute',
		left: '0',
		right: '0',
		padding: '0',
		marginTop: '-0.3em',
	},
	eventIdLabel: {
		color: Colors.debugLabels,
		fontSize: '10px',
		fontWeight: 'bolder',
	}
}

export { DropDown };
