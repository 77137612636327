import React, { Component } from 'react';
import { Colors, MainFontSize, FontName, PresentationHeaders, ButtonHeight, 
SmallFontSize, GetShortMonth, PadNumber, Today } from '../constants';
import { Button, Label, Image } from './';

class MonthSelector extends Component {
	state = {
		year: parseFloat(Today('YYYY')),
		month: parseFloat(Today('MM')),
	}

	renderAllMonths() {
		let jsx = [];

		for(let j=1; j <= 12; j++) {
			let buttonStyle = this.state.month === j ? css.squareColored : css.squareUncolored;
			let labelColor = this.state.month === j ? Colors.primary : Colors.primary+'70';

			jsx.push(
				<Button style={buttonStyle} 
					buttonStyle={css.noMargin}
					key={'disabledSquare'+j} 
					renderAnyway
					disableHover
					event={{
						id: 'event_00000', 
						onClick: ()=> {
							if(!this.props.editable)
								return;
							this.setState({month: j})
							let newDate = PadNumber(j, 2)+'_'+this.state.year;
							this.props.onClick(newDate);
						}
					}}>
					<Label text={GetShortMonth(j).toUpperCase()} style={{...css.noMargin, ...css.numberLabel}}
						labelStyle={{...css.noMargin,...{fontSize: SmallFontSize, textAlign: 'center', color: labelColor}}}/>
				</Button>
			);
		}

		return jsx;
	}

	renderMonthSelector() {
		

		return (
			<div style={css.monthsContainer}>
		        { this.renderAllMonths() }
			</div>
		);
	}

	getArrowStyleFromState() {
		return {
			MozTransform: 'scale(-1, -1)',
		    OTransform: 'scale(-1, -1)',
		    WebkitTransform: 'scale(-1, -1)',
		    transform: 'scale(-1, -1)',
		};
	}

	renderYearSelector() {
		// <label style={{...css.label, ...this.props.labelStyle}}>
		// 			{ this.state.year }
		// 		</label>
		return (
			<div style={css.yearSelector}>
				<Button event={{
					id: 'event_00000', 
					onClick: () => {
						let newYear = this.state.year-1;
						this.setState({year: newYear});
						let newDate = PadNumber(this.state.month, 2)+'_'+newYear;
						this.props.onClick(newDate);
					}
				}} >
					<Image 
						className={'buttonLarge'}
						src={'img/sys/right.png'} width={'4em'} height={'4em'} 
						color={Colors.primary} 
						style={{...css.icon, ...this.getArrowStyleFromState()}} />
				</Button>
				<Label text={this.state.year} labelStyle={css.yearLabel}/>
				<Button  event={{
					id: 'event_00000', 
					onClick: () => {
						let newYear = this.state.year+1;
						this.setState({year: newYear});
						let newDate = PadNumber(this.state.month, 2)+'_'+newYear;
						this.props.onClick(newDate);
					}
				}}>
					<Image 
						className={'buttonLarge'}
						src={'img/sys/left.png'} width={'4em'} height={'4em'} 
						color={Colors.primary} 
						style={{...css.icon, ...this.getArrowStyleFromState()}} />
				</Button>
			</div>
		);
	}

	render() {
		return (
			<div style={{...css.container, ...this.props.style}} className={this.props.className}>
				{ this.renderYearSelector() }
				{ this.renderMonthSelector() }
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: '0.5em',
		marginBottom: '0.5em',
		padding: '0.5em',
		marginLeft: '1em',
		marginRight: '1em',
		flexDirection: 'column',
	},
	yearLabel: {
		color: Colors.secondary,
		fontFamily: FontName,
		fontSize: PresentationHeaders,
		letterSpacing: '1px',
		wordWrap: 'break-word',
		overflowWrap: 'break-word',
		width: '100%',
		minWidth: '10vw',
    	textAlign: 'center',
	},
	yearSelector: {
		display: 'flex',
		flexDirection: 'row',
	},
	monthsContainer:  {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
	},
	squareColored: {
		backgroundColor: Colors.primary+'50', 
		border: `2px solid ${Colors.primary}`,
		height: ButtonHeight,
		maxHeight: ButtonHeight,
		minHeight: ButtonHeight,
		//flex: 0.1, 
		width: 'calc(9% - 5px)',
		maxWidth: 'calc(9% - 5px)',
		minWidth: 'calc(9% - 5px)',
		borderRadius: 5,
		marginHorizontal: -1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		zIndex: 2,
	},
	squareUncolored: {
		backgroundColor: Colors.primary+'20', 
		border: `2px solid ${Colors.primary}35`,
		height: ButtonHeight,
		maxHeight: ButtonHeight,
		minHeight: ButtonHeight,
		//flex: 0.1, 
		width: 'calc(9% - 5px)',
		maxWidth: 'calc(9% - 5px)',
		minWidth: 'calc(9% - 5px)',
		borderRadius: 5,
		marginHorizontal: 0,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		zIndex: 1,
	},
	noMargin: {
		marginLeft: '0px !important',
		marginRight: '0px !important',
		paddingLeft: '0px !important',
		paddingRight: '0px !important',
		padding: '0px !important',
		margin: '0px !important',
	}, 
	numberLabel: {
		position: 'absolute',
		marginBottom: '0px !important',
		marginTop: `calc(-${ButtonHeight} / 2)`,
		height: ButtonHeight, 
		maxWidth: 'calc(6% - 4px)',
	}
}

export { MonthSelector };
