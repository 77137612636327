import React, { Component } from 'react';
import { Colors, GetLabel, IsEmailValid, Alert, KeepUserSignedIn } from '../constants';
import { Image, InputField, Label, WideButton, Div, LoadingScreen } from '../components';
import * as firebase from 'firebase';

class Login extends Component{
	
	state ={
		email: '',
		password: '',
		buttonsEnabled: true,
	}

	componentDidMount() {
		if(!KeepUserSignedIn)
			firebase.auth().signOut();
		firebase.auth().onAuthStateChanged((user) => {
			//console.log('onAuthStateChanged ', user);
			if(user){
				//this.currentUser = user.uid;
				this.onLoginSuccess(user);
			}
		});
	}


	onEmailLogin() {
		let { email, password } = this.state;
		//console.log('email: ',email);
		//console.log('password: ',password);

		if(!this.validateEmail())
			return;

		this.setState({buttonsEnabled: false});
		
		firebase.auth().signInWithEmailAndPassword(email, password)
			.then((result) => {
				//console.log('email login success: ',result);
				result.user.getIdToken().then((token) => {
					global.Token = token;
					//this.onLoginSuccess();
					//SaveToLocalStorage('p-w', JSON.stringify({pw:password}));
				});

			})
			.catch((error) => {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;

				Alert(GetLabel(errorCode));
				this.setState({buttonsEnabled: true});
			});
	}

	validateEmail() {
		if(IsEmailValid(this.state.email)) 
			return true;
		else{
			Alert(GetLabel('invalidEmail'));
			return false;
		}
	}

	onLoginSuccess(user) {
		global.Log('on login success');
		this.props.onLoginSuccess(user);
	}

	render() {
		if(this.state.buttonsEnabled)
			return (
				<div style={{...css.container, ...this.props.style}}>
					<Div innerStyle={css.innerContainer}>
						{/*<Image src={'img/sys/logo.png'} width={'12em'} height={'12em'} color={'#ffffff'} style={{marginBottom: '3em', marginTop: '-8vh'}}/>*/}
						<img src={'img/sys/logo.png'} color={'#ffffff'} style={{marginBottom: '3em', marginTop: '-8vh', width: '12em', height: '12em'}}/>
						<InputField placeholder={GetLabel('username')} type={'text'} 
							onChange={(email) => this.setState({email: email})}/>
						<InputField placeholder={GetLabel('password')} type={'password'}
							onChange={(password) => this.setState({password: password})} onEnter={()=>this.onEmailLogin()}/>
						<WideButton text={GetLabel('login')} 
							event={{id: 'event_00000', icon:'img/sys/key.png', onClick:()=>this.onEmailLogin() }}/>
						<Label text={GetLabel('disclaimer')} style={css.disclaimer} labelStyle={css.disclaimerLabel}/>
					</Div>
					<div style={css.lines} />
					<div style={css.linesBlack}/>
				</div>
			);
		else
			return (<LoadingScreen/>);
	}
}
const css = {
	container: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		//.marginTop: '-2em'
	},
	innerContainer: {
		color: Colors.primary,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		paddingBottom: '-2em',
		/*
		color: Colors.primary,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	    position: 'fixed',
		height: '100%',
		width: '100%',
		marginTop: '-2em',*/
		//background: 'linear-gradient(-45deg,#161616 25%,#000 0,#000 50%,#161616 0,#161616 75%,#000 0,#000)',
		//zIndex: '-15',
	},
	lines: {
		transform: 'skewX(-45deg)',
		//backgroundImage: 'linear-gradient(transparent, #fb0, #fb0, #fb0)',
		backgroundImage: `linear-gradient(transparent 20%, ${Colors.primary} 65%, ${Colors.primary} 70%`,
		zIndex: '-10',

		width: '225em',
	    height: '252em',
	    position: 'fixed',
	    bottom: '-129em',
	    right: 'calc(-179em + 25vw)',
	},
	linesBlack: {
		transform: 'skewX(-45deg)',
		background: 'linear-gradient(-90deg,#161616 25%,#000 0,#000 50%,#161616 0,#161616 75%,#000 0,#000)',
		backgroundRepeat: 'repeat',
	    backgroundSize: '4em 4em',
	    opacity: '0.5',
	    zIndex: '-11',

	    width: '225em',
	    height: '252em',
	    position: 'fixed',
	    bottom: '-129em',
	    right: 'calc(-179em + 25vw)',
	},
	disclaimer: {
		position: 'absolute',
		bottom: '1em',
	},
	disclaimerLabel: {
		color: `${Colors.black}`,
		textDecoration: 'underline',
	}
}

export { Login };