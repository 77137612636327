import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize,
	FormatDateTimeLong, Clone } from '../../constants';
import { SideMenu, Header, SeparatorLarge, Label, BulletPointsList, Subheader, WideButton, 
	Div, Expansible, Space, ListButton, Parameter, Separator } from '../../components';
import Moment from 'moment';

class Minuta extends Component {

	state = {
		/*
		minutas: {
			_22_12_2020: {
				id: '22/12/2020',
				createdBy: 'dYEY9R6mACOKc2PmlKHW956znXu2',//user UD
				creationMoment: '22/12/2020 20:12',
				lastEditedBy: 'dYEY9R6mACOKc2PmlKHW956znXu2',//user UD
				lastModification: '22/12/2020 20:12',
				toDoList: [
					{
						//project: '',//project ID
						name: 'Venom - 15 Ago',
						points: [
							{name: 'Sensorial de productos de prueba', checked: false}, 
							{name: 'Iniciar reporte', checked: true}
						], 
					},
					{
						//project: '',//project ID
						name: 'Molécula perdida - 15 Ago',
						points: [
							{name: 'Cerrar reporte', checked: false}
						]
					}
				]
			},
		}*/
	}

	getUserName(userId) {
		let userName = global.users[userId] ? global.users[userId].name : GetLabel('userNotFound')+': '+userId;
		return userName;
	}

	renderMinutas() {
		let jsx = [];

		//sort minutas by date
		let counter = 0;

		let sortedMinutaKeys = Object.keys(global.minutas).sort(function(a, b) {
			let aMoment = Moment(a, '_DD_MM_YYYY');
			let bMoment = Moment(b, '_DD_MM_YYYY');
			let difference = aMoment.diff(bMoment , 'days');
			difference = (difference > 0) ? 1 : difference;
			difference = (difference < 0) ? - 1 : difference;
			difference = (difference == 0) ? 0 : difference;
			return  difference;
		});
		sortedMinutaKeys = sortedMinutaKeys.reverse();
		console.log('sortedMinutaKeys: ',sortedMinutaKeys);

		sortedMinutaKeys.forEach((key, i) => {
			let minuta = global.minutas[key];
			let customStyle = (counter === 0) ? { background: Colors.primary+'33' } : {};
			jsx.push(
				<ListButton key={i} event={{
						id: 'event_00000', 
						onClick: ()=> 
							this.props.innerNavigate('minutaDetails', {
								eventId: 'event_00000',
								minuta: Clone(minuta),
							})
					}} 
					disableHover
					buttonStyle={{zIndex: '10'}}
					style={{...css.columnStyle, ...{height: 'initial'}, ...customStyle}}>
					<Parameter icon={'img/sys/toDo.png'} iconLabel={''} iconSize={'5em'} style={{pointerEvents: 'none'}}>
						<div style={css.columnStyle}>
							<Subheader text={FormatDateTimeLong(minuta.lastModification, ' ')} style={css.noVerticalSpace}/>
							<Label text={GetLabel('createdBy')+': '+this.getUserName(minuta.lastEditedBy)} style={css.noVerticalSpace}/>
						</div>
					</Parameter>
				</ListButton>
			);
			jsx.push(<Separator key={'separator '+i}/>);
			counter++;
		});
		return jsx;
	}

	renderTemporalInfo() {
		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('minuta')}/>
			</div>
		);
	}

	render() {
		//return this.renderTemporalInfo();
		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('minuta')}
					buttonRight={
						{id: 'event_00026', icon: 'img/sys/add.png', onClick: ()=>
							this.props.innerNavigate('minutaEditor', {
								eventId: 'event_00026',
							})
						}
					}/>
				{/*<Div noVerticalPadding>*/}
					{ this.renderMinutas() }
				{/*</Div>*/}
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	columnStyle: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	noVerticalSpace: {
		paddingTop: '0.1em',
		paddingBottom: '0.1em',
		marginTop: '0',
		marginBottom: '0',
	}
}

export { Minuta };
