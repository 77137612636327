import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize, 
	UpdateProfile, Clone, DirtyStyle, MediumButtonSize, SelectFile, IsNullOrEmpty } from '../../constants';
import { Header, ConfirmationButtons, Label, Subheader, WideButton, InputField,
	Div, Expansible, Space, Profile, Image } from '../../components';
import { UploadFile } from '../../firebase';

class ProfileEditor extends Component {

	state = {
		isLoading: false,
		isNameDirty: false,
		isPhoneNumberDirty: false,
		isPhotoDirty: false,
		name: '',
		phoneNumber: '',
		imageURL: '',
		imageContent: '', 
		imageFile: '',
	}

	saveChanges() {
		let { isNameDirty, isPhoneNumberDirty, isPhotoDirty, name, phoneNumber } = this.state;
		this.setState({isLoading: true});

		let profileClone = Clone(global.profile);

		if(isNameDirty)
			profileClone.name = name;
		if(isPhoneNumberDirty)
			profileClone.phoneNumber = phoneNumber;
		
		if(isPhotoDirty) {
			UploadFile(
				'profiles/'+profileClone.id, 
				this.state.imageFile,
				(imageURL) => { 
					profileClone.image = imageURL;
					UpdateProfile(profileClone,
						() => this.clearDirtyState(), 
						() => this.setState({ isLoading: false }));
				}, 
				() => { this.setState({ isLoading: false }); }
			);
		}
	}

	clearDirtyState() {
		this.setState({
			isLoading: false,
			isNameDirty: false,
			isPhoneNumberDirty: false,
			isPhotoDirty: false,
			name: '',
			phoneNumber: '',
			imageURL: '',
			imageContent: '', 
			imageFile: '',
		});
	}

	renderSaveButtonIfDirty() {
		let { isNameDirty, isPhoneNumberDirty, isPhotoDirty } = this.state;
		
		if(isNameDirty || isPhoneNumberDirty || isPhotoDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	selectFile() {
		SelectFile((selectedFile, content, imageURL) => {
			this.setState({
				isPhotoDirty: true, 
				imageURL: imageURL,
				imageContent: content, 
				imageFile: selectedFile,
			}); 
		});
	}

	render() {

		let name = (this.state.isNameDirty) ? this.state.name : global.profile.name;
		let phoneNumber = (this.state.isPhoneNumberDirty) ? this.state.phoneNumber : global.profile.phoneNumber;
		let image = (this.state.isPhotoDirty) ? this.state.imageURL : global.profile.image;

		console.log('image to render: ',image);

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<Header text={GetLabel('profileDetails')} 
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					<Profile image={image} name={''} labelStyle={css.name} style={css.profile} disableHover={true}
						event={{id: 'event_00000', onClick: () =>  {/*this.selectFile()*/} }}>
						{/*<Image src={'img/sys/edit.png'} color={Colors.primary} height={MediumButtonSize} width={MediumButtonSize} 
							style={css.editIcon}/>*/}
					</Profile>

					<Subheader text={GetLabel('name')}/>
					<Label text={global.profile.name}/><Space />
					{/*<InputField onChange={ (newValue) => {this.setState({ isNameDirty: true, name: newValue })} }
						placeholder={GetLabel('name')} value={name}/><Space />*/}

					{/*<Subheader text={GetLabel('phoneNumber')}/>
						<InputField onChange={ (newValue) => {this.setState({ isPhoneNumberDirty: true, phoneNumber: newValue })} }
							placeholder={GetLabel('phoneNumber')} value={phoneNumber}/><Space />*/}

					<Subheader text={GetLabel('email')}/>
					<Label text={global.profile.email}/><Space />

					<Subheader text={GetLabel('rank')}/>
					<Label text={GetLabel(global.ranks[global.profile.rank])}/><Space />

					<Subheader text={GetLabel('domainName')}/>
					<Label text={GetLabel(global.domainNames[global.profile.domainName])}/><Space />

					{ this.renderSaveButtonIfDirty() }

					<Space /><Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	name: {
		textTransform: 'normal',
		color: Colors.primary,
	},
	profile: {
		background: 'transparent',
		//marginTop: '-2em',
	},
	domainName: {
		paddingLeft: '0.5em',
		paddingRight: '0.5em',
		paddingTop: '0em',
		paddingBottom: '0em',
	},
	wideButton: {
		marginTop: '3em',
		marginBottom: '20em',
	},
	editIcon: {
		position: 'absolute',
		left: 'calc(100vw - 50vw + 1em)',
		marginTop: '0.5em',
	},
}

export { ProfileEditor };
