import React, { Component } from 'react';
import { Colors, Sleep, GetLabel, SecondsOfInactivityBeforeForcedLogOut, 
	ShowInactiveTimerAt } from '../constants';
import { LoadingScreen, Label } from '../components';
import { Projects, MyProfile, Numeralia, Minutas, Citadelle, RetrospectivesFactories, RetrospectivesCapabilities, Pendings } from '../tabs';
import '../Animations.css';
import * as firebase from 'firebase';

class MainScreen extends Component {

	state = {
		screen: 'loading',
		navigateToCurrentMinuta: false
	}

	componentDidMount() {
		this.checkForInactivity();
	}

	async checkForInactivity() {
		while(firebase.auth().currentUser) {
			if(global.lastActionTimer > SecondsOfInactivityBeforeForcedLogOut) {
				this.signOut();
				console.log('signOut called!');
			}

			if((SecondsOfInactivityBeforeForcedLogOut - global.lastActionTimer) <= ShowInactiveTimerAt) {
				this.setState({refresh: true});
			}
			await Sleep(1e3);
			//console.log('lastActionTimer: '+global.lastActionTimer);
			global.lastActionTimer += 1;
		}
	}

	signOut() {
		global.Log('sign out called!');
		firebase.auth().signOut().then(() => {
			// await Sleep(10);
			// this.props.onLogOut();
			window.location.reload();
		});
		// this.props.onLogOut();
	}

	renderInactivityTimerWhenNeeded() {
		let timeRemaining = SecondsOfInactivityBeforeForcedLogOut - global.lastActionTimer;

		if (timeRemaining > ShowInactiveTimerAt)
			return undefined;
		else
			return (
				<div style={css.inactivityTimer}>
					<Label text={GetLabel('loggingOutDueToInactivityIn')+timeRemaining+'s'}/>
				</div>
			);
	}

	navigate(nextScreen) {
		if(nextScreen === 'signOut') {
			this.signOut();
			return;
		}
		this.setState({screen: nextScreen});
	}

	checkIfLoadingEnded() {
		//console.log('checkIfLoadingEnded:',global.events);
		if(global.events) {
			if(Object.keys(global.events).length > 0 && this.state.screen === 'loading') {
				let url = window.location.href;
				url = url.split('?');
				let action = url[1];
				url = url[0];
				window.history.pushState({}, null, url);
				// window.location.href = url;
				// console.log('url: ',url);
				// console.log('action: ',action);
				
				if(!action) 
					this.setState({screen: 'portfolio'});
				else if(action.includes('minuta')) {
					this.setState({screen: 'toDo', navigateToCurrentMinuta: true});
				}
				else
					this.setState({screen: 'portfolio'});
			}
		}
	}

	componentWillReceiveProps(newProps){
		this.checkIfLoadingEnded();
	}

	//getSnapshotBeforeUpdate(prevProps, prevState){
	//	this.checkIfLoadingEnded();
	//}

	renderScreenComponent() {
		switch(this.state.screen) {
			case 'loading':
				return (<LoadingScreen />);
			case 'portfolio':
				return (<Projects className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			case 'profile':
				return (<MyProfile className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			case 'kpis':
				return (<Numeralia className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			case 'toDo':
				return (<Minutas className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) } navigateToCurrentMinuta={this.state.navigateToCurrentMinuta}/>);
			case 'retrospectivesFactories':
				return (<RetrospectivesFactories className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			case 'retrospectivesCapabilities':
				return (<RetrospectivesCapabilities className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			case 'citadelle':
				return (<Citadelle className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			case 'pendings':
				return (<Pendings className={'transitions'} navigate={ (nextScreen) => this.navigate(nextScreen) }/>);
			default: 
				return (undefined);
		}
	}

	render() {
		return (
			<div style={css.container}>
				{ this.renderScreenComponent() }
				{ this.renderInactivityTimerWhenNeeded() }
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	inactivityTimer: {
		position: 'fixed',
		bottom: '0',
		left: '0',
		background: Colors.red,
	}
}

export { MainScreen };