import React, { Component } from 'react';
import { Colors, ButtonSize, GetFilter, IsNullOrEmpty, Clone } from '../constants';

class Image extends Component{

	state = {
		blobSrc: '',
		imageLoaded: false,
	}

	componentDidMount() {
		//this.loadImage();
	}

	componentWillUnmount() {
		//URL.revokeObjectURL(this.state.blobSrc);
	}

	componentWillReceiveProps(newProps) {
		//if(this.props.src !== newProps.src)
		//	this.setState({imageLoaded: false});
	}

	async loadImage() {
		let blob = await fetch(this.props.src, {mode: 'cors'}).then(r => r.blob());
		let tmpURL = URL.createObjectURL(blob);
		//console.log('url created: ',tmpURL);
		this.setState({blobSrc: tmpURL});
	}

	getMaskSize(width, height) {
		if(this.props.maskSize)
			return this.props.maskSize;
		else
			return `${width} ${height}`;
	}

	srcToBlobURL() {
		return IsNullOrEmpty(this.state.blobSrc) ? this.props.src : this.state.blobSrc;
	}

	onLoad() {
		this.setState({imageLoaded: true});
		if(this.props.onLoad)
			this.props.onLoad();
	}

	render() {
		let width = (this.props.width) ? this.props.width : 'auto';
		let height = (this.props.height) ? this.props.height : 'auto';
		let className = (this.props.className) ? this.props.className : '';
		
		let color = this.props.color.substr(0,7);
		let colorCalculated = {};

		if(!IsNullOrEmpty(global.colorFilters[color])) {
			colorCalculated = Clone(global.colorFilters[color]);
		}
		else {
			colorCalculated = GetFilter(color);
			while(colorCalculated.loss > 1) {
				colorCalculated = GetFilter(color);
			}
			global.colorFilters[color] = Clone(colorCalculated);
		}
		//console.log('final loss for color '+color+': '+colorCalculated.loss);
		//console.log('global.colorFilters:',global.colorFilters);

		let filter = colorCalculated.filter;
		filter = `${filter.substr(8)}`;
		filter = `${filter.substr(0, filter.length -1)}`;//'invert(67%) sepia(59%) saturate(771%) hue-rotate(358deg) brightness(103%) contrast(103%)';

		//let blobUrl = new URL('https://code91.mx/images/sys/progress.png');
		//console.log('blobUrl: ',blobUrl);
		let opacity = (this.state.imageLoaded) ? {opacity: '1'} : {opacity: '0'};

		let propsStyle = {
			
			//background: `url(${this.props.src}) center no-repeat`,
			backgroundSize: (width === 'auto') ? this.props.croppedAtWidth : width,
			filter: filter,
			width: (width === 'auto') ? this.props.croppedAtWidth : width,
			height: (height === 'auto') ? this.props.croppedAtHeight : height,

			/*
			backgroundColor: this.props.color, 
			width: (width === 'auto') ? this.props.croppedAtWidth : width,
			height: (height === 'auto') ? this.props.croppedAtHeight : height,
			maskImage: `url(${this.srcToBlobURL()})`,
			WebkitMaskImage: `url(${this.srcToBlobURL()})`,
  			maskSize: this.getMaskSize(width, height),
  			WebkitMaskSize: this.getMaskSize(width, height),*/
		};

		return (
			<div style={{...css.image, ...propsStyle, ...opacity, ...this.props.style}} draggable={false} className={className+' transitionOpacity'}>
				<img src={this.props.src} 
					draggable={false} 
					style={{
						...{
							display: 'block', 
							height: '100%'
						}, 
						...this.props.imgStyle
					}} 
					onLoad={() => this.onLoad()}
					onError={({nativeEvent: { error }}) => console.log('Error loading image: ',error)}
					/>
			</div>
		);
	}
}

const css = {
	image: {
		/*
		backgroundColor: Colors.primary, 
		width: '1em',
		height: '1em',
		maskImage: "url('img/sys/menu.png')",
  		WebkitMaskImage: "url('img/sys/menu.png')",
  		WebkitMaskSize: `1em 1em`,
  		WebkitMaskPosition: 'center',
  		*/
	}
}

export { Image };