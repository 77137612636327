import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, 
	DefaultRank, DefaultDomainName, Today } from './';
import { UploadObject } from '../firebase';

export function UpdateRetrospectivesCapabilities(capabilities, onSuccess, onFail) {
	global.capabilities = Clone(capabilities);

	UploadObject('capabilities', global.capabilities, 
		()=> {
			//console.log('User uploaded: ',profile);
			global.capabilities = Clone(capabilities);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading capabilities: ',capabilities);
			if(onFail)
				onFail();
		}
	);
}