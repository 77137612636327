import React, { Component } from 'react';
import { Colors, ButtonSize, SmallFontSize, IsNullOrEmpty } from '../constants';
import { Label, Image } from './';
import './Parameter.css';

class Parameter extends Component {

	renderLabel() {
		if(IsNullOrEmpty(this.props.text))
			return <div/>;
		
		return (
			<Label className={'parameterLabel'} text={this.props.text} 
				labelStyle={{...css.label, ...this.props.labelStyle}} style={{...css.labelStyle, ...this.props.style}}/>
		);
	}

	render() {
		let iconColor = (this.props.iconColor) ? this.props.iconColor : Colors.primary;
		let iconLabelStyle = (this.props.iconColor) ? { color: this.props.iconColor } : {};
		let iconSize = (this.props.iconSize) ? this.props.iconSize : ButtonSize;

		return (
			<div style={{...css.container, ...this.props.containerStyle}}>
				<div style={css.iconContainer}>
					<Image src={this.props.icon} color={iconColor} height={iconSize} width={iconSize} style={css.iconStyle}/>
					<Label text={this.props.iconLabel} 
						style={css.iconLabelContainer} labelStyle={{...css.iconLabel, ...iconLabelStyle, ...this.props.labelStyle}}/>
				</div>
				{ this.renderLabel() }
				{ this.props.children }
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '1.2em',
		marginBottom: '1.2em',
		//maxWidth: '45vw',
	},
	iconStyle: {
		marginTop: '-1em',
	},
	labelStyle: {
		//maxWidth: 'calc(100% - 5em)',
		flex: '0.8',
		//minWidth: 'calc(100% - 3em)',//2 de los margenes, (1em de cada lado) + 0.5em de cada lado por el padding
		//maxWidth: 'calc(100% - 3em)',//2 de los margenes, (1em de cada lado) + 0.5em de cada lado por el padding
	},
	iconContainer:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '5em',
		maxWidth: '5em',
		minWidth: '5em',
		marginRight: '0em',
		flex: '0.2',
	},
	iconLabelContainer: {
		padding: '0', 
		marginBottom: '0', 
		marginRight: '0', 
		marginLeft: '0', 
		textAlign: 'center',
		marginTop: '-1em',
	},
	iconLabel: {
		color: Colors.primary,
		fontSize: SmallFontSize,
	},
	label: {
		wordBreak: 'break-word',
	},
}

export { Parameter };
