import React, { Component } from 'react';
import { Colors, SeparatorWidth } from '../constants';

class SeparatorLarge extends Component{
	static Singleton = null;

	getColor() {
		return (this.props.color) ? this.props.color : Colors.primary;
	}

	render() {
		let propsStyle = {};

		if (this.props.disableGlow) 
			propsStyle.boxShadow = 'initial';
		/*
		return (
			<div style={{background: 'blue', height: '100%', width: '100%', position: 'fixed', left: '0', overflow: 'hidden'}}>
				
				<div style={this.css().backgroundDiagonalsContainer}>
					<div style={this.css().mainColorLines}/>
					<div style={this.css().fader}/>
				</div>
				
			</div>
		);*/

		
		return (
			<div style={this.props.style}>
				{
					(this.props.disableBoxFader) ? undefined : (<div style={{...this.css().container, ...propsStyle}} />)
				}
				{
					(this.props.disableBackgroundLines) ? undefined : 
						(
							<div style={{...this.css().backgroundDiagonalsContainer, ...this.props.backgroundDiagonalsContainer}}>
								<div style={this.css().mainColorLines}/>
								<div style={{...this.css().fader, ...this.props.faderStyle}}/>
								<div style={{...this.css().flatBackground, ...this.props.flatBackgroundStyle}}/>
							</div>
						)
				}
			</div>
		);
	}

	css() {
		return {
			container: {
				width: '100%',
				height: SeparatorWidth,
				background: this.getColor(),
		    	boxShadow: `${this.getColor()} 0px 0px 20px`,
		    	zIndex: '-1',
		    	filter: `drop-shadow(0px 0px 1em ${this.getColor()}) drop-shadow(0px 0px 1em ${this.getColor()})`,
			    position: 'absolute',
		    	left: '0',
			},
			backgroundDiagonalsContainer: {
				background: `${Colors.transparent}`,
			    height: '20em',
			    position: 'absolute',
			    width: '100%',
			    zIndex: '-2',
		    	left: '0',
			    //opacity: '0.5',
			},
			mainColorLines: {
				background: `linear-gradient(-90deg,${this.getColor()} 50%,${this.getColor()}cc 0,${this.getColor()}cc 100%,${this.getColor()}cc)`,
			    backgroundRepeat: 'repeat',
			    backgroundSize: '4em',
			    width: '170%',
			    height: '99%',
			    transform: 'skew(-45deg, 0deg)',
			    marginLeft: '-70%',
			    opacity: (this.props.mainColorLinesOpacity !== undefined) ? this.props.mainColorLinesOpacity : '0.3',

			},
			fader: {
				width: '170%',
			    height: '100%',
				top: '0em',
			    background: `linear-gradient(0deg, ${Colors.background_1} 0%, ${Colors.background_1}b2 24%, ${this.getColor()}aa 99%, ${this.getColor()} 100%)`,//`linear-gradient(0deg, ${Colors.background_1}, ${Colors.background_1}aa, ${this.getColor()}cc)`,
			    position: 'absolute',
			    transform: 'skew(-45deg, 0deg)',
			    marginLeft: '-70%',
			},
			flatBackground: {

				width: '170%',
			    height: '50em',
				top: '0em',
			    background: `${Colors.background_1}`,//`linear-gradient(0deg, ${Colors.background_1}, ${Colors.background_1}aa, ${this.getColor()}cc)`,
			    position: 'absolute',
			    transform: 'skew(-45deg, 0deg)',
			    marginLeft: 'calc(-70% + -15em)',
			    zIndex: '-3',
				/*
				width: '170%',
				marginLeft: '-70%',
			    height: '40em',
				top: '0em',
			    background: 'red',//`${Colors.background_1}`,
			    position: 'absolute',
			    zIndex: '-3',
			    transform: 'skew(-45deg, 0deg)',*/
			}
		};
	}
}

export { SeparatorLarge };
