import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, 
	DefaultRank, DefaultDomainName, Today } from './';
import { UploadObject } from '../firebase';

export function UpdateAdminObject(adminObj, onSuccess, onFail) {
	UploadObject('admin', adminObj, 
		()=> {
			console.log('Admin object uploaded: ',adminObj);
			global.admin = Clone(adminObj);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading adminObj: ',adminObj);
			if(onFail)
				onFail();
		}
	);
}