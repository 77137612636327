import React, { Component } from 'react';
import { Button, Label } from './';
import { Colors, SubHeadersSize, AmIAllowedToExecuteThis, ButtonSize, ButtonHeight,
	SmallFontSize } from '../constants';

class PercentageSelector extends Component{

	state = {

	}

	getNumericPercentageProp() {
		let { percentage } = this.props;
		return parseInt(percentage.substring(0, percentage.length - 1)) / 10;
	}

	renderPercentageSlotsDependingOnValue() {
		let jsx = [];
		let currentPercentage = this.getNumericPercentageProp();
		let labelColor = Colors.secondary;

		console.log('currentPercentage: ',currentPercentage);

		for(let i=1; i <= currentPercentage; i++) {
			jsx.push(
				<Button style={this.css().squareColored} 
					buttonStyle={this.css().noMargin}
					key={'enabledSquare'+i} 
					renderAnyway
					disableHover
					event={{
						id: this.props.event.id, 
						project: this.props.event.project,
						onClick: ()=> {
							if(!this.props.editable)
								return;
							let newPercentage = (i * 10)+'%';
							this.setState({refresh: true})
							this.props.event.onClick(newPercentage);
						}
					}}>
					<Label text={(i*10)+'%'} style={{...this.css().noMargin, ...this.css().numberLabel}}
						labelStyle={{...this.css().noMargin, ...{fontSize: SmallFontSize, textAlign: 'center', color: labelColor}}}/>
				</Button>
			);
		}

		for(let j=currentPercentage + 1; j <= 10; j++) {
			jsx.push(
				<Button style={this.css().squareUncolored} 
					buttonStyle={this.css().noMargin}
					key={'disabledSquare'+j} 
					renderAnyway
					disableHover
					event={{
						id: this.props.event.id, 
						project: this.props.event.project,
						onClick: ()=> {
							if(!this.props.editable)
								return;
							let newPercentage = (j * 10)+'%';
							this.setState({refresh: true})
							this.props.event.onClick(newPercentage);
						}
					}}>
					<Label text={(j*10)+'%'} style={{...this.css().noMargin, ...this.css().numberLabel}}
						labelStyle={{...this.css().noMargin,...{fontSize: SmallFontSize, textAlign: 'center', color: labelColor+'50'}}}/>
				</Button>
			);
		}

		return jsx;
	}

	render() {
		return (
			<div style={{...this.css().container, ...this.props.containerStyle}}>
		        { this.renderPercentageSlotsDependingOnValue() }
			</div>
		);
	}

	css() {
		let color = this.props.color ? this.props.color : Colors.primary;
		return {
			container: {
				display: 'flex',
				flexDirection: 'row',
				flex: 1,
				height: ButtonHeight,
				maxHeight: ButtonHeight,
				minHeight: ButtonHeight,
				marginVertical: 10,
				width: '100%',
			},
			squareColored: {
				backgroundColor: color+'50', 
				border: `2px solid ${color}`,
				
				height: ButtonHeight,
				maxHeight: ButtonHeight,
				minHeight: ButtonHeight,
				//flex: 0.1, 
				width: 'calc(10% - 4px)',
				maxWidth: 'calc(10% - 4px)',
				minWidth: 'calc(10% - 4px)',
				borderRadius: 5,
				marginHorizontal: -1,
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				zIndex: 2,
			},
			squareUncolored: {
				backgroundColor: color+'10', 
				border: `2px solid ${color}25`,
				
				height: ButtonHeight,
				maxHeight: ButtonHeight,
				minHeight: ButtonHeight,
				//flex: 0.1, 
				width: 'calc(10% - 4px)',
				maxWidth: 'calc(10% - 4px)',
				minWidth: 'calc(10% - 4px)',
				borderRadius: 5,
				marginHorizontal: 0,
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				zIndex: 1,
			},
			noMargin: {
				marginLeft: '0px !important',
				marginRight: '0px !important',
				paddingLeft: '0px !important',
				paddingRight: '0px !important',
				padding: '0px !important',
				margin: '0px !important',
			}, 
			numberLabel: {
				position: 'absolute',
				marginBottom: '0px !important',
				marginTop: `calc(-${ButtonHeight} / 2)`,
				height: ButtonHeight, 
				maxWidth: 'calc(6% - 4px)',
			}
		};
	}
}

export { PercentageSelector };
