import React, { Component } from 'react';
import { Colors, ButtonSize } from '../constants';
import { Button, Image } from './';

class Checkbox extends Component {

	state = {
		//checked: false
	}

	componentDidMount() {
		//this.setState({
			//checked: (this.props.checked === true) ? this.props.checked : false
		//});
	}

	toggleState() {
		//this.setState({
			//checked: !this.state.checked
		//});
	}

	renderCheckIfChecked() {
		let color = (this.props.color) ? this.props.color : Colors.primary;
		let icon = (this.props.icon) ? this.props.icon : 'img/sys/check.png';

		if(this.props.checked)
			return (
				<Image height={`calc(${ButtonSize}/2)`} width={`calc(${ButtonSize}/2)`} maskSize={`calc(${ButtonSize}*0.8)`}
					src={icon} color={color} className={'appear'} imgStyle={{height: '130%', marginTop: '-15%', marginLeft: '-15%'}}/>
			);
		else
			return <div />;
	}

	renderCheckbox() {
		if(this.props.disabled)
			return <div style={this.css().iconContainer}/>;

		let action = (this.props.nonEditable) ? ()=>{} : 
			() => {
				this.props.event.onClick(!this.props.checked);
				this.toggleState();
			};
		
		return (
			<div style={this.css().iconContainer}>
				<Button style={this.css().checkbox} buttonStyle={this.css().checkboxButton}
					className={''}
					event={{id: this.props.event.id, project: this.props.event.project, onClick: action}}>
					{ this.renderCheckIfChecked() }
				</Button>
			</div>
		);
	}

	render() {
		return (
			<div style={{...this.css().container, ...this.props.containerStyle}}>
				{ this.renderCheckbox() }
				<div style={{...this.css().childrenContainer, ...this.props.style}}>
					{ this.props.children }
				</div>
			</div>
		);
	}

	css() {
		return {
			container: {
				display: 'flex',
				flexDirection: 'row',
				marginTop: '1.2em',
				marginBottom: '1.2em',
				alignItems: 'center',
			},
			iconContainer:{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '5em',
				maxWidth: '5em',
				marginRight: '0em',
				minWidth: '5em',
			},
			childrenContainer: {
				width: '100%',
				margin: '0.5em 1em',
				padding: '0.5em',
			},
			checkbox: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				//background: Colors.buttonBackground,
				borderRadius: '0.5em',
				border: `2px solid ${(this.props.color) ? this.props.color : Colors.primary}`,
				//width: `calc(${ButtonSize})`,
				//height: `calc(${ButtonSize})`,
				width: `calc(${ButtonSize}/2)`,
				height: `calc(${ButtonSize}/2)`,
			},
			checkboxButton: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
		};
	}
}

export { Checkbox };
