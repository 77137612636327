import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, IsAnObjectArray, FormatDateTimeLong, Today,
	GetFullMonth, SubHeadersSize, DirtyStyle, ReplaceProjectInGlobalById, Clone, GetAllProjects,
	IsNullOrEmpty, 
} from '../../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, SeparatorLarge, MonthSelector, StarGrading, ConfirmationButtons, 
} from '../../components';

class RetrospectivesReport extends Component {

	state = {
		monthYear: Today('MM_YYYY'),
		isLoading: false,
	}

	renderRetrospectivasTitle() {
		return (
			<Label 
				labelStyle={{fontSize: SubHeadersSize, textAlign: 'center'}}
				text={`${GetLabel('retrospective')} ${GetFullMonth(parseInt(this.state.monthYear.substring(0, 2)))} ${this.state.monthYear.substring(3)}`}/>
		);
	}

	addRetrospectiveGrade(allUsers, userId, value, userIdDefaultValue='unknown') {
		userId = IsNullOrEmpty(userId) ? userIdDefaultValue : userId;

		if(value === undefined)
			return allUsers;

		if(IsNullOrEmpty(allUsers[userId]))
			allUsers[userId] = [];

		allUsers[userId].push(value);
		return allUsers;
	}

	addInformationFromFactory(allUsers, factory) {
		allUsers = this.addRetrospectiveGrade(allUsers, factory.factoryLeaderId, factory.factoryLeader, 'unknownFactoryLeader');
		allUsers = this.addRetrospectiveGrade(allUsers, factory.scrumMasterId, factory.scrumMaster, 'unknownFactoryScrumMaster');

		for(let i=0; i<factory.numIdeadores; i++) {
			allUsers = this.addRetrospectiveGrade(allUsers, factory['ideators_'+i+'Id'], factory['ideators_'+i], 'unknownFactoryIdeator');
		}

		return allUsers;
	}

	renderRetrospectivasDetails() {
		let allProjects = GetAllProjects();

		let usersWithRetro = {};

		//RETROSPECTIVES FROM PROJECTS
		for(let i=0; i<allProjects.length; i++) {
			let currentProject = allProjects[i];
			if(IsNullOrEmpty(currentProject.retrospectives)) {
				continue;
			}

			// console.log('projectId: '+currentProject.id);

			let retrospectivesKeys = Object.keys(currentProject.retrospectives);

			for(let j=0; j<retrospectivesKeys.length; j++) {
				let retrospectiveKey = retrospectivesKeys[j];
				let currentRetro = currentProject.retrospectives[retrospectiveKey];

				// console.log('currentRetro: ',currentRetro);

				if(retrospectiveKey === this.state.monthYear) {
					//Only calculate if the key is here

					if(!IsNullOrEmpty(currentRetro.retrospectivePO)) {
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.leader, currentRetro.retrospectivePO._1, 'unknownProjectLeader');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.leader, currentRetro.retrospectivePO._2, 'unknownProjectLeader');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.leader, currentRetro.retrospectivePO._3, 'unknownProjectLeader');
					}

					if(!IsNullOrEmpty(currentRetro.retrospectiveQuarterback)) {
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.quarterback, currentRetro.retrospectiveQuarterback._1, 'unknownProjectQuarterback');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.quarterback, currentRetro.retrospectiveQuarterback._2, 'unknownProjectQuarterback');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.quarterback, currentRetro.retrospectiveQuarterback._3, 'unknownProjectQuarterback');
					}

					if(!IsNullOrEmpty(currentRetro.retrospectiveScrumMaster)) {
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.scrumMaster, currentRetro.retrospectiveScrumMaster._1, 'unknownProjectScrumMaster');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.scrumMaster, currentRetro.retrospectiveScrumMaster._2, 'unknownProjectScrumMaster');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.scrumMaster, currentRetro.retrospectiveScrumMaster._3, 'unknownProjectScrumMaster');
						usersWithRetro = this.addRetrospectiveGrade(usersWithRetro, currentProject.scrumMaster, currentRetro.retrospectiveScrumMaster._4, 'unknownProjectScrumMaster');
					}
				}
			}
		}

		//RETROSPECTIVES FROM FACTORIES
		let factoryRetrospectiveKeys = Object.keys(global.retrospectives);
		for(let k=0; k<factoryRetrospectiveKeys.length; k++) {
			let factoryRetrospectiveKey = factoryRetrospectiveKeys[k];
			let currentRetro = global.retrospectives[factoryRetrospectiveKey];

			if(factoryRetrospectiveKey === this.state.monthYear) {
				//factoriesIdeas
				usersWithRetro = this.addInformationFromFactory(usersWithRetro, currentRetro.factoriesIdeas);

				//factoriesSolutions
				usersWithRetro = this.addInformationFromFactory(usersWithRetro, currentRetro.factoriesSolutions);

				//factoriesEntrepreneurs
				usersWithRetro = this.addInformationFromFactory(usersWithRetro, currentRetro.factoriesEntrepreneurs);
			}
		}

		// console.log('usersWithRetro: ',usersWithRetro);


		let allUsersKeys = Object.keys(usersWithRetro);

		let jsx = [];

		for(let l=0; l<allUsersKeys.length; l++) {
			let userName = allUsersKeys[l].startsWith('unknown') ? GetLabel(allUsersKeys[l]) : GetUserNameById(allUsersKeys[l]);
			let average = usersWithRetro[allUsersKeys[l]].reduce((acc, val) => acc + val, 0) / usersWithRetro[allUsersKeys[l]].length;

			average = parseInt(average * 100) / 100;

			if(average === 0)
				continue;

			jsx.push(<Label text={`${userName}: ${average}`}/>);
		}

		return jsx;
	}

	renderSeparatorSet() {
		let jsx = [];

		jsx.push(<Space/>);
		jsx.push(<SeparatorLarge disableBackgroundLines/>);
		jsx.push(<Space/>);

		return jsx;
	}

	render() {
		// let { project } = this.props.navParams;
		// project = GetProjectById(project.id);
		// let project = this.getProject();
		
		//if(!project)
		//	return <LoadingScreen />;
		// if(!project) {
		// 	this.props.goBack();
		// 	return <div/>;
		// }

		let className = (this.props.className) ? this.props.className : 'moveIn';
		return (
			<div style={css.container} className={className}>
				<Header text={GetLabel('retrospectives')} 
					// buttonRight={{
					// 	id: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],  
					// 	icon: 'img/sys/edit.png', 
					// 	project: project,
					// 	onClick: ()=>this.props.innerNavigate('projectEditor', {
					// 		project: GetProjectById(project.id),
					// 		eventId: ['event_00001', 'event_00019', 'event_00021', 'event_00020', 'event_00024', 'event_00025', 'event_00014'],
					// 	})}}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					<MonthSelector editable onClick={(newDate) => { this.setState({monthYear: newDate}); }}/>
					{/*
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('initialDeadline')} 
						text={FormatDateShort(project.initialDeadline, ' ')}/>
					<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('quarterback')} text={project.quarterback}/>
					*/}
					{ this.renderSeparatorSet() }
					{ this.renderRetrospectivasTitle() }
					{ this.renderSeparatorSet() }
					{ this.renderRetrospectivasDetails() }

					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
		marginLeft: '0em',
		marginRight: '3vw',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sprintsContainer:{
		marginTop: '9em',
		minHeight: '2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
		color: Colors.background_2,
		fontWeight: 'bold',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	listButton: {
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		paddingLeft: '0',
		paddingRight: '0',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',

	},
}

export { RetrospectivesReport };