import React, { Component } from 'react';
import { Button } from './';

class ListButton extends Component{
	render() {
		/*
		let icon = (this.props.icon) ? 
			(<Image src={this.props.icon} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />) : 
			undefined;*/

		return (
			<div style={{...css.container, ...this.props.containerStyle}}>
				<Button style={ {...{width:'100%'}, ...this.props.buttonStyle} } 
					buttonStyle={{...css.button, ...this.props.style}} 
					className={'buttonLarge'}
					disableHover={this.props.disableHover}
					renderAnyway={this.props.renderAnyway}
					event={this.props.event}>
					{ this.props.children }
				</Button>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		zIndex: '10',
		display: 'flex',
	},
	button: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		margin: '0',
		backgroundColor: 'transparent',
		border: `none`,
		paddingTop: '1em',
		paddingBottom: '1em',
		paddingLeft: '2em',
		paddingRight: '2em',
		width: '100%',
		height: '6em',
		textAlign: 'left',
	},
	icon: {
		//position: 'absolute',
		marginTop: '-0.3em',
		marginLeft: '-0.5em'
	}
}

export { ListButton };
