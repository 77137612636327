import React, { Component } from 'react';
import { Colors, FontName } from '../constants';

class BulletPointsList extends Component{

	render(){
		let bulletJsx = (this.props.disableBullet) ? undefined : <div style={css.bullet} />;
		var marginVertical = this.props.lineHeight !== undefined ? 
			{marginVertical: this.props.lineHeight} : 
			{marginVertical: 5};
		return (
			<div style={{...css.container, ...this.props.style}}>
				{
					React.Children.map(this.props.children, (child, i) => {
						
						if(child === undefined || child === null)
							return undefined;

						return (
							<div style={ {...css.row, ...marginVertical} }>
								{ 
									(this.props.numbered) ? 
									(
										<div style={{alignSelf: 'center', fontFamily: FontName, fontSize: '1.5rem',}}>
											{ i+1 }
										</div>
									) : 
									bulletJsx
								}
								{ child }
							</div>
						);
					})
				}
			</div>
		);
	}
}

const css = {
	container: {
		//flex: 1,
		flexDirection: 'column',
		//marginBottom: 15,
	},
	row:{
		//flex: 1,
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '2em',
		paddingRight: '3em',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		//marginVertical: 5,
	},
	bullet: {
		height: '0.5em',
		width: '0.5em',
		minWidth: '0.5em',
		maxWidth: '0.5em',
		borderRadius: '0.25em',
		backgroundColor: Colors.primary,
		alignSelf: 'center',
		//justifyContent: 'center',
	}
}

export { BulletPointsList };
