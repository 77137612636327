import React, { Component } from 'react';
import { Colors, ButtonSize, IsNullOrEmpty, GetLabel, AmIAllowedToExecuteThis, 
	Clone, Today, FormatDateTimeLong, LargeButtonSize } from '../constants';
import { Label, Button, Image, InputField, DropDown, Space, Checkbox, Parameter, DaySelector } from './';

class PendingsListEditor extends Component {

	state = {
		newItem: '',
		list: [],
		showDates: false,
	}

	/*editItemFromList(index, newValue) {
		if(this.props.onModify) {
			let newList = this.props.list.slice(0);
			newList[index] = newValue;
			this.props.onModify(newList);
		}
	}*/

	componentDidMount() {
		let list = (this.props.list === undefined || this.props.list === null) ? [] : this.props.list;
		this.setState({list: list});
	}

	deleteItemFromList(index) {
		if(this.props.onModify) {
			let newList = this.props.list.slice(0);
			newList.splice(index, 1);
			this.props.onModify(newList);
		}
	}

	addItemToList(newItem) {
		if(this.props.onModify) {
			// let newList = IsNullOrEmpty(this.props.list) ? [] : this.props.list.slice(0);
			let newList = IsNullOrEmpty(this.state.list) ? [] : this.state.list.slice(0);
			// newList.push(newItem);
			newList.push({
				name: newItem,
				checked: false,
				semaphore: true, 
				dateStart: Today(),
				dateEnd: Today(),
			});
			this.props.onModify(newList);
			this.setState({newItem: ''});
		}
	}

	listOrderChanged(fromIndex, toIndex) {

		console.log('listOrderChanged: '+fromIndex+ ', '+toIndex);

		let list = IsNullOrEmpty(this.props.list) ? [] : this.props.list;
		let newList = Clone(list);
		// let newListAsArray = [];

		let fromIndexMainKey = fromIndex.split('_')[0];
		fromIndex = fromIndex.split('_')[1];

		let toIndexMainKey = toIndex.split('_')[0];
		toIndex = toIndex.split('_')[1];

		if(fromIndexMainKey !== toIndexMainKey) {
			console.log('different parents, DO NOTHING');
			return;
		}

		// newList.map((item, i) => {
		// 	newListAsArray.push(Clone(item));
		// });

		// console.log('fromIndex: ',fromIndex);
		// console.log('toIndex: ',toIndex);

		toIndex = (toIndex > fromIndex) ? toIndex-1 : toIndex;

		let itemToMove = newList[fromIndex];
		newList.splice(fromIndex, 1);//Elimina el indice
		newList.splice(toIndex, 0, itemToMove);//Mueve el indice

		this.props.onModify(newList);
	}

	renderAddedItems(list) {
		let jsx = [];

		list.map((item, i) => {
			let label = (this.props.isObject) ? item.name : GetLabel(item);

			jsx.push (
				<div 
					key={i}
					onDragOver={(event) => event.preventDefault()} 
					style={css.dropTarget}
					// style={css.listContainer}
					onDragLeave={(event) => {
						event.target.style.background = 'transparent';
					}}
					onDrop={(event) => {
						event.preventDefault();
						event.target.style.background = 'transparent';
						let fromIndex = event.dataTransfer.getData('text');
	  					// ev.target.appendChild(document.getElementById(data));
						// console.log('Move item here: ',event.dataTransfer);

						// console.log('fromIndex moved: ',fromIndex);
						// console.log('container: ',i);
						this.listOrderChanged(fromIndex, this.props.listName+'_'+i);
					}}
					onDragEnter={(event) => {
						event.target.style.background = Colors.primary+'30';
					}}

				/>);
			jsx.push(this.renderAddedItem(item, i));
		});

		if(list.length > 0) {
			jsx.push(<div 
				// key={GetRandomFloat(0, 100)+''}
				onDragOver={(event) => event.preventDefault()} 
				style={css.dropTarget}
				onDragLeave={(event) => {
					event.target.style.background = 'transparent';
				}}
				onDrop={(event) => {
					event.preventDefault();
					event.target.style.background = 'transparent';
					let fromIndex = event.dataTransfer.getData('text');
					let i = Object.keys(list).length;
					
					// console.log('data moved: ',fromIndex);
					// console.log('container: ',i);
					// console.log('keyIndex: ',keyIndex);
					this.listOrderChanged(fromIndex, this.props.listName+'_'+i);
				}}
				onDragEnter={(event) => {
					event.target.style.background = Colors.primary+'30';
				}}/>);
		}

		// console.log('list: ',list);
		// console.log('jsx: ',jsx.length);

		return jsx;
	}

	dragStart(event, key) {
		event.dataTransfer.setData('text', this.props.listName+'_'+key);
	}

	toggleSemaphore(item, index) {
		console.log('item: ',item);
		console.log('index: ',index);

		let itemClone = Clone(item);
		itemClone.semaphore = !itemClone.semaphore;
		let newList = Clone(this.state.list);
		newList[index] = itemClone;

		// this.setState({list: newList});
		this.props.onModify(newList);
	}

	toggleCheckbox(item, index) {
		console.log('item: ',item);
		console.log('index: ',index);

		let itemClone = Clone(item);
		itemClone.checked = !itemClone.checked;
		let newList = Clone(this.state.list);
		newList[index] = itemClone;

		// this.setState({list: newList});
		this.props.onModify(newList);
	}

	modifyDate(item, index, parameterName, newDate) {
		let itemClone = Clone(item);
		itemClone[parameterName] = newDate;
		let newList = Clone(this.state.list);
		newList[index] = itemClone;

		// this.setState({list: newList});
		this.props.onModify(newList);
	}

	renderAddedItem(item, index) {

		let label = (this.props.isObject) ? item.name : GetLabel(item);

		return (
			<div style={css.listContainer} key={index} draggable={true} onDragStart={(event)=> this.dragStart(event, index)}>
				<Image src={'img/sys/menu.png'} color={Colors.primary} height={`calc(${ButtonSize} * 0.7)`} width={ButtonSize}/>
				<Label text={label} style={{width: '100%', marginLeft: '0'}} labelStyle={{...{color: Colors.primary}, ...this.props.itemStyle}}/>
				<div style={{display: 'flex'}}>
					<div style={{display: 'flex'}}>
						<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=> {this.setState({showDates: !this.state.showDates})} }}>
							<Image src={'img/sys/calendar.png'} color={Colors.primary} height={'5em'} width={'5em'}/>
						</Button>
						{
							this.state.showDates ? 
							(
								<div style={css.verticalSeparator}/>
							) : undefined
						}
						{
							this.state.showDates ? 
							(
								<DaySelector event={{id: this.props.eventId, project: this.props.project, onModify: (newValue)=>{ 
									this.modifyDate(item, index, 'dateStart', newValue);
								}}}
								value={item.dateStart} compactStyle/>
							) : undefined
						}
						{
							this.state.showDates ? 
							(
								<div style={css.verticalSeparator}/>
							) : undefined
						}
						{
							this.state.showDates ? 
							(
								<DaySelector event={{id: this.props.eventId, project: this.props.project, onModify: (newValue)=>{ 
									this.modifyDate(item, index, 'dateEnd', newValue);
								}}}
								value={item.dateEnd} compactStyle/>
							) : undefined
						}
						{
							this.state.showDates ? 
							(
								<div style={css.verticalSeparator}/>
							) : undefined
						}
					</div>
					{/*<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('endDate')} iconSize={ButtonSize} containerStyle={{marginTop: '0px', marginBottom: '0px'}}>
					</Parameter>*/}
				</div>
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=> this.toggleSemaphore(item, index) }}>
					<Image src={'img/sys/circle_filled.png'} color={item.semaphore ? Colors.green : Colors.red} height={ButtonSize} width={ButtonSize}/>
				</Button>
				<Checkbox event={{id: this.props.eventId, onClick: ()=> this.toggleCheckbox(item, index)}} containerStyle={{maxWidth: '5rem'}} /*style={css.checkbox}*/ checked={item.checked}>
				</Checkbox>
				<div style={css.buttonsContainer}>
					{/*<Button event={{id: this.props.eventId, onClick: ()=>this.editItemFromList(index, item)}}>
						<Image src={'img/sys/edit.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
					</Button>*/}
					<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.deleteItemFromList(index)}}>
						<Image src={'img/sys/delete.png'} color={Colors.red} height={ButtonSize} width={ButtonSize}/>
					</Button>
				</div>
			</div>
		);
	}

	renderInputArea() {
		if(!AmIAllowedToExecuteThis(this.props.eventId, this.props.project))
			return <div/>;

		if(!Array.isArray(this.props.options))
			return (
				<InputField onChange={ (newValue) => {this.setState({ newItem: newValue })} }
					placeholder={this.props.placeholder} value={this.state.newItem}/>
			);
		else {
			if(IsNullOrEmpty(this.props.options))
				return <div/>;

			return (
				<DropDown 
					containerStyle={{width: '100%'}}
					value={this.state.newItem} 
					concatenatedString={''}//global.events[this.state.eventIdBeingEdited].id+
					options={this.props.options}
					editable={true}
					placeholder={this.props.placeholder}
					event={{
						id: this.props.eventId, 
						project: this.props.project,
						onClick: (newItem) => { this.setState({newItem: newItem}); this.addItemToList(newItem); }
					}}/>
			);
		}
	}

	renderAddButtonIfNeeded() {
		if(IsNullOrEmpty(this.state.newItem))
			return <div />;
		else
			return (
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.addItemToList(this.state.newItem)}}>
					<Image src={'img/sys/add.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			);
	}

	render() {
		let propsList = (this.props.list === undefined || this.props.list === null) ? [] : this.props.list;

		if(JSON.stringify(propsList) !== JSON.stringify(this.state.list)) {
			this.setState({list: propsList});
		}

		let list = this.state.list;

		return (
			<div style={{...css.container, ...this.props.style}}>
				{
					this.props.addButtonAbove ? 
					(
						<div style={css.adderSectionContainer}>
							{ this.renderInputArea() }
							{ this.renderAddButtonIfNeeded() }
						</div>
					) : undefined
				}
				{ 
					this.renderAddedItems(list)
				}
				{
					this.props.addButtonAbove ? 
					undefined :
					(
						<div style={css.adderSectionContainer}>
							{ this.renderInputArea() }
							{ this.renderAddButtonIfNeeded() }
						</div>
					)
				}
			</div>
		);
	}
}

const css = {
	// container: {
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// 	width: '100%',
	// 	maxWidth: '100%',
	// },
	// listContainer: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// 	justifyContent: 'space-around',
	// }, 
	// adderSectionContainer: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// 	justifyContent: 'space-around',
	// },
	// buttonsContainer: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// },
	

	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: '100%',
	},
	dropTarget: {
		background: 'transparent',
		width: '100%',
		height: '1.5rem',
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
		background: Colors.primary+'10',
		border: '2px solid '+Colors.primary+'80',
		borderRadius: '20px',
		paddingLeft: '1rem',
		paddingRight: '1rem',
	}, 
	adderSectionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
	}, 
	verticalSeparator: {
		width: '4px', 
		background: Colors.primary+'60', 
		height: '100%', 
		margin: '0px 1em 0px 1em',
	}
}

export { PendingsListEditor };