import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize, UpdateAdminObject,
	UpdateProfile, Clone, DirtyStyle, ObjectToArray, IsNullOrEmpty, GetAllSelectableRanks, Sleep,
	Alert } from '../../constants';
import { Header, ConfirmationButtons, Label, Subheader, WideButton, InputField,
	Div, Expansible, Space, ListEditor, DropDown, ColorEditor, Separator, Loading } from '../../components';
import * as firebase from 'firebase';

class WebAdmin extends Component {

	state = {
		isLoading: false,
		isDirty: false,
		admin: {},
		notificationTitle: '',
		notificationBody: '',
		usersToNotify: [],
		notifyInProcess: false,
		notificationSent: false,
	}

	componentDidMount() {
		this.setState({admin: Clone(global.admin)});
		// console.log('global.admin: ',global.admin);
	}

	saveChanges() {
		let { isDirty } = this.state;
		this.setState({isLoading: true});

		let adminClone = Clone(this.state.admin);

		UpdateAdminObject(adminClone,
			() => { global.admin = Clone(this.state.admin); this.clearDirtyState(adminClone); }, 
			() => this.setState({ isLoading: false, admin: Clone(global.admin) }));
	}

	clearDirtyState(admin) {
		this.setState({
			isLoading: false,
			isDirty: false,
			admin: Clone(admin),
		});
	}

	adminModified(key, newObject) {

	}

	renderColorPaletteEditors() {
		if(!this.state.admin.colors)
			return undefined;

		let jsx = [];

		jsx.push(this.getColorJsx('primary'));
		jsx.push(this.getColorJsx('primaryDarker'));
		jsx.push(this.getColorJsx('secondary'));
		jsx.push(this.getColorJsx('background_1'));
		jsx.push(this.getColorJsx('background_2'));
		jsx.push(this.getColorJsx('disabled'));
		jsx.push(this.getColorJsx('buttonBackground'));
		jsx.push(this.getColorJsx('green'));
		jsx.push(this.getColorJsx('red'));

		return jsx;
	}

	colorModified(key, newValue) {
		let adminClone = Clone(this.state.admin);
		adminClone.colors[key] = newValue;
		this.setState({admin: adminClone, isDirty: true});
	}

	getColorJsx(colorName) {
		let { admin } = this.state;
		// console.log('admin: ',admin);
		return (
			<Label text={GetLabel(colorName)+': '}>
				<ColorEditor name={colorName} color={admin.colors[colorName]} onChange={(newValue) => this.colorModified(colorName, newValue)}/>
			</Label>
		);
	}

	// eventsModified(key, newListOfItems) {
	// 	let eventsClone = {};

	// 	if(this.state.isDirty) {
	// 		eventsClone = Clone(this.state.events);
	// 	}
	// 	else{
	// 		this.setState({
	// 			isDirty: true,
	// 			events: Clone(global.events)
	// 		});
	// 		eventsClone = Clone(global.events);
	// 	}

	// 	eventsClone[key].allowedRanks = Clone(newListOfItems);

	// 	console.log('eventsClone: ',eventsClone);

	// 	this.setState({events: eventsClone});
	// }

	renderSaveButtonIfDirty() {
		let { isDirty } = this.state;
		
		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(global.admin), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	// renderObjectListEditor(key) {
	// 	if(IsNullOrEmpty(key))
	// 		return <div />;

	// 	if(global.events[key].adminEvent)
	// 		return <div />;

	// 	let allowedRanks = (this.state.isDirty) ? this.state.events[key].allowedRanks : global.events[key].allowedRanks;

	// 	return (
	// 		<div>
	// 			<SeparatorLarge />
	// 			<Space /><Space />
	// 			<Subheader text={GetLabel('allowedRanks')}/>
	// 			<ListEditor 
	// 				//baseObject={{}}
	// 				itemStyle={{color: Colors.secondary}}
	// 				options={this.getRanksRemovingCurrentlySelected(allowedRanks)}
	// 				list={allowedRanks} 
	// 				placeholder={GetLabel('addAllowedRanks')} 
	// 				eventId={'event_00017'} 
	// 				onModify={(newList) => this.eventsModified(key, newList)}/>
	// 			<Space />
	// 		</div>
	// 	);
	// }

	// getRanksRemovingCurrentlySelected(allowedRanks) {
	// 	let allAvailableRanks = GetAllSelectableRanks();
	// 	let filteredRanks = [];
	// 	allowedRanks = IsNullOrEmpty(allowedRanks) ? [] : allowedRanks;
	// 	allAvailableRanks.forEach(rnk => {
	// 		if(!allowedRanks.includes(rnk))
	// 			filteredRanks.push(rnk);
	// 	});
	// 	return filteredRanks;
	// }

	// getEventsArray() {
	// 	let eventsArray = ObjectToArray(global.events);
	// 	let filteredEventsArray = [];
	// 	eventsArray.forEach(ev => {
	// 		if(!ev.adminEvent)
	// 			filteredEventsArray.push(Clone(ev));
	// 	});

	// 	filteredEventsArray.sort((a, b) => {
	// 		let aName = GetLabel(a.name);
	// 		let bName = GetLabel(b.name);

	// 		if (aName > bName)
	// 	        return 1;
	// 	    if (bName > aName)
	// 	        return -1;
	// 	    return 0;
	// 	});

	// 	return filteredEventsArray;
	// }

	// getUsersArray() {
	// 	let usersArray = ObjectToArray(global.users);
	// 	let usersSortedAlphabetically = [];
	// 	usersArray.forEach(ev => {
	// 		usersSortedAlphabetically.push(Clone(ev));
	// 	});

	// 	usersSortedAlphabetically.sort((a, b) => {
	// 		let aName = GetLabel(a.name);
	// 		let bName = GetLabel(b.name);

	// 		if (aName > bName)
	// 	        return 1;
	// 	    if (bName > aName)
	// 	        return -1;
	// 	    return 0;
	// 	});

	// 	return usersSortedAlphabetically;
	// }

	async isNotificationSent() {
		await Sleep(5e3);
		this.setState({notifyInProcess: false, notificationSent: true});
	}

	userFoundInArray(array, idSearched) {
		let found = false;
		array.forEach(usr => {
			if(usr.id === idSearched)
				found = true;
		});
		return found;
	}

	getUserListRemovingPreviouslyAdded(usersToNotify) {
		let allUsersPool = ObjectToArray(global.users);
		let filteredUsersToNotify = [];
		usersToNotify = IsNullOrEmpty(usersToNotify) ? [] : usersToNotify;
		allUsersPool.forEach(usr => {
			if(!this.userFoundInArray(usersToNotify, usr.id))
				filteredUsersToNotify.push(usr);
		});

		filteredUsersToNotify.sort((a, b) => {
			let aName = GetLabel(a.name);
			let bName = GetLabel(b.name);
			if (aName > bName)
		        return 1;
		    if (bName > aName)
		        return -1;
		    return 0;
		});
		filteredUsersToNotify = this.removeHiddenUsers(filteredUsersToNotify);
		return filteredUsersToNotify;
	}

	removeHiddenUsers(usersToNotify){
		usersToNotify = usersToNotify.filter((user) => {
			return (user.isHidden === false || user.isHidden === undefined || user.isHidden === null);
		});
		return usersToNotify;
	}

	getUserListWithSpecialValues(usersToNotify) {
		let filteredUsersToNotify = this.getUserListRemovingPreviouslyAdded(usersToNotify);
		filteredUsersToNotify.reverse();
		let prefix = `- `;//○●⦾⨀
		Object.keys(global.domainNames).forEach(key => {
			let domainName = prefix + GetLabel(global.domainNames[key]).toUpperCase();
			filteredUsersToNotify.push(domainName);
		});
		Object.keys(global.ranks).forEach(key => {
			let rank = prefix + GetLabel(global.ranks[key]).toUpperCase();
			filteredUsersToNotify.push(rank);
		});
		filteredUsersToNotify.push(prefix+GetLabel('none').toUpperCase());
		filteredUsersToNotify.push(prefix+GetLabel('all').toUpperCase());
		filteredUsersToNotify.reverse();
		return filteredUsersToNotify;
	}

	createUsersToModifyIdsArray(objArray) {
		let idsArray = [];
		objArray.forEach(usr => {
			idsArray.push(usr.id);
		});
		return idsArray;
	}

	usersToNotifyModified(newList) {
		let remainingUsers = this.getUserListRemovingPreviouslyAdded(this.state.usersToNotify);
		let prefix = `- `;

		let all = newList.indexOf(prefix+GetLabel('all').toUpperCase());
		if(all !== -1) {
			newList.splice(all, 1);//At position 'all' removes 1 element
			remainingUsers.forEach(usr => newList.push(usr));
		}

		let none = newList.indexOf(prefix+GetLabel('none').toUpperCase());
		if(none !== -1) {
			newList.splice(none, 1);//At position 'none' removes 1 element
			newList = [];
		}

		Object.keys(global.ranks).forEach(key => {
			let rank = GetLabel(global.ranks[key]);
			let rankSelected = newList.indexOf(prefix+rank.toUpperCase());
			if(rankSelected !== -1) {
				newList.splice(rankSelected, 1);//At position 'rankSelected' removes 1 element
				remainingUsers.forEach(usr => {
					if((prefix + GetLabel(global.ranks[usr.rank]).toUpperCase()) === prefix+rank.toUpperCase()) {
						newList.push(usr)
					}
				});
			}
		});

		Object.keys(global.domainNames).forEach(key => {
			let domainName = GetLabel(global.domainNames[key]);
			let domainNameSelected = newList.indexOf(prefix+domainName.toUpperCase());
			if(domainNameSelected !== -1) {
				newList.splice(domainNameSelected, 1);//At position 'domainNameSelected' removes 1 element
				remainingUsers.forEach(usr => {
					if((prefix + GetLabel(global.domainNames[usr.domainName]).toUpperCase()) === prefix+domainName.toUpperCase()) {
						newList.push(usr)
					}
				});
			}
		});

		this.setState({usersToNotify: newList, notificationSent: false})
	}

	render() {
		let usersToNotify =this.state.usersToNotify;
		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<Header text={GetLabel('webAdminLabel')} 
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					<Subheader text={GetLabel('customNotifications')}/>
					<InputField onChange={ (newValue) => {this.setState({notificationTitle: newValue, notificationSent: false})} }
						placeholder={GetLabel('notificationTitle')} value={this.state.notificationTitle}/>
					<InputField onChange={ (newValue) => {this.setState({notificationBody: newValue, notificationSent: false})} }
						placeholder={GetLabel('notificationBody')} value={this.state.notificationBody}/>
					<ListEditor 
						isObject={true}
						addButtonAbove={true}
						itemStyle={{color: Colors.secondary}}
						options={this.getUserListWithSpecialValues(usersToNotify)}
						list={usersToNotify} 
						placeholder={GetLabel('addUserToBeNotified')} 
						eventId={'event_00000'} 
						onModify={(newList) => this.usersToNotifyModified(newList)}/>
					{
						(this.state.notificationSent) ? <Label text={GetLabel('notificationSent')} style={{marginTop: '4rem'}} labelStyle={{textAlign: 'center'}}/> : 
						(this.state.notifyInProcess) ? <Loading /> :
							<WideButton text={GetLabel('sendNotification')} style={{}}
								event={{ id: 'event_00000', onClick:() => {
									if(this.state.notificationTitle === '' || this.state.notificationBody === '') {
										Alert(GetLabel('notificationTitleOrBodyCannotBeEmpty'));
										return;
									}
									if(this.state.usersToNotify.length === 0) {
										Alert(GetLabel('youNeedToAddAtLeastOneUserToNotify'));
										return;
									}
									let functions = firebase.functions();
									let sendPushNotificationToArrayOfUsers = functions.httpsCallable('sendPushNotificationToArrayOfUsers');
									this.setState({notifyInProcess: true, notificationSent: false});
									this.isNotificationSent();
									sendPushNotificationToArrayOfUsers({
										messageTitle: this.state.notificationTitle,
										messageBody: this.state.notificationBody,
										targetUsers: this.createUsersToModifyIdsArray(this.state.usersToNotify),//['rank_00002', 'rank_00003', 'rank_00004'],
									});
								}, icon: undefined}} />
					}
					
					<Space/><Space/><Space/>
					<Separator large glow/>
					<Space/><Space/><Space/>
					<Subheader text={GetLabel('colorPalette')}/>
					{ this.renderColorPaletteEditors() }
					{/*<DropDown 
						placeholder={GetLabel('eventToModify')}
						value={this.state.eventIdBeingEdited} 
						concatenatedString={concatenatedString}//global.events[this.state.eventIdBeingEdited].id+
						options={this.getEventsArray()}
						optionsAreObjects={true}
						editable={true}
						event={{
							id: 'event_00000', 
							onClick: (eventId)=>{
								this.setState({eventIdBeingEdited: eventId});
							}
						}}/>*/}
					<Space />
					{ this.renderSaveButtonIfDirty() }
					<Space /><Space /><Space /><Space />

				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	name: {
		textTransform: 'normal',
		color: Colors.primary,
	},
	profile: {
		background: 'transparent',
		marginTop: '-2em',
	},
	domainName: {
		paddingLeft: '0.5em',
		paddingRight: '0.5em',
		paddingTop: '0em',
		paddingBottom: '0em',
	},
	wideButton: {
		marginTop: '3em',
		marginBottom: '20em',
	},
}

export { WebAdmin };
