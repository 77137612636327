import { AreaDatabase } from '../constants';
import * as firebase from 'firebase';

export function UploadFile(path, file, onSuccess, onFail) {
	let uploadTask = firebase.storage().ref(`${AreaDatabase}/${path}`).put(file);

	uploadTask.on('state_changed', 
		(snapshot) => {
			var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    		console.log('Upload is ' + progress + '% done');
		}, 
		(error) => {
			console.log('Error uploading the file to '+path, error);
			if(onFail)
				onFail(error);
		}, 
		() => {
			uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
				console.log('File available at', downloadURL);
				if(onSuccess)
					onSuccess(downloadURL);
			});
		}
	);
}


export async function AsyncUploadFile(path, file, onFail) {
	//let uploadTask = firebase.storage().ref(`${AreaDatabase}/${path}`).put(file);
	let url = '';

	let uploadTaskSnapshot = await firebase.storage().ref(`${AreaDatabase}/${path}`).put(file);
	url = await uploadTaskSnapshot.ref.getDownloadURL();
	console.log('File available at', url);

	return url;
}
