import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize, UpdateEvents,
	UpdateProfile, Clone, DirtyStyle, ObjectToArray, IsNullOrEmpty, GetAllSelectableRanks } from '../../constants';
import { Header, ConfirmationButtons, Label, Subheader, WideButton, InputField,
	Div, Expansible, Space, ListEditor, DropDown, SeparatorLarge } from '../../components';

class EventsEditor extends Component {

	state = {
		isLoading: false,
		isDirty: false,
		events: {},
		eventIdBeingEdited: '',
	}

	componentDidMount() {
		this.setState({
			eventIdBeingEdited: '',//this.getEventsArray()[0].id
		});
	}

	saveChanges() {
		let { isDirty } = this.state;
		this.setState({isLoading: true});

		let eventsClone = Clone(this.state.events);

		UpdateEvents(eventsClone,
			() => this.clearDirtyState(), 
			() => this.setState({ isLoading: false }));
	}

	clearDirtyState() {
		this.setState({
			isLoading: false,
			isDirty: false,
		});
	}

	eventsModified(key, newListOfItems) {
		let eventsClone = {};

		if(this.state.isDirty) {
			eventsClone = Clone(this.state.events);
		}
		else{
			this.setState({
				isDirty: true,
				events: Clone(global.events)
			});
			eventsClone = Clone(global.events);
		}

		eventsClone[key].allowedRanks = Clone(newListOfItems);

		console.log('eventsClone: ',eventsClone);

		this.setState({events: eventsClone});

		/*
		let newBalanceScore = {...this.state.balanceScore};
		newBalanceScore[key].items = {...newListOfItems};
		//Report modification
		//this.setState({isDirty: true, balanceScore: newBalanceScore});
		let newState = {
			balanceScore: newBalanceScore,
		};

		switch(key){
			case 'equipo': newState.isDirtyEquipo = true; break;
			case 'finanzas': newState.isDirtyFinanzas = true; break;
			case 'legal': newState.isDirtyLegal = true; break;
			case 'rokkett': newState.isDirtyRokkett = true; break;
			case 'vinculaciones': newState.isDirtyVinculaciones = true; break;
		}

		this.setState(newState);*/
	}

	renderSaveButtonIfDirty() {
		let { isDirty } = this.state;
		
		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	renderObjectListEditor(key) {
		if(IsNullOrEmpty(key))
			return <div />;

		if(global.events[key].adminEvent)
			return <div />;

		let allowedRanks = (this.state.isDirty) ? this.state.events[key].allowedRanks : global.events[key].allowedRanks;
		console.log('allowedRanks: ',allowedRanks);
		return (
			<div>
				<SeparatorLarge />
				<Space /><Space />
				<Subheader text={GetLabel('allowedRanks')}/>
				<ListEditor 
					//baseObject={{}}
					itemStyle={{color: Colors.secondary}}
					options={this.getRanksRemovingCurrentlySelected(allowedRanks)}
					list={allowedRanks} 
					placeholder={GetLabel('addAllowedRanks')} 
					eventId={'event_00017'} 
					onModify={(newList) => this.eventsModified(key, newList)}/>
				<Space />
			</div>
		);
	}

	getRanksRemovingCurrentlySelected(allowedRanks) {
		let allAvailableRanks = GetAllSelectableRanks();
		let filteredRanks = [];
		allowedRanks = IsNullOrEmpty(allowedRanks) ? [] : allowedRanks;
		allAvailableRanks.forEach(rnk => {
			if(!allowedRanks.includes(rnk))
				filteredRanks.push(rnk);
		});
		return filteredRanks;
	}

	getEventsArray() {
		let eventsArray = ObjectToArray(global.events);
		let filteredEventsArray = [];
		eventsArray.forEach(ev => {
			if(!ev.adminEvent)
				filteredEventsArray.push(Clone(ev));
		});

		filteredEventsArray.sort((a, b) => {
			let aName = GetLabel(a.name);
			let bName = GetLabel(b.name);

			if (aName > bName)
		        return 1;
		    if (bName > aName)
		        return -1;
		    return 0;
		});

		return filteredEventsArray;
	}

	render() {

		let name = (this.state.isNameDirty) ? this.state.name : global.profile.name;
		let phoneNumber = (this.state.isPhoneNumberDirty) ? this.state.phoneNumber : global.profile.phoneNumber;

		let concatenatedString = '';
			//IsNullOrEmpty(global.events[this.state.eventIdBeingEdited]) ?
			//'' : ` [ ${global.events[this.state.eventIdBeingEdited].allowedRanks.join(',')} ]`;

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<Header text={GetLabel('eventsEditor')} 
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>

					<Subheader text={GetLabel('selectAnEventToModify')}/>
					<DropDown 
						placeholder={GetLabel('eventToModify')}
						value={this.state.eventIdBeingEdited} 
						concatenatedString={concatenatedString}//global.events[this.state.eventIdBeingEdited].id+
						options={this.getEventsArray()}
						optionsAreObjects={true}
						editable={true}
						event={{
							id: 'event_00000', 
							onClick: (eventId)=>{
								this.setState({eventIdBeingEdited: eventId});
							}
						}}/>
					<Space /><Space />
					{ this.renderObjectListEditor(this.state.eventIdBeingEdited) }
					{ this.renderSaveButtonIfDirty() }
					<Space /><Space /><Space /><Space />

				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	name: {
		textTransform: 'normal',
		color: Colors.primary,
	},
	profile: {
		background: 'transparent',
		marginTop: '-2em',
	},
	domainName: {
		paddingLeft: '0.5em',
		paddingRight: '0.5em',
		paddingTop: '0em',
		paddingBottom: '0em',
	},
	wideButton: {
		marginTop: '3em',
		marginBottom: '20em',
	},
}

export { EventsEditor };
