import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, AmIAllowedToExecuteThis, Colors, GetSprintById, DirtyStyle,
	ReplaceSprintInGlobalById, GetProjectById, PushNewSprint, Today, DeleteSprintInGlobalById, 
	SetValue,
} from '../../constants';
import { Header, Separator, Label, ProjectIcon, InputField, Div, WideButton, DropDown, DaySelector, 
	Checkbox, Parameter, Space, ConfirmationButtons } from '../../components';
import { SendPushNotification } from '../../firebase';

class SprintEditor extends Component {

	state = {
		id: -1, 
		isLoading: false,
		title: '',
		description: '',
		helpNeeded: false,
		deadline: Today(),
		status: 'inProcess',//inProcess, disabled, cancelled, completed
		activities: [],

		dirtyTitle: false,
		dirtyDescription: false,
		dirtyHelpNeeded: false,
		dirtyDeadline: false,
		dirtyStatus: false,
	}

	newSprintFromState() {
		let { title, description, deadline, status, helpNeeded, activities,
			dirtyTitle, dirtyDescription, dirtyDeadline, dirtyStatus, 
			dirtyHelpNeeded } = this.state;
		let sprintObject = {};

		if(dirtyTitle)
			sprintObject.title = title;
		if(dirtyDescription)
			sprintObject.description = description;
		if(dirtyDeadline)
			sprintObject.deadline = deadline;
		if(dirtyStatus)
			sprintObject.status = status;
		if(dirtyHelpNeeded)
			sprintObject.helpNeeded = helpNeeded;

		return sprintObject;
	}

	newSprint() {
		let { id, title, description, deadline, status, helpNeeded, activities,
			dirtyTitle, dirtyDescription, dirtyDeadline, dirtyStatus, 
			dirtyHelpNeeded } = this.state;
		
		let sprintObject = {};

		sprintObject.id = id;
		sprintObject.title = title;
		sprintObject.description = description;
		sprintObject.deadline = deadline;
		sprintObject.status = status;
		sprintObject.helpNeeded = helpNeeded;

		return sprintObject;
	}

	saveChanges() {
		this.setState({isLoading: true });

		let { sprint, project } = this.props.navParams;

		project = {...GetProjectById(project.id)};
		sprint = GetSprintById(project, sprint);

		if(sprint === undefined || sprint === null)
			sprint = {...this.newSprint()};
		else
			sprint = {...sprint, ...this.newSprintFromState()};

		if(sprint.id === -1)
			PushNewSprint(project, sprint,
				() => { this.props.navParams.sprint = {...sprint}; this.clearDirtyState(); }, 
				() => this.setState({ isLoading: false }));
		else {
			if(this.state.dirtyStatus && sprint.status === 'cancelled') {
				SendPushNotification({
					messageTitle: SetValue(GetLabel('sprintWasCancelled'), project.name),
					messageBody: SetValue(GetLabel('sprintWasCancelledDetails_1'), sprint.title) + SetValue(GetLabel('sprintWasCancelledDetails_2'), project.name),
					projectLeader: undefined,
					projectScrumMaster: project.scrumMaster,
					projectQuarterback: undefined,
					targetUsers: ['rank_00002', 'rank_00004'],
				});
			}
			ReplaceSprintInGlobalById(project, sprint,
				() => { this.props.navParams.sprint = {...sprint}; this.clearDirtyState(); }, 
				() => this.setState({ isLoading: false }));
		}
	}

	clearDirtyState() {
		this.setState({
			isLoading: false,
			title: '',
			description: '',
			helpNeeded: false,
			deadline: Today(),
			status: 'inProcess',

			dirtyTitle: false,
			dirtyDescription: false,
			dirtyHelpNeeded: false,
			dirtyDeadline: false,
			dirtyStatus: false,
		});
	}

	renderSaveButtonIfDirty() {
		let { eventId, project } = this.props.navParams;
		let { dirtyTitle, dirtyDescription, dirtyHelpNeeded, dirtyDeadline, dirtyStatus } = this.state;
		
		let isDirty = (dirtyTitle || dirtyDescription || dirtyHelpNeeded || dirtyDeadline || dirtyStatus);

		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: eventId, project: project, onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	deleteSprint() {
		let confirmation = window.confirm(GetLabel('deleteSprintConfirmation'));
		if(confirmation) {
			let { sprint, project } = this.props.navParams;
			project = GetProjectById(project.id);
			sprint = GetSprintById(project, sprint);
			console.log('sprint to be deleted: ',sprint);
			DeleteSprintInGlobalById(project, sprint, ()=>{}, ()=>{});
		}
	}

	renderHeaderDependingOnSprint() {
		let { sprint, project } = this.props.navParams;
		project = GetProjectById(project.id);
		sprint = GetSprintById(project, sprint);

		if(sprint === undefined || sprint === null)
			return (
				<Header text={GetLabel('sprintEditor')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
			);
		else
			return (
				<Header text={GetLabel('sprintEditor')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					buttonRight={{id: 'event_00013', project: project, icon: 'img/sys/delete.png', onClick: ()=>this.deleteSprint()}}/>
			);
	}

	render() {
		let { sprint, project } = this.props.navParams;
		project = GetProjectById(project.id);

		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		sprint = GetSprintById(project, sprint);

		if(sprint === undefined || sprint === null)
			sprint = this.newSprint();			

		let sprintTitle = (this.state.dirtyTitle) ? this.state.title : sprint.title;
		let sprintDescription = (this.state.dirtyDescription) ? this.state.description : sprint.description;
		let sprintHelpNeeded = (this.state.dirtyHelpNeeded) ? this.state.helpNeeded : sprint.helpNeeded;
		let sprintDeadline = (this.state.dirtyDeadline) ? this.state.deadline : sprint.deadline;
		let sprintStatus = (this.state.dirtyStatus) ? this.state.status : sprint.status;

		return (
			<div style={{height: '100%'}}>
				{ this.renderHeaderDependingOnSprint() }
				<Div noVerticalPadding>
					<InputField 
						onChange={ (newValue) => {
							if(AmIAllowedToExecuteThis('event_00007', project))
								this.setState({dirtyTitle: true, title: newValue})
						}}
						placeholder={GetLabel('sprintTitle')} 
						disabled={!AmIAllowedToExecuteThis('event_00007', project)}
						value={sprintTitle}/>
					<InputField 
						onChange={ (newValue) => {
							if(AmIAllowedToExecuteThis('event_00007', project))
								this.setState({dirtyDescription: true, description: newValue})
						}}
						multiline={true} 
						placeholder={GetLabel('sprintDetails')} 
						disabled={!AmIAllowedToExecuteThis('event_00007', project)}
						value={sprintDescription}/>
					<Checkbox event={{id: 'event_00000', onClick: (newValue)=> { this.setState({dirtyHelpNeeded: true, helpNeeded: newValue}) }}} 
						nonEditable={!AmIAllowedToExecuteThis('event_00007', project)}
						color={Colors.red} checked={sprintHelpNeeded}
						icon={'img/sys/flag.png'} style={css.checkbox}>
						<Label text={GetLabel('helpNeeded')} style={css.helpLabel}/>
					</Checkbox>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')}>
						<DaySelector event={{id: 'event_00012', project: project, onModify: (newValue)=>{ this.setState({dirtyDeadline: true, deadline: newValue}) }}}
							value={sprintDeadline} />
					</Parameter>
					<Space />
					<DropDown 
						value={`${sprintStatus}`}
						options={['inProcess', 'disabled', 'completed', 'cancelled']}
						editable={AmIAllowedToExecuteThis('event_00011', project)}
						event={{id: 'event_00011', project: project, onClick: (newValue)=>{ this.setState({dirtyStatus: true, status: newValue}) }}}/>
					{ this.renderSaveButtonIfDirty() }
					
					<Space/><Space/>
					<Space/><Space/>
					<Space/><Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	helpLabel: {
		paddingLeft: '0em',
		marginLeft: '0em',
	}, 
	checkbox: {

	},
}

export { SprintEditor };