import React, { Component } from 'react';
import { Colors, ButtonSize, IsNullOrEmpty } from '../constants';
import { Button, Image } from './';

class SemaphoreToggler extends Component {

	state = {

	}

	componentDidMount() {
		//this.setState({
			//checked: (this.props.checked === true) ? this.props.checked : false
		//});
	}

	renderSemaphoreLight() {
		let color = IsNullOrEmpty(this.props.color) ? 'red' : this.props.color;
		color = (color === 'red') ? Colors.red : Colors.green;

		return (
			<div style={{...this.css().light, ...{/*filter: `drop-shadow(0px 0px 10px ${color})`,*/ background: color}}} className={'appear'}/>
		);
	}

	renderCheckbox() {

		let action = (this.props.nonEditable) ? ()=>{} : 
			() => {
				let newColor = (this.props.color === 'green') ? 'red' : 'green'; 
				this.props.event.onClick(newColor);
			};
		
		return (
			<div style={this.css().iconContainer}>
				<Button style={this.css().checkbox} buttonStyle={this.css().checkboxButton}
					className={''}
					event={{id: this.props.event.id, project: this.props.event.project, onClick: action}}>
					{ this.renderSemaphoreLight() }
				</Button>
			</div>
		);
	}

	render() {
		return (
			<div style={{...this.css().container, ...this.props.containerStyle}}>
				{ this.renderCheckbox() }
				<div style={{...this.css().childrenContainer, ...this.props.style}}>
					{ this.props.children }
				</div>
			</div>
		);
	}

	css() {
		let color = IsNullOrEmpty(this.props.color) ? 'red' : this.props.color;
		color = (color === 'red') ? Colors.red : Colors.green;

		return {
			container: {
				display: 'flex',
				flexDirection: 'row',
				marginTop: '0em',
				marginBottom: '0em',
				alignItems: 'center',
			},
			light: {
				height: `calc(${ButtonSize}/2)`,
				width: `calc(${ButtonSize}/2)`,
				minHeight: `calc(${ButtonSize}/2)`,
				minWidth: `calc(${ButtonSize}/2)`,
				borderRadius: '50%',
			},
			iconContainer:{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '5em',
				maxWidth: '5em',
				marginRight: '0em',
				minWidth: '5em',
			},
			childrenContainer: {
				width: '100%',
				margin: '0em',
				padding: '0em',
			},
			checkbox: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				//background: Colors.buttonBackground,
				borderRadius: '50%',
				border: `2px solid ${(color) ? color : Colors.primary}`,
				//width: `calc(${ButtonSize})`,
				//height: `calc(${ButtonSize})`,
				width: `calc(${ButtonSize}/2)`,
				height: `calc(${ButtonSize}/2)`,
			},
			checkboxButton: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
		};
	}
}

export { SemaphoreToggler };
