import { AreaDatabase } from '../constants';
import * as firebase from 'firebase'
const axios = require('axios');

//https://firebase.google.com/docs/firestore/rtdb-vs-firestore
//https://firebase.google.com/docs/database/web/structure-data
//https://firebase.google.com/docs/auth/admin/

//IMPORTANT: https://firebase.google.com/docs/database/web/read-and-write?authuser=0

export function InitializeFirebase() {
	const config = {
		apiKey: "AIzaSyCp-4MzZ8qGaWOjoAoOH1HjFg7zKKHHNfk",
		authDomain: "gtd-app-acaa2.firebaseapp.com",
		databaseURL: "https://gtd-app-acaa2.firebaseio.com",
		projectId: "gtd-app-acaa2",
		storageBucket: "gtd-app-acaa2.appspot.com",
		messagingSenderId: "890381098800",
		appId: "1:890381098800:web:8e970fc71af07d4768b2f5",
		measurementId: "G-8Q2D2Y6GSC"
	}
	firebase.initializeApp(config);
	firebase.analytics();
}

export function InitializeRealtimeDatabase(onSuccess) {
	firebase.database().ref(`${AreaDatabase}`).on('value', (snapshot) => {
		//console.log('snapshot.val(): ',snapshot.val());
		if(onSuccess)
			onSuccess(snapshot.val());
	});

	// firebase.database().ref(`${AreaDatabase}`)
	// 	.onDisconnect(() => {
	// 		console.log('I got dosconnected');
	// 	});
}

export async function IsOnlineValidation(url) {
	// let url = 'http://webcode.me';
	// let url = 'https://gtd-app-acaa2.web.app/';
	// let url = 'https://app.gbtentech.com/';
	// let url = 'http://www.mozilla.org/';
	try{
		let result = await axios.get(url, {timeout: global.onlineCheckInterval * 0.5});
		return true;
	}
	catch(error) {
		console.log('error: ',error);
		return false;
	}
}

export function UploadObject(databasePath, obj, onSuccess, onFail) {
	if(!global.onlineStatus) {
		if(onFail)
			onFail();
		console.log('Not online');
		alert('Se perdió la conexión a internet, favor de actualizar la página e intentarlo de nuevo.');
		return;
	}
	// console.log('isOnline: '+isOnline);
	obj = JSON.parse(JSON.stringify(obj));
	global.databaseWriteInProcess = true;
	firebase.database().ref(`${AreaDatabase}/${databasePath}`).set(obj, (error) => {
		if (error) {
			console.log(`Error writing to database ${AreaDatabase}/${databasePath}`);
			if(onFail)
				onFail();
		} else {
			//console.log('Data saved successfully!');
			if(onSuccess)
				onSuccess();
		}
		global.databaseWriteInProcess = false;
	});
}

export async function AsyncUploadObject(databasePath, obj, onFail) {
	obj = JSON.parse(JSON.stringify(obj));

	await firebase.database().ref(`${AreaDatabase}/${databasePath}`).set(obj, (error) => {
		if (error) {
			console.log(`Error writing to database ${AreaDatabase}/${databasePath}`);
			if(onFail)
				onFail();
		}
	});
}
/*
export function UploadLabels() {
	console.log('UploadLabels called');
	firebase.database().ref(`${AreaDatabase}/labels`).set(global.labels, (error) => {
		if (error) {
			// The write failed...
			console.log('The write failed...');
		} else {
			// Data saved successfully!
			console.log('Data saved successfully!');
		}
	});
}

export function UploadEvents() {
	console.log('UploadEvents called');
	firebase.database().ref(`${AreaDatabase}/events`).set(global.events, (error) => {
		if (error) {
			// The write failed...
			console.log('The write failed...');
		} else {
			// Data saved successfully!
			console.log('Data saved successfully!');
		}
	});
}*/
