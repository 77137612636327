import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, HeadersSize,
	FormatDateTimeLong, Clone, ButtonSize, IsNullOrEmpty, DirtyStyle, GetMinutaById,
	PushMinuta, Sleep, UploadMinutas, GetMinutaKey } from '../../constants';
import { SideMenu, Header, SeparatorLarge, Label, BulletPointsList, Subheader, WideButton, 
	Div, Expansible, Space, Parameter, Checkbox, ConfirmationButtons, Loading } from '../../components';
import * as firebase from 'firebase';

class MinutaDetails extends Component {

	state = {
		isLoading: false,
		isDirty: false,
		toDoList: [],
		sendingEmail: false,
		emailSent: false,
		isDeleting: false,
	}

	getUserName(userId) {
		// return global.users[userId].name;
		let userName = global.users[userId] ? global.users[userId].name : GetLabel('userNotFound')+': '+userId;
		return userName;
	}

	checkPoint(i, j, newValue) {
		let { minuta } = this.props.navParams;
		minuta = GetMinutaById(minuta);
		let toDoList = (this.state.isDirty) ? Clone(this.state.toDoList) : Clone(minuta.toDoList);

		toDoList[i].points[j].checked = newValue;
		
		this.setState({
			toDoList: toDoList,
			isDirty: true
		});
	}

	renderToDoPoints() {
		let { minuta } = this.props.navParams;
		minuta = GetMinutaById(minuta);

		if(IsNullOrEmpty(minuta.toDoList))
			return <div/>;
		
		let toDoList = (this.state.isDirty) ? this.state.toDoList : minuta.toDoList;
		
		return (
			toDoList.map((toDoItem, i) => {
				toDoItem.points = (!toDoItem.points) ? [] : toDoItem.points;
				// console.log('toDoItem.points: ',toDoItem.points);
				return (
					<Expansible title={`${toDoItem.name}`} eventId={'event_00000'} key={i}>
						{
							toDoItem.points.map((point, j) => {
								//let point = toDoItem.points[key];
								//console.log('point: ',point);
								//console.log('key: ',key);
								//console.log('toDoItem.points: ',toDoItem.points);
								return (
									<Checkbox 
										event={{id: 'event_00000', onClick: ()=> { 
											this.checkPoint(i, j, !point.checked)
										}}} 
										key={i+' '+j} 
										style={css.noHorizontalSpace}
										containerStyle={css.noVerticalSpace}
										disabled={toDoItem.hideCheckbox}
										checked={point.checked}>
										<Label text={point.name} style={css.noHorizontalSpace}/>
									</Checkbox>
								)
							}) 
						}
					</Expansible>
				)
			})
		);
	}

	clearDirtyState() {
		this.setState({
			isDirty: false,
			isLoading: false, 
		});
	}

	deleteMinuta(minuta) {
		this.setState({isDeleting: true})
		// console.log('minuta to delete: ',minuta);
		// console.log('global.minutas: ',global.minutas);
		let minutaKey = GetMinutaKey(minuta);
		let minutas = Clone(global.minutas);
		delete minutas[minutaKey];
		// console.log('minutas: ',minutas);
		UploadMinutas(minutas, ()=>{
			this.props.goBack();
		}, ()=>{
			this.setState({isDeleting: false})
		});
	}

	saveChanges() {
		this.setState({isLoading: true,});

		let { minuta } = this.props.navParams;
		minuta = GetMinutaById(minuta);
		minuta.toDoList = Clone(this.state.toDoList);

		//console.log('minuta to be replaced: ',minuta);

		PushMinuta(minuta, 
			() => { 
				this.props.navParams.minuta = Clone(minuta); 
				//this.initialToDoList = Clone(minuta.toDoList); 
				this.clearDirtyState(); 
			}, 
			() => this.setState({ isLoading: false }));
	}

	renderSaveButtonIfDirty() {
		let { isDirty } = this.state;
		
		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: 'event_00000', onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	async isEmailSent() {
		await Sleep(5e3);
		this.setState({sendingEmail: false, emailSent: true});
	}

	render() {
		let { minuta } = this.props.navParams;
		minuta = GetMinutaById(minuta);

		let editEventObject = {
			id: 'event_00026', icon: 'img/sys/edit.png', onClick: ()=>
			this.props.innerNavigate('minutaEditor', {
				eventId: 'event_00026',
				minuta: Clone(minuta),
			})
		};

		if(this.state.isDeleting)
			return <Loading/>;

		if(minuta) {
			if(minuta.createdBy !== global.profile.id && global.profile.rank !== 'rank_00004') 
				editEventObject = undefined;
		}

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<Header text={GetLabel('minutaDetails')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					buttonRight={ editEventObject }/>
				<Div noVerticalPadding>
					<Parameter icon={'img/sys/toDo.png'} iconLabel={GetLabel('creation')} iconSize={ButtonSize} 
						containerStyle={{marginBottom: '2em'}}>
						<div style={css.columnStyle}>
							<Subheader text={this.getUserName(minuta.createdBy)} style={css.noVerticalSpace}/>
							<Label text={FormatDateTimeLong(minuta.creationMoment, ' ')} style={css.noVerticalSpace}/>
						</div>
					</Parameter>
					<Parameter icon={'img/sys/edit.png'} iconLabel={GetLabel('lastEdition')} iconSize={ButtonSize}>
						<div style={css.columnStyle}>
							<Subheader text={this.getUserName(minuta.lastEditedBy)} style={css.noVerticalSpace}/>
							<Label text={FormatDateTimeLong(minuta.lastModification, ' ')} style={css.noVerticalSpace}/>
						</div>
					</Parameter>
					<Space/>
					<SeparatorLarge disableBackgroundLines/>
					<Space/>
					<Subheader text={GetLabel('toDoPoints')} labelStyle={{color: Colors.secondary}}/>
					{ this.renderToDoPoints() }
					{ this.renderSaveButtonIfDirty() }
					{
						(this.state.emailSent) ? <Label text={GetLabel('emailSent')} style={{marginTop: '4rem'}} labelStyle={{textAlign: 'center'}}/> : 
						(this.state.sendingEmail) ? <Loading /> : 
						(minuta.createdBy !== global.profile.id && global.profile.rank !== 'rank_00004') ? undefined : 
						<WideButton text={GetLabel('sendMinutaViaEmail')} style={{marginTop: '4rem', marginBottom: '3rem'}}
							event={{ id: 'event_00000', onClick:() => {
								let functions = firebase.functions();
								// let sendEmailConfirmationRequest = functions.httpsCallable('sendEmailConfirmationRequest');
								let sendEmailConfirmationRequestTecnovoali = functions.httpsCallable('sendEmailConfirmationRequestTecnovoali');
								this.setState({sendingEmail: true});
								this.isEmailSent();
								// sendEmailConfirmationRequest();
								sendEmailConfirmationRequestTecnovoali();
							}, icon:'img/sys/toDo.png'}} />
					}
					{
						(minuta.createdBy !== global.profile.id && global.profile.rank !== 'rank_00004') ? undefined : 
						<WideButton text={GetLabel('deleteMinuta')} style={{
								backgroundColor: Colors.red+'50',
								border: `2px solid ${Colors.red}`,
							}}
							iconColor={Colors.red}
							labelStyle={{color: '#ffffff'}}
							event={{ id: 'event_00000', onClick:() => {
								let confirmation = window.confirm(GetLabel('deleteMinutaConfirmation'));
  								if(confirmation) {
  									this.deleteMinuta(minuta)
  								}
							}, icon: 'img/sys/delete.png'}} />
					}
					
					<Space /><Space /><Space /><Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	columnStyle: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	noVerticalSpace: {
		paddingTop: '0.3em',
		paddingBottom: '0.3em',
		marginTop: '0',
		marginBottom: '0',
	},
	noHorizontalSpace: {
		paddingRight: '0',
		paddingLeft: '0',
		marginRight: '0',
		marginLeft: '0'
	}
}

export { MinutaDetails };
