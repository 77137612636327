import React, { Component } from 'react';
import { Colors, ButtonSize, GetHighestIdFromObjectsList, GetObjectKey, IsNullOrEmpty } from '../constants';
import { Label, Button, Image, InputField } from './';

class ObjectListEditor extends Component {

	state = {
		isLoading: false,
		newItem: '',
	}

	deleteItemFromList(key) {
		let list = (this.props.list === undefined || this.props.list === null) ? 
			{} : this.props.list;

		if(this.props.onModify) {
			let newList = {...list};
			delete newList[key];
			this.props.onModify(newList);
		}
	}

	addItemToList() {
		let list = IsNullOrEmpty(this.props.list) ? {} : this.props.list;

		if(this.props.onModify) {
			let newList = {...list};

			let newId = IsNullOrEmpty(this.props.list) ? 0 : GetHighestIdFromObjectsList(newList) + 1;
			let newObject = {...this.props.baseObject, ...{id: newId, name: this.state.newItem}}
			let newKey = GetObjectKey('item', newObject);
			newList[newKey] = {...newObject};

			this.props.onModify(newList);
			this.setState({newItem: ''});
		}
	}

	renderAddedItem(item, key) {
		//console.log('renderAddedItem called: ',item);
		return (
			<div style={css.listContainer} key={key}>
				<Label text={item.name} style={{width: '100%', marginLeft: '0'}}/>
				<div style={css.buttonsContainer}>
					<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.deleteItemFromList(key)}}>
						<Image src={'img/sys/delete.png'} color={Colors.red} height={ButtonSize} width={ButtonSize}/>
					</Button>
				</div>
			</div>
		);
	}

	renderAddedItems(list) {
		let jsx = [];
		Object.keys(list).forEach(key => {
			jsx.push(this.renderAddedItem(list[key], key));
		});
		return jsx;
	}

	renderAddButtonIfNeeded() {
		if(IsNullOrEmpty(this.state.newItem))
			return <div />;
		else
			return (
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.addItemToList()}}>
					<Image src={'img/sys/add.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			);
	}

	render() {
		let list = (this.props.list === undefined || this.props.list === null) ? {} : this.props.list;
		//console.log('list to render: ',list);
		return (
			<div style={{...css.container, ...this.props.style}}>	
				{ this.renderAddedItems(list) }
				<div style={css.adderSectionContainer}>
					<InputField onChange={ (newValue) => {this.setState({ newItem: newValue })} }
						placeholder={this.props.placeholder} value={this.state.newItem}/>
					{ this.renderAddButtonIfNeeded() }
					{/*<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.addItemToList()}}>
						<Image src={'img/sys/add.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
					</Button>*/}
				</div>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: '100%',
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	}, 
	adderSectionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
	}
}

export { ObjectListEditor };