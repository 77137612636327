import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, PadNumber, IsNullOrEmpty, GetProjectsByStage,
	ButtonSize, Colors, LargeButtonSize } from '../../constants';
import { SideMenu, Header, Separator, Label, ProjectIcon, Space, Image, Button } from '../../components';
import './Projects.css';

class Portfolio extends Component {

	state = {
		sectionsWithOverflow: []
	}

	getAllProjects(sectionName) {
		let jsx = [];
		/*
		for(let i=0; i<Object.keys(global.projects).length; i++) {
			if(global.projects['projects_'+PadNumber(i, 5)].stage === sectionName) {
				jsx.push(<ProjectIcon project={global.projects[i]} key={i} 
					event={{id: 0, onClick: ()=> this.props.innerNavigate('projectDetails', {project: global.projects[i]})}}/>)
			}
		}*/
		if(IsNullOrEmpty(global.projects))
			return jsx;
		/*
		Object.keys(global.projects).forEach((key) => {
			let project = global.projects[key];
			if(project.stage === sectionName) {
				jsx.push(<ProjectIcon project={global.projects[key]} key={key} 
					event={{id: 'event_00000', onClick: ()=> this.props.innerNavigate('projectDetails', {project: global.projects[key]})}}/>)
			}
		});*/
		
		let projects =  GetProjectsByStage(sectionName);
		projects.map((project, i) => {
			jsx.push(<ProjectIcon project={project} key={i} id={'projectIcon'+i}
				event={{id: 'event_00000', onClick: ()=> this.props.innerNavigate('projectDetails', {project: project})}}/>)
		});

		return  jsx;
	}

	componentDidMount() {
		this.projectIconWidth = 1000;
		window.addEventListener("resize", () => {
			this.updateSectionsWithOverflow();
		});

		window.requestAnimationFrame(() => {
			this.updateSectionsWithOverflow();
		});
	}

	componentDidUpdate() {
		//this.updateSectionsWithOverflow();
	}

	updateSectionsWithOverflow() {
		//console.log('screen.width: '+window.innerWidth);
		if(!IsNullOrEmpty(document.getElementById("projectIcon0"))) {
			this.projectIconWidth = document.getElementById("projectIcon0").offsetWidth * 1.2;//The 1.2 is due to the space between each project Icons
			//console.log('projectIconWidth: ',this.projectIconWidth);
			
			let sectionsWithOverflow = [];
			if(GetProjectsByStage('pending').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('pending');
			if(GetProjectsByStage('ideation').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('ideation');
			if(GetProjectsByStage('frame').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('frame');
			if(GetProjectsByStage('understand').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('understand');
			if(GetProjectsByStage('experiment').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('experiment');
			if(GetProjectsByStage('accelerate').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('accelerate');
			if(GetProjectsByStage('scale').length * this.projectIconWidth > window.innerWidth)
				sectionsWithOverflow.push('scale');

			if(this.state.sectionsWithOverflow.length !== sectionsWithOverflow.length) {
				//console.log('new sectionsWithOverflow: ',sectionsWithOverflow);
				this.setState({sectionsWithOverflow: sectionsWithOverflow});
			}
		}
	}	

	wheel(event) {
		//event.preventDefault();
		let target = (event.target.className === 'horizontalScrollable') ? event.target : null;

		target = (target === null && event.target.parentElement.className === 'horizontalScrollable') ? event.target.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.className === 'horizontalScrollable') ? event.target.parentElement.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.parentElement.className === 'horizontalScrollable') ? event.target.parentElement.parentElement.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.parentElement.parentElement.className === 'horizontalScrollable') ? event.target.parentElement.parentElement.parentElement.parentElement : target;
		target = (target === null && event.target.parentElement.parentElement.parentElement.parentElement.parentElement.className === 'horizontalScrollable') ? event.target.parentElement.parentElement.parentElement.parentElement.parentElement : target;

		//console.log('target: ',target);

		if(target !== null) {
			//event.preventDefault();
			target.scrollLeft = target.scrollLeft += event.deltaY / 2;
		}
	}

	wheelVertical(event) {
		/*
		event.passive = false;
		let target = (event.target.className === 'verticalScroll') ? event.target : null;
		console.log('vertical target: ',target);
		event.preventDefault();*/
	}

	scrollHorizontally(target, direction) {
		let deltaX = 60;

		console.log('target: ',target);

		if(direction !== 'left')
			target.scrollLeft = target.scrollLeft += deltaX;
		else
			target.scrollLeft = target.scrollLeft -= deltaX;
	}

	renderArrow(direction, reference) {
		let obj = (direction === 'left') ? 
			{left: 0, marginLeft: 'calc(50vw - 4em)'} : 
			{right: 0, marginRight: 'calc(50vw - 4em)'};
		let offset1 = (direction === 'left') ? {left: '0em'} : {right: '0em'};
		let offset2 = (direction === 'left') ? {left: '-1em'} : {right: '-1em'};
		return (
			<Button className={'pulse'} style={{...css.overflowArrow, ...obj}}
				event={{id: 'event_00000', onClick: () => {this.scrollHorizontally(this.refs[reference], direction)}}} 
				buttonStyle={{height: `calc(${LargeButtonSize} - 1em)`, pointerEvents: 'all'}}>
				{/*<div style={{...css.overflowArrow, ...obj}} >*/}
					<Image src={'img/sys/'+direction+'.png'} width={LargeButtonSize} height={LargeButtonSize} color={Colors.primary} 
						style={{...{position: 'absolute', bottom: '0', opacity: '0.5'}, ...offset1}}/>
					<Image src={'img/sys/'+direction+'.png'} width={LargeButtonSize} height={LargeButtonSize} color={Colors.primary} 
						style={{...{position: 'absolute', bottom: '0', opacity: '0.5'}, ...offset2}}/>
				{/*</div>*/}
			</Button>
		);
	}
	renderSection(sectionName) {
		let renderArrows = (this.state.sectionsWithOverflow.includes(sectionName));
		return (
			<div>
				<Label text={GetLabel(sectionName)} style={css.sectionNameContainer} labelStyle={css.sectionName}/>
				<div ref={sectionName+'Bar'} style={css.scrollable} className={'horizontalScrollable'} onWheel={(e) => this.wheel(e)}>
					{ (renderArrows) ? this.renderArrow('left', sectionName+'Bar') : undefined }
					{ this.getAllProjects(sectionName) }
					{ (renderArrows) ? this.renderArrow('right', sectionName+'Bar') : undefined }
				</div>
			</div>
		);
	}

	render() {
		let className = (this.props.className) ? this.props.className : '';
		return (
			<div style={{height: '100%'}} className={className}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('portfolio')} 
					buttonRight={[
						{id: 'event_00000', icon: 'img/sys/presentation.png', onClick: ()=> this.props.innerNavigate('presentation')},
						{id: 'event_00003', icon: 'img/sys/add.png', onClick: ()=>
							this.props.innerNavigate('projectEditor', {
								project: {id: -1},
								eventId: 'event_00003',
							})
						},
					]}/>
				<div style={{overflowY: 'scroll', position: 'relative', height: '100%'}} className={'verticalScroll'} onWheel={(e) => this.wheelVertical(e)}>
					{ this.renderSection('pending') }
					<Separator />
					{ this.renderSection('ideation') }
					<Separator />
					{ this.renderSection('frame') }
					<Separator />
					{ this.renderSection('understand') }
					<Separator />
					{ this.renderSection('experiment') }
					<Separator />
					{ this.renderSection('accelerate') }
					<Separator />
					{ this.renderSection('scale') }
					<div style={{marginBottom: '5em'}}/>
				</div>
			</div>
		);
	}
}

const css = {
	sectionNameContainer: {
		justifyContent: 'flex-start',
		marginLeft: '0.5em',
		marginBottom: '-0.7em',
		paddingBottom: '0',
	},
	sectionName: {
		textTransform: 'uppercase',
		textAlign: 'left',
		marginBottom: '-0.7em',
		paddingBottom: '0',
	},
	scrollable: {
		overflowX: 'scroll',
		overflowY: 'hidden',
		display: 'flex',
		height: `calc(${ProjectIconDiameter} + 6em)`,
	},
	overflowArrow: {
		position: 'absolute',
		width: '3em',
		height: `calc(13em)`,
		//background: 'red',
		display: 'flex',
		zIndex: '10',
		justifyContent: 'center',
		alignItems: 'flex-end',
		paddingTop: `1.5em`,
		pointerEvents: 'none',
	}, 
}

export { Portfolio };