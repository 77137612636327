import React, { Component } from 'react';
import { Colors, GetLabel, HeadersSize, ButtonSize } from '../constants';
import { Label, Button, Image } from './';

class Header extends Component{

	renderRightButton(eventObject, colorInStyle, i) {
		return (
			<Button event={eventObject} key={i}>
				<Image src={ eventObject.icon } width={ButtonSize} height={ButtonSize} color={colorInStyle}/>
			</Button>
		);
	}

	render() {
		let { buttonRight, buttonLeft } = this.props;
		
		let buttonLeftJsx = undefined;
		let buttonRightJsx = undefined;

		let colorInStyle = Colors.primary;

		if (this.props.labelStyle)
			if(this.props.labelStyle.color)
				colorInStyle = this.props.labelStyle.color;
			
		if(buttonRight) {
			if(buttonRight[0]) {
				buttonRightJsx = (
					<div style={css.rightButtonsContainer}>
						{
							buttonRight.map((item, i) => {
								return this.renderRightButton(item, colorInStyle, i)
							})
						}
					</div>
				);
			}
			else
				buttonRightJsx = (
					<div style={css.rightButtonsContainer}>
						{ this.renderRightButton(buttonRight, colorInStyle) }
					</div>
				);
		}

		if(buttonLeft)
			buttonLeftJsx = (
				<Button style={ css.buttonLeft } event={buttonLeft}>
					<Image src={ buttonLeft.icon } width={ButtonSize} height={ButtonSize} color={colorInStyle}/>
				</Button>
			);
		return (
			<Label text={GetLabel(this.props.text)} style={{...css.text, ...this.props.style}} 
				labelStyle={{...css.headerLabel, ...this.props.labelStyle}}>
				{ buttonLeftJsx }
				{ buttonRightJsx }
			</Label>
		);
	}
}

const css = {
	text: {
		//position: 'absolute',
		top: '0em',
		marginLeft: ButtonSize,
		//lineHeight: `5em`,
		padding: '0',
		height: ButtonSize,
		maxHeight: ButtonSize,
		width: '100%',
		justifyContent: 'flex-start',

		//marginTop: '1.2em',
	},
	buttonRight: {
		position: 'absolute',
		right: '0em'
	},
	buttonLeft: {
		position: 'absolute',
		left: '0em'
	},
	headerLabel: {
		fontSize: HeadersSize,
		maxWidth: '50vw',
	},
	rightButtonsContainer: {
		position: 'absolute',
		right: '0em',
		display: 'flex',
		flexDirection: 'row',
	}
}

export { Header };
