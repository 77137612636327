import React, { Component } from 'react';
import { HorizontalPadding, VerticalPadding, ButtonSize, Colors, HeadersSize } from '../constants';
import { Button, Image, Label } from './';

class Selector extends Component {

	state = {

	}

	render() {

		let styleUp = (this.props.compactStyle) ? {maxHeight: '2rem'} : {};
		let styleDown = (this.props.compactStyle) ? {maxHeight: '2rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'} : {};

		return (
			<div style={{...css.container, ...this.props.style}}>	
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.props.onUp()}} style={styleUp}>
					<Image src={'img/sys/up.png'} height={ButtonSize} width={ButtonSize} color={Colors.primary}/>
				</Button>
				<Label text={this.props.value} labelStyle={css.labelStyle}/>
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.props.onDown()}} style={styleDown}>
					<Image src={'img/sys/down.png'} height={ButtonSize} width={ButtonSize} color={Colors.primary}/>
				</Button>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: '7em',
	},
	labelStyle: {
		fontSize: HeadersSize,
	}
}

export { Selector };