import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, GetSprintStatusIcon, ButtonSize, GetSprintById, 
	GetProjectById, IsNullOrEmpty, GetActivityById, ReplaceSprintInGlobalById, DirtyStyle,
	AmIAllowedToExecuteThis, SetValue } from '../../constants';
import { Header, Separator, Label, Subheader, Div, Parameter, Space, Checkbox, WideButton, 
	ConfirmationButtons, DropDown } from '../../components';
import { SendPushNotification } from '../../firebase';

class Sprint extends Component {

	state = {
		isDirty: false,
		dirtyStatus: false,
		status: 'inProcess',//inProcess, disabled, cancelled, completed
		isLoading: false,
		activitiesAltered: {},
	}

	renderHelpIfNeeded(sprint) {
		if(sprint.helpNeeded)
			return (
				<Parameter 
					icon={'img/sys/help.png'}
					iconColor={Colors.red}
					iconLabel={GetLabel('help')} 
					text={GetLabel('helpNeeded')}/>
			);
		else
			return <div />;
	}

	editActivity(activity, eventId) {
		let { sprint, project } = this.props.navParams;
		project = GetProjectById(project.id);
		sprint = GetSprintById(project, sprint);

		this.props.innerNavigate('activityEditor', {
			project: project, 
			sprint: sprint,
			eventId: eventId,
			activity: activity,
		});
	}

	renderActivities() {
		let { sprint, project } = this.props.navParams;
		project = GetProjectById(project.id);
		sprint = GetSprintById(project, sprint);
		let jsx = [];

		if(!sprint.activities)
			jsx.push(<div key={'0'}/>);
		else if(Object.keys(sprint.activities).length > 0) {			
			//sprint.activities.map((activity, i) => {
			Object.keys(sprint.activities).forEach((key) => {
				let activity = sprint.activities[key];

				let activityCompleted = (this.state.isDirty) ? 
					IsNullOrEmpty(this.state.activitiesAltered[key]) ? activity.completed : this.state.activitiesAltered[key].completed : 
					activity.completed;

				let newState = {};
				newState.isDirty = true;
				newState.activitiesAltered = {...this.state.activitiesAltered};
				newState.activitiesAltered[key] = {};
				newState.activitiesAltered[key].completed = !activityCompleted;
					//IsNullOrEmpty(this.state.activitiesAltered[key]) ? true : !this.state.activitiesAltered[key].completed

				jsx.push(
					<Checkbox event={{id: 'event_00009', project: project, onClick: ()=> { this.setState(newState) }}} key={key} checked={activityCompleted}>
						<Subheader text={activity.title} style={css.activitiesElement}/>
						<Label text={activity.description} style={css.activitiesElement}/>
						<Label text={FormatDateShort(activity.deadline)} style={css.activitiesElement}/>
						<WideButton  text={GetLabel('edit')} style={css.activitiesElement}
							event={{
								id: 'event_00010', 
								project: project,
								onClick: ()=> {this.editActivity(activity, 'event_00010')}
							}}/>
					</Checkbox>
				);
			})
		}
		else
			jsx.push(<div/>);

		return jsx;
	}

	saveActivityChanges() {
		//console.log('altered activities: ',this.state.activitiesAltered);
		this.setState({isLoading: true });
		let { sprint, project } = this.props.navParams;
		project = {...GetProjectById(project.id)};
		sprint = GetSprintById(project, sprint);
		Object.keys(this.state.activitiesAltered).forEach(key => {
			sprint.activities[key].completed = this.state.activitiesAltered[key].completed;
		});
		ReplaceSprintInGlobalById(project, sprint, 
			() => { this.props.navParams.sprint = {...sprint}; this.clearDirtyActivityState(); }, 
			() => this.setState({ isLoading: false }));
	}

	saveStatusChanges() {
		this.setState({isLoading: true });
		let { sprint, project } = this.props.navParams;
		project = {...GetProjectById(project.id)};
		sprint = GetSprintById(project, sprint);
		sprint.status = this.state.status;

		if(this.state.status === 'cancelled') {
			SendPushNotification({
				messageTitle: SetValue(GetLabel('sprintWasCancelled'), project.name),
				messageBody: SetValue(GetLabel('sprintWasCancelledDetails_1'), sprint.title) + SetValue(GetLabel('sprintWasCancelledDetails_2'), project.name),
				projectLeader: undefined,
				projectScrumMaster: project.scrumMaster,
				projectQuarterback: undefined,
				targetUsers: ['rank_00002', 'rank_00004'],
			});
		}

		ReplaceSprintInGlobalById(project, sprint, 
			() => { this.props.navParams.sprint = {...sprint}; this.clearDirtyStatusState(); }, 
			() => this.setState({ isLoading: false }));
	}

	clearDirtyActivityState() {
		this.setState({
			isDirty: false,
			isLoading: false,
			activitiesAltered: {},
		});
	}

	clearDirtyStatusState() {
		this.setState({
			isLoading: false,
			dirtyStatus: false,
			status: 'inProcess',//inProcess, disabled, cancelled, completed
		});
	}

	renderSaveButtonIfActivitiesDirty() {
		let { project } = this.props.navParams;
		let eventId = 'event_00009';
		let { isDirty } = this.state;

		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: eventId, project: project, onClick: ()=>this.saveActivityChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyActivityState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	renderSaveButtonIfStatusDirty() {
		let { project } = this.props.navParams;
		let eventId = 'event_00011';
		let { dirtyStatus } = this.state;

		if(dirtyStatus)
			return (
				<div>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: eventId, project: project, onClick: ()=>this.saveStatusChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyStatusState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	renderStatusSelector(sprint, project) {
		let stageContainerStyle = (this.state.dirtyStatus) ? DirtyStyle : {};
		let sprintStatus = (this.state.dirtyStatus) ? this.state.status : sprint.status;
		return (
			<div style={stageContainerStyle}>
				<DropDown 
					value={`${sprintStatus}`}
					options={['inProcess', 'disabled', 'completed', 'cancelled']}
					editable={AmIAllowedToExecuteThis('event_00011', project)}
					event={{id: 'event_00011', project: project, onClick: (newValue)=>{ this.setState({dirtyStatus: true, status: newValue}) }}}>
					<Parameter 
						containerStyle={{marginTop: '0', marginBottom: '0'}}
						icon={GetSprintStatusIcon(sprintStatus).icon} 
						iconColor={GetSprintStatusIcon(sprintStatus).color}
						iconLabel={GetLabel('status')} 
						text={''}/>
				</DropDown>
				{ this.renderSaveButtonIfStatusDirty() }
			</div>
		);
	}

	render() {
		let { sprint, project } = this.props.navParams;
		project = GetProjectById(project.id);

		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		sprint = GetSprintById(project, sprint);

		if(!sprint) {
			this.props.goBack();
			return <div/>;
		}

		let className = (this.props.className) ? this.props.className : '';
		return (
			<div style={{height: '100%'}} className={className}>
				<Header text={sprint.title} 
					buttonRight={{
						id: ['event_00007', 'event_00011', 'event_00012', 'event_00013'], project: project, icon: 'img/sys/edit.png',
						onClick: ()=>this.props.innerNavigate('sprintEditor', {
							project: project,
							sprint: GetSprintById(GetProjectById(project.id), sprint),//or simply use: sprint
							eventId: ['event_00007', 'event_00011', 'event_00012', 'event_00013'],
						})
					}}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					<Subheader text={GetLabel('description')}/>
					<Label text={sprint.description}/>
					{ this.renderStatusSelector(sprint, project) }
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')} 
						text={FormatDateShort(sprint.deadline)}/>
					
					{ this.renderHelpIfNeeded(sprint) }
					<Space/>
					<Separator style={{position: 'absolute', left: '0',}}/>
					<Header text={GetLabel('activities')} style={css.subheaders} labelStyle={css.subheadersLabelStyle} 
						buttonRight={{
							id: 'event_00008', project: project, icon: 'img/sys/add.png', 
							onClick: ()=>this.props.innerNavigate('activityEditor', {
								project: project, 
								sprint: sprint,
								eventId: 'event_00008',
								activity: GetActivityById(project, sprint, -1),
							})
						}}/>
					<Space/>
					<Space/>
					{ this.renderActivities() }
					{ this.renderSaveButtonIfActivitiesDirty() }
					<Space/><Space/>
					<Space/><Space/>
					<Space/><Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	contianer: {

	},
	activitiesElement: {
		padding: '0',
		marginLeft: '0',
		marginRight: '0',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
	subheaders: {
		marginTop: '1em',
		marginLeft: '0em',
		position: 'absolute',
    	top: 'initial !important',
    	width: `100%`,
    	left: '0',
	},
	subheadersLabelStyle: {
		paddingLeft: `calc(${ButtonSize}/2)`,
	},
}

export { Sprint };