import React, { Component  } from 'react';
import { Colors, ProjectIconDiameter, GetLabel, Alert } from '../constants';
import { ProfileDetails, ProfileEditor, EventsEditor, RetrospectivesReport,
	WebAdmin, UsersEditor,
} from '../screens/screens_profile';

class MyProfile extends Component {

	state = {
		screen: 'profile',
		poppedScreen: '',
		navParams: {}
	}

	componentDidMount() {
		this.stack = [];
	}

	navigate(target, params) {
		this.stack.push(target);
		this.setState({screen: target, navParams: params, poppedScreen: ''});//selectedProjectId
	}

	goBack() {
		if(global.databaseWriteInProcess) {
			Alert(GetLabel('databaseWriteInProcess'));
			return;
		}
		let poppedScreen = (this.stack.length > 0) ? this.stack[this.stack.length-1] : '';
		this.stack.pop();
		let prevScreen = (this.stack.length > 0) ? this.stack[this.stack.length-1] : 'profile';
		this.setState({screen: prevScreen, poppedScreen: poppedScreen});
	}

	renderFromState(type, screenName) {
		let classToAdd = undefined;

		if(type === 'popped') 
			classToAdd = 'moveOut';
		
		switch(screenName) {
			case 'profile':
				return (
					<ProfileDetails 
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						className={classToAdd}
						navigate={ this.props.navigate } />
				);
			case 'profileEditor':
				return (
					<ProfileEditor 
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						className={classToAdd}
						goBack={() => this.goBack()} />
				);
			case 'eventsEditor':
				return (
					<EventsEditor
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						className={classToAdd}
						goBack={() => this.goBack()} />
				);
			case 'retrospectivesReport':
				return (
					<RetrospectivesReport 
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						className={classToAdd}
						goBack={() => this.goBack()} />
				);
			case 'usersEditor':
				return (
					<UsersEditor
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						className={classToAdd}
						goBack={() => this.goBack()} />
				);
			case 'webAdmin':
				return (
					<WebAdmin
						innerNavigate={ (target, params) => this.navigate(target, params) }
						navParams={this.state.navParams}
						className={classToAdd}
						goBack={() => this.goBack()} />
				);
			default:
				return <div/>; 
		}
	}

	render() {

		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				{ this.renderFromState('current', this.state.screen) }
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	sectionNameContainer: {
		justifyContent: 'flex-start',
		marginLeft: '0.5em',
	},
	sectionName: {
		textTransform: 'uppercase',
		textAlign: 'left',
	},
	scrollable: {
		overflowX: 'scroll',
		display: 'flex',
		height: `calc(${ProjectIconDiameter} + 6em)`,
	},
}

export { MyProfile };