import React, { Component } from 'react';
import { Colors, GetLabel, FormatDateShort, ButtonSize, GetProjectById, GetPercentageFromStage, 
	AmIAllowedToExecuteThis, GetUserNameById, Today, PushNewProject, ReplaceProjectInGlobalById,
	DeleteProjectInGlobalById, Clone, ObjectToArray, DefaultUser, IsNullOrEmpty, DirtyStyle, 
	SelectFile, GetProjectKey, IsAnObjectArray, PadNumber, GetHighestIdFromObjectsList,
	DefaultPadNumber, DateFormat, SetValue, Alert } from '../../constants';
import { Header, Parameter, Div, LoadingScreen, WideButton, Separator, Label, ProjectIcon, Subheader, 
	Space, BulletPointsList, DropDown, ConfirmationButtons, InputField, DaySelector, ListEditor, Image } from '../../components';
import { UploadFile, SendPushNotification } from '../../firebase';

class ProjectEditor extends Component {

	state = {
		id: -1, 
		isLoading: false,
		
		balanceScore: '',
		deadline: Today(),
		desirable: [],
		feasible: [],
		icon: '',
		kickOff: Today(),
		leader: '',//DefaultUser(),
		name: '',
		objectives: [],
		quarterback: '',//DefaultUser(),
		scrumComments: '',
		scrumMaster: '',//DefaultUser(),
		semaphore: 'green',
		sprints: {},
		stage: 'frame',
		viable: [],
		iconFile: '',

		dirtyName: false,
		dirtyScrumMaster: false,
		dirtyLeader: false,
		dirtyDeadline: false,
		dirtyKickOff: false,
		dirtyProjectStage: false,
		dirtyQuarterback: false,
		dirtyObjectives: false,
		dirtyDesirable: false,
		dirtyFeasible: false,
		dirtyViable: false,
		dirtyIcon: false,
	}

	componentDidMount() {
		let { project } = this.props.navParams;
		this.setState({
			deadline: project.deadline
		});
	}

	newProject() {
		let { id, name, balanceScore, desirable, scrumMaster, stage,
			quarterback, objectives, leader, icon, feasible,
			viable,	scrumComments, semaphore, sprints } = this.state;
		
		console.log('leader: ',leader);

		let projectObject = {
			id: id, 
			balanceScore: balanceScore,
			deadline: Today(),/*{
				item_00000: {
					id: 0,
					dateUpdated: Today(DateFormat+' HH:mm'), //Date and time of the update
					updatedBy: global.profile.id,
					deadline: Today(),
				}
			},*/
			desirable: desirable,
			feasible: feasible,
			icon: icon,
			kickOff: Today(),
			leader: leader,
			name: name,
			objectives: objectives,
			quarterback: quarterback,
			scrumComments: scrumComments,
			scrumMaster: scrumMaster,
			semaphore: semaphore,
			sprints: sprints,
			stage: stage,
			viable: viable,
		};

		return projectObject;
	}

	newProjectFromState() {
		let { name, desirable, scrumMaster, stage, viable, deadline, 
			quarterback, objectives, leader, icon, feasible, kickOff,
			dirtyName, dirtyScrumMaster, dirtyLeader, dirtyDeadline, dirtyProjectStage,
			dirtyKickOff, dirtyQuarterback, dirtyObjectives, dirtyDesirable,
			dirtyFeasible, dirtyViable, dirtyIcon } = this.state;
		
		let projectObject = {};

		if(dirtyName) projectObject.name = name;
		if(dirtyScrumMaster) projectObject.scrumMaster = scrumMaster;
		if(dirtyLeader) projectObject.leader = leader;
		//if(dirtyDeadline) projectObject.deadline = Clone(deadline);
		if(dirtyProjectStage) projectObject.stage = stage;
		if(dirtyKickOff) projectObject.kickOff = kickOff;
		if(dirtyQuarterback) projectObject.quarterback = quarterback;
		if(dirtyObjectives) projectObject.objectives = objectives;
		if(dirtyDesirable) projectObject.desirable = desirable;
		if(dirtyFeasible) projectObject.feasible = feasible;
		if(dirtyViable) projectObject.viable = viable;
		if(dirtyIcon) projectObject.icon = icon;

		return projectObject;
	}

	renderSaveButtonIfDirty() {
		let { eventId, project } = this.props.navParams;
		let { dirtyName, dirtyScrumMaster, dirtyLeader, dirtyDeadline, dirtyProjectStage,
			dirtyKickOff, dirtyQuarterback, dirtyObjectives, dirtyDesirable,
			dirtyFeasible, dirtyViable, dirtyIcon } = this.state;
		
		let isDirty = (dirtyName || dirtyScrumMaster || dirtyLeader || dirtyDeadline || 
			dirtyProjectStage || dirtyKickOff || dirtyQuarterback || dirtyObjectives ||
			dirtyDesirable || dirtyFeasible || dirtyViable || dirtyIcon);

		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: eventId, project: project, onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	getNewDeadlineObject(project) {
		let deadline = {};
		if(typeof project.deadline === 'string' || IsNullOrEmpty(project.deadline)) {
			//Execute refactor
			//if(IsNullOrEmpty(project.initialDeadline))
			//;//Clone(this.state.deadline);
			deadline['item_00000'] = {
				id: 0,
				dateUpdated: Today(DateFormat+' HH:mm'), //Date and time of the update
				updatedBy: global.profile.id,
				deadline: IsNullOrEmpty(this.state.deadline) ? Today() : this.state.deadline
			};
		}
		else {
			if(!this.state.dirtyDeadline)
				return project.deadline;

			let nextId = GetHighestIdFromObjectsList(project.deadline) + 1;
			deadline = Clone(project.deadline);
			deadline['item_'+PadNumber(nextId, DefaultPadNumber)] = {
				id: nextId,
				dateUpdated: Today(DateFormat+' HH:mm'), //Date and time of the update
				updatedBy: global.profile.id,
				deadline: this.state.deadline
			};
		}
		return deadline;
	}

	saveChanges() {
		// if (this.state.leader === '' || 
		// 	this.state.quarterback === '' ||
		// 	this.state.scrumMaster === '') {
		// 	Alert(GetLabel('leaderQuarterbackScrumMasterCannotBeEmpty'));
		// 	return;
		// }

		this.setState({isLoading: true });

		let { project } = this.props.navParams;
		project = GetProjectById(project.id);

		if(project === undefined || project === null){
			project = {...this.newProject()};
			project.kickOff = Clone(this.state.kickOff);
		}
		else
			project = {...project, ...this.newProjectFromState()};
		
		project.deadline = this.getNewDeadlineObject(project);
		if(!IsNullOrEmpty(project.initialDeadline))
			delete project.initialDeadline;

		if(project.id === -1) {
			//console.log('NEW project to upload: ',project);
			PushNewProject(project, this.state.dirtyIcon, this.state.iconFile, 
				() => { this.props.navParams.project = Clone(project); this.clearDirtyState(); }, 
				() => this.setState({ isLoading: false }));
		}
		else {
			if(this.state.dirtyDeadline) {
				SendPushNotification({
					messageTitle: project.name,
					messageBody: SetValue(GetLabel('deadlineChanged'), project.name),
					projectLeader: undefined,
					projectScrumMaster: project.scrumMaster,
					projectQuarterback: undefined,
					targetUsers: ['rank_00002', 'rank_00003', 'rank_00004'],
				});
			}
			if(this.state.dirtyLeader || this.state.dirtyScrumMaster || this.state.dirtyQuarterback) {
				SendPushNotification({
					messageTitle: project.name,
					messageBody: SetValue(GetLabel('teamChanged'), project.name),
					projectLeader: project.leader,
					projectScrumMaster: project.scrumMaster,
					projectQuarterback: undefined,
					targetUsers: ['rank_00003', 'rank_00004'],
				});
			}

			if(this.state.dirtyIcon) {
				UploadFile(
					'projects/'+GetProjectKey(project)+'/icon', 
					this.state.iconFile,
					(imageURL) => { 
						project.icon = imageURL;
						ReplaceProjectInGlobalById(project, 
							() => { this.props.navParams.project = Clone(project); this.clearDirtyState(); }, 
							() => this.setState({ isLoading: false }));
					}, 
					() => { this.setState({ isLoading: false }); }
				);
			}
			else {
				ReplaceProjectInGlobalById(project, 
					() => { this.props.navParams.project = Clone(project); this.clearDirtyState(); }, 
					() => this.setState({ isLoading: false }));
			}
		}
	}

	clearDirtyState() {
		this.setState({
			isLoading: false,
			balanceScore: '',
			deadline: Today(),
			desirable: [],
			feasible: [],
			icon: '',
			kickOff: Today(),
			leader: DefaultUser(),
			name: '',
			objectives: [],
			quarterback: DefaultUser(),
			scrumComments: '',
			scrumMaster: DefaultUser(),
			semaphore: 'green',
			sprints: {},
			stage: 'frame',
			viable: [],
			iconFile: '',

			dirtyName: false,
			dirtyScrumMaster: false,
			dirtyLeader: false,
			dirtyDeadline: false,
			dirtyKickOff: false,
			dirtyProjectStage: false,
			dirtyQuarterback: false,
			dirtyObjectives: false,
			dirtyDesirable: false,
			dirtyFeasible: false,
			dirtyViable: false,
			dirtyIcon: false,
		});
	}

	deleteProject() {
		let confirmation = window.confirm(GetLabel('deleteProjectConfirmation'));
		if(confirmation) {
			let { project } = this.props.navParams;
			project = GetProjectById(project.id);
			console.log('project to be deleted: ',project);
			DeleteProjectInGlobalById(project, ()=>{}, ()=>{});
		}
	}

	renderHeaderDependingOnProject() {
		let { project } = this.props.navParams;
		project = GetProjectById(project.id);

		if(project === undefined || project === null)
			return (
				<Header text={GetLabel('projectEditor')} 
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
			);
		else
			return (
				<Header text={GetLabel('projectEditor')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					buttonRight={{id: 'event_00014', project: project, icon: 'img/sys/delete.png', onClick: ()=>this.deleteProject()}}/>
			);
	}

	selectIcon() {
		SelectFile((selectedFile, content, imageURL) => {
			this.setState({
				dirtyIcon: true, 
				icon: imageURL,
				iconFile: selectedFile,
			}); 
		});
	}

	getUsersSortedAlphabetically() {
		let usersArray = ObjectToArray(Clone(global.users));
		usersArray.sort((a, b) => {
			let aName = GetLabel(a.name);
			let bName = GetLabel(b.name);

			if (aName > bName)
		        return 1;
		    if (bName > aName)
		        return -1;
		    return 0;
		});
		usersArray = usersArray.filter((user) => {
			return (user.isHidden === false || user.isHidden === undefined || user.isHidden === null);
		});
		return usersArray;
	}

	updateDeadline(newValue) {
		this.setState({
			dirtyDeadline: true, 
			deadline: newValue
		});
	}

	getProjectDeadline(project) {
		if(IsAnObjectArray(project.deadline)) {
			if(this.state.dirtyDeadline)
				return this.state.deadline;
			else {
				let lastDeadlineId = 'item_'+PadNumber(GetHighestIdFromObjectsList(project.deadline), DefaultPadNumber);
				return project.deadline[lastDeadlineId].deadline;
			}
		}
		else
			return (this.state.dirtyDeadline) ? this.state.deadline : project.deadline;
	}

	render() {
		let { project, eventId } = this.props.navParams;
		project = GetProjectById(project.id);
		
		if(project === undefined || project === null)
			project = this.newProject();

		let projectName = (this.state.dirtyName) ? this.state.name : project.name;
		let projectLeader = (this.state.dirtyLeader) ? this.state.leader : project.leader;
		let projectScrumMaster = (this.state.dirtyScrumMaster) ? this.state.scrumMaster : project.scrumMaster;
		let projectQuarterback = (this.state.dirtyQuarterback) ? this.state.quarterback : project.quarterback;
		let projectStage = (this.state.dirtyProjectStage) ? this.state.stage : project.stage;
		let projectDeadline = this.getProjectDeadline(project);//
		let projectKickOff = (this.state.dirtyKickOff) ? this.state.kickOff : project.kickOff;
		let projectIcon = (this.state.dirtyIcon) ? this.state.icon : project.icon;

		let projectObjectives = (this.state.dirtyObjectives) ? this.state.objectives : project.objectives;
		let projectDesirable = (this.state.dirtyDesirable) ? this.state.desirable : project.desirable;
		let projectFeasible = (this.state.dirtyFeasible) ? this.state.feasible : project.feasible;
		let projectViable = (this.state.dirtyViable) ? this.state.viable : project.viable;

		projectObjectives = IsNullOrEmpty(projectObjectives) ? [] : projectObjectives;
		projectDesirable = IsNullOrEmpty(projectDesirable) ? [] : projectDesirable;
		projectFeasible = IsNullOrEmpty(projectFeasible) ? [] : projectFeasible;
		projectViable = IsNullOrEmpty(projectViable) ? [] : projectViable;

		let className = (this.props.className) ? this.props.className : 'moveIn';
		let projectClone = Clone(project);
		projectClone.icon = projectIcon;

		return (
			<div style={css.container} className={className}>
				{ this.renderHeaderDependingOnProject() }
				<Div noVerticalPadding>
					<div style={css.projectHeaderContainer}>
						<ProjectIcon project={projectClone} style={css.projectIcon} verticalLines={false} disableHover={true}
							event={{id: 'event_00021', project: projectClone, onClick: () => this.selectIcon()}}>
							<Image src={'img/sys/edit.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize} 
								style={css.editIcon}/>
						</ProjectIcon>
					</div>
					<InputField onChange={ 
							(newValue) => {
								if(AmIAllowedToExecuteThis('event_00021', project))
									this.setState({dirtyName: true, name: newValue})
							}
						}
						disabled={!AmIAllowedToExecuteThis('event_00021', project)}
						placeholder={GetLabel('projectName')} value={projectName}/>

					<Subheader text={GetLabel('leader')}/>
					<DropDown 
						value={`${projectLeader}`}
						optionsAreObjects={true}
						options={ this.getUsersSortedAlphabetically() }
						editable={AmIAllowedToExecuteThis('event_00019', project)}
						event={{id: 'event_00019', project: project, onClick: (newValue)=>{ this.setState({dirtyLeader: true, leader: newValue}) }}}/>

					<Subheader text={GetLabel('scrumMaster')}/>
					<DropDown 
						value={`${projectScrumMaster}`}
						optionsAreObjects={true}
						options={ this.getUsersSortedAlphabetically() }
						editable={AmIAllowedToExecuteThis('event_00019', project)}
						event={{id: 'event_00019', project: project, onClick: (newValue)=>{ this.setState({dirtyScrumMaster: true, scrumMaster: newValue}) }}}/>

					<Subheader text={GetLabel('quarterback')}/>
					<DropDown 
						value={`${projectQuarterback}`}
						optionsAreObjects={true}
						options={ this.getUsersSortedAlphabetically() }
						editable={AmIAllowedToExecuteThis('event_00019', project)}
						event={{id: 'event_00019', project: project, onClick: (newValue)=>{ this.setState({dirtyQuarterback: true, quarterback: newValue}) }}}/>
					{/*<DropDown 
						value={`${projectStage}`}
						options={['frame', 'understand', 'experiment', 'scale', 'closed']}
						editable={AmIAllowedToExecuteThis(eventId)}
						event={{id: eventId, onClick: (newValue)=>{ this.setState({dirtyProjectStage: true, stage: newValue}) }}}/>*/}
					<Space/>
					<Separator style={{position: 'absolute', left: '0',}}/>
					<Space/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('kickOff')}>
						<DaySelector event={{id: 'event_00020', project: project, onModify: (newValue)=>{ 
							this.setState({dirtyKickOff: true, kickOff: newValue}) }}}
							value={projectKickOff} />
					</Parameter>

					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')}>
						<DaySelector event={{id: 'event_00020', project: project, onModify: (newValue)=>{ 
							this.updateDeadline(newValue) }}}
							value={projectDeadline} />
					</Parameter><Space/>

					<Separator style={{position: 'absolute', left: '0',}}/>
					<Space/>
					
					<Subheader text={GetLabel('projectObjectives')} labelStyle={{color: Colors.secondary}}/>
					<ListEditor list={projectObjectives} placeholder={GetLabel('addNewObjective')} eventId={'event_00024'} project={project}
						onModify={(newList) => {this.setState({dirtyObjectives: true, objectives: newList})}}/>
					<Space/><Space/>

					<Subheader text={GetLabel('desirable')} labelStyle={{color: Colors.secondary}}/>
					<ListEditor list={projectDesirable} placeholder={GetLabel('addNewDesirable')} eventId={'event_00025'} project={project}
						onModify={(newList) => {this.setState({dirtyDesirable: true, desirable: newList})}}/>
					<Space/><Space/>

					<Subheader text={GetLabel('feasible')} labelStyle={{color: Colors.secondary}}/>
					<ListEditor list={projectFeasible} placeholder={GetLabel('addNewFeasible')} eventId={'event_00025'} project={project}
						onModify={(newList) => {this.setState({dirtyFeasible: true, feasible: newList})}}/>
					<Space/><Space/>

					<Subheader text={GetLabel('viable')} labelStyle={{color: Colors.secondary}}/>
					<ListEditor list={projectViable} placeholder={GetLabel('addNewViable')} eventId={'event_00025'} project={project}
						onModify={(newList) => {this.setState({dirtyViable: true, viable: newList})}}/>
					<Space/><Space/>

					<Separator style={{position: 'absolute', left: '0',}}/>
					<Space/>

					{ this.renderSaveButtonIfDirty() }
					
					<Space/>
					<Space/>
					<Space/>
					<Space/>
					<Space/>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	},
	projectIcon: {
		maxWidth: '30%',
		padding: '0',
	},
	projectHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	editIcon: {
		position: 'absolute',
		left: 'calc(50vw + 3.5em)',
		marginTop: '4.2em',
	},
}

export { ProjectEditor };