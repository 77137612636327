import React, { Component } from 'react';
import { Colors, ClampLengthAt, LargeButtonSize, ButtonSize, FormatDateShortest, GetSprintStatusIcon } from '../constants';
import { Image, Label, Subheader } from './';

class SprintInRow extends Component {

	renderHelpFlagIfNeeded(sprint) {
		if(sprint.helpNeeded)
			return (
				<Image src={'img/sys/flag.png'} color={Colors.red} style={{...css.flag, ...this.props.flagStyle}}
					height={ButtonSize} width={ButtonSize}/>
			);
		else
			return undefined;
	}

	renderSprintIcon(sprint) {
		let iconAndColor = GetSprintStatusIcon(sprint.status);
		return (
			<Image src={iconAndColor.icon} color={iconAndColor.color} 
				height={LargeButtonSize} width={LargeButtonSize} style={{minWidth: `calc(${LargeButtonSize} / 2)`}}/>
		);
	}

	render() {
		let { sprint } = this.props;
		return (
			<div style={css.container}>
				{ this.renderSprintIcon(sprint) }
				<div style={css.sprintDetailsOuterContainer}>
					<div style={css.sprintDetailsInnerContainer}>
						<Subheader text={sprint.title} style={css.sprintsLabels} labelStyle={this.props.titleLabelStyle}/>
						<Label text={ClampLengthAt(sprint.description, 60)} style={css.sprintsLabels} 
							labelStyle={this.props.labelStyle}/>
					</div>
					<div style={css.sprintDetailsInnerDateAndFlag}>
						<Label text={FormatDateShortest(sprint.deadline, '/', true)} style={css.sprintDate} 
							labelStyle={this.props.labelStyle}/>
						{ this.renderHelpFlagIfNeeded(sprint) }
					</div>
				</div>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		margin: '0',
		backgroundColor: 'transparent',
		border: `none`,
		paddingTop: '1em',
		paddingBottom: '1em',
		paddingLeft: '2em',
		paddingRight: '2em',
		//height: '6em',
		textAlign: 'left',


		
		flexDirection: 'row',
		marginTop: '0.5em', 
		marginBottom: '1.5em',
		padding: '0',
		height: 'initial',
		width: '100%',
	},
	sprintDetailsInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flex: '0.75',
	},
	sprintDetailsInnerDateAndFlag: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		flex: '0.25',
	},
	sprintDetailsOuterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		//height: '5em',
	},
	sprintsLabels: {
		paddingTop: '0.2em',
		paddingBottom: '0.2em',
		marginTop: '0.2em',
		marginBottom: '0.2em',
		width: '100%',
	},
	flag: {
	    position: 'absolute',
    	marginTop: '1.7em',
        marginLeft: '0.8em',
	},
	sprintDate: {
		paddingTop: '0.2em',
		paddingRight: '0',
		paddingBottom: '0',
		marginTop: '0.5em',
		marginRight: '0',
		marginBottom: '0',
	},
}

export { SprintInRow };