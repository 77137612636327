import React, { Component } from 'react';
import { Colors } from '../constants';
import './Loading.css';
//https://tobiasahlin.com/spinkit/

class Loading extends Component {
	render() {
		return (
			<div className={"spinner"}>
				<div className={"cube1"} style={css.squares}/>
				<div className={"cube2"} style={css.squares}/>
				<div className={"cube3"} style={css.squares}/>
				<div className={"cube4"} style={css.squares}/>
			</div>
		);
	}
}

const css = {
	squares: {
		background: Colors.primary
	}
}

export { Loading };