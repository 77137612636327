import React, { Component } from 'react';
import { Colors, HeadersSize, IsNullOrEmpty } from '../constants';
import { Label, Image, Button } from './';

class Profile extends Component{

	renderProfileInfo() {
		if(IsNullOrEmpty(this.props.name) && IsNullOrEmpty(this.props.children))
			return undefined;

		let jsxName = IsNullOrEmpty(this.props.name) ? undefined : 
			(<Label text={this.props.name} style={css.profileName} labelStyle={{...css.profileNameLabel, ...this.props.labelStyle}}/>);

		return (
			<div style={css.profileInfo}>
				{ jsxName }
				{ this.props.children }
			</div>
		);
	}

	render() {
		let image = IsNullOrEmpty(this.props.image) ? 'img/sys/user.png' : this.props.image;
		return (
			<Button disableHover={this.props.disableHover} style={{width: '100%'}}
				buttonStyle={{...css.button, ...this.props.style}} event={this.props.event} className={'buttonLarge'}>
				<div style={css.imageContainer}>
					{/*<img src={this.props.image} style={{width: '6em'}} alt=''/>*/}
					<Image src={image} color={Colors.primary} 
						width={'5.8em'} height={'5.8em'} style={{marginLeft: '0.1em', marginTop: '0.1em'}}/>
				</div>
				{ this.renderProfileInfo() }
			</Button>
		);
	}
}

const css = {
	button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		margin: '0',
		background: `linear-gradient(-45deg, ${Colors.background_1} 0,${Colors.background_1} 65%,${Colors.background_2} 0,${Colors.background_2})`,
		border: 'none',
		paddingTop: '0em',
		paddingBottom: '0em',
		paddingLeft: '2vw',
		paddingRight: '2vw',
		color: Colors.primary,
		fontSize: '1rem',
		letterSpacing: '2px',
		width: '100%',
		minHeight: 'calc(6em + 7vh)',//11em
	},
	imageContainer: {
		width: '6em',
		minWidth: '6em',
		height: '6em',
		minHeight: '6em',
		backgroundColor: Colors.background_2,
		borderRadius: '50%',
		overflow: 'hidden',
		border: `3px solid ${Colors.primary}`,
		marginLeft: '1rem'
	},
	profileName: {
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		textAlign: 'left',
	},
	profileNameLabel: {
		fontSize: HeadersSize,
		lineHeight: '2em',
		textTransform: 'uppercase',
		maxWidth: '50vw',
	},
	icon: {
		position: 'absolute',
		marginTop: '-0.3em',
		marginLeft: '-0.5em'
	},
	profileInfo: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '0.5rem',
		// paddingRight: '3',
		alignItems: 'flex-start',
	}
}

export { Profile };
