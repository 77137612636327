const LocalStorage = require('local-storage');

export const IsMobileProject = false;
//import { IsMobileProject } from '../Main';

//https://codepen.io/sosuke/pen/Pjoqqp
export const Icon = 'https://code91.mx/images/logo/small_logo_f30.png';

export const LocalDomains = ["localhost", "127.0.0.1"];
export const KeepUserSignedIn = IsLocalHost();
export const Environment = 'production';//testing//development//production
export const AreaDatabase = 'ddt_'+Environment;
export const UploadLocalLabels = true;
export const UploadLocalEvents = false;
export const MaxNumberOfMinutas = 6;
export const SecondsOfInactivityBeforeForcedLogOut = 7200;
export const ShowInactiveTimerAt = 60;
export const MaxSprintsToRenderInProjectDetails = 5;

export const DefaultPadNumber = 5;//The number of '0' that a events key has padded in the left in realtimeDB
export const MaximumNumberOfSprintsToShowInPresentationMode = 4;
/*
 * Fonts Related
 */
export const FontName = 'Exo';
export let MobileMarginTop = 30; 
export let MenuButtons = (IsMobileProject) ? 24 : '1.1rem';
export let HeadersSize = (IsMobileProject) ? 22 : '1.1rem';
export let SubHeadersSize = (IsMobileProject) ? 20 : '1rem';
export let MainFontSize = (IsMobileProject) ? 16 : '0.9rem';
export let SmallFontSize = (IsMobileProject) ? 14 : '0.8rem';
export let DebugLabelsSize = (IsMobileProject) ? 14 : '12px';

export let PresentationHeaders = (IsMobileProject) ? 12 : '1.6rem';
export let PresentationSubheaders = (IsMobileProject) ? 12 : '1.5rem';
export let PresentationText = (IsMobileProject) ? 12 : '1.4rem';

export let HorizontalPadding = (IsMobileProject) ? 30 : '7vw';
export let VerticalPadding = (IsMobileProject) ? 50 : '4vh';

export const SeparatorWidth = (IsMobileProject) ? 2 : '2px';
export const SeparatorWidthSmall = (IsMobileProject) ? 2 : '1px';
export const SpaceHeight = (IsMobileProject) ? 35 : '2em';

export const SmallButtonSize = (IsMobileProject) ? 12 : '1em';
export const MediumButtonSize = (IsMobileProject) ? 16 : '3em';
export const ButtonSize = (IsMobileProject) ? 52 : '4em';
export const LargeButtonSize = (IsMobileProject) ? 70 : '6em';
export const ProjectSmallIconDiameter = (IsMobileProject) ? 45 : '7em';
export const ProjectIconDiameter = (IsMobileProject) ? 60 : '7em';
export const ButtonHeight = (IsMobileProject) ? 55 : '4em';

export const DateFormat = 'DD/MM/YYYY';
export const DateTimeFormat = 'DD/MM/YYYY HH:mm';

global.admin = {};
const initialColors = {
	primary: '#ffbb00',//'#ffbb00',
	primaryDarker: '#906E11',
	secondary: '#ffffff',
	background_1: '#222222',
	background_2: '#161616',
	disabled: "#666666",
	buttonBackground: '#39321E',
	black: '#000000',
	transparent: '#00000000',
	green: '#6EDA12',
	red: '#CF0021',
	debugLabels: '#00ffff',
}

function GetColorPalette() {
	let jsonAdmin = LocalStorage('admin');
	let colorsInLocalStorage = undefined;

	if(jsonAdmin) {
		jsonAdmin = JSON.parse(jsonAdmin);
		colorsInLocalStorage = jsonAdmin.colors;
	}

	let colorsUsed =  (colorsInLocalStorage) ? colorsInLocalStorage : initialColors;
	return (global.admin.colors) ? global.admin.colors : colorsUsed;
}

export const FixedColors = {
	red: '#E53142',
	green: '#26C184',
	purple: '#A66DF5',
	blue: '#1C9EEC',
	gray: '#686E73',
	black: '#000000',
	orange: '#F08230',
}

export const Colors = {
	get primary() {
		return GetColorPalette().primary;
	},
	get primaryDarker() {
		return GetColorPalette().primaryDarker;
	},
	get secondary() {
		return GetColorPalette().secondary;
	},
	get background_1() {
		return GetColorPalette().background_1;
	},
	get background_2() {
		return GetColorPalette().background_2;
	},
	get disabled() {
		return GetColorPalette().disabled;
	},
	get buttonBackground() {
		return GetColorPalette().buttonBackground;
	},
	get transparent() {
		return GetColorPalette().transparent;
	},
	get green() {
		return GetColorPalette().green;
	},
	get red() {
		return GetColorPalette().red;
	},
	get debugLabels() {
		return GetColorPalette().debugLabels;
	},
};

// export const Colors = {
// 	primary: '#ffbb00',
// 	primaryDarker: '#906E11',
// 	secondary: '#ffffff',
// 	background_1: '#222222',
// 	background_2: '#161616',
// 	disabled: "#666666",
// 	buttonBackground: '#39321E',
// 	black: '#000000',
// 	transparent: '#00000000',
// 	green: '#6EDA12',
// 	red: '#CF0021',
// 	debugLabels: '#00ffff',
// };

export const ScreenStyle = {
	height: '100%', 
	backgroundColor: Colors.background_1, 
	zIndex: 20, 
	paddingTop: MobileMarginTop
};

export const DefaultRank = () => {
	return Object.keys(global.ranks)[0];
}
export const DefaultDomainName = () => {
	return Object.keys(global.domainNames)[0];
}

export const DefaultUser = () => {
	let firstKey = Object.keys(global.users)[0];
	return global.users[firstKey].id;
}

export const DirtyStyle = {
	background: Colors.green+'55', 
	width: '100%', 
	maxWidth: '100%',
	borderBottomLeftRadius: '1em',
	borderBottomRightRadius: '1em',

	overflow: 'hidden',
	paddingLeft: '100px', 
	paddingRight: '100px', 
	marginLeft: '-100px',
	borderRadius: '1em',
	marginTop: '3em',
}

export function IsLocalHost() {

	if(window === undefined || window === null)
		return false;
	else if (window.location === undefined || window.location === null)
		return false;
	else {
		let isLocalHost = LocalDomains.includes(window.location.hostname);

		isLocalHost = (isLocalHost) ? isLocalHost : 
			(
				window.location.hostname === 'localhost' || 
				window.location.hostname === '[::1]' || 
				window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
			);
		
		return isLocalHost;
	}
	
}