import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, AmIAllowedToExecuteThis, Colors, GetSprintById, GetActivityById,
	ReplaceActivityInGlobalById, GetProjectById, PushNewActivity, Today, DeleteActivityInGlobalById, 
	DirtyStyle } from '../../constants';
import { Header, Separator, Label, ProjectIcon, InputField, Div, WideButton, DropDown, DaySelector, 
	Checkbox, Parameter, Space, ConfirmationButtons } from '../../components';

class ActivityEditor extends Component {

	state = {
		id: -1, 
		isLoading: false,

		title: '',
		description: '',
		deadline: Today(),
		completed: false,

		dirtyTitle: false,
		dirtyDescription: false,
		dirtyDeadline: false,
		dirtyCompleted: false,
	}

	newActivityFromState() {
		let { title, description, deadline, completed, dirtyCompleted,
			dirtyTitle, dirtyDescription, dirtyDeadline } = this.state;
		let activityObject = {};

		if(dirtyTitle)
			activityObject.title = title;
		if(dirtyDescription)
			activityObject.description = description;
		if(dirtyDeadline)
			activityObject.deadline = deadline;
		if(dirtyCompleted)
			activityObject.completed = completed;

		return activityObject;
	}

	newActivity() {
		let { id, title, description, deadline, completed,
			dirtyTitle, dirtyDescription, dirtyDeadline, dirtyCompleted } = this.state;
		
		let activityObject = {};

		activityObject.id = id;
		activityObject.title = title;
		activityObject.description = description;
		activityObject.deadline = deadline;
		activityObject.completed = completed;

		return activityObject;
	}

	saveChanges() {
		this.setState({isLoading: true });

		let { sprint, project, activity } = this.props.navParams;

		project = {...GetProjectById(project.id)};
		sprint = GetSprintById(project, sprint);
		activity = GetActivityById(project, sprint, activity);

		if(activity === undefined || activity === null)
			activity = {...this.newActivity()};
		else
			activity = {...activity, ...this.newActivityFromState()};

		if(activity.id === -1)
			PushNewActivity(project, sprint, activity,
				() => { this.props.navParams.activity = {...activity}; this.clearDirtyState(); }, 
				() => this.setState({ isLoading: false }));
		else	
			ReplaceActivityInGlobalById(project, sprint, activity,
				() => { this.props.navParams.activity = {...activity}; this.clearDirtyState(); }, 
				() => this.setState({ isLoading: false }));
	}

	clearDirtyState() {
		this.setState({
			isLoading: false,
			title: '',
			description: '',
			deadline: Today(),
			completed: false,

			dirtyTitle: false,
			dirtyDescription: false,
			dirtyDeadline: false,
			dirtyCompleted: false,
		});
	}

	renderSaveButtonIfDirty() {
		let { eventId, project } = this.props.navParams;
		let { dirtyTitle, dirtyDescription, dirtyDeadline, dirtyCompleted } = this.state;
		
		let isDirty = (dirtyTitle || dirtyDescription || dirtyDeadline || dirtyCompleted);

		if(isDirty)
			return (
				<div style={DirtyStyle}>
					<Space/>
					<ConfirmationButtons isLoading={this.state.isLoading}
						eventYes={{id: eventId, project: project, onClick: ()=>this.saveChanges(), 
							icon: 'img/sys/save.png', label: GetLabel('yes')}}
						eventNo={{id: 'event_00000', onClick: ()=>this.clearDirtyState(), 
							icon: 'img/sys/exit.png', label: GetLabel('no')}}/>
					<Space/>
				</div>
			);
		else
			return <div />;
	}

	deleteActivity() {
		let confirmation = window.confirm(GetLabel('deleteActivityConfirmation'));
		if(confirmation) {
			let { activity, sprint, project } = this.props.navParams;
			project = GetProjectById(project.id);
			sprint = GetSprintById(project, sprint);
			activity = GetActivityById(project, sprint, activity);

			console.log('activity to be deleted: ',activity);
			DeleteActivityInGlobalById(project, sprint, activity, ()=>{}, ()=>{});
		}
	}

	renderHeaderDependingOnActivity() {
		let { sprint, project, activity } = this.props.navParams;
		project = GetProjectById(project.id);
		sprint = GetSprintById(project, sprint);
		
		activity = GetActivityById(project, sprint, activity);

		if(activity === undefined || activity === null)
			return (
				<Header text={GetLabel('activityEditor')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
			);
		else
			return (
				<Header text={GetLabel('activityEditor')}
					buttonLeft={{id: 'event_00000', icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}
					buttonRight={{id: 'event_00018', project: project, icon: 'img/sys/delete.png', onClick: ()=>this.deleteActivity()}}/>
			);
	}

	render() {
		let { sprint, project, activity } = this.props.navParams;
		project = GetProjectById(project.id);

		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		sprint = GetSprintById(project, sprint);

		if(!sprint) {
			this.props.goBack();
			return <div/>;
		}

		if(activity === undefined || activity === null)
			activity = this.newActivity();			

		console.log('activity: ',activity);

		let activityTitle = (this.state.dirtyTitle) ? this.state.title : activity.title;
		let activityDescription = (this.state.dirtyDescription) ? this.state.description : activity.description;
		let activityDeadline = (this.state.dirtyDeadline) ? this.state.deadline : activity.deadline;
		let activityCompleted = (this.state.dirtyCompleted) ? this.state.completed : activity.completed;

		return (
			<div style={{height: '100%'}}>
				{ this.renderHeaderDependingOnActivity() }
				<Div noVerticalPadding>
					<InputField onChange={ (newValue) => {this.setState({dirtyTitle: true, title: newValue})} }
						placeholder={GetLabel('activityTitle')} value={activityTitle}/>
					<InputField onChange={ (newValue) => {this.setState({dirtyDescription: true, description: newValue})} }
						multiline={true} placeholder={GetLabel('activityDescription')} value={activityDescription}/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')}>
						<DaySelector event={{id: 'event_00012', project: project, onModify: (newValue)=>{ this.setState({dirtyDeadline: true, deadline: newValue}) }}}
							value={activityDeadline} />
					</Parameter>
					<Space />
					{ this.renderSaveButtonIfDirty() }
					
					<Space/><Space/>
					<Space/><Space/>
					<Space/><Space/>
				</Div>
			</div>
		);
	}
	/*
	render() {
		let { activity, sprint, project, eventId } = this.props.navParams;

		project = GetProjectById(project.id);

		if(!project) {
			this.props.goBack();
			return <div/>;
		}

		sprint = GetSprintById(project, sprint);

		if(!sprint) {
			this.props.goBack();
			return <div/>;
		}
		
		if(activity === undefined || activity === null)
			activity = this.newActivity();

		return (
			<div style={{height: '100%'}}>
				<Header text={GetLabel('activityEditor')}
					buttonLeft={{id: 0, icon: 'img/sys/back.png', onClick: ()=>this.props.goBack()}}/>
				<Div noVerticalPadding>
					<InputField placeholder={GetLabel('activityTitle')} value={activity.title}/>
					<InputField multiline={true} placeholder={GetLabel('activityDescription')} value={activity.description}/>
					<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')}>
						<DaySelector value={activity.deadline} event={{id: 12, onModify: (modified)=>{}}}/>
					</Parameter>
					<Space />
					<WideButton text={GetLabel('save')} 
						event={{id: eventId, icon:'img/sys/save.png', onClick:()=>this.saveChanges() }}/>
					<Space/><Space/>
					<Space/><Space/>
					<Space/><Space/>
				</Div>
			</div>
		);
	}*/
}

const css = {

}

export { ActivityEditor };