import { GetFullMonth, GetShortMonth, Colors, DateFormat, GetLabel, DefaultPadNumber, 
	PadNumber, Alert, GetHighestIdFromObjectsList, IsNullOrEmpty, Clone, Today, 
	MaxNumberOfMinutas, DateTimeFormat, ObjectToArray } from './';
import { UploadObject, UploadFile, AsyncUploadObject } from '../firebase';
import Moment from 'moment';

export function GetMinutaKey(minuta) {
	return '_'+(minuta.id.replace(/\//g, '_'));
}

export function GetMinutaKeyById(id) {
	return '_'+(id.replace(/\//g, '_'));
}

export function GetMinutaById(minuta) {
	if(IsNullOrEmpty(minuta.id))
		return {};
	else
		return global.minutas[GetMinutaKey(minuta)];
}

export function UploadMinutas(minutas, onSuccess, onFail) {
	UploadObject('minutas', Clone(minutas), 
		()=> {
			global.minutas = Clone(minutas);
			if(onSuccess)
				onSuccess();
		}, 
		()=> {
			console.log('Error while uploading minutas: ',minutas);
			if(onFail)
				onFail();
		}
	);
}

export function PushMinuta(newMinuta, onSuccess, onFail) {
	//project.id = GetHighestIdFromObjectsList(global.projects) + 1;
	let newMinutaKey = GetMinutaKey(newMinuta);
	//console.log('minuta sencilla: ',newMinuta);
	//console.log('newMinutaKey: ',newMinutaKey);
	//console.log('global.minutas: ',global.minutas);
	
	UploadObject('minutas/'+newMinutaKey, Clone(newMinuta), 
		()=> {
			global.minutas[newMinutaKey] = Clone(newMinuta);
			
			if(Object.keys(global.minutas).length > MaxNumberOfMinutas) {
				//Delete the oldest
				let oldest = GetOldestMinuta();
				//console.log('oldest minuta: ',oldest);
				let allKeys = Object.keys(global.minutas)
				//console.log('allKeys: ',allKeys);
				for(let i=0; i<allKeys.length; i++){
					if(global.minutas[allKeys[i]].id === oldest.id) {
						delete global.minutas[allKeys[i]];
						break;
					}
				}
				//console.log('global.minutas updated: ',global.minutas);
				UploadObject('minutas', Clone(global.minutas), onSuccess, onFail);
			}
			else {
				if(onSuccess)
					onSuccess();
			}
		}, 
		onFail
	);
}

export function GetOldestMinuta() {
	let minutaArray = ObjectToArray(global.minutas);

	minutaArray.sort((a, b) => {
		/*
		let aName = GetLabel(a.name);
		let bName = GetLabel(b.name);

		if (aName > bName)
	        return 1;
	    if (bName > aName)
	        return -1;
	    return 0;*/

		let dateA = Moment(a.lastModification, DateTimeFormat);
		let dateB = Moment(b.lastModification, DateTimeFormat);

		if(dateA.diff(dateB, 'minutes') > 0)
			return 1;
		if(dateA.diff(dateB, 'minutes') < 0)
			return -1;
		return 0;
	});

	/*
	Object.keys(global.minutas).forEach((minuta, i) => {
		
		let today = Moment(new Date(), DateFormat);
		
		return today.diff(dateA, 'days') <= 0 && today.diff(dateB, 'days') > 0;
	});*/

	return Clone(minutaArray[0]);
}
