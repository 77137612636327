import React, { Component } from 'react';
import { GetLabel, ProjectIconDiameter, ButtonSize, FormatDateShortest, GetSprintStatusIcon,
	LargeButtonSize, ClampLengthAt, GetPresentSprints, Colors, FormatDateShort, SeparatorWidth, 
	GetUserNameById, Sleep, SmallButtonSize, GetFutureSprints, GetAllActiveProjects, IsNullOrEmpty,
	GetAllSprintsByDate, IsTodayBetweenTheseDates, MaximumNumberOfSprintsToShowInPresentationMode,
	PresentationHeaders, PresentationSubheaders, PresentationText } from '../../constants';
import { Header, Separator, Label, ProjectIcon, Div, LoadingScreen, BulletPointsList, 
	Subheader, Image, Parameter, Space, Button, Checkbox, WideButton, SprintInRow } from '../../components';
import './Presentation.css';
//References:
//https://freefrontend.com/css-slideshows/
//https://codepen.io/Reklino/pen/wawopV

const AnimationDuration = 0.47e3;

class Presentation extends Component {

	state = {
		allProjects: [],
		currentProjectIndex: 0,
		nextProjectIndex: -1,
		loadNextProject: false,
	}

	async componentDidMount() {
		this.setState({
			allProjects: GetAllActiveProjects(),
			loadNextProject: true,
			nextProjectIndex: 0, 
			currentProjectIndex: -1,
		});
		await Sleep(AnimationDuration);
		this.setState({
			loadNextProject: false,
			currentProjectIndex: this.state.nextProjectIndex,
			nextProjectIndex: -1, 
		});
	}

	componentWillReceiveProps() {
		let currentProjects = JSON.stringify(this.state.allProjects);
		let newProjects = JSON.stringify(GetAllActiveProjects());

		if(currentProjects !== newProjects)
			this.setState({allProjects: GetAllActiveProjects()});
	}

	renderBullets(title, bulletsArray) {
		if(IsNullOrEmpty(bulletsArray))
			return <div />;
		return (
			<div>
				<Subheader text={title} labelStyle={css.labelsSubheaders}/>
				<BulletPointsList>
					{
						bulletsArray.map((item, i) => {
							return (<Label text={item} style={css.bullets} key={i} labelStyle={css.labelsText}/>);
						})
					}
				</BulletPointsList>
			</div>
		);
	}
/*
	renderActivities(sprint) {
		if(!sprint.activities)
			return <div/>;
		if(sprint.activities.length > 0)
			return (
				sprint.activities.map((activity, i) => {
					return (
						<Checkbox event={{id: 9, onClick: ()=> {}}} key={i} containerStyle={css.checkbox} style={css.checkbox}
							checked={activity.completed} nonEditable>
							<Subheader text={activity.title} style={css.activitiesElement} labelStyle={css.labelsSubheaders}/>
							<Label text={FormatDateShort(activity.deadline)} style={css.activitiesElement}/>
						</Checkbox>
					);
				})
			);
		else
			return <div/>;
	}

	renderHelpFlagIfNeeded(sprint) {
		if(sprint.helpNeeded)
			return (
				<Parameter 
					icon={'img/sys/help.png'}
					iconColor={Colors.red}
					iconLabel={GetLabel('help')} 
					text={GetLabel('helpNeeded')}/>
			);
		else
			return <div />;
	}

	renderSprint(sprint) {
		if(!sprint)
			return (
				<div style={css.sprintCard}>
					<Subheader text={GetLabel('noSprintsInProject')} style={css.sprintsLabels} labelStyle={css.labelsSubheaders}/>
				</div>
			);

		return (
			<div style={css.sprintCard}>
				<div style={css.sprintRow}>
					<Subheader text={sprint.title} style={css.sprintsLabels} labelStyle={css.labelsSubheaders}/>
				</div>
				<Separator color={'#00000044'} disableGlow/>
				<Parameter icon={'img/sys/calendar.png'} iconLabel={GetLabel('deadline')} 
					text={FormatDateShort(sprint.deadline)}/>
				<Parameter 
					icon={GetSprintStatusIcon(sprint.status).icon} 
					iconColor={GetSprintStatusIcon(sprint.status).color}
					iconLabel={GetLabel('status')} 
					text={GetLabel(sprint.status)}/>
				{ this.renderHelpFlagIfNeeded(sprint) }

				<Separator color={'#00000044'} disableGlow/>

				
				<Subheader text={GetLabel('description')} style={css.sprintsLabels} labelStyle={css.labelsSubheaders}/>
				<Label text={sprint.description} style={css.sprintsLabels}/>
				
				<Separator color={'#00000044'} disableGlow/>
				<Subheader text={GetLabel('activities')} style={css.sprintsLabels} labelStyle={css.labelsSubheaders}/>
				{ this.renderActivities(sprint) }
			</div>
		);
	}*/

	renderColumnRight(project) {
		let sprints = GetAllSprintsByDate(project.sprints);//GetPresentSprints(project.sprints);
		//sprints = (sprints) ? sprints : GetFutureSprints(project.sprints);
		//let sprint = sprints[0];
		let scrumComments = project.scrumComments.split('\n');
		return (
			<div style={css.sprintCenterer}>
				<Subheader text={GetLabel('monthlyScrumComments')} labelStyle={css.labelsSubheaders}/>
				{
					scrumComments.map((item, i) => {
						return <Label key={i} text={item} labelStyle={css.labelsText}/>;
					})
				}
				<Space />
				{ /*this.renderSprint(sprint)*/ }
				{ this.renderSprints(sprints) }
			</div>
		);
	}

	renderSprints(sprints) {
		let jsx = [];
		if(sprints.length === 0)
			jsx = <Label text={GetLabel('sprintsEmpty')} labelStyle={css.labelsText}/>;

		sprints.map((item, i) => {

			if(i < MaximumNumberOfSprintsToShowInPresentationMode) {
				jsx.push( <SprintInRow key={i} sprint={item} labelStyle={css.labelsText} titleLabelStyle={css.labelsSubheaders}/> );

				if((i + 1) < sprints.length)
					if(IsTodayBetweenTheseDates(item.deadline, sprints[i+1].deadline))
						jsx.push(<Separator text={GetLabel('today')}/>);
			}
			
		});

		return (
			<div style={css.sprintsContainer}>
				{ jsx }
			</div>
		);
	}

	async loadPreviousProject() {
		let { currentProjectIndex, allProjects } = this.state;
		let nextIndex = (currentProjectIndex - 1) < 0 ? allProjects.length - 1: currentProjectIndex - 1;
		await this.loadProjectWithIndex(nextIndex);
	}

	async loadNextProject() {
		let { currentProjectIndex, allProjects } = this.state;
		let nextIndex = (currentProjectIndex + 1) > allProjects.length - 1 ? 0 : currentProjectIndex + 1;
		await this.loadProjectWithIndex(nextIndex);
	}

	async loadProjectWithIndex(nextIndex) {
		if(this.state.loadNextProject)
			return;

		this.setState({
			loadNextProject: true,
			nextProjectIndex: nextIndex,//currentProjectIndex + 1,
		});
		await Sleep(AnimationDuration);
		this.setState({
			loadNextProject: false,
			currentProjectIndex: this.state.nextProjectIndex,
			nextProjectIndex: -1,
		});
		this.columnL.scrollTop = 0;
		this.columnR.scrollTop = 0;
	}

	renderPresentationCard(project, type, classColumnLeft, classColumnRight) {
		if(!project)
			return <div className={'empty'}/>;

		return (
			<div style={css.card}>
				<div style={css.columnsContainer}>	
					<div style={css.columnLeft} className={classColumnLeft+' verticalScroll'} ref={(l) => {this.columnL = l}}>
						<Space /><Space />
						<div style={css.cardHeader}>
							<Header style={css.projectName} text={project.name} labelStyle={css.labelsHeaders}/><Space />
							<div style={{display: 'flex', flexDirection: 'row'}} className={'toColumnIfTooTight'}>
								<ProjectIcon project={project} verticalLines/>
								<div>
									<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('leader')} 
										text={GetUserNameById(project.leader)} labelStyle={css.labelsText}/>
									<Parameter icon={'img/sys/user.png'} iconLabel={GetLabel('scrumMaster')} 
										text={GetUserNameById(project.scrumMaster)} labelStyle={css.labelsText}/>
									<Parameter icon={'img/sys/stage.png'} iconLabel={GetLabel('stage')} 
										text={GetLabel(project.stage)} labelStyle={css.labelsText}/>
								</div>
							</div>
						</div><Space />
						{ this.renderBullets(GetLabel('desirable'), project.desirable) }
						{ this.renderBullets(GetLabel('feasible'), project.feasible) }
						{ this.renderBullets(GetLabel('viable'), project.viable) }
						<Space /><Space /><Space /><Space />
					</div>
					<div style={css.columnRight} className={classColumnRight+' verticalScroll'} ref={(r) => {this.columnR = r}}>
						<Space /><Space />
						{ this.renderColumnRight(project) }
						<Space /><Space /><Space /><Space />
					</div>
				</div>
			</div>
		);
	}

	currentProject() {
		return this.state.allProjects[this.state.currentProjectIndex];
	}

	nextProject() {
		return this.state.allProjects[this.state.nextProjectIndex];
	}

	renderNavigationBar() {
		return (
			<div style={css.navBar}>
				<Button event={{id: 'event_00000', onClick: ()=> this.loadPreviousProject()}} buttonStyle={css.arrowButton}>
					<Image src={'img/sys/left.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
					<Label text={GetLabel('previous').toUpperCase()} labelStyle={css.arrowButtonLabel} style={css.noSpaces}/>
				</Button>
				<div style={{display: 'flex', flexDirection: 'row'}}>
				{ 
					this.state.allProjects.map((item, index) => {
						let color = (index === this.state.currentProjectIndex) ? Colors.primary : Colors.disabled;
						if(this.state.nextProjectIndex !== -1) {
							color = (index === this.state.nextProjectIndex) ? Colors.primary : Colors.disabled;
						}

						return (
							<Button key={index} event={{id: 'event_00000', onClick: ()=>this.loadProjectWithIndex(index)}} 
								buttonStyle={css.navigationHexagons}>
								<Image src={'img/sys/hexagon.png'} color={color} 
									width={SmallButtonSize} height={SmallButtonSize}/>
							</Button>
						);
					}) 
				}
				</div>
				<Button event={{id: 'event_00000', onClick: ()=> this.loadNextProject()}} buttonStyle={css.arrowButton}>
					<Label text={GetLabel('next').toUpperCase()} labelStyle={css.arrowButtonLabel} style={css.noSpaces}/>
					<Image src={'img/sys/right.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			</div>
		);
	}

	render() {
		let className = (this.props.className) ? this.props.className : '';
		let { loadNextProject } = this.state;

		if(this.state.allProjects.length === 0)
			return <LoadingScreen />;

		return (
			<div style={{height: '100%'}} className={className}>
				<Header text={''} labelStyle={css.labelsHeaders}
					buttonRight={[
						{id: 'event_00000', icon: 'img/sys/exit.png', onClick: ()=> this.props.goBack()}
					]}/>
				<div style={css.container}>
					{/* this.renderPresentationCard(this.currentProject(), 'current',
						loadNextProject ? 'centerToBottom' : 'topToCenter', 
						loadNextProject ? 'centerToTop' : 'bottomToCenter') */}
					{/* this.renderPresentationCard(this.nextProject(), 'next',
						loadNextProject ? 'centerToBottom' : 'topToCenter', 
						loadNextProject ? 'centerToTop' : 'bottomToCenter') */}

					{ this.renderPresentationCard(this.currentProject(), 'current',
						loadNextProject ? 'centerToBottom' : '', 
						loadNextProject ? 'centerToTop' : '') }
					{ this.renderPresentationCard(this.nextProject(), 'next',
						loadNextProject ? 'topToCenter' : 'centerToBottom', 
						loadNextProject ? 'bottomToCenter' : 'centerToTop') }
				</div>
				{ this.renderNavigationBar() }
			</div>
		);
	}
}

const css = {
	container: {
		height: '100%',
		paddingLeft: '1em',
		paddingRight: '1em',
	},
	scrollable: {
		overflowX: 'scroll',
		display: 'flex',
		height: `calc(${ProjectIconDiameter} + 6em)`,
	},
	columnsContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		width: '100%',
		minWidth: '100%',
		overflowY: 'visible',
	},
	navigationHexagons: {
		padding: '1em',
	},
	labelsSubheaders: {
		fontSize: PresentationSubheaders,
	},
	labelsHeaders: {
		fontSize: PresentationHeaders,
	},
	labelsText: {
		fontSize: PresentationText,
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: `calc(100% - 10em)`,
	},
	projectName: {
		justifyContent: 'center',
		marginLeft: '0em',
	},
	cardHeader: {

	},
	checkbox: {
		marginTop: '0',
		marginBottom: '0',
	},
	columnLeft: {
		position: 'absolute',
		left: '15vw',
		//top: `calc(${ButtonSize} + 6vh)`,
   		//height: `calc(100% - (${ButtonSize} * 3 + 6vh)`,
		maxWidth: 'calc(55% - 15vw)',
		width: 'calc(55% - 15vw)',
   		overflowY: 'scroll',
   		overflowX: 'hidden',

   		height: `100%`,
   		top: '0',
   		display: 'flex',
   		flexDirection: 'column',
   		justifyContent: 'flex-start',
	},
	columnRight: {
		position: 'absolute',
		right: '15vw',
		//top: `calc(${ButtonSize} + 6vh)`,
   		//height: `calc(100% - (${ButtonSize} * 3 + 6vh)`,
		width: 'calc(45% - 15vw)',//'calc(60% - 30vw)',
		maxWidth: 'calc(45% - 15vw)',//'calc(60% - 30vw)',
   		overflowY: 'scroll',
   		overflowX: 'visible',

   		height: `100%`,
   		top: '0',
   		display: 'flex',
   		flexDirection: 'column',
   		justifyContent: 'flex-start',
	},
	sprintCenterer: {
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center',
		//height: '100%',
	},
	sprintRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	navBar: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		position: 'absolute',
		bottom: '0.5em',
	},
	arrowButton: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	noSpaces: {
		margin: '0',
		padding: '0',
	},
	arrowButtonLabel: {
		lineHeight: ButtonSize,
		color: Colors.primary,
		fontSize: PresentationText,
	},
	bullets: {
		marginBottom: '0.1em',
		marginTop: '0.1em',
		paddingBottom: '0',
		paddingTop: '0',
	}, 
	sprintCard: {
		background: Colors.background_1+'33',
		border: `${SeparatorWidth} solid #00000044`, 
		borderRadius: '1em',
		padding: '1em'
	},
	activitiesElement: {
		padding: '0',
		marginLeft: '0',
		marginRight: '0',
		marginTop: '0.2em',
		marginBottom: '0.2em',
	},
}

export { Presentation };