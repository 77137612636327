import React, { Component } from 'react';
import { Colors, FontName, MainFontSize, SubHeadersSize, GetLabel } from '../constants';
import { InputField, Label } from './';


class ColorEditor extends Component {

	renderPreview() {
		return <div style={{...css.preview, ...{background: this.props.color}}}/>;
	}

	colorChanged(newValue) {
		let secondPart = newValue.substr(1);
		secondPart = secondPart.replace(/([^0-9abcdef])/g, '');
		newValue = '#'+secondPart;
		this.props.onChange(newValue);
	}

	render() {
		let style = {
			borderColor: this.props.color,
			borderLeft: '1.5rem solid '+this.props.color,
			width: 'initial',
			paddingLeft: '0.8rem',
			paddingRight: '0px',
			letterSpacing: '4px',
			outline: 'none',
		}
		return (
			<div style={css.container}>
				{/*this.renderPreview()*/}
				{/*<Label text={GetLabel(this.props.name)}/>*/}
				<InputField 
					maxlength={"9"}
					inputStyle={style}
					onChange={ (newValue) => {this.colorChanged(newValue)} }
					placeholder={'#ffffff'} value={this.props.color}/>
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		fledDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	preview: {
		borderTopLeftRadius: '0.2rem',
		borderBottomLeftRadius: '0.2rem',
		width: '1.5rem',
		height: '1.5rem',
	}
}

export { ColorEditor };
