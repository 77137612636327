import React, { Component } from 'react';
import { Colors, SubHeadersSize, IsNullOrEmpty, GetLabel } from '../constants';
import { Image, Label, Button } from './';

class Expansible extends Component{

	state = {
		closed: true,
		childContainerHeight: -1,
	}

	constructor(props){
		super(props);
	}

	componentDidMount() {
		if(this.state.childContainerHeight === -1) {
			this.setState({childContainerHeight: this.measurer.clientHeight});
			this.measurer.style.display = 'none';
		}
	}

	componentDidUpdate() {
		var childCount = React.Children.count(this.props.children);

		if(childCount > 0 && this.measurer.clientHeight === 0)
			return;

		if(this.state.childContainerHeight !== this.measurer.clientHeight) {
			this.setState({childContainerHeight: this.measurer.clientHeight});
			this.measurer.style.display = 'none';
		}
	}

	toggleDropdown() {
		this.setState({
			selectorOptionsClass: (this.state.closed) ? 'expand' : 'contract',
			closed: !this.state.closed
		});
	}

	getArrowStyleFromState() {
		if(this.state.closed)
			return {};
		else
			return {
				MozTransform: 'scale(-1, -1)',
			    OTransform: 'scale(-1, -1)',
			    WebkitTransform: 'scale(-1, -1)',
			    transform: 'scale(-1, -1)',
			};
	}

	
	renderChildren() {
		let color = (this.props.color) ? this.props.color : Colors.background_2;

		let optionsContainer = {
			transition: 'height 0.3s',
			height: '0',
			borderLeft: `2px solid ${color}`,
			borderRight: `2px solid ${color}`,
			borderBottom: `2px solid ${color}`,
			background: `${color}22`,
			borderRadius: '0 0 0.5em 0.5em',
			marginTop: `calc(-1em - 2px)`,
			overflow: 'hidden',
			position: 'relative',

			//NEW
			//transition: 'max-height 1s ease-in-out',
			//maxHeight: '0',
			//height: 'auto',

		}

		var childCount = React.Children.count(this.props.children);

		let expandOrContract = (this.state.closed) ? {
			height: '0'
			//maxHeight: '0'
		} : {
			height: `${this.state.childContainerHeight}px`
			//height: `calc(${this.selectorOptionsHeight} * ${childCount} + 2px * ${childCount - 1})`
			//maxHeight: '500px'
		};

		return (
			<div style={{...optionsContainer, ...expandOrContract}}>
			{
				React.Children.map(this.props.children, (child, i) => {
					if(child === undefined || child === null)
						return undefined;

					return (
						<div style={{paddingBottom: '0', paddingTop: '0'}} key={i}>
							{ child }
						</div>
					);
				})
			}
			</div>
		);
	}

	renderMeasurer() {
		let optionsContainer = {
			height: 'auto',
			// position: 'absolute', 
			// visibility: 'hidden', 
			pointerEvents: 'none',
			// opacity: '0',
			background: 'red',
			display: 'block',
			// left: '-100vw', 
			// top: '-100vh'
		}
		return (
			<div style={ optionsContainer } ref={(measurer) => {this.measurer = measurer}}>
			{
				React.Children.map(this.props.children, (child, i) => {
					if(child === undefined || child === null)
						return undefined;

					return (
						<div style={{height: '100%', paddingBottom: '0', paddingTop: '0', background: 'red'}} key={i}>
							{ child }
						</div>
					);
				})
			}
			</div>
		);
	}

	onMouseHover() {
		if(this.props.editable)
			this.showFollower();
	}

	hideFollower(){
		document.getElementById('follower').style.display = 'none';
	}

	showFollower(){
		document.getElementById('follower').style.display = 'block';
	}

	renderArrow(color) {
		return (
			<Image 
				className={'buttonLarge'}
				src={'img/sys/arrow.png'} width={'1em'} height={'1em'} 
				color={Colors.primary} 
				style={{...css.icon, ...this.getArrowStyleFromState()}} />
		);
	}

	render() {

		let styleFromProps = {};
		let color = Colors.background_2;
		let onClick = ()=>{this.toggleDropdown()};

		if(this.props.color) {
			color = this.props.color;
			styleFromProps = {
				border: `2px solid ${color}`
			}
		}

		return (
			<div>
				<Button 
					renderAnyway={true}
					glowInHover={color}
					buttonStyle={{...css.button, ...this.props.style, ...styleFromProps}} 
					className={'button'} 
					event={{id: this.props.eventId, project: this.props.project, onClick: () => {
						onClick();
						this.hideFollower();
					}}}
					//onClick={() => {onClick(); this.hideFollower();}} 
					onMouseOver={()=>this.onMouseHover()} onMouseOut={()=>this.hideFollower()}>
					<Label text={this.props.title} style={css.labelContainer} 
						labelStyle={css.labelStyle}/>
					{ this.renderArrow(color) }
				</Button>
				{ this.renderMeasurer() }
				{ this.renderChildren() }
			</div>
		);
	}
}

const css = {
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: '0',
		background: Colors.background_2+'50',
		border: `2px solid ${Colors.background_2}`,
		//borderRadius: '0.5em',
		borderRadius: '0.5em 0.5em 0 0',
		paddingTop: '1em',
		paddingBottom: '1em',
		paddingLeft: '2em',
		paddingRight: '2em',
		color: Colors.primary,
		fontSize: '1rem',
		letterSpacing: '2px',
		marginTop: '1em',
		marginBottom: '1em',
		width: '100%',
		//maxWidth: 'calc(70vw + 4em)',
	},
	labelContainer: {
		margin: '0',
		padding: '0',
		justifyContent: 'flex-start',
	},
	icon: {
		//position: 'absolute',
		marginTop: `0.2em`,
		//marginLeft: `calc(${ButtonSize} / -2.8)`,
	},
	labelStyle: {
		color: Colors.primary,
		fontSize: SubHeadersSize,
	},
	eventIdContainer: {
		display: 'block',
		position: 'absolute',
		left: '0',
		right: '0',
		padding: '0',
		marginTop: '-0.3em',
	},
}

export { Expansible };
