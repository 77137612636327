import React, { Component } from 'react';
import { Colors, ButtonSize, IsNullOrEmpty, GetLabel, AmIAllowedToExecuteThis } from '../constants';
import { Label, Button, Image, InputField, DropDown } from './';

class ListEditor extends Component {

	state = {
		newItem: ''
	}

	/*editItemFromList(index, newValue) {
		if(this.props.onModify) {
			let newList = this.props.list.slice(0);
			newList[index] = newValue;
			this.props.onModify(newList);
		}
	}*/

	deleteItemFromList(index) {
		if(this.props.onModify) {
			let newList = this.props.list.slice(0);
			newList.splice(index, 1);
			this.props.onModify(newList);
		}
	}

	addItemToList(newItem) {
		if(this.props.onModify) {
			let newList = IsNullOrEmpty(this.props.list) ? [] : this.props.list.slice(0);
			newList.push(newItem);
			this.props.onModify(newList);
			this.setState({newItem: ''});
		}
	}

	renderAddedItem(item, index) {

		let label = (this.props.isObject) ? item.name : GetLabel(item);

		return (
			<div style={css.listContainer} key={index}>
				<Label text={label} style={{width: '100%', marginLeft: '0'}} labelStyle={{...{color: Colors.primary}, ...this.props.itemStyle}}/>
				<div style={css.buttonsContainer}>
					{/*<Button event={{id: this.props.eventId, onClick: ()=>this.editItemFromList(index, item)}}>
						<Image src={'img/sys/edit.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
					</Button>*/}
					<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.deleteItemFromList(index)}}>
						<Image src={'img/sys/delete.png'} color={Colors.red} height={ButtonSize} width={ButtonSize}/>
					</Button>
				</div>
			</div>
		);
	}

	renderInputArea() {
		if(!AmIAllowedToExecuteThis(this.props.eventId, this.props.project))
			return <div/>;

		if(!Array.isArray(this.props.options))
			return (
				<InputField onChange={ (newValue) => {this.setState({ newItem: newValue })} }
					placeholder={this.props.placeholder} value={this.state.newItem}/>
			);
		else {
			if(IsNullOrEmpty(this.props.options))
				return <div/>;

			return (
				<DropDown 
					containerStyle={{width: '100%'}}
					value={this.state.newItem} 
					concatenatedString={''}//global.events[this.state.eventIdBeingEdited].id+
					options={this.props.options}
					editable={true}
					placeholder={this.props.placeholder}
					event={{
						id: this.props.eventId, 
						project: this.props.project,
						onClick: (newItem) => { this.setState({newItem: newItem}); this.addItemToList(newItem); }
					}}/>
			);
		}
	}

	renderAddButtonIfNeeded() {
		if(IsNullOrEmpty(this.state.newItem))
			return <div />;
		else
			return (
				<Button event={{id: this.props.eventId, project: this.props.project, onClick: ()=>this.addItemToList(this.state.newItem)}}>
					<Image src={'img/sys/add.png'} color={Colors.primary} height={ButtonSize} width={ButtonSize}/>
				</Button>
			);
	}

	render() {
		let list = (this.props.list === undefined || this.props.list === null) ? [] : this.props.list;
		return (
			<div style={{...css.container, ...this.props.style}}>
				{
					this.props.addButtonAbove ? 
					(
						<div style={css.adderSectionContainer}>
							{ this.renderInputArea() }
							{ this.renderAddButtonIfNeeded() }
						</div>
					) : undefined
				}
				{ 
					list.map((item, index) => {
						return this.renderAddedItem(item, index)
					}) 
				}
				{
					this.props.addButtonAbove ? 
					undefined :
					(
						<div style={css.adderSectionContainer}>
							{ this.renderInputArea() }
							{ this.renderAddButtonIfNeeded() }
						</div>
					)
				}
			</div>
		);
	}
}

const css = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: '100%',
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	}, 
	adderSectionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
	}
}

export { ListEditor };