import React, { Component } from 'react';
import { Colors } from '../constants';

class ScreenOverlay extends Component{

	state = {
		visible: false
	}

	show(visible) {
		this.setState({visible: visible});
	}

	render() {
		let { visible } = this.state;
		let stateStyle = (visible) ? { visibility: 'visible', opacity: '1', zIndex: '5' } : { opacity: '0', visibility: 'visible', zIndex: '0' };
		return (
			<button style={{...css.container, ...stateStyle}} onClick={this.props.onClick}/>
		);
	}
}

const css = {
	container: {
		width: '100%',
		height: '100%',
	    position: 'fixed',
	    top: '0',
	    background: '#00000099',
	    border: 'none',
	    visibility: 'hidden',
	    opacity: '0',
	    transition: 'opacity 0.2s',
		WebkitTransition: 'opacity 0.2s',
		zIndex: '0'
	},
}

export { ScreenOverlay };
