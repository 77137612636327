import React, { Component } from 'react';
import { Colors, GetLabel, HeadersSize } from '../constants';
import { LoadingScreen, Subheader, Div, Loading } from '../components';
import '../Animations.css';
import * as firebase from 'firebase';
import { Login } from './';

class Maintenance extends Component {

	state = {
		
	}

	componentDidMount() {
		firebase.auth().signOut();
	}

	render() {
		return (
			<div style={css.container}>
				<Login onLoginSuccess={(user)=> this.props.onLoginSuccess(user)} style={css.hiddenLogin}/>
				<Div style={{overflow: 'hidden',}}>
					<Subheader 
						style={css.subheader} 
						labelStyle={css.labelStyle} 
						text={GetLabel('maintenanceInProgress')}>
						<Loading />
					</Subheader>
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	hiddenLogin: {
		zIndex: 10,
		background: Colors.background_1,
		display: 'none',
	},
	subheader: {
		justifyContent: 'center',
		height: 'calc(100% - 7em)',
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		padding: '0',
		margin: '0',
	},
	labelStyle: {
		textAlign: 'center',
		fontSize: HeadersSize,
		lineHeight: '2em',
	}
}

export { Maintenance };