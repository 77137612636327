import React, { Component } from 'react';
import { Colors, GetLabel, SeparatorWidth, SeparatorWidthSmall, ButtonSize, 
	MenuButtons, IsMobileDevice, AmIAllowedToExecuteThis } from '../constants';
import { Image, Button, ScreenOverlay, ListButton, Profile, Separator, 
	Label, Space } from './';

let menuTransitionDuration = '0.5s';

class SideMenu extends Component{

	state = {
		menuShown: false,
		/*profileInfo: {
			name: 'Victor R. Esquivel Estrada',
			image: 'https://code91.mx/images/vree/vree_alternative_alpha_light.png'
		}*/
	}

	toggleMenu() {
		this.overlay.show(!this.state.menuShown);
		menuTransitionDuration = (!this.state.menuShown) ? '0.5s' : '0.2s';
		this.setState({menuShown: !this.state.menuShown});
	}

	closeMenu() {
		this.overlay.show(false);
		menuTransitionDuration = '0.2s';
		this.setState({menuShown: false});
	}

	navigate(target) {
		this.props.navigate(target);
		this.closeMenu();
	}

	conditionalSeparators() {
		if(IsMobileDevice())
			return <div />;
		else
			return (<Separator disableGlow={true} color={Colors.background_1} style={css.blackSeparator}/>);
	}

	render() {
		let { menuShown } = this.state;

		let menuStyleFromState = (menuShown) ? { left: '0%' } : { transition: `left ${menuTransitionDuration}`, WebkitTransition: `left ${menuTransitionDuration}` };
		let buttonLeftOffset = (menuShown) ? { opacity: '0' } : {};

		return (
			<div style={css.container}>
				<ScreenOverlay onClick={()=>{this.closeMenu()}} ref={(overlay) => {this.overlay = overlay;}}/>
				
				<div style={{...css.menu, ...menuStyleFromState}}>
					<div style={css.verticalSeparator}/>
					<Profile image={ global.profile.image } labelStyle={{maxWidth: '40vw'}} name={ global.profile.name } disableHover={true}
						event={{id: 'event_00000', onClick: ()=>this.navigate('profile')}}/>
					<Separator />
					<div style={css.buttonsList} className={'verticalScroll'}>
						<div style={{...css.diagonals_1, ...css.pattern}}/>
						<div style={{...css.diagonals_2, ...css.pattern}}/>
						<div style={{...css.yellow, ...css.pattern}}/>

						<ListButton event={{id: 'event_00000', onClick: ()=>{this.navigate('portfolio')}}} style={css.listButton}>
							<Image src={'img/sys/stage.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('projects') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ this.conditionalSeparators() }
						
						<ListButton event={{id: 'event_00000', onClick: ()=>{this.navigate('kpis')}}} style={css.listButton}>
							<Image src={'img/sys/kpis.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('kpis') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ this.conditionalSeparators() }
						
						<ListButton event={{id: 'event_00000', onClick: ()=>{this.navigate('toDo')}}} style={css.listButton}>
							<Image src={'img/sys/toDo.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('toDo') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ this.conditionalSeparators() }

						<ListButton event={{id: 'event_00000', onClick: ()=>{this.navigate('retrospectivesFactories')}}} style={css.listButton}>
							<Image src={'img/sys/calendar.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('retrospectivesFactories') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ this.conditionalSeparators() }

						<ListButton event={{id: 'event_00000', onClick: ()=>{this.navigate('retrospectivesCapabilities')}}} style={css.listButton}>
							<Image src={'img/sys/calendar.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('retrospectivesCapabilities') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ this.conditionalSeparators() }

						<ListButton event={{id: 'event_00028', onClick: ()=>{this.navigate('citadelle')}}} style={css.listButton}>
							<Image src={'img/sys/citadelle.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('citadelle') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ AmIAllowedToExecuteThis('event_00028') ? this.conditionalSeparators() : undefined }

						<ListButton event={{id: 'event_00033', onClick: ()=>{this.navigate('pendings')}}} style={css.listButton}>
							<Image src={'img/sys/toDo.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('pendings') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						{ AmIAllowedToExecuteThis('event_00033') ? this.conditionalSeparators() : undefined }
						
						<ListButton event={{id: 'event_00000', onClick: ()=>{this.navigate('signOut')}}} style={css.listButton}>
							<Image src={'img/sys/user.png'} width={ButtonSize} height={ButtonSize} color={Colors.background_2} style={css.icon} />
							<Label text={ GetLabel('signOut') } style={{marginLeft: '1em'}} labelStyle={css.options}/>
						</ListButton>
						<Space /><Space />
					</div>
				</div>
				
				<Button 
					event={ {id: 'event_00000', onClick:()=>this.toggleMenu()} } 
					style={ {...css.menuButton, ...buttonLeftOffset} }>
					<Image src={'img/sys/menu.png'} width={ButtonSize} height={ButtonSize} color={Colors.primary}/>
				</Button>
				
			</div>
		);
	}
}

const css = {
	container: {
		
	},
	disabled: {
		backgroundColor: Colors.lightGrey, 
	},
	listButton: {
		marginTop: '1em', 
		marginBottom: '1em',
		position: 'relative',
		minHeight: '4em',
	},
	options: {
		color: Colors.background_2, 
		fontSize: MenuButtons, 
		fontWeight: '600', 
		letterSpacing: '2px',
	},
	verticalSeparator: {
		width: SeparatorWidth,
		position: 'absolute',
		right: '0',
		height: '100%',
		background: Colors.primary,
    	boxShadow: `${Colors.primary} 0px 0px 20px`,
	},
	blackSeparator: {
		height: SeparatorWidthSmall,
		opacity: '0.5', 
		width: '95%',
	},
	buttonsList: {
		background: `linear-gradient(${Colors.primary}00 0%, ${Colors.background_2} 95%)`,
		width: '100%',
		height: '100%',
		display: 'block',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
	yellow: {
		background: `linear-gradient(${Colors.primary} 10%, ${Colors.primary}55 100%)`,
		opacity: '0.85',
	},
	diagonals_1: {
		background: `linear-gradient(-45deg,
			${Colors.buttonBackground} 25%,
			${Colors.transparent} 0,
			${Colors.transparent} 50%,
			${Colors.black} 0,
			${Colors.black} 75%,
			${Colors.transparent} 0,
			${Colors.transparent})`,
		backgroundRepeat: 'repeat',
	    backgroundSize: '3.4em 3.4em',
	    opacity: '0.25',
	},
	diagonals_2: {
		background: `linear-gradient(45deg,
			${Colors.transparent} 25%,
			${Colors.buttonBackground} 0,
			${Colors.buttonBackground} 50%,
			${Colors.transparent} 0,
			${Colors.transparent} 75%,
			${Colors.buttonBackground} 0,
			${Colors.buttonBackground})`,
		backgroundRepeat: 'repeat',
	    backgroundSize: '3.4em 3.4em',
	    opacity: '0.2',
	},
	pattern: {
	    width: '100%',
	    height: '100%',
	    position: 'absolute',
	    pointerEvents: 'none',
	},
	menu: {
		width: '40em',
		maxWidth: '90%',
		background: Colors.background_2,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		//background: `linear-gradient(-45deg, ${Colors.background_1} 0,${Colors.background_1} 65%,${Colors.background_2} 0,${Colors.background_2})`,
		position: 'fixed',
		height: '100%',
		left: '-42em',
		top: '0',
		transition: `left ${menuTransitionDuration}`,
		WebkitTransition: `left ${menuTransitionDuration}`,
		zIndex: '100',
	},
	menuButton: {
		//padding: '1.5em',
		position: 'absolute',
		height: ButtonSize,
		width: ButtonSize,
		marginLeft: '0',
		opacity: '1',
		transition: 'all 0.2s',
		WebkitTransition: 'all 0.2s',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}
}

export { SideMenu };