import React, { Component } from 'react';
import { Colors, GetLabel, GetProjectsByStage, GetAllActiveProjects, GetAllProjects, 
	HeadersSize } from '../../constants';
import { SideMenu, Header, SeparatorLarge, Label, BulletPointsList, Subheader, WideButton, 
	Div, Expansible, Space } from '../../components';

class NumeraliaSummary extends Component {

	state = {

	}

	renderExpansibleOfStage(label, stage, openAvailable) {
		return (
			<Expansible title={`${GetLabel(label)} (${GetProjectsByStage(stage).length})`} eventId={'event_00000'}>
				<BulletPointsList>
					{
						GetProjectsByStage(stage).map((project, i) => {
							return (
								<Label text={project.name} style={css.bullets} key={i}>
									{
										(openAvailable) ? 
											(
												<WideButton  text={GetLabel('see')} style={css.openProjectButton}
													event={{
														id: 'event_00000', 
														onClick: ()=> 
															this.props.innerNavigate('projectDetails', {project: {...project}})
													}}/>
											) : undefined
									}
								</Label>
							);
						})
					}
				</BulletPointsList>
			</Expansible>
		);
	}

	render() {
		return (
			<div style={{...css.container, ...this.props.style}} className={`${this.props.className}`}>
				<SideMenu navigate={this.props.navigate}/>
				<Header text={GetLabel('kpis')}/>
				<Div noVerticalPadding>
					<SeparatorLarge disableBackgroundLines/><Space />
					<Subheader text={GetLabel('projectsScore')} labelStyle={css.subheaderLabels} style={css.totals}>
						<Label labelStyle={css.subheaderLabels}
							text={GetLabel('total')+': '+GetAllProjects().length}/>
					</Subheader>
					{ this.renderExpansibleOfStage('transferred', 'transferred', true) }
					{ this.renderExpansibleOfStage('research', 'research', true) }
					{ this.renderExpansibleOfStage('closed', 'closed', true) }
					{ this.renderExpansibleOfStage('ongoing', ['pending', 'ideation', 'frame', 'understand', 'experiment', 'accelerate', 'scale'], true) }
					<Space /><Space /><Space />
					<SeparatorLarge disableBackgroundLines/><Space />
					<Subheader text={GetLabel('solutionsStatus')} labelStyle={css.subheaderLabels} style={css.totals}>
						<Label labelStyle={css.subheaderLabels}
							text={GetLabel('total')+': '+GetAllActiveProjects().length}/>
					</Subheader>
					{ this.renderExpansibleOfStage('pending', 'pending', true) }
					{ this.renderExpansibleOfStage('ideation', 'ideation', true) }
					{ this.renderExpansibleOfStage('frame', 'frame', true) }
					{ this.renderExpansibleOfStage('understand', 'understand', true) }
					{ this.renderExpansibleOfStage('experiment', 'experiment', true) }
					{ this.renderExpansibleOfStage('accelerate', 'accelerate', true) }
					{ this.renderExpansibleOfStage('scale', 'scale', true) }
					<Space /><Space /><Space />
				</Div>
			</div>
		);
	}
}

const css = {
	container: {
		color: Colors.primary,
		width: '100%',
		height: '100%',
	},
	openProjectButton: {
		position: 'absolute',
		right: '1em',
		padding: '0',
		marginLeft: '0',
		marginRight: '0',
		marginTop: '0.2em',
		marginBottom: '0.2em',
		width: '5em',
		minWidth: '5em',
		maxWidth: '5em',
	},
	subheaderLabels: {
		fontSize: HeadersSize,
		color: Colors.secondary,
		fontWeight: 'bold',
	},
	totals: {
		justifyContent: 'space-between',
		marginLeft: '0',
		marginRight: '0',
		paddingLeft: '0',
		paddingRight: '0',
	}
}

export { NumeraliaSummary };
