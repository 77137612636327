import React, { Component } from 'react';
import { Colors, IsNullOrEmpty, AmIAllowedToExecuteThis, DebugLabelsSize, IsMobileDevice } from '../constants';
import { Label } from './';

class Button extends Component {

	state = {
		hoveredStyle: {filter: `initial`}
	}

	constructor(props) {
		super(props);

		// Este enlace es necesario para hacer que `this` funcione en el callback
		this.handleClick = this.handleClick.bind(this);
	}

	renderEventId() {
		if(!global.isInDebug) 
			return undefined;
		else {
			let id = (Array.isArray(this.props.event.id)) ? this.props.event.id.join(',') : 
				(IsNullOrEmpty(this.props.event.id)) ? 'undef' : this.props.event.id.substr(8);
			return (<Label text={id} style={css.eventIdContainer} labelStyle={css.eventIdLabel}/>);
		}
	}

	onMouseHover() {
		if(IsMobileDevice())
			return;
		if(this.props.disableHover)
			return;
		if(!IsNullOrEmpty(this.props.event.id))
			this.showFollower();
		if(this.props.glowInHover)
			this.setState({hoveredStyle: {filter: `drop-shadow(0px 0px 10px ${this.props.glowInHover})`}});
	}

	onMouseOut() {
		if(this.props.disableHover)
			return;
		this.setState({hoveredStyle: {filter: `initial`}});
		this.hideFollower();
	}

	hideFollower(){
		document.getElementById('follower').style.display = 'none';
	}

	showFollower(){
		document.getElementById('follower').style.display = 'block';
	}

	handleClick(e) {
		this.props.event.onClick();
		this.hideFollower();
		global.lastActionTimer = 0;
	}

	render() {
		let className = (this.props.event) ? 'buttonSmall' : '';

		if(this.props.className !== undefined)
			className = this.props.className;

		if(this.props.disableHover)
			className = '';

		if(AmIAllowedToExecuteThis(this.props.event.id, this.props.event.project, this.props.event.retrospectivesFactories) || this.props.renderAnyway)
			return (
				<div style={{...this.props.style, ...this.state.hoveredStyle}} className={className} id={this.props.id}>
					<button style={{...css.button, ...this.props.buttonStyle}} 
						onClick={this.handleClick} 
						onMouseOver={()=>this.onMouseHover()} onMouseOut={()=>this.onMouseOut()}>
						{ this.props.children }
						{ this.renderEventId() }
					</button>
				</div>
			);
		else if(this.props.renderBlockedIfNotAllowedToExecuteEvent)
			return (
				<div style={{...this.props.style, ...this.state.hoveredStyle}} className={className} id={this.props.id}>
					<button style={{...css.button, ...this.props.buttonStyle}} 
						disabled
						onClick={()=>{}} 
						onMouseOver={()=>this.onMouseHover()} onMouseOut={()=>this.onMouseOut()}>
						{ this.props.children }
						{ this.renderEventId() }
					</button>
				</div>
			);
		else
			return <div />;
	}
}

const css = {
	button: {
		margin: '0',
		backgroundColor: 'transparent',
		border: `0px solid transparent`,
		width: '100%',
		height: '100%',
	},
	eventIdContainer: {
		display: 'block',
		position: 'absolute',
		padding: '0',
		marginTop: '-1em',
		marginLeft: '1em',
	},
	eventIdLabel: {
		color: Colors.debugLabels,
		fontSize: DebugLabelsSize,
	}
}

export { Button };
